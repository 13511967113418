/** @format */

import ACTION_TYPES from "./actionTypes";

const { GET_SELECTED_MOVIE_DATA_FOR_PLAYER } = ACTION_TYPES;

/** Platform List */
export const getMovieDataForPlayer = (payload) => ({
  type: GET_SELECTED_MOVIE_DATA_FOR_PLAYER,
  payload,
});
