/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_DEFAULT_PLATFORMS_LIST_START,
  GET_DEFAULT_PLATFORMS_LIST_SUCCESS,
  GET_DEFAULT_PLATFORMS_LIST_FAILED,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED,
} = ACTION_TYPES;

const initialState = {
  Defaultplatforms: [],
  isLoading: false,
  errors: "",
  DefaultplatformsforRoute: [],
};

export default (state = initialState, { type, payload }) => {
  let isLoading = "false";
  let errors = "";
  switch (type) {
    case GET_DEFAULT_PLATFORMS_LIST_START:
      return {
        ...state,

        isLoading: true,
        errors,
      };
    case GET_DEFAULT_PLATFORMS_LIST_SUCCESS:
      return {
        ...state,
        Defaultplatforms: payload,
        isLoading,
        errors,
      };

    case GET_DEFAULT_PLATFORMS_LIST_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    case GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START:
      return {
        ...state,
      };
    case GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS:
      return {
        ...state,
        DefaultplatformsforRoute: payload?.data,
      };

    case GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
