/** @format */

import { createSelector } from "reselect";
const getLiveVideoUrl = (state) => state.videoReducer.liveVideoUrl;
const getMovieVideoUrl = (state) => state.videoReducer.movieVideoUrl;
const getTimeStamp = (state) => state.videoReducer.timeStamp;
const getEpgVideoUrl = (state) => {
  return state.videoReducer.epgVideoUrl;
};
const getIsCatchupRequired = (state) => {
  return state.videoReducer.isDrmCatchupRequired;
};
const getLiveApiData = (state) => {
  return state.videoReducer.liveApiData;
};

const getLiveChannelData = (state) => {
  return state.videoReducer.liveChannelData;
};

const getMovieData = (state) => {
  return state.videoReducer.movieData;
};
const getSeriesData = (state) => {
  return state.videoReducer.seriesData;
};

const getGenreChannelData = (state) => {
  return state.videoReducer.genreChannels;
};
export const getIsCatchupRequiredSelector = createSelector(
  getIsCatchupRequired,
  (isDrmCatchupRequired) => isDrmCatchupRequired
);

export const getEpgVideoUrlSelector = createSelector(
  getEpgVideoUrl,
  (epgVideoUrl) => epgVideoUrl
);
export const getLiveVideoUrlSelector = createSelector(
  getLiveVideoUrl,
  (liveVideoUrl) => liveVideoUrl
);

export const getMovieVideoUrlSelector = createSelector(
  getMovieVideoUrl,
  (movieVideoUrl) => movieVideoUrl
);

export const getTimeStampSelector = createSelector(
  getTimeStamp,
  (timeStamp) => timeStamp
);

export const getLiveApiDataSelector = createSelector(
  getLiveApiData,
  (liveApiData) => liveApiData
);

export const getLiveChannelDataSelector = createSelector(
  getLiveChannelData,
  (liveChannelData) => liveChannelData
);

export const getGenreChannelDataSelector = createSelector(
  getGenreChannelData,
  (genreChannels) => genreChannels
);

export const getMovieDataSelector = createSelector(
  getMovieData,
  (movieData) => movieData
);
export const getSeriesDataSelector = createSelector(
  getSeriesData,
  (seriesData) => seriesData
);
