import videojs from "video.js";
import "./moviePlugin.css";
import "videojs-contrib-eme";
import $ from "jquery";
import "videojs-hotkeys"; // Import videojs-hotkeys

import { types } from "react-alert";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const moviePlugin = function(playlistItems, video_index = 0) {
  const player = this;

  // const history = useHistory();
  let playerTitle,
    skipButton,
    postercloseButton,
    posteradimg,
    posteradContainer,
    playlistBackButton,
    skipContainer,
    skipThumbnail,
    skipThumbnailImg,
    imgsrc,
    playerIcons;

  let selectedIndex = null;
  let adPlaying = false;
  let adConfig = []; // To store ad configuration
  let skipconfig = [];
  let mainVideoTime;
  let playedAds = new Set();
  let posterads = false;
  let posterclosebtn = false;
  let currentAdId = null; // Variable to store the current ad ID

  function initialize() {
    const playerEl = player.el();
    if (!playerEl) {
      console.error("Player element not found.");
      return;
    }

    // Add player icons
    playerIcons = document.createElement("div");
    playerIcons.className = "vjs-icons";
    playerIcons.style.display = "block";
    player.el().appendChild(playerIcons);

    //create player back button
    playlistBackButton = document.createElement("div");
    playlistBackButton.className = "vjs-playerback-button";
    playlistBackButton.innerHTML = ` <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="white"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>`;

    playlistBackButton.style.display = "flex";
    playlistBackButton.addEventListener("click", goBack);

    playerIcons.appendChild(playlistBackButton);

    // player.el().appendChild(playlistButton);
    //playerEl.appendChild(playlistButton);
    playerEl.appendChild(playerIcons);

    // Add player episode title
    playerTitle = document.createElement("div");
    playerTitle.className = "vjs-playerTitle";
    playerTitle.textContent = playlistItems[0].title;
    playerTitle.style.display = "flex";
    player.el().appendChild(playerTitle);

    // Create skip button for ads
    skipButton = document.createElement("button");
    // skipButton.className = "vjs-skip-button";

    skipButton.className = "vjs-skip-ad-button";
    skipButton.innerHTML = "Skip Ad";
    imgsrc = playlistItems[0].poster;

    skipButton.style.display = "none";
    skipButton.addEventListener("click", skipAd);
    player.el().appendChild(skipButton);

    // poster container baby
    posteradContainer = document.createElement("div");
    posteradContainer.className = "vjs-posterAd-container";
    posteradContainer.style.display = "none";

    posteradimg = document.createElement("img");
    posteradimg.className = "vjs-posterAd";
    // posteradimg.src = src;

    postercloseButton = document.createElement("button");
    postercloseButton.className = "vjs-posterads-close-button";
    postercloseButton.innerHTML = "&times;";
    postercloseButton.style.display = "none";

    posteradContainer.appendChild(posteradimg);
    posteradContainer.appendChild(postercloseButton);
    player.el().appendChild(posteradContainer);

    // Create skip button for ads
    skipContainer = document.createElement("div");
    skipContainer.className = "skip-ad-container";
    skipContainer.style.display = "none";

    skipButton = document.createElement("div");
    skipButton.className = "skip-ad-button";

    skipThumbnail = document.createElement("div");
    skipThumbnail.className = "skip-ad-thumbnail";
    skipThumbnailImg = document.createElement("img");
    skipThumbnailImg.className = "skip-ad-thumbnail-img";
    skipContainer.appendChild(skipButton);
    skipThumbnail.appendChild(skipThumbnailImg);
    skipContainer.appendChild(skipThumbnail);
    player.el().appendChild(skipContainer);

    player.on("useractive", () => {
      if (!adPlaying) {
        // playlistButton.style.display = "flex";
        // playlistContainer.style.display = "blcok";
        playerTitle.style.display = "flex";
        playerIcons.style.display = "block";
      }
    });

    // player.on("userinactive", () => {
    //   playerTitle.style.display = "none";
    //   playerIcons.style.display = "none";
    // });
    // Attach the event listener initially
    player.on("userinactive", handleUserInactive);

    // Initialize the hover snapshot functionality

    playVideo(video_index);
  }

  const handleUserInactive = () => {
    playerTitle.style.display = "none";
    playerIcons.style.display = "none";
  };

  initialize();
  player.on("error", errordisplay);
  function errordisplay() {
    if (adPlaying) {
      onAdEnded();
    } else {
      var modalDialogs = document.querySelectorAll(
        " .video-js .vjs-modal-dialog"
      );
      modalDialogs.forEach(function(dialog) {
        dialog.style.position = "absolute";
        dialog.style.top = "0";
        dialog.style.left = "0";
        dialog.style.width = "100%";
        dialog.style.height = "100%";
        dialog.style.zIndex = "1";
      });
    }
  }

  document.addEventListener("keydown", function(event) {
    if (event.code === "ArrowRight") {
      // Seek forward 10 seconds
      seekVideo(10);
    } else if (event.code === "ArrowLeft") {
      // Seek backward 10 seconds
      seekVideo(-10);
    } else if (event.code === "ArrowUp") {
      // Increase volume
      changeVolume(0.1);
    } else if (event.code === "ArrowDown") {
      // Decrease volume
      changeVolume(-0.1);
    }
  });

  function seekVideo(seconds) {
    if (!adPlaying && player.currentTime() != null) {
      let newTime = player.currentTime() + seconds;
      if (newTime < 0) newTime = 0; // Ensure time doesn't go below 0
      if (newTime > player.duration()) newTime = player.duration(); // Ensure time doesn't exceed duration
      player.ready(() => {
        player?.currentTime(newTime);
      });
    }
  }

  function changeVolume(amount) {
    if (player.volume() != null) {
      player.volume(player.volume() + amount);
    }
  }

  function playVideo(index, startTime) {
    // //console.log("lastitem"+JSON.stringify(lastItem.cw_duration))
    if (index === selectedIndex) {
      //console.log("aleready selected baby");
    } else {
      posterclosebtn = false;

      //console.log("cccc__Wwww", startTime);
      if (
        playlistItems[index] &&
        playlistItems[index].sources &&
        playlistItems[index].sources.length > 0
      ) {
        var selectedItem = playlistItems[index];
        const videoSrc = playlistItems[index].sources[0].src;
        const keySystems = playlistItems[index].sources[0].keySystems;
        const startTime = playlistItems[index].cw_duration;

        if (keySystems) {
          player.ready(() => {
            player.src({
              src: videoSrc,
              type: getVideoType(videoSrc),
              keySystems: keySystems,
            });
          });
          player.one("loadedmetadata", () => {
            player.currentTime(startTime);
            player.play();
            showAdIndicators(adConfig);
          });
          //console.log("mamu__Wwww", startTime);
        } else {
          player.one("loadedmetadata", () => {
            player.currentTime(startTime);
            player.play();
          });
        }

        // Get the adConfig array from the current playlist item
        //  adConfig = playlistItems[index].adConfig || [];

        const cwDuration = parseFloat(selectedItem.cw_duration);

        // Filter and assign the adConfig array to include only ads with time greater than or equal to cw_duration
        adConfig = (playlistItems[index].adConfig || []).filter(
          (ad) => Object.keys(ad).length !== 0
        ); // Remove empty objects

        if (cwDuration && cwDuration !== undefined) {
          //console.log("before");
          adConfig = adConfig.filter((ad) => ad.time >= cwDuration);
        }

        //console.log("Filtered adConfig: " + JSON.stringify(adConfig));

        selectedIndex = index;

        //console.log("adconfig................................", adConfig);
        //console.log("skipconfig................................", skipconfig);
        player.on("timeupdate", checkAdTime);
        player.on("timeupdate", checkPosterAd);

        const posterAdConfig = playlistItems[index].PosteradConfig;
        if (posterads) {
          posteradContainer.style.display = "none";
        }

        // if (posterAdConfig && posterAdConfig.length > 0) {
        // const showPosterAd = function() {
        //   if (player.currentTime() >= posterAdConfig[0].time) {
        //     player.off("timeupdate", showPosterAd);
        //     displayPosterAd(
        //       posterAdConfig[0].src,
        //       posterAdConfig[0].closeButtonInMilisec,
        //       posterAdConfig[0].id
        //     );
        //   }
        // };
        //   player.on("timeupdate", checkPosterAd);
        // }
      } else {
        console.error(`No valid source found for item at index ${index}`);
      }
    }
  }
  function getVideoType(videoSrc) {
    let videoType = "";

    if (videoSrc) {
      if (videoSrc.endsWith(".mp4")) {
        videoType = "video/mp4";
      } else if (videoSrc.endsWith(".m3u8")) {
        videoType = "application/x-mpegURL";
      } else if (videoSrc.endsWith(".mpd")) {
        videoType = "application/dash+xml";
      }
    }

    return videoType;
  }

  function checkAdTime() {
    if (adConfig.length > 0) {
      //console.log("checkaddddddddddddddddddddd", adConfig.length);
      if (!adPlaying && Array.isArray(adConfig)) {
        adConfig.forEach((ad, index) => {
          //console.log("ddddddddd", ad);
          if (
            ad &&
            typeof ad.time === "number" &&
            typeof ad.src === "string" &&
            player.currentTime() > ad.time
          ) {
            // && !playedAds.has(ad.src)) {
            mainVideoTime = player.currentTime();
            //console.log("inside ad baby");
            player.pause();
            skipconfig.push(ad.skip);

            playAd(ad.src, ad.skip, ad.id);

            //console.log("ad.skip......", skipconfig);

            playedAds.add(ad.src);
            adConfig.splice(index, 1);
          }
        });
      }
    } else {
      player.off("timeupdate", checkAdTime);
    }
  }

  function playAd(adSrc, skiptime, adId) {
    // player.el().classList.add("vjs-ad-playing");
    currentAdId = adId;
    player.el().classList.add("vjs-ad-playing");

    //console.log("playing_ad_src_baby", adSrc);
    adPlaying = true;
    player.currentTime(0);

    player.src({
      src: adSrc,
      type: getVideoType(adSrc),
    });

    player.play();

    // player.controlBar.show();
    player.off("timeupdate", checkPosterAd);
    player.on("timeupdate", comeskip);

    // player.on("ended", () => {
    // const replayButtonStyle = document.createElement("style");
    // document.head.appendChild(replayButtonStyle);
    // replayButtonStyle.textContent =
    //   ".vjs-replay-button { display: block !important; }";
    // replayButtonStyle.style.display = "block";
    // });

    if (posterads) {
      hidePosterAd();
    } // Hide poster ad when ad starts playing

    const progressControl = player.controlBar.getChild("progressControl");
    if (progressControl) {
      progressControl.disable();
    }

    // const seekButtonStyle = document.createElement("style");

    // document.head.appendChild(seekButtonStyle);
    // seekButtonStyle.textContent =
    //   ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
    // Add adTextDiv to the control bar

    const adTextDiv = player.controlBar.addChild("component", {}, 1);
    adTextDiv.el().innerHTML =
      '<div id="ad-text" style="display:none; margin-top:12px; background: linear-gradient(to right, rgb(222 218 172), rgb(186 166 43)); -webkit-background-clip: text; -webkit-text-fill-color: white; font-size: 16px; padding-left: 10px;">Advertisement</div>';
    document.getElementById("ad-text").style.display = "block";

    // skipButton.style.display = "block";
    // player.off("ended", playNextVideo);
    turnOffUserInactiveListener();

    player.off("timeupdate", checkAdTime);
    // player.one("ended", onAdEnded);
    player.one("ended", skipAd);
    const adplaying = new CustomEvent("adplaying", {
      detail: {
        id: adId,
      },
    });

    player.el().dispatchEvent(adplaying);
  }

  function comeskip() {
    let a = skipconfig[0];
    //console.log("appu", a);
    if (skipconfig[0] !== undefined && player.duration() - a > 5) {
      //console.log("baby inside come skip");
      let skipenable = 5;
      let previousTime = Math.floor(player.currentTime()); // Initialize with the player's current time in seconds

      function onTimeUpdate() {
        //console.log("baby inside skip timer");

        const currentTime = Math.floor(player.currentTime()); // Get the current time in seconds

        // Check if one second has passed since the last update
        if (currentTime !== previousTime) {
          skipContainer.style.display = "flex";

          previousTime = currentTime;

          skipThumbnailImg.src = playlistItems[selectedIndex].poster;
          skipButton.innerHTML = `${skipenable}`;
          skipenable -= 1;
          if (skipenable < 0) {
            skipContainer.disabled = false;
            skipContainer.removeChild(skipThumbnail);
            skipContainer.classList.add("skip-ad-container-after");
            skipButton.style.maxWidth = "100%";
            skipButton.addEventListener("click", skipAd);
            skipButton.innerHTML = `
            <span class="skip-ad-text">Skip Ad</span>
                <span class="skip-ad-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="white">
                        <path fill-rule="white" clip-rule="evenodd" d="M2.538 4.113a1 1 0 0 1 1.035.068l10 7a1 1 0 0 1 0 1.638l-10 7A1 1 0 0 1 2 19V5a1 1 0 0 1 .538-.887ZM16 5.8A1.8 1.8 0 0 1 17.8 4h1.4A1.8 1.8 0 0 1 21 5.8v12.4a1.8 1.8 0 0 1-1.8 1.8h-1.4a1.8 1.8 0 0 1-1.8-1.8V5.8Z" fill="white"/>
                    </svg>
                </span>`;
            // Remove the event listener once the countdown reaches 0
            player.off("timeupdate", onTimeUpdate);
          }
        }
      }

      // Check the current time to start the countdown
      if (player.currentTime() > skipconfig[0]) {
        skipButton.disabled = true; // Disable initially

        // Start or resume the countdown timer
        // onTimeUpdate();
        player.on("timeupdate", onTimeUpdate);

        player.off("timeupdate", comeskip); // Remove comeskip as listener
        skipconfig.shift(); // Remove processed item from skipconfig array
      }
    } else {
      player.off("timeupdate", comeskip);
      skipconfig.shift();
    }
  }

  function onAdEnded() {
    // alert("poster_ads" + JSON.stringify(posterads));
    player.controlBar.removeChild("component", {}, 1);

    skipContainer.appendChild(skipThumbnail);
    skipButton.removeEventListener("click", skipAd);
    skipContainer.style.display = "none";
    skipContainer.classList.remove("skip-ad-container-after");
    skipButton.style.maxWidth = "30%";
    adPlaying = false;
    // const seekButtonStyle = document.createElement("style");

    // document.head.appendChild(seekButtonStyle);
    // seekButtonStyle.textContent =
    //   ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
    player.controlBar.show();
    const progressControl = player.controlBar.getChild("progressControl");
    if (progressControl) {
      progressControl.enable();
    }

    // showPosterAd(); // Show poster ad after ad ends
    if (posterads) {
      showPosterAd();
      // displayPosterAd(
      //   playlistItems[selectedIndex].PosteradConfig[0].src,
      //   playlistItems[selectedIndex].PosteradConfig[0].closeButtonInMilisec,
      //   playlistItems[selectedIndex].PosteradConfig[0].id
      // );
    } else {
      player.on("timeupdate", checkPosterAd);
    }
    player.el().classList.remove("vjs-ad-playing");
    player.off("timeupdate", comeskip);

    const adended = new CustomEvent("adended", {
      detail: {
        id: currentAdId, // Use the stored ad ID
      },
    });
    player.el().dispatchEvent(adended);

    resumevideo(selectedIndex);
  }

  function resumevideo(index, resumetime) {
    if (adConfig.length > 0) {
      player.on("timeupdate", checkAdTime);
    }

    player.src({
      src: playlistItems[index].sources[0].src,
      type: getVideoType(playlistItems[index].sources[0].src),
      keySystems: playlistItems[index].sources[0].keySystems,
    });
    // alert("mainvideo_time" + mainVideoTime);
    player.currentTime(mainVideoTime);
    turnOnUserInactiveListener();

    // player.on("ended", playNextVideo);
  }
  function checkPosterAd() {
    console.log("hi_babbyyyyyyyyyyyyyyyyyyy");
    if (
      playlistItems[selectedIndex].PosteradConfig &&
      playlistItems[selectedIndex].PosteradConfig.length > 0
    ) {
      if (
        player.currentTime() >=
        playlistItems[selectedIndex].PosteradConfig[0].time
      ) {
        console.log("hi_babbyyyyyyyyyyyyyyyyyyy_1");

        let src = playlistItems[selectedIndex].PosteradConfig[0].src;
        let close_time =
          playlistItems[selectedIndex].PosteradConfig[0].closeButtonInMilisec;
        let adId = playlistItems[selectedIndex].PosteradConfig[0].id;

        displayPosterAd(src, close_time, adId);
        playlistItems[selectedIndex].PosteradConfig = null;
      }
    } else {
      player.off("timeupdate", checkPosterAd);
    }
  }

  function displayPosterAd(src, time, adId) {
    // alert("display_posterad");
    console.log("hi_babbyyyyyyyyyyyyyyyyyyy_2");

    posterads = true;

    posteradimg.src = src;
    postercloseButton.style.display = "none";

    if (adPlaying) {
      posteradContainer.style.display = "none";
    } else {
      posteradContainer.style.display = "block";
      const timeout = setTimeout(() => {
        postercloseButton.style.display = "block";
      }, time);

      postercloseButton.onclick = function() {
        posterclosebtn = true;
        posterads = false;
        posteradContainer.style.display = "none";
      };
    }
    player.off("timeupdate", checkPosterAd);
    const posterAdplaying = new CustomEvent("posterAdplaying", {
      detail: { id: adId },
    });

    player.el().dispatchEvent(posterAdplaying);
  }

  function skipAd() {
    player.off("ended", onAdEnded);
    onAdEnded();
  }

  function hidePosterAd() {
    //console.log("hideposterad", posterads);

    if (posterads) {
      const posteradContainer = player
        .el()
        .querySelector(".vjs-posterAd-container");
      if (posteradContainer) {
        posteradContainer.style.display = "none";
      }
    }
  }
  function showPosterAd() {
    const posteradContainer = player
      .el()
      .querySelector(".vjs-posterAd-container");
    if (posteradContainer) {
      posteradContainer.style.display = "block";
    }
  }

  function showAdIndicators(adConfig) {
    //console.log("adconnnnn" + JSON.stringify(adConfig));

    // var total = player.duration();
    // var total = convertDurationToSeconds(playlistItems[selectedIndex].duration);
    var total = player.duration();
    //console.log("Total duration: ", total); // Debugging total duration
    var progressControlElement = $(
      player.controlBar.progressControl.children_[0].el_
    ); // Select the progress control element

    // Clear existing markers
    progressControlElement.find(".vjs-marker").remove();

    adConfig.forEach((ad) => {
      //console.log("Ad time: ", ad.time); // Debugging each ad time
      var left = (ad.time / total) * 100 + "%"; // Calculate the left position as a percentage
      //console.log("Left position: ", left); // Debugging calculated left position

      var marker = $(
        '<div class="vjs-marker" style="left:' +
          left +
          '" data-time="' +
          ad.time +
          '"></div>'
      );

      progressControlElement.append(marker);
    });
  }
  player.getCurrentPlaylistItem = function() {
    if (selectedIndex !== null && playlistItems[selectedIndex]) {
      return playlistItems[selectedIndex];
    }
    return null;
  };
  // Example of turning off the event listener
  function turnOffUserInactiveListener() {
    player.off("userinactive", handleUserInactive);
  }

  // Example of turning on the event listener
  function turnOnUserInactiveListener() {
    player.on("userinactive", handleUserInactive);
  }

  player.hi = function() {
    return selectedIndex;
  };
  function goBack() {
    window.history.back();
  }
};

videojs.registerPlugin("moviePlugin", moviePlugin);
