import ACTION_TYPES from "./actionTypes";
const {
    ACTIVE_EMAIL_START,
    ACTIVE_EMAIL_SUCCESS,
    ACTIVE_EMAIL_FAILED,
    ACTIVE_USER_ID,
} = ACTION_TYPES;


const initialState = {
  activationInfo:{},
  isLoading: false,
  errors: "",
  isActivated:false,
  activeUserId:null,
  
  

};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  //console.log("reducer is calling");

  switch (type) {
    case ACTIVE_EMAIL_START:
      

      return {
        ...state,
        isLoading: true,
        errors: "",
      };
    case ACTIVE_EMAIL_SUCCESS:
    
     
      return {
        ...state,
        activationInfo:payload,
        isActivated:true,

        isLoading,
      };

    case ACTIVE_EMAIL_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        isActivated:false,
      };
      case ACTIVE_USER_ID:
        return {
          ...state,
          isLoading,
         activeUserId:payload,
          isActivated:false,
        };
     
     
    default:
      return state;
  }
};
