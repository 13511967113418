import ACTION_TYPES from "./action.types";

const {
  GET_ADD_DEVICES_START,
  GET_ADD_DEVICES_SUCCESS,
  GET_ADD_DEVICES_FAILED,
  CLEAR_DEVICE_TOCKEN,
  SAVE_DEVICE_TOCKEN,
  CLEAR_DEVICE_STATUS,
  SAVE_DEVICE_NAME,
} = ACTION_TYPES;

export const getAddDevicesStart = (payload) => ({
  type: GET_ADD_DEVICES_START,
  payload,
});

export const getAddDevicesSuccess = (payload) => ({
  type: GET_ADD_DEVICES_SUCCESS,
  payload,
});
export const getAddDevicesFailed = (payload) => ({
  type: GET_ADD_DEVICES_FAILED,
  payload,
});
export const saveDeviceTocken = (payload) => ({
  type: SAVE_DEVICE_TOCKEN,
  payload,
});
export const saveDeviceName = (payload) => ({
  type: SAVE_DEVICE_NAME,
  payload,
});

export const clearDeviceTocken = () => ({
  type: CLEAR_DEVICE_TOCKEN,
});
export const clearDeviceStats = () => ({
  type: CLEAR_DEVICE_STATUS,
});
