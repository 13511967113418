import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as DATA_API from "../../services/Notification details service/NotificationDataService";

const { GET_LIVE_ASSETS_DATA_START } = ACTION_TYPES;

/** Sagas */

function* getLiveAssetsDataSaga(payload) {
  try {
    //console.log("get live assets calling");
    const { data } = yield call(DATA_API.liveDataAsync, payload);

    yield put(ACTION.getLiveAssetsDataSuccess({ data }));
    // if(data.length > 0){

    //   yield put(ACTION.getLiveAssetsDataStart(payload))
    // }
  } catch ({ message }) {
    yield put(ACTION.getLiveAssetsDataFailed({ message }));
  }
}

/** Watchers or Observers */

function* getLiveAssetDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_LIVE_ASSETS_DATA_START);
    yield call(getLiveAssetsDataSaga, payload);
  }
}

export default function*() {
  yield all([getLiveAssetDataWatcher()]);
}
