/** @format */

import { call, put, take, all } from "redux-saga/effects";
import { getEmailInfoAsync } from "./../../../services/manageprofile/manageProfileService";
import { update_email_info_success, update_email_info_failure } from "./action";
// import * as PROFILE_ACION from "./../../ProfileDetails/actions";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import types from "./actiontypes";
import * as AUTHENTICATION_ACTIONS from "./../../authentication/actions";

function* emailInfoSaga(payload) {
  //console.log("response...///", JSON.stringify(payload));

  try {
    const { data } = yield call(getEmailInfoAsync, payload);
    if (data.status == true) {
      // Swal.fire({
      //   icon: "success",
      //   // position: "top-end",
      //   text: data.message,
      //   allowOutsideClick: () => !swal.isLoading(),
      //   confirmButtonColor: "#3085d6",
      //   button: "OK",
      // });

      swal({
        text: data.message,
        icon: "success",
        closeOnClickOutside: false,
        confirmButtonColor: "#3085d6",
        // dangerMode: true,
      });
    }
    // alert("email data" + JSON.stringify(data));
    yield put(update_email_info_success(payload));
    yield put(AUTHENTICATION_ACTIONS.userDetailsAction({ id: payload.id }));
  } catch (error) {
    // alert(JSON.stringify(error));
    yield put(update_email_info_failure({ error }));
  }
}

function* watcherSaga() {
  while (true) {
    const { payload } = yield take(types.UPDATE_EMAIL_INFO_START);
    yield call(emailInfoSaga, payload);
  }
}

export default function*() {
  yield all([watcherSaga()]);
}
