import ACTION_TYPES from "./action.types";

const {
  CHECKING_MAIL_VALIDITY_START,
  CHECKING_MAIL_VALIDITY_SUCCESS,
  CHECKING_MAIL_VALIDITY_FAILED,
  CLEAR_MAIL_VALIDITY_STATE,
} = ACTION_TYPES;

const initialState = {
  isMailValid: null,
  mailLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECKING_MAIL_VALIDITY_START:
      return {
        ...state,
        mailLoading: true,
      };
    case CHECKING_MAIL_VALIDITY_SUCCESS:
      return {
        ...state,
        isMailValid: payload.data.status,
        mailLoading: false,
      };

    case CHECKING_MAIL_VALIDITY_FAILED:
      return {
        ...state,
        isMailValid: false,
        mailLoading: false,
      };
    case CLEAR_MAIL_VALIDITY_STATE:
      return {
        ...state,
        isMailValid: false,
      };

    default:
      return state;
  }
};
