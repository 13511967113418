/** @format */

import axiosInstance from "./../../utils/axiosInstance";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import store from "./../../Redux/store.js";
import * as activeAccessTokenService from "./../activeAccessToken/activeAccessToken.js";
import * as AUTH_ACTION from "./../../Redux/authentication/actions.js";
import {
  HeadersAcessToken,
  HeadersAcessTokenForFormData,
} from "../../components/HeadersAcessToken";
var qs = require("qs");

// export const getPersonalInfoAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post(
//       "/user/updatePersonalDetailsV1/",
//       payload,
//       {
//         headers: HeadersAcessToken(access_token),
//       }
//     );
//     return response;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/user/updatePersonalDetailsV1/",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       throw error.response.data;
//     }
//   }
// };
export const getPersonalInfoAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/user/updatePersonalDetailsV1/",
      payload
    );
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// export const getEmailInfoAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post(
//       "/user/updateEmailOrNumberV1",
//       payload,
//       {
//         headers: HeadersAcessToken(access_token),
//       }
//     );
//     return response;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/user/updateEmailOrNumberV1",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       Swal.fire({
//         icon: "warning",
//         // position: "top-end",
//         text: error?.response?.data?.message,
//         allowOutsideClick: () => !swal.isLoading(),
//         confirmButtonColor: "#3085d6",
//         button: "OK",
//       });
//       //console.log("error response " + error);
//       Promise.reject(error);
//     }
//   }
// };
export const getEmailInfoAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/user/updateEmailOrNumberV1",
      payload
    );
    return response;
  } catch (error) {
    Swal.fire({
      icon: "warning",
      text: error?.response?.data?.message,
      allowOutsideClick: () => !swal.isLoading(),
      confirmButtonColor: "#3085d6",
      button: "OK",
    });
    throw error;
  }
};

// export const getMobileInfoAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post(
//       "/user/updateMobileNumberV1/",
//       payload,
//       {
//         headers: HeadersAcessToken(access_token),
//       }
//     );
//     return response;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/user/updateMobileNumberV1/",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       throw error.response.data;
//     }
//   }
// };
export const getMobileInfoAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/user/updateMobileNumberV1/",
      payload
    );
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// export const getPasswordInfoAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post(
//       "/user/updatePasswordV1",
//       payload,
//       {
//         headers: HeadersAcessToken(access_token),
//       }
//     );
//     return response;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/user/updatePasswordV1",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       throw error.response.data;
//     }
//   }
// };
export const getPasswordInfoAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/user/updatePasswordV1",
      payload
    );
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};
