/** @format */

import React from "react";
import Slider from "./../Slider";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  liveAssetSelector,
  liveTagSelector,
  movieSelector,
  liveLanguageSelector,
  liveAdsSelector,
} from "./../../Redux/live/selectors";
import * as NOTIFICATION_ACTION from "./../../Redux/PushNotification/actions";
import * as EPG_ACTION from "./../../Redux/EPG/action";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import MyListCategory from "./MyListCategory";
import FooterPage from "../footerComponent/FooterPage";
import SearchPage from "../SearchComponent/SearchPage";
import MainHeader from "../MainHeader";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import * as CHECK_DEVICES from "./../../Redux/Delete Device/actions";
import { getPackageDetailsSelector } from "../../Redux/package&subscriptions/selector";
import * as GET_FAVORITES_ACTION from "../../Redux/FavouteDataRedux/action";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import { getFavouriteSelector } from "../../Redux/FavouteDataRedux/selector";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MyListContainer = ({
  TAGDETAILS,

  LANGUAGEDETAILS,

  searchState,
  setSearchState,
  setSearchKey,
  searchKey,

  setIsVideoPlayer,
  setClearSearchState,
  clearSearchState,
  PACKAGE_DETAILS,
  USER_DETAILS,
  GET_FAVOURITES_DATA,
  selectedCategory,
  setSelectedCategory,
  AUTHENTICATION,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setIsVideoPlayer(false);
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (searchState == false) {
      document.body.style.overflowY = "scroll";
    }
  }, [searchState]);

  let keysArray;
  if (PACKAGE_DETAILS != undefined && PACKAGE_DETAILS != null) {
    keysArray = Object.keys(PACKAGE_DETAILS);
  }

  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.push("/login");
    }
  }, [AUTHENTICATION]);
  useEffect(() => {
    dispatch(
      GET_FAVORITES_ACTION.getFavouriteDataStart({ userId: USER_DETAILS?.id })
    );

    // dispatch(
    //   NOTIFICATION_ACTION.getPushNotificationStart({
    //     packageId: JSON.stringify(keysArray),
    //   })
    // );
  }, []);

  return (
    <div>
      <MainHeader
        setSearchState={setSearchState}
        setSearchKey={setSearchKey}
        setClearSearchState={setClearSearchState}
        clearSearchState={clearSearchState}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      {searchState == true ? (
        <SearchPage
          searchKey={searchKey}
          setSearchState={setSearchState}
          setClearSearchState={setClearSearchState}
        />
      ) : (
        ""
      )}

      <div>
        <div style={{ height: "75px", color: "black" }}></div>
        <Slider />

        <div className="main-content p-0">
          <section id="iq-favorites">
            <div className="container-fluid">
              <div className="row">
                <MyListCategory
                  tagDetails={TAGDETAILS}
                  languageDetails={LANGUAGEDETAILS}
                  // favourateState={favourateState}
                  GET_FAVOURITES_DATA={GET_FAVOURITES_DATA}
                />

                {/* )) : ""} */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  LIVEASSETS: liveAssetSelector,
  TAGDETAILS: liveTagSelector,
  LANGUAGEDETAILS: liveLanguageSelector,
  PLATFORM_ID: platformsSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  GET_FAVOURITES_DATA: getFavouriteSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(MyListContainer);
