/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as AUTHENTICATION_ACTIONS from "../../Redux/authentication/actions";

import * as DELETE_DEVICE_API from "./../../services/authentication/authentication";
import * as CHECK_DEVICE_API from "./../../services/addDeviceService/CheckExistanceOfDevice";
import * as DEVICE from "./../auth/actions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import swal from "sweetalert";
import "./../../components/sweet.css";
import { socketStatus, ws } from "../../App";
import { socketStatusAPI } from "../../services/datAnalytics/dataAnalytics";
const {
  GET_DELETE_DEVICES_START,
  CHECK_EXISTANCE_OF_DEVICE_START,
} = ACTION_TYPES;

/** Sagas */

function* deleteDeviceDataSaga(payload) {
  let deviceData = {
    account_id: payload.orgId,
    userId: payload.userId,
    packageId: payload.packageId,
  };
  try {
    //console.log("get live assets calling");
    const { data } = yield call(DELETE_DEVICE_API.logoutAsync, payload);

    yield put(DEVICE.getDeviceListStart(deviceData));
    yield put(ACTION.getDeleteDevicesSuccess({ data }));

    let dataForApi = {
      deviceId: payload?.deviceId,
      message: "Removed From Another DEVICE through API in webapplication",
    };

    if (socketStatus == true) {
      socketStatusAPI(dataForApi);
    }
    if (socketStatus == false) {
      ws.send(
        JSON.stringify({
          deviceId: payload?.deviceId,
          message:
            "Removed From Another DEVICE through Socket in webapplication",
        })
      );
    }
    // console.log("ws" + ws);
  } catch ({ message }) {
    yield put(ACTION.getDeleteDevicesFailed({ message }));
  }
}

function* checkExistanceOfDeviceSaga(payload) {
  try {
    //console.log("get live assets calling");
    const { data } = yield call(
      CHECK_DEVICE_API.checkExistanceDeviceAsync,
      payload
    );
    // alert(JSON.stringify(data));
    if (data.status === false) {
      let data1 = {
        deviceId: payload?.deviceId,
        userId: payload?.userId,
        orgId: payload?.account_id,
        history: payload?.history,
      };
      let isLogOut = false;
      // document.exitPictureInPicture(); // Exit PiP mode when navigating back

      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: data.message,
        allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonColor: "#3085d6",

        button: "OK",
      }).then((OK) => {
        if (OK) {
          // alert("hhhhh");
          // payload.history.push("/login");
          isLogOut = true;
        }
      });
      // if (isLogOut == true) {
      //   alert("hh");
      // alert(JSON.stringify(data1));

      yield put(AUTHENTICATION_ACTIONS.logoutStart(data1));

      // yield put(ACTION.deviceStatus(true));
      // }
      yield put(ACTION.checkExistanceOfDeviceFailed({ data }));
    } else if (data.status == true) {
      yield put(ACTION.checkExistanceOfDeviceSuccess({ data }));
    }
  } catch (error) {
    // alert(JSON.stringify(error));
  }
}
/** Watchers or Observers */

function* deleteDeviceWatcher() {
  while (true) {
    const { payload } = yield take(GET_DELETE_DEVICES_START);
    yield call(deleteDeviceDataSaga, payload);
  }
}

function* checkExistanceOfDeviceWatcher() {
  while (true) {
    const { payload } = yield take(CHECK_EXISTANCE_OF_DEVICE_START);
    yield call(checkExistanceOfDeviceSaga, payload);
  }
}

export default function*() {
  yield all([checkExistanceOfDeviceWatcher(), deleteDeviceWatcher()]);
}
