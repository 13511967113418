import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useLocation } from "react-router-dom";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { newImageIpPath } from "../../App";
import "../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../loader/loadingPlugin.js";
import "../LatestVideoJsPlugin/keywordControlPluginWithIcons.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { authenticatedStatusSelector } from "../../Redux/authentication/selectors.js";
import { getDeviceStatusSelector } from "../../Redux/Delete Device/selectors.js";

const TeaserPlayer = ({ CURRENT_PLATFORM, AUTHENTICATION, DEVICE_STATUS }) => {
  const location = useLocation().state;
  const history = useHistory();
  const platformLogo = newImageIpPath + CURRENT_PLATFORM.productLogo;

  const [showOverlay, setShowOverlay] = useState(false); // Set showOverlay to true initially

  const src = location?.src;
  let posterUrl = location?.posterUrl;

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  let type = null;

  if (src !== undefined && src !== null) {
    if (src.endsWith(".mp4")) {
      type = "video/mp4";
    } else if (src.endsWith(".m3u8")) {
      type = "application/x-mpegURL";
    } else if (src.endsWith(".mpd")) {
      type = "application/dash+xml";
    }
  }
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: true,
        preload: "auto",
        fluid: true,
        aspectRatio: "16:7",
        loop: true,
        controlBar: {
          volumePanel: {
            inline: false, // Horizontal volume control
          },
        },
      });
      playerRef.current.loadingPlugin();
      playerRef.current.keywordControlPluginWithIcons();
      playerRef.current.src({ src, type });
      playerRef.current.displayPlatFormLogo(platformLogo);
      playerRef.current.on("error", errordisplay);
    }

    function errordisplay() {
      // Hide the modal dialogs
      var modalDialogs = document.querySelectorAll(
        ".video-js .vjs-modal-dialog"
      );
      modalDialogs.forEach(function(dialog) {
        dialog.style.display = "none";
      });

      // Show the overlay image
      setShowOverlay(true);
    }

    // function errordisplay() {
    //   var modalDialogs = document.querySelectorAll(
    //     " .video-js .vjs-modal-dialog"
    //   );
    //   modalDialogs.forEach(function(dialog) {
    //     dialog.style.display = "none";
    //   });
    // }
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [src, type]);

  return (
    <div data-vjs-player style={{ position: "relative" }}>
      <span
        className="go-back"
        style={{
          position: "absolute",
          top: "30px",
          left: "30px", // Adjusted from right to left for better placement on small screens
          zIndex: 10, // Ensures the back button appears above other elements
          cursor: "pointer",
        }}
        onClick={() => history.goBack()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-left-circle"
          style={{ cursor: "pointer" }}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <polyline points="12 8 8 12 12 16"></polyline>
          <line x1="16" y1="12" x2="8" y2="12"></line>
        </svg>
      </span>

      <video ref={videoRef} className="video-js vjs-default-skin" />
      {showOverlay && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <img
            src={posterUrl}
            alt="Error Poster"
            style={{
              width: "120%",
              height: "120%",
              filter: "blur(10px)",
              objectFit: "cover",
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(TeaserPlayer);
