import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";

var qs = require("qs");

// export const chechMailValidityAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   // payload.programmeDate = "2022-09-17";
//   // alert(JSON.stringify(payload));

//   return await axiosInstance()
//     .get(
//       "/user/varify/" + payload,

//       {
//         headers: ConstantHeaders, // Use the headers obtained from createHeaders
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response);
//     });
// };
export const chechMailValidityAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the payload
    const ciphertext = encryptData(payload);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().get(
      `/user/varify/${urlSafeCiphertext}`,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }

      // "/user/varify/" + urlSafeCiphertext
    );

    return response; // Return data directly
  } catch (error) {
    throw error.response.data; // Throw the response data in case of error
  }
};

// export const chechMailValidityAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   // payload.programmeDate = "2022-09-17";
//   // alert(JSON.stringify(payload));

//   return await axiosInstance()
//     .get(
//       "/user/varify/" + payload,

//       {
//         headers: ConstantHeaders, // Use the headers obtained from createHeaders
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response);
//     });
// };
