import React from "react";
import "./platform.css";
import { useHistory } from "react-router-dom";
import * as ACTION_TYPES from "./../../Redux/platforms/actions";
import * as CURRENT_PLATFORM_ACTION from "./../../Redux/platforms/actions";
import { connect, useDispatch } from "react-redux";
import * as DELETE_ACTION from "./../../Redux/Delete Device/actions";
import * as EPG from "./../../Redux/EPG/action";
import { useEffect } from "react";
import {
  getCurrentPlatformSelector,
  platformsLoadingSelector,
  platformsSelector,
} from "./../../Redux/platforms/selectors";
import { createStructuredSelector } from "reselect";
import * as LIVE_ACTION from "./../../Redux/live/actions";
import NewLoadingPage from "../NewLoadingPage";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
const PlatForm = ({
  PLATFORMS,
  setOrgId,
  PLATFORM_LOADING,
  DEVICE_TOKEN,
  PLATFORM_DATA,
  USER_DETAILS,
  AUTHENTICATION,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let id = PLATFORMS.currentPlatformId;

  useEffect(() => {
    dispatch(ACTION_TYPES.getPlatformsListStart());
  }, []);
  let data = PLATFORMS.platforms.filter(({ status }) => status == 1);

  // if (data.length == 1) {
  //   dispatch(ACTION_TYPES.getCurrentPlatformsId(PLATFORMS.platforms[0].orgId));
  //   history.push("/login");
  // }

  const handleClick = (item) => {
    dispatch(EPG.clearEpgSuccess());

    let logoutData;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      logoutData = {
        deviceId: DEVICE_TOKEN,
        userId: USER_DETAILS?.id,
        orgId: PLATFORMS.currentPlatformId,
        packageId: USER_DETAILS?.packageId,
      };
    }
    dispatch(DELETE_ACTION.getDeleteDevicesStart(logoutData));
    // dispatch(ACTION_TYPES_LOGIN.loginStart({ credentials, history: history })); if need auto login

    dispatch(CURRENT_PLATFORM_ACTION.getSelectedPlatform(item));
    dispatch(CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(item.orgId));

    dispatch(ACTION_TYPES.getCurrentPlatformsId(item.orgId));
    let account_id = item.orgId;
    // let data = {
    //   account_id : orgId,
    // }

    // if (AUTHENTICATION== true) {
    //   history.push("/devicesPage");
    // }
    // else{

    //   dispatch(LOGOUT_ACTION.logoutStart());

    //   history.push("/login")

    // }

    // dispatch(LOGOUT_ACTION.getDeviceListStart({ account_id }));
    setOrgId(item.orgId);

    // if(AUTHENTICATION== false){
    // history.push("/login");
    // }
    // else{
    //   history.push('/container');
    // }
  };

  let brandBackgroundImage;
  if (PLATFORM_DATA != undefined && PLATFORM_DATA != null) {
    brandBackgroundImage = newImageIpPath + PLATFORM_DATA.webappBackgroundImage;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${brandBackgroundImage})`,
        backgroundAttachment: "fixed",
        minHeight: "600px",
        height: "100%",
      }}
    >
      {PLATFORM_LOADING == false ? (
        <div
          className="container-fluid h-100 align-items-center"
          style={{ justifyItems: "center" }}
        >
          <div
            className="container-xl align-items-center"
            style={{ marginLet: 120 }}
          >
            <div className="row align-items-center ">
              <div
                className="col-md-12 align-items-center"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <h3
                  style={{
                    fontWeight: "bolder",
                    color: "#71757a",

                    marginLeft: "40%",
                    marginTop: "10px",
                  }}
                >
                  Select Platform
                </h3>
                <div
                  id="myCarousel"
                  className="carousel slide align-items-center"
                  data-ride="carousel"
                  data-interval="0"
                >
                  <div className="">
                    <div className="item carousel-item active">
                      <div className="row">
                        {data.map((item, index) => (
                          <div
                            className="col-sm-3 mt-5  align-items-center"
                            style={{ alignItems: "center", cursor: "pointer" }}
                          >
                            <div
                              className="thumb-wrapper"
                              onClick={() => handleClick(item)}
                            >
                              <span className="wish-icon">
                                <i className="fa fa-heart-o"></i>
                              </span>
                              <div className="img-box">
                                <img
                                  src={newImageIpPath + item.productLogo}
                                  className="img-fluid"
                                  alt="dish"
                                  style={{
                                    maxHeight: "150px",
                                    maxWidth: "200px",
                                  }}
                                />
                              </div>
                              <div className="thumb-content">
                                <h4 style={{ color: "black" }}>
                                  {item.productName}
                                </h4>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NewLoadingPage />
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORMS: platformsSelector,
  PLATFORM_LOADING: platformsLoadingSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  PLATFORM_DATA: getCurrentPlatformSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(PlatForm);
