import videojs from "video.js";
import "./PlatformLogoPlugin.css";

const displayPlatFormLogo = function(platformLogo) {
  const player = this;

  // Define the source of the platform logo

  // Function to display the platform logo
  function displayPlatformLogo(src, time) {
    const logoElement = document.createElement("div");
    logoElement.className = "p-0";
    logoElement.style.width = "430px";
    logoElement.style.float = "right";
    logoElement.style.marginRight = "40px";
    logoElement.style.opacity = "0.5";

    const imgElement = document.createElement("img");
    imgElement.src = src;
    imgElement.height = 70;
    imgElement.width = 70;

    logoElement.appendChild(imgElement);

    // Add the logo to the player
    player.el().appendChild(logoElement);

    // Positioning the logo (adjusted to be a little bit above and right)
    logoElement.style.position = "absolute";
    logoElement.style.bottom = "70px"; // Adjust as needed to move it above
    logoElement.style.right = "-300px"; // Adjust as needed to move it to the right
  }

  // Call the function to display the platform logo
  displayPlatformLogo(platformLogo, 0);
};

// Register the plugin with video.js
videojs.registerPlugin("displayPlatFormLogo", displayPlatFormLogo);
