import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");
export const checkExistanceDeviceAsync = async (payload) => {
  try {
    // Encrypt the userId
    var ciphertext = encryptData(payload.userId);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().post(
      // `/check/devicesV1/${urlSafeCiphertext}`,
      "/check/devicesV1/" + urlSafeCiphertext,

      payload
    );

    return response;
  } catch (error) {
    throw error;
  }
};
