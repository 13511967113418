import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as ACTION_TYPE from "./../Redux/forgot-password/action";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getActiveUserIdSelector } from "../Redux/email-activation/selector";
import "./forgotPassword.css";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
import { useHistory } from "react-router-dom";
import { resetPasswordStateSelector } from "../Redux/resetPassword/selector";
import * as DEFAULT_PLATFORM from "./../Redux/DefaultPlatform/actions";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { newImageIpPath } from "../App";
function ForgotPasswordPage({
  USERID,
  PLATFORM_ID,
  RESET_STATUS,
  CURRENT_PLATFORM,
}) {
  const [brandBackgroundImage, setBrandBackgroundImage] = useState(
    newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(DEFAULT_PLATFORM.getDefaultPlatformsListStart());
  }, []);
  // alert(JSON.stringify(PLATFORM_ID.currentPlatformId))
  const handleValidation = (event) => {
    let formIsValid = true;
    // if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
    //   formIsValid = false;
    //   setemailError("Email Not Valid");
    //   return false;
    // } else {
    //   setemailError("");
    //   formIsValid = true;
    // }

    if (!newPassword.match(/^[a-zA-Z]{8,22}$/)) {
      formIsValid = false;
      setpasswordError(
        "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
      );
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };
  let data = {
    account_id: PLATFORM_ID.currentPlatformId,
    emailId: newPassword,
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (newPassword?.length > 0) {
      dispatch(
        ACTION_TYPE.forgotResetPasswordStart({ data: data, history: history })
      );
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "E-mail should not be empty",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }

    handleValidation();
  };
  const handleClick = () => {
    history.push("/login");
  };
  let backgroundUrl = newImageIpPath + CURRENT_PLATFORM?.brandBackgroundImage;

  return (
    <div
      className="main-content p-0 iq-favorites pt-5 col-lg-12"
      style={{
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${brandBackgroundImage})`,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "fixed",
      }}
    >
      <div
        className="container justify-content-center col-lg-6"
        style={{
          width: "600px",
          height: "300px",
          backgroundColor: "rgba(52, 53, 53, 0.9)",

          borderRadius: "15px",
        }}
      >
        <div className="row d-flex justify-content-center col-lg-12">
          <span className="col-lg-5 mb-3">
            <span
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                padding: "15px",
                marginBottom: "20%",
              }}
            >
              Reset Password
            </span>
          </span>
          <div style={{ color: "white", fontSize: "18px" }}>
            Lost your password? <br />
            Please enter your email address, to receive a link.
          </div>
          <div
            className="col-lg-6 mt-4"
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              display: "flex",
            }}
          >
            <form id="loginform" onSubmit={loginSubmit}>
              <div className="form-group col-lg-4">
                <label style={{ color: "white" }}>
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputPassword1"
                  onChange={(event) => setNewPassword(event.target.value)}
                  style={{
                    width: "300px",
                    backgroundColor: "transparent",
                    borderRadius: "10px",
                    border: "1px solid white",
                    color: "white",
                  }}
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary rounded "
                style={{
                  marginLeft: "40%",
                  fontFamily: "Montserrat,sans-serif",
                  backgroundColor: "red",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                  borderColor: "transparent",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  USERID: getActiveUserIdSelector,
  PLATFORM_ID: platformsSelector,
  RESET_STATUS: resetPasswordStateSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
});
export default connect(mapStateToProps)(ForgotPasswordPage);
