const ACTION_TYPES = {
  GET_PLATFORMS_LIST_START: "GET_PLATFORMS_LIST_START",
  GET_PLATFORMS_LIST_SUCCESS: "GET_PLATFORMS_LIST_SUCCESS",
  GET_PLATFORMS_LIST_FAILED: "GET_PLATFORMS_LIST_FAILED",
  GET_CURRENT_PLATFORM_ID: "GET_CURRENT_PLATFORM_ID",
  CLEAR_PLATFORM_DATA: "CLEAR_PLATFORM_DATA",
  GET_SELECTED_PLATFORM: "GET_SELECTED_PLATFORM"
};

export default ACTION_TYPES;
