/** @format */

import base64 from "base-64";
import videojs from "video.js";
import { newImageIpPath } from "../../../App";
function timeStringToSeconds(timeString) {
  let totalSeconds = 0;
  if (timeString != undefined) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    totalSeconds = hours * 3600 + minutes * 60 + seconds;
  }
  return totalSeconds;
}
export const getPlayList = (payload) => {
  // alert(payload?.DRM_DETAILS?.[0]?.widewineLicense.trim());
  // console.log("payload?.DRM_DETAILS" + JSON.stringify(payload?.DRM_DETAILS));

  let selectedDrmCategory = null;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }

  let allChannelList = [];

  var keyServer;
  var url1;
  if (selectedDrmCategory == 1) {
    var pos = payload?.SELECTED_MOVIE_DATA?.assetUrl?.lastIndexOf(".");
    let widewineLicense = payload?.DRM_DETAILS?.data[0]?.widewineLicense?.trim();
    let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
    let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
    let drmId = payload?.SELECTED_MOVIE_DATA?.drmId;
    let PlayState = "1";
    let DrmSystem = "Widevine";
    let date = new Date();
    let firstPartUrl = payload?.SELECTED_MOVIE_DATA?.assetUrl?.substring(
      0,
      pos
    );
    url1 = firstPartUrl + ".mpd";
    let month1 = date.getMonth() + 1;
    let nowStampString =
      date.getFullYear() +
      "-" +
      month1 +
      "-" +
      date.getDate() +
      " " +
      0 +
      ":" +
      date.getHours() +
      ":" +
      date.getMinutes();
    let ContentUrl = url1;
    let UniqueDeviceId = payload.SAVED_DEVICE_TOCKEN;
    let DeviceTypeName = payload.DEVICE_NAME;
    let LoginName = base64.encode(loginName);
    let Password = base64.encode(loginPassword);
    let KeyId = base64.encode(drmId);
    let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);
    let ContentUrlEncode = base64.encode(ContentUrl);
    let NowStamp = base64.encode(nowStampString);
    let DeviceTypeNameEncode = base64.encode(DeviceTypeName);
    keyServer =
      widewineLicense +
      "?" +
      "PlayState=" +
      PlayState +
      "&" +
      "DrmSystem=" +
      DrmSystem +
      "&" +
      "LoginName=" +
      LoginName +
      "&" +
      "Password=" +
      Password +
      "&" +
      "KeyId=" +
      KeyId +
      "&" +
      "UniqueDeviceId=" +
      UniqueDeviceIdEncode +
      "&" +
      "ContentUrl=" +
      ContentUrlEncode +
      "&" +
      "NowStamp=" +
      NowStamp +
      "&" +
      "DeviceTypeName=" +
      DeviceTypeNameEncode;
    // "https://drmproxy.riversilica.com/fpe/fpe_wv.php?assetId=1&categoryId=1&userId=12";
  } else if (selectedDrmCategory == 2) {
    var pos = payload?.SELECTED_MOVIE_DATA?.assetUrl?.lastIndexOf(".");

    let firstPartUrl = payload?.SELECTED_MOVIE_DATA?.assetUrl?.substring(
      0,
      pos
    );
    url1 = firstPartUrl + ".mpd";

    // "https://soapboxstreama.riversilica.com/Movies/demo.mp4/index.mpd";

    keyServer = payload?.AUTHENTICATION
      ? payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim() +
        "?assetId=" +
        payload?.SELECTED_MOVIE_DATA?.id +
        "&categoryId=3&userId=" +
        payload.USER_DETAILS?.id
      : payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
  }

  let data = {
    // src:
    //   "https://soapboxplayer.riversilica.com/genesis/medialocal_1/video/dwapara2.mp4",
    // videoType: "video/mp4",

    // src: url1,

    // type: "application/dash+xml",
    // keySystems: {
    //   "com.widevine.alpha": keyServer,
    // },
    src: url1,

    type: "application/dash+xml",
    keySystems: {
      "com.widevine.alpha": {
        videoRobustness: "SW_SECURE_CRYPTO",

        getLicense: (emeOptions, keyMessage, callback) => {
          videojs.xhr(
            {
              method: "POST",

              url: keyServer,

              responseType: "arraybuffer",

              body: keyMessage,
            },

            (err, response, responseBody) => {
              if (err) {
                callback(err);

                return;
              }

              callback(null, responseBody);
            }
          );
        },
      },
    },

    name: payload?.SELECTED_MOVIE_DATA?.title,
    id: payload?.SELECTED_MOVIE_DATA?.id,
  };
  let title = payload?.SELECTED_MOVIE_DATA?.title;
  let name = payload?.SELECTED_MOVIE_DATA?.title;
  let dataForPoster = {};
  if (
    payload?.GENERAl_POST_ADS[
      payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId
    ] != undefined
  ) {
    dataForPoster = {
      id: payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId,
      src:
        newImageIpPath +
        payload?.GENERAl_POST_ADS[
          payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId
        ]?.adv_url,
      time: Number(payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.duration),
      closeButtonInMilisec: 2000,
    };
  }
  let PosteradConfig = [dataForPoster];
  let adConfig = payload.videoAdsArray;
  let sources = [data];
  allChannelList.push({
    sources,
    logo: newImageIpPath + payload?.SELECTED_MOVIE_DATA?.thumbnailUrl,
    poster: newImageIpPath + payload?.SELECTED_MOVIE_DATA?.thumbnailUrl,
    cw_duration:
      payload.startOver == 1
        ? timeStringToSeconds(payload?.durationForPLayer[0]?.duration)
        : 0,
    name,
    title,
    duration: payload?.SELECTED_MOVIE_DATA?.duration,
    id: payload?.SELECTED_MOVIE_DATA?.id,
    PosteradConfig: PosteradConfig,
    adConfig: adConfig,
  });
  return allChannelList;
};
export const getPlayListForFairplay = (payload) => {
  function licenseRequestLoaded(stringbuffer) {
    const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));

    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  var selectedDrmCategory = null;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }
  var keyServer1;
  let allChannelList = [];
  var fairplayCertificate;

  var url2;
  if (selectedDrmCategory == 1) {
    var pos = payload?.SELECTED_MOVIE_DATA?.assetUrl?.lastIndexOf(".");
    let fairplayLicense = payload?.DRM_DETAILS?.data[0]?.fairplayLicense?.trim();
    fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
    let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName.trim();
    let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
    let drmId = payload?.SELECTED_MOVIE_DATA?.drmId;
    let PlayState = "1";
    let DrmSystem2 = "Fairplay";
    let date = new Date();
    let firstPartUrl = payload?.SELECTED_MOVIE_DATA?.assetUrl?.substring(
      0,
      pos
    );
    url2 = firstPartUrl + ".m3u8";
    let month1 = date.getMonth() + 1;
    let nowStampString =
      date.getFullYear() +
      "-" +
      month1 +
      "-" +
      date.getDate() +
      " " +
      0 +
      ":" +
      date.getHours() +
      ":" +
      date.getMinutes();
    let ContentUrl2 = url2;
    let UniqueDeviceId1 = payload.SAVED_DEVICE_TOCKEN;
    let DeviceTypeName1 = payload.DEVICE_NAME;
    let LoginName = base64.encode(loginName);
    let Password = base64.encode(loginPassword);
    let KeyId = base64.encode(drmId);
    let UniqueDeviceIdEncode1 = base64.encode(UniqueDeviceId1);
    let ContentUrlEncode1 = base64.encode(ContentUrl2);
    let NowStamp = base64.encode(nowStampString);
    let DeviceTypeNameEncode1 = base64.encode(DeviceTypeName1);
    keyServer1 =
      fairplayLicense +
      "?" +
      "PlayState=" +
      PlayState +
      "&" +
      "DrmSystem=" +
      DrmSystem2 +
      "&" +
      "LoginName=" +
      LoginName +
      "&" +
      "Password=" +
      Password +
      "&" +
      "KeyId=" +
      KeyId +
      "&" +
      "UniqueDeviceId=" +
      UniqueDeviceIdEncode1 +
      "&" +
      "ContentUrl=" +
      ContentUrlEncode1 +
      "&" +
      "NowStamp=" +
      NowStamp +
      "&" +
      "DeviceTypeName=" +
      DeviceTypeNameEncode1;
  } else if (selectedDrmCategory == 2) {
    var pos = payload?.SELECTED_MOVIE_DATA?.assetUrl?.lastIndexOf(".");

    let firstPartUrl = payload?.SELECTED_MOVIE_DATA?.assetUrl?.substring(
      0,
      pos
    );
    url2 = firstPartUrl + ".m3u8";
    // url2 = "https://soapboxstreama.riversilica.com/Movies/demo.mp4/index.mpd";

    keyServer1 = payload?.AUTHENTICATION
      ? payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim() +
        "?assetId=" +
        payload?.SELECTED_MOVIE_DATA?.id +
        "&categoryId=2&userId=" +
        payload.USER_DETAILS?.id
      : payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim();

    fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();

    // "https://drmproxy.riversilica.com/fpe/fpe_wv.php?assetId=1&categoryId=1&userId=12";
  }

  let data = {
    src: url2,
    type: "application/x-mpegURL",
    keySystems: {
      "com.apple.fps.1_0": {
        videoRobustness: "SW_SECURE_CRYPTO",
        getCertificate: (emeOptions, callback) => {
          videojs.xhr(
            {
              method: "GET",
              url: fairplayCertificate,
              responseType: "arraybuffer",
              Pragma: "no-cache",
              CacheControl: "no-cache",
              ContentType: "text/xml; charset=utf-8",
            },
            (err, response, responseBody) => {
              if (err) {
                callback(err);
                return;
              }
              callback(null, new Uint8Array(responseBody));
            }
          );
        },
        getLicense: (emeOptions, contentId, keyMessage, callback) => {
          videojs.xhr(
            {
              method: "POST",
              url: keyServer1,
              responseType: "string",
              body: keyMessage,
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
              },
            },
            (err, response, responseBody) => {
              if (err) {
                callback(err);
                return;
              }
              callback(null, licenseRequestLoaded(responseBody));
            }
          );
        },
      },
    },
    name: payload?.SELECTED_MOVIE_DATA?.title,
    id: payload?.SELECTED_MOVIE_DATA?.id,
  };
  let title = payload?.SELECTED_MOVIE_DATA?.title;
  let name = payload?.SELECTED_MOVIE_DATA?.title;
  let sources = [data];

  let dataForPoster = {};
  if (
    payload?.GENERAl_POST_ADS[
      payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId
    ] != undefined
  ) {
    dataForPoster = {
      id: payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId,
      src:
        newImageIpPath +
        payload?.GENERAl_POST_ADS[
          payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.adId
        ]?.adv_url,
      time: Number(payload?.SELECTED_MOVIE_DATA?.vodAssetsPostAds[0]?.duration),
      closeButtonInMilisec: 2000,
    };
  }
  let PosteradConfig = [dataForPoster];
  let adConfig = payload.videoAdsArray;

  allChannelList.push({
    sources,
    logo: newImageIpPath + payload?.SELECTED_MOVIE_DATA?.thumbnailUrl,
    poster: newImageIpPath + payload?.SELECTED_MOVIE_DATA?.thumbnailUrl,
    cw_duration:
      payload.startOver == 1
        ? timeStringToSeconds(payload?.durationForPLayer[0]?.duration)
        : 0,
    name,
    title,
    duration: payload?.SELECTED_MOVIE_DATA?.duration,
    id: payload?.SELECTED_MOVIE_DATA?.id,
    PosteradConfig: PosteradConfig,
    adConfig: adConfig,

    // sources,
    // logo: newImageIpPath + a?.thumbnailUrl,
    // poster: newImageIpPath + a?.thumbnailUrl,
    // name,
    // // thumbnail,
    // title,
    // // thumb,
    // duration: a?.duration,
    // id: a?.id,
    // index,
  });

  return allChannelList;
};
