const ACTION_TYPES = {
  READ_NOTIFICATION_START: "READ_NOTIFICATION_START",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILED: "READ_NOTIFICATION_FAILED",

  PRIVACY_POLICY_STATUS_START: "PRIVACY_POLICY_STATUS_START",
  PRIVACY_POLICY_STATUS_SUCCESS: "PRIVACY_POLICY_STATUS_SUCCESS",
  PRIVACY_POLICY_STATUS_FAILED: "PRIVACY_POLICY_STATUS_FAILED",
};

export default ACTION_TYPES;
