import ACTION_TYPES from "./action.types";

const {
  GET_LIVE_ASSETS_DATA_START,
  GET_LIVE_ASSETS_DATA_SUCCESS,
  GET_LIVE_ASSETS_DATA_FAILED,
} = ACTION_TYPES;

export const getLiveAssetsDataStart = (payload) => ({
  type: GET_LIVE_ASSETS_DATA_START,
  payload,
});

export const getLiveAssetsDataSuccess = (payload) => ({
  type: GET_LIVE_ASSETS_DATA_SUCCESS,
  payload,
});
export const getLiveAssetsDataFailed = (payload) => ({
  type: GET_LIVE_ASSETS_DATA_FAILED,
  payload,
});
