import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as PLATFORMS_API from "./../../services/platforms/platforms";
// import { Alert } from "react-native";
const { GET_PLATFORMS_LIST_START } = ACTION_TYPES;

function* getPlatformsListSaga() {
  try {
    const { data } = yield call(PLATFORMS_API.getPlatformsListAsync);

    yield put(ACTION.getPlatformsListSuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.getPlatformsListFailed({ message }));
  }
}

function* getPlatformsListWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    yield take(GET_PLATFORMS_LIST_START);
    yield call(getPlatformsListSaga);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([getPlatformsListWatcher()]);
}
