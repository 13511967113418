/** @format */

import Axios from "axios";
import CryptoJS from "crypto-js";
import axiosRetry from "axios-retry";
import { serverIpPath } from "../components/IpConstantFile/IpConstantFile";
import { SK } from "../Redux/Search/actions";
import * as AUTH_ACTION from "../Redux/authentication/actions.js";
import * as activeAccessTokenService from "../services/activeAccessToken/activeAccessToken.js";
import store from "../Redux/store.js";
import {
  HeadersAcessToken,
  HeadersAcessTokenForFormData,
} from "../components/HeadersAcessToken.js"; // Importing both header functions

const SKK = SK;
var qs = require("qs");

export const encryptData = (data) => {
  const jsonString = JSON.stringify(data);
  return CryptoJS.AES.encrypt(jsonString, SKK).toString();
};

const decryptData = (cipherText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, SKK);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  } catch (error) {
    console.log("Decryption failed:", error);
    return cipherText;
  }
};

const axiosInstance = () => {
  const headers = {};
  const axiosInstance = Axios.create({
    baseURL: serverIpPath,
    headers,
  });

  axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: (_error) => true, // retry no matter what
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const accessToken = store.getState().authenticationReducer.loginDetails
      .access_token;

    if (accessToken) {
      if (req.headers["Content-Type"]?.includes("multipart/form-data")) {
        req.headers = {
          ...req.headers,
          ...HeadersAcessTokenForFormData(accessToken),
        };
      } else {
        req.headers = {
          ...req.headers,
          ...HeadersAcessToken(accessToken),
        };
      }
    }

    if (
      req.data &&
      !req.headers["Content-Type"]?.includes("multipart/form-data") &&
      typeof req.data === "object" &&
      !req.data.data &&
      !req.url.includes("/epgjson") &&
      !req.url.includes("/genre/liveDetails") &&
      !req.url.includes("/liveAssetsBasedOnkey") &&
      !req.url.includes("/generalDetails") &&
      !req.url.includes("/genre/vodDetails") &&
      !req.url.includes("/genre/seriesDetails")
    ) {
      req.data = { data: encryptData(req.data) };
    }

    return req;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data && typeof response.data === "string") {
        response.data = decryptData(response.data);
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response?.status === 401 &&
        error.response?.data?.auth === false
      ) {
        try {
          const refreshToken = store.getState().authenticationReducer
            .loginDetails.refresh_token;
          const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
            { refreshToken }
          );
          const newAccessToken = newAccessTokenResponse.data.accessToken;

          await store.dispatch(
            AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
          );

          originalRequest.headers["x-access-token"] = newAccessToken;
          originalRequest.headers = {
            ...originalRequest.headers,
            ...HeadersAcessToken(newAccessToken),
          };

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      if (typeof error?.response?.data === "string") {
        error.response.data = decryptData(error.response.data);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default axiosInstance;
