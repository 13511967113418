import ACTION_TYPES from "./actionType";
const {
  GET_FAVOURITES_DATA_START,
  GET_FAVOURITES_DATA_SUCCESS,
  GET_FAVOURITES_DATA_FAILED,
} = ACTION_TYPES;

const initialState = {
  favoriteData: {},
  favoriteDataById: {},

  isLoading: false,
  errors: "",
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  switch (type) {
    case GET_FAVOURITES_DATA_START:
      return {
        ...state,
        isLoading: true,
        errors: "",
      };

    case GET_FAVOURITES_DATA_SUCCESS:
      return {
        ...state,
        favoriteDataById: payload?.data,
        isLoading,
      };

    case GET_FAVOURITES_DATA_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    default:
      return state;
  }
};
