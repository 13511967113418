import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
import store from "./../../Redux/store.js";
import * as activeAccessTokenService from "./../activeAccessToken/activeAccessToken.js";
import * as AUTH_ACTION from "./../../Redux/authentication/actions.js";
var qs = require("qs");

// export const addFavouritesAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   // console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .post("/create/favourites", payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       Promise.reject(error.response.data);
//     });
// };

// export const addFavouritesAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post(
//       "/create/favouritesV1",
//       payload,
//       {
//         headers: HeadersAcessToken(access_token),
//       }
//     );
//     return response;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/create/favouritesV1",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       throw error.response.data;
//     }
//   }
// };
export const addFavouritesAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/create/favouritesV1",
      payload
    );
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};
