import ACTION_TYPES from "./action.types";

const {
  GET_VIDEO_ADVERTICEMENT_START,
  GET_VIDEO_ADVERTICEMENT_SUCCESS,
  GET_VIDEO_ADVERTICEMENT_FAILED,
} = ACTION_TYPES;

const DEFAULT_ERROR_MESSAGE_PROPS = {
  // name: "",
  // password: "",
  // username: "",
  // loginType: "2",
  // avatar: "",
};

const initialState = {
  videoAdverticement: [],
  videoGenreAssociation: [],
  videoPackageAssociation: [],
  isLoading: false,
  errors: "",
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = DEFAULT_ERROR_MESSAGE_PROPS;
  //console.log("reducer is calling");

  switch (type) {
    case GET_VIDEO_ADVERTICEMENT_START:
      return {
        ...state,
        videoAdverticement: [],

        isLoading: true,
        errors,
      };
    case GET_VIDEO_ADVERTICEMENT_SUCCESS:
      return {
        ...state,
        videoAdverticement: payload,

        isLoading,
        errors,
      };

    case GET_VIDEO_ADVERTICEMENT_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    default:
      return state;
  }
};
