/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@sweetalert2/themes/dark/dark.scss";
import swal from "sweetalert";

// import * as PROFILE_ACION from "../ProfileDetails/actions";
// import * as RootNavigation from "./../../../navigation/RootNavigation";
import * as LOGIN_API from "./../../services/auth/login";
// import * as USER_API from "./../../../services/User";
// import * as REMIND_ME_API from "./../../../services/movie/remind.me";
import * as DEVICE_API from "./../../services/auth/device";
import * as PROFILE_API from "./../../services/auth/profile";
// import * as SecureStoreInstance from "../../utils/SecureStoreInstance";
import * as ACTION from "./actions";
import * as AUTH_ACTION from "../auth/actions";

import * as AUTHENTICATION_ACTIONS from "../../Redux/authentication/actions";

import * as ADD_DEVICE_ACTION from "./../deviceRedux/actions";
import * as DEVICE_ACTION from "./../../Redux/Delete Device/actions";
import { socketStatus, ws } from "../../App";
import { socketStatusAPI } from "../../services/datAnalytics/dataAnalytics";

// import * as LIVE_ACTION from "./../live/actions";
// import * as SERVER_EXPO_NOTIF from "./../../../services/expo-notifications/server.expo.notification";
// import * as NOTIFICATION_UTIL from "./../../../utils/notification";
// import { useDispatch } from "react-redux";
// import { useNavigation } from "@react-navigation/native";
toast.configure();

const {
  // ADD_TO_RECENT_WATCHES_START,
  // CLEAR_RECENT_WATCHES_START,
  // CREATE_PROFILE_START,
  DELETE_PROFILE_START,
  // EDIT_PROFILE_START,
  // GET_PROFILS_START,
  PROFILE_CREATE_START,
  GET_PROFILE_LIST_START,
  PROFILE_UPDATE_START,
  PROFILE_BLOCK_START,
  PROFILE_UNBLOCK_START,
  PROFILE_SENDEMAIL_START,
  SIGNUP_START,
  // DEVICE_REGISTER_START,
  GET_DEVICE_LIST_START,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_START,
  REPLACE_DEVICE_START,
  PROFILEPIC_URL_START,
} = ACTION_TYPES;

function* signupSaga(payload) {
  try {
    const { data } = yield call(LOGIN_API.signupAsync, payload);
    yield put(ACTION.signupSuccess(data));

    if (data.status == true) {
      alert(
        "Successfully Registerd",
        [
          {
            text: "OKAY",
            // onPress: () => RootNavigation.navigate("Login"),
            style: "cancel",
          },
        ],
        {
          cancelable: true,
        }
      );
      const notify = () => {
        toast("sign up successfully");
      };
      return notify;
    } else {
      swal(data.message);
    }
  } catch ({ message }) {
    yield put(ACTION.signupFailed({ message }));
  }
}

function* getDeviceListForAddDeviceSaga(payload) {
  let oldDeviceToken = payload?.deviceData.oldDeviceToken;
  let maxNumOfDevice = payload?.deviceData.maxNumOfDevice;
  let account_id = payload?.deviceData.account_id;
  let packageId = payload?.deviceData?.packageId;
  let userId = payload?.deviceData?.userId;
  let history = payload?.deviceData?.history;
  let newDeviceTocken = payload?.deviceData?.newDeviceTocken;
  // console.log("payload?.deviceData" + JSON.stringify(payload));
  try {
    const { data } = yield call(
      DEVICE_API.getDeviceListAsync,
      payload?.deviceData
    );
    yield put(ACTION.getDeviceListForAddingDeviceSuccess({ data }));
    // console.log("payload?.deviceDatapragathi" + JSON.stringify(data));

    if (data.status == true) {
      let platFormDevices = data?.data?.filter((item) => {
        if (item.orgId == account_id) {
          return item;
        }
      });
      // console.log("platFormDevicespragathi" + JSON.stringify(platFormDevices));

      let isDeviceExist = data?.data?.filter((item) => {
        if (item.deviceId == oldDeviceToken) {
          return item;
        }
      });
      // console.log("isDeviceExistpragathi" + JSON.stringify(isDeviceExist));

      if (platFormDevices?.length < maxNumOfDevice) {
        let osName = null;
        // console.log(
        //   "platFormDevicespragathi" + JSON.stringify(platFormDevices)
        // );

        function getOS() {
          var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
            windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
            iosPlatforms = ["iPhone", "iPad", "iPod"],
            os = null;

          if (macosPlatforms.indexOf(platform) !== -1) {
            osName = "Mac OS";
          } else if (iosPlatforms.indexOf(platform) !== -1) {
            osName = "iOS";
          } else if (windowsPlatforms.indexOf(platform) !== -1) {
            osName = "Windows";
          } else if (/Android/.test(userAgent)) {
            osName = "Android";
          } else if (!os && /Linux/.test(platform)) {
            osName = "Linux";
          }

          return osName;
        }
        getOS();
        //alert(getOS());

        let browserName;
        function fnBrowserDetect() {
          let userAgent = navigator.userAgent;
          // if (/Edg/.test(window.navigator.userAgent)) {
          //   alert("You are using Edge.");
          // }
          let firefox = userAgent.indexOf("Edg");
          //  alert(userAgent)
          if (/edg|Edg/i.test(userAgent)) {
            browserName = "edge";
          } else if (/chrome|chromium|crios/i.test(userAgent)) {
            browserName = "chrome";
          } else if (/firefox|fxios/i.test(userAgent)) {
            browserName = "firefox";
          } else if (/safari/i.test(userAgent)) {
            browserName = "safari";
          } else if (/opr\//i.test(userAgent)) {
            browserName = "opera";
          } else {
            browserName = "No browser detection";
          }
        }

        fnBrowserDetect();

        let addDeviceData = {
          userId: userId,

          deviceId: newDeviceTocken,

          deviceName: osName + "-" + browserName,

          deviceType: 1,

          account_id: account_id,

          noOfDevice: maxNumOfDevice,

          packageId: packageId,
          history: history,
        };

        if (isDeviceExist.length == 0) {
          yield put(
            ADD_DEVICE_ACTION.getAddDevicesStart({
              addDeviceData,
              navigationPath: payload?.navigationPath,
            })
          );
        } else {
          history?.replace(payload?.navigationPath);
        }
      } else if (isDeviceExist.length > 0) {
        history?.replace(payload?.navigationPath);
      } else if (payload.from != "replaceDevice") {
        history.push("/devicesPage");
      }
    }
  } catch ({ message }) {
    yield put(ACTION.getDeviceListForAddingDeviceFailed({ message }));
  }
}

function* getDeviceListSaga(payload) {
  try {
    const { data } = yield call(DEVICE_API.getDeviceListAsync, payload);

    yield put(ACTION.getDeviceListSuccess({ data }));
  } catch ({ message }) {
    yield put(ACTION.getDeviceListFailed({ message }));
  }
}

function* replaceDeviceSaga(payload) {
  let history = payload?.history;
  try {
    const { data } = yield call(DEVICE_API.replaceDeviceAsync, payload);

    let dataForAPI = {
      deviceId: payload?.oldDeviceToken,
      message: "Replaced By Other Device  through API in webapplication",
    };

    if (socketStatus == true) {
      socketStatusAPI(dataForAPI);
    }
    // console.log(socketStatus + "arun socketStatus");
    if (socketStatus == false) {
      ws?.send(
        JSON.stringify({
          deviceId: payload?.oldDeviceToken,
          message: "Replaced By Other Device  through Socket in webapplication",
        })
      );
    }

    yield put(DEVICE_ACTION.deviceStatus(false));
    // console.log(JSON.stringify(data) + "data");

    yield put(ACTION.replaceDeviceSuccess({ data }));

    yield put(AUTH_ACTION.device_rigister_status(true));

    if (data.status == true) {
      let data = {
        account_id: payload.account_id,
        userId: payload.userId,
        packageId: payload.packageId,
      };
      yield put(ACTION.getDeviceListStart(data));
      history.replace(payload?.navigationPath);

      yield put(ACTION.navigateDeviceScreenSuccess());
      return;
    } else {
      yield put(ACTION.device_rigister_status(false));

      return;
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.replaceDeviceFailed({ message }));
  }
}

function* profileBlockSaga(payload) {
  try {
    const { data } = yield call(PROFILE_API.profileBlockAsync, payload);
    //console.log(" profileBlock response //////////// " + JSON.stringify(data));
    yield put(ACTION.profileBlockSuccess({ data }));
    if (data.status == true) {
      yield put(
        ACTION.getProfileListStart({
          account_id: payload.account_id,
          userId: payload.userId,
        })
      );
      // alert(
      //   "Profile Locked Successfully",
      //   [
      //     {
      //       text: "OKAY",
      //       // onPress: () => RootNavigation.navigate("SelectProfile"),
      //       style: "cancel",
      //     },
      //   ],
      //   {
      //     cancelable: true,
      //   }
      // );
      //   return;
      // } else {
      //   alert(
      //     "Profile Is not  Locked",
      //     [
      //       {
      //         text: "OKAY",
      //         style: "cancel",
      //       },
      //     ],
      //     {
      //       cancelable: true,
      //     }
      //   );

      return;
    }
    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.profileBlockFailed({ message }));
  }
}

function* profileUnBlockSaga(payload) {
  try {
    const { data } = yield call(PROFILE_API.profileUnBlockAsync, payload);

    yield put(ACTION.profileUnBlockSuccess({ data }));
    if (data.status == true) {
      yield put(
        ACTION.getProfileListStart({
          account_id: payload.account_id,
          userId: payload.userId,
        })
      );
      //   alert(
      //     "Profile Unlocked Successfully",
      //     [
      //       {
      //         text: "OKAY",
      //         //onPress: () => RootNavigation.navigate("SelectProfile"),

      //         style: "cancel",
      //       },
      //     ],
      //     {
      //       cancelable: true,
      //     }
      //   );
      //   return;
      // } else {
      //   alert(
      //     "Profile Is not  Unocked",
      //     [
      //       {
      //         text: "OKAY",
      //         style: "cancel",
      //       },
      //     ],
      //     {
      //       cancelable: true,
      //     }
      //   );

      return;
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.profileUnBlockFailed({ message }));
  }
}

function* profilepicUrlSaga(payload) {
  try {
    const { data } = yield call(PROFILE_API.getProfilepicUrlAsync, payload);
    //console.log(" profilepicUrl response //////////// " + JSON.stringify(data));

    yield put(ACTION.profilepicUrlSuccess({ data }));
    if (data.status == true) {
      yield put(AUTHENTICATION_ACTIONS.userDetailsAction({ id: payload.id }));

      // window.location.reload();
    }

    if (data.status == true) {
      // alert(
      //   "Success",
      //   data.message,
      //   [
      //     {
      //       text: "OKAY",
      //       // onPress: () => RootNavigation.navigate("AccountScreen"),
      //       style: "cancel"
      //     }
      //   ],
      //   {
      //     cancelable: true
      //   }
      // );
      return;
    } else {
      alert(
        "DishHome GO Says!",
        data.message,
        [
          {
            text: "OKAY",
            style: "cancel",
          },
        ],
        {
          cancelable: true,
        }
      );

      return;
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.profilepicUrlFailed({ message }));
  }
}

function* profileSendEmailSaga(payload) {
  try {
    const { data } = yield call(PROFILE_API.profileSendEmailAsync, payload);

    yield put(ACTION.profileSendEmailSuccess({ data }));
    if (data.status == true) {
      // alert(
      //   "check your mail ",

      //   [
      //     {
      //       text: "OKAY",
      //       onPress: () => //console.log("ok is pressed"),
      //       style: "cancel",
      //     },
      //   ],
      //   {
      //     cancelable: true,
      //   }
      // );
      return;
    } else {
      alert(
        "DishHome GO!",
        "Something is Worng Please Try Again",
        [
          {
            text: "OKAY",
            style: "cancel",
          },
        ],
        {
          cancelable: true,
        }
      );

      return;
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.profileSendEmailFailed({ message }));
  }
}
function* profileCreateSaga(payload) {
  //alert(JSON.stringify(payload));
  let userId = payload.userId;

  try {
    const { data } = yield call(PROFILE_API.profileCreateAsync, payload);

    yield put(ACTION.profileCreateSuccess({ data }));
    yield put(ACTION.getProfileListStart({ userId: userId }));

    //console.log("profile response ",data)
    if (data.status == true) {
      swal("Successfully Created");
      return;
    } else {
      alert("You Are Cross The Maximum Profiles");

      return;
    }
  } catch ({ message }) {
    yield put(ACTION.profileCreateFailed({ message }));
  }
}
function* getProfileListSaga(payload) {
  try {
    const { data } = yield call(PROFILE_API.getProfileListAsync, payload);

    yield put(ACTION.getProfileListSuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.getProfileListFailed({ message }));
  }
}

function* profileUpdateSaga(payload) {
  let id = payload.updatedBy;

  try {
    const { data } = yield call(PROFILE_API.profileUpdateAsync, payload);

    // alert(JSON.stringify(data))
    yield put(ACTION.profileUpdateSuccess({ data }));
    yield put(ACTION.getProfileListStart({ userId: id }));

    if (data.status == true) {
      swal("Profile Updated Successfully");

      return;
    } else {
      alert(
        "Wrong Input!",
        "Profile Is not Updated",
        [
          {
            text: "OKAY",
            style: "cancel",
          },
        ],
        {
          cancelable: true,
        }
      );

      return;
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.profileUpdateFailed({ message }));
  }
}
function* createProfileSaga(payload) {
  //console.log("createProfile excuteing ////////////////////////////////////////"+ JSON.stringify(payload));
  try {
    const { data } = yield call(PROFILE_API.createProfileAsync, payload);
    //console.log("createProfile excuteing ////////////////////////////////////////"+ JSON.stringify(payload));
    yield put(ACTION.createProfileSuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.createProfileFailed({ message }));
  }
}

function* deleteProfileSaga(payload) {
  //console.log("deleteProfile excuteing ////////////////////////////////////////"+ JSON.stringify(payload));
  try {
    const { data } = yield call(PROFILE_API.deleteProfileAsync, payload);
    //console.log(" deleteProfile response device//////////// " + JSON.stringify(data));
    yield put(ACTION.deleteProfileSuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.deleteProfileFailed({ message }));
  }
}

function* editProfileSaga(payload) {
  //console.log("editProfile excuteing ////////////////////////////////////////"+ JSON.stringify(payload));
  try {
    const { data } = yield call(PROFILE_API.editProfileAsync, payload);
    //console.log(" editProfile response device//////////// " + JSON.stringify(data));
    yield put(ACTION.editProfileSuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.editProfileFailed({ message }));
  }
}

/** Watchers or Observers */
// function* addToRecentWatchesWatcher() {
//   while (true) {
//     const { payload } = yield take(ADD_TO_RECENT_WATCHES_START);
//     yield call(addToRecentWatchesSaga, payload);
//   }
// }

// function* clearRecentWatchesWatcher() {
//   while (true) {
//     const { payload } = yield take(CLEAR_RECENT_WATCHES_START);
//     yield call(clearRecentWatchesSaga, payload);
//   }
// }

// function* createProfileWatcher() {
//   while (true) {
//     const { payload } = yield take(CREATE_PROFILE_START);
//     yield call(createProfileSaga, payload);
//   }
// }

function* deleteProfileWatcher() {
  while (true) {
    const { payload } = yield take(DELETE_PROFILE_START);
    yield call(deleteProfileSaga, payload);
  }
}

// function* editProfileWatcher() {
//   while (true) {
//     const { payload } = yield take(EDIT_PROFILE_START);
//     yield call(editProfileSaga, payload);
//   }
// }

// function* getProfileWatcher() {
//   while (true) {
//     const { payload } = yield take(GET_PROFILS_START);
//     yield call(getProfileSaga, payload);
//   }
// }

function* signupWatcher() {
  while (true) {
    const { payload } = yield take(SIGNUP_START);
    yield call(signupSaga, payload);
  }
}

function* profileBlockWatcher() {
  while (true) {
    const { payload } = yield take(PROFILE_BLOCK_START);
    yield call(profileBlockSaga, payload);
  }
}
function* profileUnBlockWatcher() {
  while (true) {
    const { payload } = yield take(PROFILE_UNBLOCK_START);
    yield call(profileUnBlockSaga, payload);
  }
}
// function* profilepicUrlWatcher() {
//   while (true) {
//     const { payload } = yield take(PROFILEPIC_URL_START);
//     yield call(profilepicUrlSaga, payload);
//   }
// }
function* profileSendEmaiWatcher() {
  while (true) {
    const { payload } = yield take(PROFILE_SENDEMAIL_START);
    yield call(profileSendEmailSaga, payload);
  }
}

function* profilepicUrlWatcher() {
  while (true) {
    const { payload } = yield take(PROFILEPIC_URL_START);
    yield call(profilepicUrlSaga, payload);
  }
}

// function* markRemindedMovieAsReadWatcher() {
//   while (true) {
//     const { payload } = yield take(MARK_REMINDED_MOVIE_AS_READ_START);
//     yield call(markRemindedMovieAsReadSaga, payload);
//   }
// }

// function* rateShowWatcher() {
//   while (true) {
//     const { payload } = yield take(RATE_SHOW_START);
//     yield call(rateShowSaga, payload);
//   }
// }

// function* rateRecentlyWatchedMovieWatcher() {
//   while (true) {
//     const { payload } = yield take(RATE_RECENTLY_WATCHED_MOVIE_START);
//     yield call(rateRecentlyWatchedMovieSaga, payload);
//   }
// }

// function* removeToRecentWatchesWatcher() {
//   while (true) {
//     const { payload } = yield take(REMOVE_TO_RECENT_WATCHES_START);
//     yield call(removeToRecentWatchesSaga, payload);
//   }
// }

// function* selectProfileWatcher() {
//   while (true) {
//     const { payload } = yield take(SELECT_PROFILE_START);
//     yield call(selectProfileSaga, payload);
//   }
// }

// function* showSubscriberWatcher() {
//   while (true) {
//     yield take(SHOW_SUBSCRIBER_START);
//     yield call(showSubscriberSaga);
//   }
// }

// function* toggleAddToMyListWatcher() {
//   while (true) {
//     const { payload } = yield take(TOGGLE_ADD_TO_MY_LIST_START);
//     yield call(toggleAddToMyListSaga, payload);
//   }
// }

// function* toggleRemindMeOfComingShowWatcher() {
//   while (true) {
//     const { payload } = yield take(TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START);
//     yield call(toggleRemindMeOfComingShowSaga, payload);
//   }
// }

// function* updateAuthenticatedProfileWatcher() {
//   while (true) {
//     const { payload } = yield take(UPDATE_AUTHENTICATED_PROFILE_START);
//     yield call(updateAuthenticatedProfileSaga, payload);
//   }
// }

// function* updateRecentlyWatchedAtPositionMillisWatcher() {
//   while (true) {
//     const { payload } = yield take(
//       UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START
//     );
//     yield call(updateRecentlyWatchedAtPositionMillisSaga, payload);
//   }
// }

// function* deviceRegisterWatcher() {
//   while (true) {
//     const { payload } = yield take(DEVICE_REGISTER_START);
//     yield call(deviceRegisterSaga, payload);
//   }
// }
function* getDeviceListWatcher() {
  while (true) {
    const { payload } = yield take(GET_DEVICE_LIST_START);
    yield call(getDeviceListSaga, payload);
  }
}

function* getDeviceListForAddDeviceWatcher() {
  while (true) {
    const { payload } = yield take(GET_DEVICE_LIST_FOR_ADDING_DEVICE_START);
    yield call(getDeviceListForAddDeviceSaga, payload);
  }
}

function* replaceDeviceWatcher() {
  while (true) {
    const { payload } = yield take(REPLACE_DEVICE_START);
    yield call(replaceDeviceSaga, payload);
  }
}
function* profileCreateWatcher() {
  while (true) {
    const { payload } = yield take(PROFILE_CREATE_START);
    yield call(profileCreateSaga, payload);
  }
}
function* getProfileListWatcher() {
  while (true) {
    const { payload } = yield take(GET_PROFILE_LIST_START);
    yield call(getProfileListSaga, payload);
  }
}
function* profileUpdateWatcher() {
  while (true) {
    const { payload } = yield take(PROFILE_UPDATE_START);
    yield call(profileUpdateSaga, payload);
  }
}

export default function*() {
  yield all([
    // addToRecentWatchesWatcher(),
    // clearRecentWatchesWatcher(),
    // createProfileWatcher(),
    deleteProfileWatcher(),
    // editProfileWatcher(),
    // getProfileWatcher(),

    signupWatcher(),
    // markRemindedMovieAsReadWatcher(),
    // rateShowWatcher(),
    // rateRecentlyWatchedMovieWatcher(),
    // removeToRecentWatchesWatcher(),
    // selectProfileWatcher(),
    // showSubscriberWatcher(),
    // toggleAddToMyListWatcher(),
    // toggleRemindMeOfComingShowWatcher(),
    // updateAuthenticatedProfileWatcher(),
    // updateRecentlyWatchedAtPositionMillisWatcher(),
    // deviceRegisterWatcher(),
    getDeviceListWatcher(),
    getDeviceListForAddDeviceWatcher(),
    replaceDeviceWatcher(),
    profileCreateWatcher(),
    getProfileListWatcher(),
    profileUpdateWatcher(),
    profileBlockWatcher(),
    profileUnBlockWatcher(),
    profileSendEmaiWatcher(),
    profilepicUrlWatcher(),
  ]);
}
