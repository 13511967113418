/** @format */

import { createSelector } from "reselect";
const getMovieDetails = (state) => state.movies;

const getMovieAssetDetails = (state) => state.movies.movieliveAssetDetails;
const getMovieGenreDetails = (state) => state.movies.moviegenres;
const getmovieGenreAssetForListing = (state) =>
  state.movies.movieGenreAssetForListing;
const getTestingData = (state) => state.movies.testingData;

const getMovieDataLoading = (state) => state.movies.movieLoading;

const getMovieAssetLength = (state) => state.movies.vodAssetLength;

export const getMovieAssetLengthSelector = createSelector(
  getMovieAssetLength,
  (vodAssetLength) => vodAssetLength
);
export const getMovieDataLoadingSelector = createSelector(
  getMovieDataLoading,
  (movieLoading) => movieLoading
);
export const getTestingDataSelector = createSelector(
  getTestingData,
  (testingData) => testingData
);
export const getmovieGenreAssetForListingSelector = createSelector(
  getmovieGenreAssetForListing,
  (movieGenreAssetForListing) => movieGenreAssetForListing
);
export const getMovieDetaisSelector = createSelector(
  getMovieDetails,
  (movies) => movies
);

export const getMovieAssetDetailsSelector = createSelector(
  getMovieAssetDetails,
  (movieliveAssetDetails) => movieliveAssetDetails
);

export const getMovieGenreDetailsSelector = createSelector(
  getMovieGenreDetails,
  (moviegenres) => moviegenres
);
