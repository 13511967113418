/** @format */

import authReducer from "./auth/reducer";
import platformsReducer from "./platforms/reducer";
import { combineReducers } from "redux";
import SaveDeviceTockenReducer from "./SaveDeviceTocken/reducer";
import epgReducer from "./EPG/reducer";
import passwordInfoReducer from "./../Redux/manageProfile/passwordInfo/reducer";
import emailInfoReducer from "./manageProfile/emailInfo/reducer";
import mobileInfoReducer from "./manageProfile/mobileInfo/reducer";
import personalInfoReducer from "./manageProfile/personalInfo/reducer";
import favouriteReducer from "./favourites/reducer";
import getFavouriteReducer from "./FavouteDataRedux/reducer";
import movieReducer from "./MoviesRedux/reducer";
import liveReducer from "./live/reducer";
import seriesGenreReducer from "./SeriesGenre/reducer";
import CheckMailValidityReducer from "./Checking the mail validity/reducer";
import activeEmailReducer from "./email-activation/reducer";
import resetPasswordReducer from "./../Redux/resetPassword/reducer";
import forgotPasswordReducer from "./../Redux/forgot-password/reducer";
import VideoReducer from "./../Redux/VideoRedux/reducer";
import VideoAdsReducer from "./../Redux/video-adverticement/reducer";
import DataAnalyticsReducer from "./../Redux/DATA-analytics/reducer";
import NotificationReducer from "./../Redux/PushNotification/reducer";
import DetailsReducer from "./../Redux/Notication Assets redux/reducer";
import SearchReducer from "./../Redux/Search/reducer";
import addDeviceReducer from "./deviceRedux/reducer";
import deleteDevideReducer from "./Delete Device/reducer";
import DefaultPlatformReducer from "./DefaultPlatform/reducer";

import DrmDetailsReducer from "./DRM integration/reducer";
import AllLiveAssetReducer from "./AllLiveDetails/reducer";
import vodAsset from "./playerMovieData/reducer";
import packageDetailsReducer from "./package&subscriptions/reducer";
import dataVersionReducer from "./Dataversion/reducer";
import latestEpgReducer from "./LatestEPG/reducer";
import authenticationReducer from "./authentication/reducer";
const config = {
  key: "root",
  blacklist: ["navigation", "toast"], // array list not to persits
  debug: true, //to get useful logging
};

const rootReducers = combineReducers({
  SaveDeviceTockenReducer,
  auth: authReducer,
  platforms: platformsReducer,
  liveAssets: liveReducer,
  epg: epgReducer,
  passwordInfoReducer,
  emailInfoReducer,
  mobileInfoReducer,
  personalInfoReducer,
  setFavourites: favouriteReducer,
  getFavouriteData: getFavouriteReducer,
  movies: movieReducer,
  seriesGenre: seriesGenreReducer,
  emailActivation: activeEmailReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  videoReducer: VideoReducer,
  VideoAds: VideoAdsReducer,
  DataAnalytics: DataAnalyticsReducer,
  Notification: NotificationReducer,
  detailsReducer: DetailsReducer,
  searchReducer: SearchReducer,
  addDevices: addDeviceReducer,
  deleteDevice: deleteDevideReducer,
  defaultPlatform: DefaultPlatformReducer,
  DrmDetails: DrmDetailsReducer,
  CheckMailValidityReducer,
  AllLiveAssetReducer,
  vodAsset,
  packageDetailsReducer,
  dataVersionReducer,
  latestEpgReducer,
  authenticationReducer: authenticationReducer,
});

export default rootReducers;
