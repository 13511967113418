/** @format */

import React, { useEffect } from "react";
import SeriesVideoJs from "./SeriesVideoJs";

const videoJsOptions = {
  controls: true,

  aspectRatio: "16:8",
  autoplay: true,
  preload: "auto",
  responsive: true,
  // playsinilie: true,
  shareMenu: false,
};

const SeriesVideoPlayer = ({ setIsVideoPlayer, setSelectedCategory }) => {
  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);
  return (
    <div className="App">
      <SeriesVideoJs
        videoJsOptions={videoJsOptions}
        setSelectedCategory={setSelectedCategory}
      />
    </div>
  );
};
export default SeriesVideoPlayer;
