/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./action";
import * as ACTIVE_API from "./../../services/resetPassword/resetpassword";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import { baseName } from "../../components/IpConstantFile/IpConstantFile.js";

const { RESET_PASSWORD_START } = ACTION_TYPES;

function* resetPasswordSaga(payload) {
  try {
    const { data } = yield call(ACTIVE_API.resetPasswordAsync, payload);

    yield put(ACTION.resetPasswordSuccess({ data }));
    if (data.status == true) {
      // Swal.fire({
      //   icon: "success",
      //   // position: "top-end",
      //   text: data.message,
      //   allowOutsideClick: () => !swal.isLoading(),
      //   confirmButtonColor: "#3085d6",
      //   button: "OK",
      // }).then((OK) => {
      //   if (OK) {
      //     // alert("hhhhh");
      //     window.location.href = "//login";
      //   }
      // });

      swal({
        text: "Successfully updated",
        icon: "success",
        closeOnClickOutside: false,
        // dangerMode: true,
        confirmButtonColor: "#3085d6",
        button: "OK",
      }).then((ok) => {
        if (ok) {
          window.location.href = baseName + "login";
        }
      });
      //
    }
  } catch ({ message }) {
    // swal(message)
    yield put(ACTION.resetPasswordFailed({ message }));
  }
}

function* restWatcher() {
  while (true) {
    const { payload } = yield take(RESET_PASSWORD_START);
    yield call(resetPasswordSaga, payload);
  }
}

export default function*() {
  yield all([restWatcher()]);
}
