import { createSelector } from "reselect";
const getPlatforms = (state) => state.platforms;

const getPlatformsLoading = (state) => state.platforms.platformLoading;
const getCurrentPlatform = (state) => state.platforms.currentPlatformData;

export const platformsSelector = createSelector(
  getPlatforms,
  (platforms) => platforms
);

export const platformsLoadingSelector = createSelector(
  getPlatformsLoading,
  (platformLoading) => platformLoading
);

export const getCurrentPlatformSelector = createSelector(
  getCurrentPlatform,
  (currentPlatformData) => currentPlatformData
);
