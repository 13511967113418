import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
// import * as API from "./../../../services/movie/movie";
import * as LIVE_API from "./../../services/live/live";
import * as ACTION_DATA_VERSIONING from "../Dataversion/actions";

// import * as MOVIE_NOTIFICATION_API from "./../../../services/movie/movie.notification";
import {
  getLiveAssetsSucces,
  getLiveAssetsFailed,
  getBannersSuccess,
  getBannersFailed,
  getGeneralSettingsSuccess,
  getGeneralSettingsFailed,
} from "./actions";

const {
  GET_LIVE_ASSETS_START,
  GET_BANNERS_START,
  GET_GENERAL_SETTINGS_START,
} = ACTION_TYPES;

/** Sagas */

function* getLiveAssetsSaga(payload) {
  try {
    const { data: movies } = yield call(LIVE_API.liveAsync, payload);
    const genreDetails = { genreId: movies[0].id, genreAssets: movies };
    yield put(getLiveAssetsSucces({ genreDetails }));
  } catch ({ message }) {
    yield put(getLiveAssetsFailed({ message }));
  }
}

function* getBannersSaga(payload) {
  let payload1 = {
    account_id: 1,
  };
  try {
    const { data } = yield call(LIVE_API.bannerAsync, payload);
    // console.log("LIVEASSETS?.banners" + JSON.stringify(data));

    yield put(getBannersSuccess({ data }));

    yield put(ACTION_DATA_VERSIONING.nullDataVersions("banner"));
  } catch ({ message }) {
    yield put(getBannersFailed({ message }));
  }
}

function* getGeneralSettingsSaga(payload) {
  try {
    const { data } = yield call(LIVE_API.generalDataAsync, payload);

    yield put(getGeneralSettingsSuccess({ data }));
  } catch ({ message }) {
    yield put(getGeneralSettingsFailed({ message }));
  }
}

/** Watchers or Observers */

function* getLiveAssetsWatcher() {
  while (true) {
    const { payload } = yield take(GET_LIVE_ASSETS_START);
    yield call(getLiveAssetsSaga, payload);
  }
}
function* getBannerWatcher() {
  while (true) {
    const { payload } = yield take(GET_BANNERS_START);
    yield call(getBannersSaga, payload);
  }
}

function* getGeneralDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_GENERAL_SETTINGS_START);
    yield call(getGeneralSettingsSaga, payload);
  }
}

export default function*() {
  yield all([
    getLiveAssetsWatcher(),
    getBannerWatcher(),
    getGeneralDataWatcher(),
  ]);
}
