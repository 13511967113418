import { createSelector } from "reselect";

const getDeviceToken = (state) => {
  // alert("selector" + state.addDevices.deviceToken);
  return state.addDevices.deviceToken;
};
const getDeviceName = (state) => {
  // alert("selector" + state.addDevices.deviceToken);
  return state.addDevices.deviceName;
};
const getDeviceDetails = (state) => {
  return state.addDevices;
};

const getDeviceTockenStatus = (state) => {
  return state.addDevices.deviceTokenStatus;
};

export const getDeviceNameSelector = createSelector(
  getDeviceName,
  (deviceName) => deviceName
);

export const getDeviceTokenSelector = createSelector(
  getDeviceToken,
  (deviceToken) => deviceToken
);

export const getDeviceDetailsSelector = createSelector(
  getDeviceDetails,
  (addDevices) => addDevices
);

export const getDeviceTockenStatusSelector = createSelector(
  getDeviceTockenStatus,
  (deviceTokenStatus) => deviceTokenStatus
);
