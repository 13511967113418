import { all, take, put, call } from "redux-saga/effects";
import ACTIONSTYPES from "./actionTypes";
import * as DATAVERSION_ACTION from "./actions";
import * as API from "./../../services/DataVersions/dataVersions";

const { CHECK_DATA_VERSIONS } = ACTIONSTYPES;

function* getCheckDataVersionSaga() {
  // alert("saga calling");
  try {
    const { data } = yield call(API.getDataVersionAsync);

    yield put(DATAVERSION_ACTION.storingDataVersions(data));
  } catch ({ message }) {}
}
function* getCheckDataVersionWatcher() {
  while (true) {
    yield take(CHECK_DATA_VERSIONS);
    yield call(getCheckDataVersionSaga);
  }
}

export default function*() {
  yield all([getCheckDataVersionWatcher()]);
}
