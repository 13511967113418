import React, { useState } from "react";
import ReactDOM from "react-dom";

import Modal from "react-modal";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "50%",
    height: "50%",
    //overflowY: "hidden",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const setAppElement = "#yourAppElement";

function UpdateProfileModal(props) {
  let imgUrl;
  const classes = useStyles();
  function closeModal() {
    props.setIsOpen(false);
  }
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const onChangeImage = (e) => {
    imgUrl = URL.createObjectURL(e.target.files[0]);
    //console.log("url", imgUrl);
    setUrl(imgUrl);
    setFile(e.target.files[0]);
    //console.log("file", e.target.files[0]);
  };
  const uploadFile = () => {
  //console.log("profile fdfghjkl;kjhgffghjkl url",file)
    props.setProfileUrl(url);
    props.setProfile(file);
    props.setIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          color="white"
          onClick={closeModal}
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onChangeImage}
        />
        <Grid container justify="center">
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Choose file
            </Button>
          </label>
        </Grid>
        <img
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          width="300px"
          src={url}
          alt=""
        />
        {url.length != 0 ? (
          <div>
            <Grid container justify="center">
              <Button style={{ color: "white" }} onClick={uploadFile}>
                Upload
              </Button>
            </Grid>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
export default UpdateProfileModal;

// const [img, setImg] = useState("");
// const [imgName, setImgName] = useState("");
// const [type, setType] = useState("");
////////////////////
// props.setProfileName(imgName);
// props.setProfileType(type);
///////////////////////
// const formData = new FormData();
// formData.append("file", img);
// formData.append("fileName", imgName);
// try {
//   const res = await axios.post(
//     "http://10.0.90.25:3000/pixfixws/update/profile/" + updateProfile.id,
//     formData
//   );
//   //console.log(res);
// } catch (ex) {
//   //console.log(ex);
// }
/////////// // //console.log("filename", imgName);
// //console.log("type", type);
///////////////////
// setImg(e.target.files[0]);
// setImgName(e.target.files[0].name);
// setType(e.target.files[0].type);
//////////////// // var lastindexof = e.target.files[0].name.lastIndexOf(".");
// //console.log("lastindexx//", lastindexof);
// setUrl(imgUrl + e.target.files[0].name.substr(lastindexof));
// //console.log("new url//////////////////", url);
// alert(e.target.files[0].path);
