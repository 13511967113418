import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as SEARCH_API from "../../services/Search/search";

const {
  GET_SEARCH_DATA_START,
 
  
} = ACTION_TYPES;

/** Sagas */

function* getSearchDataSaga(payload) {
  try {
    //console.log("get live assets calling");
    const { data } = yield call(SEARCH_API.searchDataAsync, payload);
   
    yield put(ACTION.getSearchSuccess({ data }));
    // if(data.status == true){
    //   yield put(ACTION.getSearchStart(payload))
    // }
    // alert(JSON.stringify(data))
  } catch ({ message }) {
    yield put(ACTION.getSearchFailed({ message }));
  }
}



/** Watchers or Observers */

function* getSearchWatcher() {
  while (true) {
    const { payload } = yield take(GET_SEARCH_DATA_START);
    yield call(getSearchDataSaga, payload);
  }
}





export default function* () {
  yield all([
    getSearchWatcher(),
    
   
  ]);
}
