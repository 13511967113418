import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionType";
import * as ACTION from "./action";
import * as GET_FAVOURATE_DATA_API from "./../../services/favourites/getFavouriteList";
const { GET_FAVOURITES_DATA_START } = ACTION_TYPES;

function* getFavouriteDataSaga(payload) {
  // console.log("getFavouriteSelector" + JSON.stringify(payload));
  try {
    const { data } = yield call(
      GET_FAVOURATE_DATA_API.addFavouritesDetailsAsync,
      payload
    );
    // console.log("watcherr" + JSON.stringify(data));

    yield put(ACTION.getFavouriteDataSuccess({ data }));
  } catch ({ message }) {
    yield put(ACTION.getFavouriteDataFailed({ message }));
  }
}

function* getFavouriteDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_FAVOURITES_DATA_START);
    yield call(getFavouriteDataSaga, payload);
  }
}

export default function*() {
  yield all([getFavouriteDataWatcher()]);
}
