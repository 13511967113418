import ACTION_TYPES from "./action.types";

const {
  LOGIN_SUCCESS_NEW,
  LOGIN_FAILED,
  VERIFY_LOGIN_START,

  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,

  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,

  USER_DETAILS_START,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILED,

  LOGIN_STATUS_FALSE,
  USER_DETAILS_ACTION,
  UPDATE_ACCESS_TOKEN,
} = ACTION_TYPES;

export const loginSuccessNew = (payload) => ({
  type: LOGIN_SUCCESS_NEW,
  payload,
});

export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const verifyLoginStart = (payload) => ({
  type: VERIFY_LOGIN_START,
  payload,
});

/* Logout */
export const logoutStart = (payload) => ({
  type: LOGOUT_START,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const updateAccessToken = (payload) => ({
  type: UPDATE_ACCESS_TOKEN,
  payload,
});

export const logoutFailed = (payload) => ({
  type: LOGOUT_FAILED,
  payload,
});

/* Signup */
export const signupStart = (payload) => ({
  type: SIGNUP_START,
  payload,
});

export const signupSuccess = (payload) => ({
  type: SIGNUP_SUCCESS,
  payload,
});

export const signupFailed = (payload) => ({
  type: SIGNUP_FAILED,
  payload,
});

/* user details */

export const userDetailsStart = (payload) => ({
  type: USER_DETAILS_START,
  payload,
});

export const userDetailsSuccess = (payload) => ({
  type: USER_DETAILS_SUCCESS,
  payload,
});

export const userDetailsFailed = (payload) => ({
  type: USER_DETAILS_FAILED,
  payload,
});

/* Login staus */
export const loginStausFalse = () => ({
  type: LOGIN_STATUS_FALSE,
});

/* user */
export const userDetailsAction = (payload) => ({
  type: USER_DETAILS_ACTION,
  payload,
});
