import { createSelector } from "reselect";

const getAuth = (state) => {
  //console.log("data in state..............", state);
  return state.auth;
};

const getProfileLoading = (state) => state.auth.profieLoading;
const getDeviceLoading = (state) => state.auth.deviceLoadingStatus;
const getDeviceStatusForDeleteDevice = (state) =>
  state.auth.Device_Status_For_Delete_Device;

// const getUser = (state) => state.auth.auth.user;

// const getAuthProfile = state => {

//     const { auth: { profiles, profile } } = state;

//     if (! profiles.length) {
//         return profiles;
//     }

//     return profiles.find(prof => prof?.id === profile.id) || profile;
// }

// const getAuthDevice = state => {

//     const { auth: { devices, device } } = state;

//     if (! devices.length) {
//         return devices;
//     }

//     return profiles.find(prof => prof?.id === device.id) || device;
// }
// const getErrorMessages = state => state.auth.errors;
const getProfiles = (state) => state.auth.profiles;

const getProfilePicLoading = (state) => state.auth.profilePicLoading;

const getAthentication = (state) => state.auth.isAuthenticated;
const getReplaceDeviceStatus = (state) => state.auth.ReplaceDeviceStatus;

const getProfileAuth = (state) => {
  // alert("poojassssssssssssssss"+JSON.stringify(state.auth.profileState))
  return state.auth.profileState;
};
export const getProfileAuthSelector = createSelector(
  getProfileAuth,
  (profileState) => profileState
);

export const getReplaceDeviceStatusSelector = createSelector(
  getReplaceDeviceStatus,
  (ReplaceDeviceStatus) => ReplaceDeviceStatus
);

// const getDevices = state => state.auth.getdevicelis;
// const getChannelList= state=>state.auth.channelList;
// const getProgramList= state=>state.auth.programlist;

// export const authProfileSelector = createSelector(getAuthProfile, authProfile => authProfile);
// export const authDeviceSelector= createSelector (getAuthDevice ,  authDevice => authDevice);
// export const authChannelListSelector= createSelector (getChannelList ,  authChannelList =>  authChannelList);
// export const authProgramListSelector= createSelector (getProgramList , authChannelList => authChannelList);
export const authSelector = createSelector(getAuth, (auth) => auth);

export const getAthenticationSelector = createSelector(
  getAthentication,
  (isAuthenticated) => isAuthenticated
);
export const getProfileSelector = createSelector(
  getProfiles,
  (profiles) => profiles
);

export const getProfilePicLoadingSelector = createSelector(
  getProfilePicLoading,
  (profilePicLoading) => profilePicLoading
);

export const getProfileLoadingSelector = createSelector(
  getProfileLoading,
  (profieLoading) => profieLoading
);

export const getDeviceLoadingSelector = createSelector(
  getDeviceLoading,
  (deviceLoadingStatus) => deviceLoadingStatus
);
export const getDeviceStatusForDeleteDeviceSelector = createSelector(
  getDeviceStatusForDeleteDevice,
  (Device_Status_For_Delete_Device) => Device_Status_For_Delete_Device
);
// export const userSelector = createSelector(getUser, user => user);

// export const selectAuthErrorMessages = createSelector(getErrorMessages, errors => errors);

// export const selectAuthHasErrorMessages = createSelector(getErrorMessages, errors =>
// {
//     let hasErrorMessages = {};

//     for (const key in errors) {
//         hasErrorMessages = {
//             ...hasErrorMessages,
//             [key]: Boolean(errors[key])
//         };
//     }

//     return hasErrorMessages;
// });

// export const selectOrderedProfiles = createSelector([getDevices, authProfileSelector], (profiles, authProfile) =>
// {
//     if (authProfile.id) {
//         const middleIndex = Math.floor(profiles.length / 2);
//         const currentProfileIndex = profiles.findIndex(profile => profile.id === authProfile.id);

//         const getCenteredProfile = profiles[middleIndex];

//         profiles.splice(middleIndex, 1, authProfile);
//         profiles.splice(currentProfileIndex, 1, getCenteredProfile);
//     }

//     return profiles;
// });

// export const selectOrderedDevices = createSelector([getProfiles, authDeviceSelector], (devices, authDevice) =>
// {
//     if (authDevice.id) {
//         const middleIndex = Math.floor(devices.length / 1);
//         const currentDeviceIndex = devices.findIndex(device => device.id === authDevice.id);

//         const getCenteredDevice = profiles[middleIndex];

//         devices.splice(middleIndex, 1, authDevice);
//         devices.splice(currentDeviceIndex, 1, getCenteredDevice);
//     }

//     return devices;
// });
