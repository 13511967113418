import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as ACTION_TYPES from "./../../Redux/auth/actions";
import * as CURRENT_PLATFORM_ACTION from "./../../Redux/platforms/actions";

import { createStructuredSelector } from "reselect";
import {
  authSelector,
  getDeviceLoadingSelector,
  getProfileLoadingSelector,
  getProfileSelector,
} from "./../../Redux/auth/selectors";
import OpenLockProfile from "./OpenLockProfile";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import { platformsSelector } from "../../Redux/platforms/selectors";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
function ProfileList({
  AUTH,
  DEVICE_TOKEN,
  PLATFORMS,
  DEVICE_LOADING,
  USER_DETAILS,
}) {
  //console.log("profile detailsss" + JSON.stringify(AUTH));

  // alert("userId" + JSON.stringify(userId));

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ACTION_TYPES.getProfileListStart({ userId: USER_DETAILS?.id }));

    // dispatch(ACTION_TYPES_LOGIN.loginStart({ credentials, history: history }));  if auto login needed
    dispatch(
      CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(USER_DETAILS?.orgId)
    );
    // dispatch(ACTION_TYPES.getDeviceListStart(deviceData));
  }, []);
  const handleProfile = () => {
    history.push("/HandleProfile");
  };
  const handleContainer = () => {
    setTimeout(function() {
      window.location.reload();
    }, 100);
    history.push("/container");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [secreteKey, setSecreteKey] = useState();
  const [loockedId, setLockedId] = useState();

  const handleOpenModal = (secreteKey, id) => {
    setSecreteKey(secreteKey);
    setLockedId(id);

    setIsOpen(true);
  };
  if (modalIsOpen == true) {
    return (
      <OpenLockProfile
        id={loockedId}
        secretKey={secreteKey}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
      />
    );
  }

  return (
    <div style={{ marginLeft: "30%" }}>
      <h3 style={{ color: "white", marginLeft: "12%" }}> Who's Watching !!!</h3>
      <div style={{ marginLeft: "40%", marginTop: "10px", color: "white" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-pencil"
          viewBox="0 0 16 16"
          color="white"
          onClick={handleProfile}
          style={{ marginLeft: "2%" }}
        >
          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
        </svg>
      </div>

      {AUTH.profiles != undefined && AUTH.profiles != null
        ? AUTH.profiles.map((item, index) => (
            <div
              key={index}
              className="row"
              style={{
                marginTop: "20px",
                display: "inline-block",
              }}
            >
              {item.secretKey != undefined ? (
                <div className="form-group col-md-5">
                  {item.profileUrl.indexOf("http://") >= 0 ? (
                    <span>
                      <img
                        src={item.profileUrl}
                        width="350px"
                        style={{
                          height: "150px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModal(item.secretKey, item.id)}
                      />

                      <div
                        style={{ color: "white", fontSize: "14px" }}
                        key={index}
                      >
                        {item.name.toUpperCase()}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-lock"
                          viewBox="0 0 16 16"
                          color="white"
                          style={{ marginLeft: "10%" }}
                        >
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                        </svg>
                      </div>
                    </span>
                  ) : (
                    <span>
                      <img
                        src={newImageIpPath + item.profileUrl}
                        width="350px"
                        style={{
                          height: "150px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModal(item.secretKey, item.id)}
                      />

                      <div
                        style={{ color: "white", fontSize: "14px" }}
                        key={index}
                      >
                        {item.name.toUpperCase()}

                        {item.secretKey != undefined ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-lock"
                            viewBox="0 0 16 16"
                            color="white"
                            style={{ marginLeft: "10%" }}
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                          </svg>
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  )}
                </div>
              ) : (
                <div className="form-group col-md-5">
                  {item.profileUrl.indexOf("http://") >= 0 ? (
                    <span onClick={handleContainer}>
                      <img
                        src={item.profileUrl}
                        width="350px"
                        style={{
                          height: "150px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={handleContainer}
                      />

                      <div
                        style={{ color: "white", fontSize: "14px" }}
                        key={index}
                      >
                        {item.name.toUpperCase()}

                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-lock"
                          viewBox="0 0 16 16"
                          color="white"
                          style={{ marginLeft: "10%" }}
                        >
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                        </svg> */}
                      </div>
                    </span>
                  ) : (
                    <span>
                      <img
                        src={newImageIpPath + item.profileUrl}
                        width="350px"
                        style={{
                          height: "150px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={handleContainer}
                      />
                      <div
                        style={{ color: "white", fontSize: "14px" }}
                        key={index}
                      >
                        {item.name.toUpperCase()}

                        {item.secretKey != undefined ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-lock"
                            viewBox="0 0 16 16"
                            color="white"
                            style={{ marginLeft: "10%" }}
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                          </svg>
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  )}
                </div>
              )}
            </div>
          ))
        : ""}
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  // PROFILELIST: getProfileSelector,
  AUTH: authSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  PLATFORMS: platformsSelector,
  DEVICE_LOADING: getDeviceLoadingSelector,
  PROFILELOADING: getProfileLoadingSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(ProfileList);
// if (
//   PROFILELIST.PROFILELIST.profiles != undefined &&
//   PROFILELIST.PROFILELIST.profiles != null &&
//   PROFILELIST.PROFILELIST.profiles.length > 0
// ) {
////////
// let ProfileUrl = PROFILELIST.PROFILELIST.map((item) => {
//   return item;
// });
// //console.log("gdtrfyr",JSON.stringify(ProfileUrl));
// }
// alert("profile url" + JSON.stringify(PROFILELIST.PROFILELIST[0].profileUrl));
// const [prefix, setPrefix] = useState();
