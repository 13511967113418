import ACTION_TYPES from "./actionTypes";
const {
  SET_LIVE_VIDEO_URL,
  SET_MOVIE_VIDEO_URL,
  SET_TIMESTAMP,
  EPG_VIDEO_URL,
  GET_DRM_CATCHUP_REQUIRED,
  GET_LIVE_API_DATA,
  GET_LIVE_DETAILS_START,
  GET_LIVE_DETAILS_SUCESS,
  GET_LIVE_DETAILS_FAILED,
  LIVE_CHANNEL_DATA,
  GENRE_CHANNELS,
  GET_MOVIE_DETAILS_START,
  GET_MOVIE_DETAILS_SUCESS,
  GET_MOVIE_DETAILS_FAILED,
  MOVIE_DATA,
  GET_SERIES_DETAILS_START,
  GET_SERIES_DETAILS_SUCESS,
  GET_SERIES_DETAILS_FAILED,
  SERIES_DATA,
} = ACTION_TYPES;
export const getLiveVideoUrl = (payload) => ({
  type: SET_LIVE_VIDEO_URL,
  payload,
});
export const getMovieVideoUrl = (payload) => ({
  type: SET_MOVIE_VIDEO_URL,
  payload,
});
export const getEpgVideoUrl = (payload) => ({
  type: EPG_VIDEO_URL,
  payload,
});
export const setTimeStamp = (payload) => ({
  type: SET_TIMESTAMP,
  payload,
});
export const getDrmCatchupRequired = (payload) => ({
  type: GET_DRM_CATCHUP_REQUIRED,
  payload,
});
export const getLiveApiData = (payload) => ({
  type: GET_LIVE_API_DATA,
  payload,
});
export const getLiveDetailsStart = (payload) => ({
  type: GET_LIVE_DETAILS_START,
  payload,
});
export const getLiveDetailsSuccess = (payload) => ({
  type: GET_LIVE_DETAILS_SUCESS,
  payload,
});
export const getLiveDetailsFailed = (payload) => ({
  type: GET_LIVE_DETAILS_FAILED,
  payload,
});
export const getLiveChannelData = (payload) => ({
  type: LIVE_CHANNEL_DATA,
  payload,
});
export const getGenreChannels = (payload) => ({
  type: GENRE_CHANNELS,
  payload,
});
//movies data

export const getMovieDetailsStart = (payload) => ({
  type: GET_MOVIE_DETAILS_START,
  payload,
});
export const getMovieDetailsSuccess = (payload) => ({
  type: GET_MOVIE_DETAILS_SUCESS,
  payload,
});

export const getMovieDetailsFailed = (payload) => ({
  type: GET_MOVIE_DETAILS_FAILED,
  payload,
});

export const getMovieData = (payload) => ({
  type: MOVIE_DATA,
  payload,
});

//Series data

export const getSeriesDetailsStart = (payload) => ({
  type: GET_SERIES_DETAILS_START,
  payload,
});
export const getSeriesDetailsSuccess = (payload) => ({
  type: GET_SERIES_DETAILS_SUCESS,
  payload,
});

export const getSeriesDetailsFailed = (payload) => ({
  type: GET_SERIES_DETAILS_FAILED,
  payload,
});

export const getSeriesData = (payload) => ({
  type: SERIES_DATA,
  payload,
});
