/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as LIVE_API from "./../../services/live/live";
import * as ACTIONS from "./actions";

import * as ACTION_DATA_VERSIONING from "../../Redux/Dataversion/actions";

const {
  GET_ALL_LIVE_ASSETS_START,
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START,
  GET_GENERAL_DETAILS_START,
} = ACTION_TYPES;

/** Sagas */

function* getAllLiveAssetsSaga(payload) {
  try {
    const { data } = yield call(LIVE_API.allLiveAssetsAsync, payload);
    if (data?.status == true) {
      yield put(ACTIONS.getAllLiveAssetsSucces(data));
      yield put(ACTION_DATA_VERSIONING.nullDataVersions("live"));
    }
  } catch ({ message }) {
    yield put(ACTIONS.getAllLiveAssetsFailed({ message }));
  }
}

function* getAllAssetsBasedOnKeySaga(payload) {
  try {
    const { data } = yield call(LIVE_API.allLiveAssetsBasedOnKeyAsync, payload);

    yield put(ACTIONS.getAllLiveAsetsBasedOnKeySucces(data));
  } catch ({ message }) {
    yield put(ACTIONS.getAllLiveAsetsBasedOnKeyFailed({ message }));
  }
}

function* getGeneralDetailsSaga(payload) {
  try {
    const { data } = yield call(LIVE_API.getGeneralDetailsAsync, payload);

    yield put(ACTIONS.getGeneralDetailsSucces(data));

    yield put(ACTION_DATA_VERSIONING.nullDataVersions("generalDetails"));
  } catch ({ message }) {
    yield put(ACTIONS.getGeneralDetailsFailed({ message }));
  }
}

/** Watchers or Observers */

function* getAllLiveAssetsWatcher() {
  while (true) {
    const { payload } = yield take(GET_ALL_LIVE_ASSETS_START);
    yield call(getAllLiveAssetsSaga, payload);
  }
}

function* getAllAssetsBasedOnKeyWatcher() {
  while (true) {
    const { payload } = yield take(GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START);
    yield call(getAllAssetsBasedOnKeySaga, payload);
  }
}

function* getGeneralDetailsWatcher() {
  while (true) {
    const { payload } = yield take(GET_GENERAL_DETAILS_START);
    yield call(getGeneralDetailsSaga, payload);
  }
}

export default function*() {
  yield all([
    getAllLiveAssetsWatcher(),
    getAllAssetsBasedOnKeyWatcher(),
    getGeneralDetailsWatcher(),
  ]);
}
