/** @format */

const ACTION_TYPES = {
  GET_ALL_LIVE_ASSETS_START: "GET_ALL_LIVE_ASSETS_START",
  GET_ALL_LIVE_ASSETS_SUCCESS: "GET_ALL_LIVE_ASSETS_SUCCESS",
  GET_ALL_LIVE_ASSETS_FAILED: "GET_ALL_LIVE_ASSETS_FAILED",

  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START:
    "GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START",
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS:
    "GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS",
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED:
    "GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED",

  GET_GENERAL_DETAILS_START: "GET_GENERAL_DETAILS_START",
  GET_GENERAL_DETAILS_SUCCESS: "GET_GENERAL_DETAILS_SUCCESS",
  GET_GENERAL_DETAILS_FAILED: "GET_GENERAL_DETAILS_FAILED",

  GET_LIVE_ASSETS_OFFSET_WISE: "GET_LIVE_ASSETS_OFFSET_WISE",
  GET_ALL_LIVE_ASSETS_FOR_LISTING: "GET_ALL_LIVE_ASSETS_FOR_LISTING",
};

export default ACTION_TYPES;
