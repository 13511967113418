import ACTION_TYPES from "./actionTypes";
const {
    FORGOT_RESET_PASSWORD_START,
    FORGOT_RESET_PASSWORD_SUCCESS,
    FORGOT_RESET_PASSWORD_FAILED,
    FORGOT_RESET_PASSWORD_STATUS,
} = ACTION_TYPES;

const initialState = {
 forgotPasswordInfo:{},
 forgotreserPasswordStatus:false,
  isLoading: false,
  errors: "",
  
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  

  switch (type) {
    case FORGOT_RESET_PASSWORD_START:
      
      return {
        ...state,
        isLoading: true,
        errors: "",
      };
    case FORGOT_RESET_PASSWORD_SUCCESS:
    
     
      return {
        ...state,
        forgotPasswordInfo:payload,
        forgotreserPasswordStatus:true,

        isLoading,
      };

    case FORGOT_RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        forgotreserPasswordStatus:false,
      };

      case FORGOT_RESET_PASSWORD_STATUS:
        return {
          ...state,
          isLoading,
          forgotreserPasswordStatus:true
          
        };
     
     
    default:
      return state;
  }
};
