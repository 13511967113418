import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as DRM_API from "./../../services/DRM details/DrmService";
// import { Alert } from "react-native";
const { GET_DRM_DETAILS_START } = ACTION_TYPES;

function* getDrmDetailsSaga() {
  try {
    const { data } = yield call(DRM_API.getDrmDetailsAsync);
    // alert("dataa      " + JSON.stringify(data));
    yield put(ACTION.getDrmDetailsSuccess(data));

    // RootNavigation.navigate("packageDetails");
  } catch (error) {
    // alert(JSON.stringify(error));
    yield put(ACTION.getDrmDetailsFailed(error));
  }
}

function* getDrmDetailstWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    yield take(GET_DRM_DETAILS_START);
    yield call(getDrmDetailsSaga);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([getDrmDetailstWatcher()]);
}
