/** @format */

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
  getmovieGenreAssetForListingSelector,
  getMovieGenreDetailsSelector,
} from "../../../Redux/MoviesRedux/selector";
import * as OFFSET_ACTION from "./../../../Redux/MoviesRedux/actions";
import { getGenreDetailsPropsSelector } from "../../../Redux/live/selectors";
import MovieScreenDisplayMovieImage from "../../MoviesScreens/MovieScreenDisplayMovieImage";
const MovieGenreCategory = ({
  GENRE_PROPS,
  MOVIE_GENRE_ASSETS_FOR_LISTING,
  GENRE,
  setSelectedCategory,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    setSelectedCategory(2);

    history.push("/container");
  };

  useEffect(() => {
    let genreData = GENRE?.filter((a) => {
      if (a.id == GENRE_PROPS?.genreId) {
        // alert(JSON.stringify(a));
        return a;
      }
    });
    let data = {
      value: GENRE_PROPS?.value,
      finalLiveAssetDetils: genreData?.[0]?.genreassetassociation,
    };
    dispatch(OFFSET_ACTION.getMovieGenreAssetsForListing(data));
  }, []);

  let newData = [];
  MOVIE_GENRE_ASSETS_FOR_LISTING?.finalLiveAssetDetils?.map((a) => {
    if (a?.active == 1) {
      newData?.push(a);
    }
  });
  return (
    <div
      className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 p-0 m-0"
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div className="iq-main-header  align-items-center justify-content-between">
        <div
          className="row col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12"
          style={{
            zIndex: 200,
            position: "fixed",
            opacity: "0.7",
            backgroundColor: "rgba(52, 53, 53, 0.9)",
            width: "100%",
            height: "10%",
            top: 0,
          }}
        >
          <div
            className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1"
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              color="white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left"
              onClick={handleClick}
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </div>

          <h4
            className="main-title col-10 col-sm-10 col-xs-10 col-md-10 col-lg-10 ml-0"
            style={{
              fontFamily: "Montserrat,sans-serif",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              float: "left",
              color: "white",
            }}
          >
            {MOVIE_GENRE_ASSETS_FOR_LISTING?.value}
          </h4>
        </div>
      </div>{" "}
      <div
        className="col-12"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
          top: 50,
        }}
      >
        {MOVIE_GENRE_ASSETS_FOR_LISTING?.finalLiveAssetDetils?.map(
          (item, index) =>
            item.active == 1 ? (
              <div style={{ width: "280px" }}>
                <MovieScreenDisplayMovieImage
                  key={index}
                  item={item}
                  from={"genreCategory"}
                />{" "}
              </div>
            ) : (
              ""
            )
        )}{" "}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  MOVIE_GENRE_ASSETS_FOR_LISTING: getmovieGenreAssetForListingSelector,
  GENRE_PROPS: getGenreDetailsPropsSelector,
  GENRE: getMovieGenreDetailsSelector,
});

export default connect(mapStateToProps)(MovieGenreCategory);
