/** @format */

import React from "react";
import { connect, useDispatch } from "react-redux";

import { createStructuredSelector } from "reselect";
import "./../SeariesPages/seriesPageModal.css";

import DisplayMovieImage from "../DisplayMovieImage";
import MovieScreenDisplayMovieImage from "../MoviesScreens/MovieScreenDisplayMovieImage";
import SeriesPageDisplayImage from "../SeariesPages/SeriesPageDisplayImage";

const SearchDisplayImage = ({ item }) => {
  const getItem1 = (item) => {
    let data = { assetId: item.id, categoryId: 1, active: 1 };
    return data;
  };
  const getItem2 = (item) => {
    let data = { assetId: item.id, categoryId: 2, active: 1 };
    return data;
  };
  const getItem3 = (item) => {
    let data = { assetId: item.id, categoryId: 3, active: 1 };
    return data;
  };

  return (
    <div className="" style={{ display: "block" }}>
      {/* {item.categoryId == 3 ? (
        <div
          className="favorites-contens "
          style={{ display: "block", backgroundColor: "yellow" }}
        ></div>
      ) : item.categoryId == 1 &&
        item.searchLiveAsset != undefined &&
        item.searchLiveAsset != null ? (
        <DisplayMovieImage item={getItem(item.searchLiveAsset)} />
      ) : item.categoryId == 2 &&
        item?.searchVodAsset != undefined &&
        item?.searchVodAsset != null ? (
        <MovieScreenDisplayMovieImage item={getItem(item?.searchVodAsset)} />
      ) : (



        <></>
      )} */}
      {item.categoryId == 1 &&
        item.searchLiveAsset != undefined &&
        item.searchLiveAsset != null && (
          <DisplayMovieImage
            item={getItem1(item.searchLiveAsset)}
            from={"search"}
          />
        )}
      {item.categoryId == 2 &&
        item.searchVodAsset != undefined &&
        item.searchVodAsset != null && (
          <MovieScreenDisplayMovieImage
            item={getItem2(item?.searchVodAsset)}
            from={"search"}
          />
        )}
      {item.categoryId == 3 &&
        item.searchSeries != undefined &&
        item.searchSeries != null && (
          <SeriesPageDisplayImage
            item={getItem3(item?.searchSeries)}
            from={"search"}
          />
        )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps)(SearchDisplayImage);
