import { createSelector } from "reselect";
const getCheckingMailValidity = (state) =>
  state.CheckMailValidityReducer.isMailValid;

export const getCheckingMailValiditySelector = createSelector(
  getCheckingMailValidity,
  (isMailValid) => isMailValid
);

const getMailLoading = (state) => state.CheckMailValidityReducer.mailLoading;

export const getMailLoadingSelector = createSelector(
  getMailLoading,
  (mailLoading) => mailLoading
);
