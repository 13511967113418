/** @format */

import { createSelector } from "reselect";

const getLiveAssets = (state) => {
  return state.liveAssets;
};

const getOfffsetLength = (state) => state.liveAssets.offset;

const getLiveAssetDetails = (state) => state.liveAssets.liveAssetDetails;
const getLiveAssetsGenres = (state) => state.liveAssets.genres;
const getUppendedGenreData = (state) => state.liveAssets.data;
const getTagDetails = (state) => state.liveAssets.tagDetails;
const getLanguageDetails = (state) => state.liveAssets.languageDetails;
const getAdsDetails = (state) => state.liveAssets.adsDetails;
const getGeneralSettingsDetails = (state) => state.liveAssets.generalData;
const getBanners = (state) => state.liveAssets.banners;
const getchangedGenreIndex = (state) => state.liveAssets.changedGenreIndex;
const getGeneralPath = (state) => {
  return state?.liveAssets?.generalPath;
};

export const getNewGeneralPath = (state) => {
  return state.liveAssets.generalPath;
};

const getLiveGenreAssetsForListing = (state) =>
  state.liveAssets.liveGenreAssetForListing;
const getFinalLiveAssetDetails = (state) =>
  state.liveAssets.finaleLiveAssetDetails;
const getnewGenGenreDataAssetDetails = (state) => {
  return state.liveAssets.newFinalData;
};
const getGenreDetailsProps = (state) => {
  return state.liveAssets.genreDetailsProps;
};

const getOffsetLoading = (state) => {
  return state.liveAssets.offsetLoading;
};
export const getOffsetLoadingSelector = createSelector(
  getOffsetLoading,
  (offsetLoading) => offsetLoading
);

export const getGenreDetailsPropsSelector = createSelector(
  getGenreDetailsProps,
  (genreDetailsProps) => genreDetailsProps
);

export const getOfffsetLengthSelector = createSelector(
  getOfffsetLength,
  (offset) => offset
);

export const getLiveGenreAssetsForListingSelector = createSelector(
  getLiveGenreAssetsForListing,
  (liveGenreAssetForListing) => liveGenreAssetForListing
);
export const getUppendedGenreDataSelector = createSelector(
  getUppendedGenreData,
  (data) => data
);
export const getchangedGenreIndexSelector = createSelector(
  getchangedGenreIndex,
  (changedGenreIndex) => changedGenreIndex
);
export const getnewGenGenreDataAssetDetailsSelector = createSelector(
  getnewGenGenreDataAssetDetails,
  (newFinalData) => newFinalData
);

export const getLiveAssetDetailsSelector = createSelector(
  getLiveAssetDetails,
  (liveAssetDetails) => liveAssetDetails
);
export const getFinalLiveAssetDetailsSelector = createSelector(
  getFinalLiveAssetDetails,
  (finaleLiveAssetDetails) => finaleLiveAssetDetails
);

export const liveAssetSelector = createSelector(
  getLiveAssets,
  (liveAssets) => liveAssets
);

export const liveAssetGenresSelector = createSelector(
  getLiveAssetsGenres,
  (genres) => genres
);

export const liveTagSelector = createSelector(
  getTagDetails,
  (tagDetails) => tagDetails
);

export const liveLanguageSelector = createSelector(
  getLanguageDetails,
  (languageDetails) => languageDetails
);

export const liveAdsSelector = createSelector(
  getAdsDetails,
  (adsDetails) => adsDetails
);
export const generalSettingsSelector = createSelector(
  getGeneralSettingsDetails,
  (generalData) => generalData
);

export const getBannerSelector = createSelector(
  getBanners,
  (banners) => banners
);

export const getGeneralPathSelector = createSelector(
  getGeneralPath,
  (generalPath) => generalPath
);
