/** @format */

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import * as OFFSET_ACTION from "../../../Redux/live/actions";
import {
  getGenreDetailsPropsSelector,
  getOfffsetLengthSelector,
  liveAssetGenresSelector,
} from "../../../Redux/live/selectors";
import {
  getAllLiveAssetDetailsSelector,
  getAllLiveGenreAssetsForListingSelector,
} from "../../../Redux/AllLiveDetails/selectors";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors";
import DisplayMovieImage from "../../DisplayMovieImage";

const LiveGenreCategory = ({
  GENRES,
  LIVE_GENRE_ASSET_FOR_LISTING,
  GENRE_PROPS,
  OFFSET,
  ASSET_DETAILS,
  CURRENT_PLATFORM,
  setSelectedCategory,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  const handleClick = () => {
    setSelectedCategory(1);

    history.push("/container");
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      let offsetLentFromLocalStorage = localStorage.getItem("offsetLength");
      dispatch(OFFSET_ACTION.getOffsetLength(OFFSET + 5));
      localStorage.setItem(
        "offsetLength",
        Number(offsetLentFromLocalStorage) + 5
      );
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    document.querySelector("div").addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let genreData = GENRES.filter((a) => {
      if (a.id == GENRE_PROPS?.genreId) {
        return a;
      }
    });
    let data = {
      value: GENRE_PROPS?.value,
      finalLiveAssetDetils: genreData?.[0]?.genreassetassociation,
    };
    dispatch(OFFSET_ACTION.getLiveGenreAssetsForListing(data));
  }, []);

  let newData = [];
  LIVE_GENRE_ASSET_FOR_LISTING?.map((a) => {
    newData?.push(ASSET_DETAILS[a?.assetId]);
  });

  return (
    <div
      className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 p-0 m-0"
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div className="iq-main-header  align-items-center justify-content-between">
        <div
          className="row col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12"
          style={{
            zIndex: 200,
            position: "fixed",
            opacity: "0.7",
            backgroundColor: "rgba(52, 53, 53, 0.9)",
            width: "100%",
            height: "10%",
            top: 0,
          }}
        >
          <div
            className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1"
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              color="white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left"
              onClick={handleClick}
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </div>

          <h4
            className="main-title col-10 col-sm-10 col-xs-10 col-md-10 col-lg-10 ml-0"
            style={{
              fontFamily: "Montserrat,sans-serif",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              float: "left",
              color: "white",
            }}
          >
            {GENRE_PROPS?.value}
          </h4>
        </div>
      </div>
      <div
        className="col-12"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
          top: 50,
        }}
      >
        {LIVE_GENRE_ASSET_FOR_LISTING?.map((item, index) =>
          item.active == 1 ? (
            <>
              <div style={{ width: "280px" }}>
                <DisplayMovieImage
                  key={index}
                  item={item}
                  index={index}
                  from={"liveGenreCatogory"}
                />
              </div>
            </>
          ) : (
            // <NewComponent />
            ""
          )
        )}
      </div>

      {/* </div>
        )} 
           */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  GENRES: liveAssetGenresSelector,
  LIVE_GENRE_ASSET_FOR_LISTING: getAllLiveGenreAssetsForListingSelector,
  GENRE_PROPS: getGenreDetailsPropsSelector,
  OFFSET: getOfffsetLengthSelector,
  ASSET_DETAILS: getAllLiveAssetDetailsSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector /* Current platform data */,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(LiveGenreCategory);
