import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as FAVOURITES_API from "./../../services/favourites/favourites";
import * as GET_FAVORITES_ACTION from "./../../Redux/FavouteDataRedux/action";

const { ADD_FAVOURITES_START } = ACTION_TYPES;

function* addFavouritesSaga(payload) {
  try {
    const { data } = yield call(FAVOURITES_API.addFavouritesAsync, payload);
     yield put(ACTION.addFavouritesSuccess({ data }));
    yield put(GET_FAVORITES_ACTION.getFavouriteDataStart({ userId: payload?.userId }));



  } catch ({ message }) {
    yield put(ACTION.addFavouritesFailed({ message }));
  }
}

function* addFavouritesWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(ADD_FAVOURITES_START);
    yield call(addFavouritesSaga, payload);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([addFavouritesWatcher()]);
}
