/** @format */

import { createSelector } from "reselect";

const getDeviceToken = (state) => {
  return state.addDevices.deviceToken;
};

const getDeviceStatus = (state) => {
  return state.deleteDevice.deviceStatus;
};

export const getDeviceStatusSelector = createSelector(
  getDeviceStatus,
  (deviceStatus) => deviceStatus
);

export const getDeviceTokenSelector = createSelector(
  getDeviceToken,
  (deviceToken) => deviceToken
);
