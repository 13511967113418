import ACTION_TYPES from "./actionTypes";

const {
  LATEST_EPG_FAILED,
  LATEST_EPG_START,
  LATEST_EPG_SUCCESS,
} = ACTION_TYPES;

export const getLatestEpgStart = (payload) => ({
  type: LATEST_EPG_START,
  payload,
});

export const getLatestEpgSuccess = (payload) => ({
  type: LATEST_EPG_SUCCESS,
  payload,
});

export const getLatestEpgFailed = (payload) => ({
  type: LATEST_EPG_FAILED,
  payload,
});
