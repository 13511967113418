import ACTION_TYPES from "./actionTypes";
const {
  CONFIGURATION_START,
  CONFIGURATION_SUCCESS,
  CONFIGURATION_FAILED,

  SENDING_DATA_TO_DAS_START,
  SENDING_DATA_TO_DAS_SUCCESS,
  SENDING_DATA_TO_DAS_FAILED,

  // /continue/watching
  FETCING_DATA_FOR_CONTINUE_WATCHING_START,
  FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS,
  FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED,
  FETCHING_PLAYER_DATA_SUCCESS,
  // /watchesHistory
  FETCING_DATA_FOR_WATCHED_HISTORY_START,
  FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS,
  FETCING_DATA_FOR_WATCHED_HISTORY_FAILED,

  // /remove/continueWatching
  REMOVE_CONTINUE_WATCHING_HISTORY_START,
  REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS,
  REMOVE_CONTINUE_WATCHING_HISTORY_FAILED,

  // /remove/WatchesHistory
  REMOVE_WATCHES_HISTORY_START,
  REMOVE_WATCHES_HISTORY_SUCCESS,
  REMOVE_WATCHES_HISTORY_FAILED,

  // viewAnalyticsStatus
  VIEW_ANALYTICS_STATUS_START,
  VIEW_ANALYTICS_STATUS_SUCCESS,
  VIEW_ANALYTICS_STATUS_FAILED,

  CLEAR_CONTINUE_WATCHING,
  CLEAR_WATCHED_HISTORY,

  SAVE_CW_DATA,
  SAVE_CW_SERIES_DATA,

  SAVE_WH_DATA,
  SAVE_WH_SERIES_DATA,
} = ACTION_TYPES;

export const configurationStart = (payload) => ({
  type: CONFIGURATION_START,
  payload,
});

export const configurationSucess = (payload) => ({
  type: CONFIGURATION_SUCCESS,
  payload,
});

export const configurationFailed = (payload) => ({
  type: CONFIGURATION_FAILED,
  payload,
});

export const sendingDataToDasStart = (payload) => ({
  type: SENDING_DATA_TO_DAS_START,
  payload,
});

export const sendingDataToDasSuccess = (payload) => ({
  type: SENDING_DATA_TO_DAS_SUCCESS,
  payload,
});

export const sendingDataToDasFailed = (payload) => ({
  type: SENDING_DATA_TO_DAS_FAILED,
  payload,
});
// /continue/watching
export const fetchingDataForContinueWatchingStart = (payload) => ({
  type: FETCING_DATA_FOR_CONTINUE_WATCHING_START,
  payload,
});
export const fetchingDataForContinueWatchingSuccess = (payload) => ({
  type: FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS,
  payload,
});
export const fetchingDataForContinueWatchingFailed = (payload) => ({
  type: FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED,
  payload,
});
export const fetchPlayerDataSuccess = (payload) => ({
  type: FETCHING_PLAYER_DATA_SUCCESS,
  payload,
});
// /watchesHistory
export const fetchingDataForWatchesHistoryStart = (payload) => ({
  type: FETCING_DATA_FOR_WATCHED_HISTORY_START,
  payload,
});
export const fetchingDataForWatchesHistorySuccess = (payload) => ({
  type: FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS,
  payload,
});
export const fetchingDataForWatchesHistoryFailed = (payload) => ({
  type: FETCING_DATA_FOR_WATCHED_HISTORY_FAILED,
  payload,
});
// /remove/continueWatching
export const removeContinueWatchingStart = (payload) => ({
  type: REMOVE_CONTINUE_WATCHING_HISTORY_START,
  payload,
});
export const removeContinueWatchingSuccess = (payload) => ({
  type: REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS,
  payload,
});
export const removeContinueWatchingFailed = (payload) => ({
  type: REMOVE_CONTINUE_WATCHING_HISTORY_FAILED,
  payload,
});
// /remove/WatchesHistory
export const removeWatchesHistoryStart = (payload) => ({
  type: REMOVE_WATCHES_HISTORY_START,
  payload,
});

export const removeWatchesHistorySuccess = (payload) => ({
  type: REMOVE_WATCHES_HISTORY_SUCCESS,
  payload,
});

export const removeWatchesHistoryFailed = (payload) => ({
  type: REMOVE_WATCHES_HISTORY_FAILED,
  payload,
});

// viewAnalyticsStatus;
export const viewAnalyticsStatusStart = (payload) => ({
  type: VIEW_ANALYTICS_STATUS_START,
  payload,
});

export const viewAnalyticsStatusSuccess = (payload) => ({
  type: VIEW_ANALYTICS_STATUS_SUCCESS,
  payload,
});

export const viewAnalyticsStatusFailed = (payload) => ({
  type: VIEW_ANALYTICS_STATUS_FAILED,
  payload,
});

export const clearContinueWatching = () => ({
  type: CLEAR_CONTINUE_WATCHING,
});
export const clearWatchedHistory = () => ({
  type: CLEAR_WATCHED_HISTORY,
});
