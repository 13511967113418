import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as MAIL_API from "./../../services/CheckMailValidity/checkMailValidity";

const { CHECKING_MAIL_VALIDITY_START } = ACTION_TYPES;

function* checkMailValiditySaga(payload) {
  // alert(payload);
  try {
    const { data } = yield call(MAIL_API.chechMailValidityAsync, payload);

    yield put(ACTION.checkingMailValiditySuccess({ data }));

    // RootNavigation.navigate("packageDetails");
  } catch (message) {
    yield put(ACTION.checkingMailValidityFailed({ message }));
  }
}

function* checkMailValidityWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(CHECKING_MAIL_VALIDITY_START);
    yield call(checkMailValiditySaga, payload);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([checkMailValidityWatcher()]);
}
