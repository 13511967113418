import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ProgressBar from "@ramonak/react-progress-bar";

const StyledDiv = styled("div")`
  background-color: #bcbcbc;

  align-item: center;
`;

const StyledBar = styled("div")`
  background-color: red;
  height: 4px;
  width: ${({ completed }) => completed}%;
`;

const DetailsPageProgressBar = ({ displayMovieData }) => {
  let totalDuration;
  let timeEllapsed;
  let percentage = 0;

  totalDuration =
    displayMovieData?.currentEpg?.stopTimeTimestamp -
    displayMovieData?.currentEpg?.startTimeTimestamp;

  let currentDate = new Date();
  let milisecond = currentDate.getTime();

  timeEllapsed = milisecond - displayMovieData?.currentEpg?.startTimeTimestamp;
  percentage = (timeEllapsed / totalDuration) * 100;
  return (
    <StyledDiv
      style={{
        borderBottomLeftRadius: "50px",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
      }}
    >
      <ProgressBar
        completed={percentage}
        bgColor="red"
        height="5px"
        isLabelVisible={false}
        animateOnRender={true}
        borderRadius="50px"
        baseBgColor="white"
        width="100%"
      />
    </StyledDiv>
  );
};

DetailsPageProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
};

export default DetailsPageProgressBar;
