/** @format */

const ACTION_TYPES = {
  SET_LIVE_VIDEO_URL: "SET_LIVE_VIDEO_URL",
  SET_MOVIE_VIDEO_URL: "SET_LIVE_MOVIE_URL",
  SET_TIMESTAMP: "SET_TIMESTAMP",
  EPG_VIDEO_URL: "EPG_VIDEO_URL",
  GET_DRM_CATCHUP_REQUIRED: "GET_DRM_CATCHUP_REQUIRED",
  GET_LIVE_API_DATA: "GET_LIVE_API_DATA",

  GET_LIVE_DETAILS_START: "GET_LIVE_DETAILS_START",
  GET_LIVE_DETAILS_SUCESS: "GET_LIVE_DETAILS_SUCESS",
  GET_LIVE_DETAILS_FAILED: "GET_LIVE_DETAILS_FAILED",

  LIVE_CHANNEL_DATA: "LIVE_CHANNEL_DATA",

  GENRE_CHANNELS: "GENRE_CHANNELS",

  GET_MOVIE_DETAILS_START: "GET_MOVIE_DETAILS_START",
  GET_MOVIE_DETAILS_SUCESS: "GET_MOVIE_DETAILS_SUCESS",
  GET_MOVIE_DETAILS_FAILED: "GET_MOVIE_DETAILS_FAILED",
  MOVIE_DATA: "MOVIE_DATA",

  GET_SERIES_DETAILS_START: "GET_SERIES_DETAILS_START",
  GET_SERIES_DETAILS_SUCESS: "GET_SERIES_DETAILS_SUCESS",
  GET_SERIES_DETAILS_FAILED: "GET_SERIES_DETAILS_FAILED",
  SERIES_DATA: "SERIES_DATA",
};
export default ACTION_TYPES;
