/** @format */

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
// import "@sweetalert2/themes/dark/dark.scss";

import { authSelector } from "../../Redux/auth/selectors";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import * as DELETE_ACTION from "./../../Redux/Delete Device/actions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import swal from "sweetalert";
import "./deliteDevice.css";
import * as ACTION_TYPES from "./../../Redux/auth/actions";
import { useHistory } from "react-router-dom";
import * as EPG_ACTION from "./../../Redux/EPG/action";
import { newImageIpPath } from "../../App";

import * as AUTHENTICATION_ACTIONS from "./../../Redux/authentication/actions";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import MainHeader from "../MainHeader";

const DeleteDevicePage = ({
  AUTH,
  PLATFORMS,

  DEVICE_TOKEN,
  CURRENT_PLATFORM,

  USER_DETAILS,
  AUTHENTICATION,
  setSearchState,
  setSearchKey,
  setClearSearchState,
  clearSearchState,
  selectedCategory,
  setSelectedCategory,
}) => {
  const history = useHistory();

  let brandBackgroundImage;
  if (CURRENT_PLATFORM != undefined && CURRENT_PLATFORM != null) {
    brandBackgroundImage =
      newImageIpPath + CURRENT_PLATFORM?.brandBackgroundImage;
  }

  const dispatch = useDispatch();
  // alert(JSON.stringify(AUTH?.devices));
  let platFormDevices = AUTH?.devices?.filter((item) => {
    if (item?.orgId == PLATFORMS?.currentPlatformId) {
      return item;
    }
  });
  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.push("/login");
    }
  }, [AUTHENTICATION]);

  useEffect(() => {
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
    var arr2 = todayDate.split("/");

    var arr3 = arr2[2] + "/" + arr2[1];
    let programMonth = arr2[1];
    let programmeDate = arr2[2];
    if (parseInt(programMonth) <= 9) {
      programMonth = "0" + programMonth;
    }
    if (parseInt(programmeDate) <= 9) {
      programmeDate = "0" + programmeDate;
    }
    // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

    let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
    let startTimeStamp = new Date(
      programMonth + "/" + programmeDate + "/" + arr2[0]
    ).setHours(0, 0, 0, 0);
    let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
    const data1 = {
      startTimeTimestamp: startTimeStamp,
      stopTimeTimestamp: stopTimeStamp,
      account_id: PLATFORMS?.currentPlatformId,
      programmeDate: date,
      offset: 0,
      limit: 1000,
      a: false,
    };

    dispatch(EPG_ACTION.getEpgDetailsStart(data1));
  }, []);

  useEffect(() => {
    setSelectedCategory(null);
    let data2;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      data2 = {
        account_id: PLATFORMS?.currentPlatformId,
        userId: USER_DETAILS?.id,
        packageId: USER_DETAILS?.packageId,
      };
    }

    dispatch(ACTION_TYPES.getDeviceListStart(data2));
  }, []);

  const replaceIconRef = useRef({});

  const handleDelete = (item) => {
    let logoutData;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      logoutData = {
        deviceId: item?.deviceId,
        userId: USER_DETAILS?.id,
        orgId: PLATFORMS?.currentPlatformId,
        packageId: USER_DETAILS?.packageId,
        history: history,
      };
    }
    if (item?.deviceId == DEVICE_TOKEN) {
      Swal.fire({
        // title: "Are you sure?",
        text: "Are you sure to logout your own device?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(AUTHENTICATION_ACTIONS.logoutStart(logoutData));

          // alert(logoutData.deviceId);

          // let data = {
          //   deviceId: logoutData?.deviceId,
          //   message: "Removed By Own DEVICE  through API in webapplication",
          // };

          // if (socketStatus == true) {
          //   socketStatusAPI(data);
          // }

          // ws.send(
          //   JSON.stringify({
          //     deviceId: logoutData?.deviceId,
          //     message:
          //       "Removed By Own DEVICE  through Socket in webapplication",
          //   })
          // );

          dispatch(AUTHENTICATION_ACTIONS.loginStausFalse());
          history.replace("/login");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to remove a device from your list!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(DELETE_ACTION.getDeleteDevicesStart(logoutData));

          // Send a message to the server
          // alert(logoutData.deviceId);
          // console.log("ws" + ws);
          // console.log("wssocketStatus" + JSON.stringify(socketStatus));

          swal("Deleted!", "Device has been deleted .", {
            icon: "success",
          });
          // let data = {
          //   deviceId: logoutData?.deviceId,
          //   message:
          //     "Removed From Another DEVICE through API in webapplication",
          // };

          // if (socketStatus == true) {
          //   socketStatusAPI(data);
          // }
          // // console.log("ws" + ws);
          // ws.send(
          //   JSON.stringify({
          //     deviceId: logoutData?.deviceId,
          //     message:
          //       "Removed From Another DEVICE through Socket in webapplication",
          //   })
          // );
          // Swal.fire("Deleted!", "Your Device has been deleted.", "success");
        }
      });
    }
  };
  return (
    <div
    // style={{
    //   backgroundImage: `url(${brandBackgroundImage})`,
    //   backgroundAttachment: "fixed",

    //   minHeight: "600px",
    //   height: "100%",
    // }}
    >
      <div>
        <MainHeader
          setSearchState={setSearchState}
          setSearchKey={setSearchKey}
          setClearSearchState={setClearSearchState}
          clearSearchState={clearSearchState}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <div style={{ height: "80px" }}></div>
      </div>

      {/* {showDeviceList == true ? ( */}
      <div className="container">
        <div
          className="mb-4"
          style={{
            alignItems: "center",
            textAlign: "center",
            width: "100",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Montserrat,sans-serif",
              color: "white",
            }}
          >
            My Devices
          </h3>
          <div
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Montserrat,sans-serif",
              fontSize: "20px",
            }}
          >
            {/* Press remove to delete devices/sessions from your account. */}
            You can use a total of{" "}
            <b>{USER_DETAILS?.totalNoOfDevices}&nbsp; devices</b>. A device will
            automatically be registered when you first login. A device can be
            removed by clicking below.
          </div>
        </div>
        {/* {AUTH?.isLoading == false ? ( */}
        <div className="row">
          <div className="col-lg-3 col-xl-2" style={{}}></div>

          <div
            className="col-lg-3 col-xl-8  "
            style={{ color: "white", cursor: "pointer" }}
          >
            {platFormDevices?.map((item, index) => (
              <div
                onClick={() => handleDelete(item)}
                onMouseOver={() => {
                  replaceIconRef.current[index].style.display = "inline-block";
                  // replaceIconRef?.current[index]?.style?.borderLeft =
                  //   "2px solid gray";
                }}
                onMouseLeave={() => {
                  replaceIconRef.current[index].style.display = "none";
                }}
                key={index}
                className="row p-4 m-2 mb-4 cardContainer"
                style={{ border: "2px solid white", borderRadius: 10 }}
              >
                <div
                  className="col-lg-2 col-xl-2 text-center"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item?.deviceType == 1 ? (
                    <img
                      className="mt-4"
                      height={90}
                      width={90}
                      src="images/login/laptop1.png"
                    />
                  ) : item?.deviceType == 2 ? (
                    <img
                      height={80}
                      style={{ maxWidth: "90px", maxHeight: "80px" }}
                      src="images/login/moble1.png"
                    />
                  ) : item?.deviceType == 3 ? (
                    <img height={90} width={90} src="images/login/tv2.png" />
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="col-lg-8 col-xl-8 text-center"
                  style={{ color: "white" }}
                >
                  <div
                    className="css-1old884-ProgramTitle e1f8qikj3"
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      fontFamily: "Montserrat,sans-serif",
                      textTransform: "uppercase",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",

                      marginTop: "35px",
                      marginBottom: "35px",
                    }}
                  >
                    {item?.deviceName}
                  </div>
                </div>
                <div
                  className="col-lg-2"
                  style={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "35px",
                    marginBottom: "35px",
                  }}
                >
                  {/* <img
                      ref={ref => (replaceIconRef.current[index] = ref)}
                      src="images/login/replaceicon.png"
                      style={{
                        display: "none",
                        width: "80px",
                        height: "80px",
                        cursor: "pointer"
                      }}
                      onClick={() => handleReplace(item)}
                    /> */}
                  <div
                    ref={(ref) => (replaceIconRef.current[index] = ref)}
                    style={{
                      display: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-lg-3 col-xl-2" style={{}}></div>
        </div>
        {/* ) : (
          <NewLoadingPage />
        )} */}
      </div>
      {/* ) : (
        <NewLoadingPage />
      )} */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  AUTH: authSelector,
  PLATFORMS: platformsSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,

  CURRENT_PLATFORM: getCurrentPlatformSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(DeleteDevicePage);
