import ACTION_TYPES from "./action.types";

const {
  ADD_TO_RECENT_WATCHES_START,
  ADD_TO_RECENT_WATCHES_SUCCESS,
  ADD_TO_RECENT_WATCHES_FAILED,
  BROADCAST_CREATE_PROFILE,
  BROADCAST_DELETE_PROFILE_BY_ID,
  BROADCAST_UPDATE_PROFILE,
  CREATE_PROFILE_START,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILED,
  DELETE_PROFILE_START,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAILED,
  DISABLE_PROFILE,
  LOGIN_FAILED,
  SAVE_CREADENTIALS_FOR_REMEMBER_ME,

  MARK_REMINDED_MOVIE_AS_READ_START,
  MARK_REMINDED_MOVIE_AS_READ_SUCCESS,
  MARK_REMINDED_MOVIE_AS_READ_FAILED,
  RATE_SHOW_START,
  RATE_SHOW_SUCCESS,
  RATE_SHOW_FAILED,
  RATE_RECENTLY_WATCHED_MOVIE_START,
  RATE_RECENTLY_WATCHED_MOVIE_SUCCESS,
  RATE_RECENTLY_WATCHED_MOVIE_FAILED,
  REMOVE_TO_RECENT_WATCHES_START,
  REMOVE_TO_RECENT_WATCHES_SUCCESS,
  REMOVE_TO_RECENT_WATCHES_FAILED,
  CLEAR_RECENT_WATCHES_START,
  CLEAR_RECENT_WATCHES_SUCCESS,
  CLEAR_RECENT_WATCHES_FAILED,
  SELECT_PROFILE_START,
  SELECT_PROFILE_SUCCESS,
  SELECT_PROFILE_FAILED,
  SET_PROFILE_COUNT_TO_DISABLE,
  SELECT_DEVICE_START,
  SELECT_DEVICE_SUCCESS,
  SELECT_DEVICE_FAILED,
  SET_DEVICE_COUNT_TO_DISABLE,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_STATE_CLEAR,
  SHOW_SUBSCRIBER_START,
  SHOW_SUBSCRIBER_SUCCESS,
  SHOW_SUBSCRIBER_FAILED,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED,
  TOGGLE_ADD_TO_MY_LIST_START,
  TOGGLE_ADD_TO_MY_LIST_SUCCESS,
  TOGGLE_ADD_TO_MY_LIST_FAILED,
  UPDATE_REMIND_ME_IS_RELEASED_PROPERTY,
  UPDATE_AUTHENTICATED_PROFILE_START,
  UPDATE_AUTHENTICATED_PROFILE_SUCCESS,
  UPDATE_AUTHENTICATED_PROFILE_FAILED,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED,
  UPDATE_USER_PROFILE,
  CLEAR_ERRORS_PROPERTY,
  UPDATE_SUBSCRIPTION_DETAILS,
  GET_PROFILES_START,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILED,
  EDIT_PROFILE_START,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILED,
  DEVICE_REGISTER_START,
  DEVICE_REGISTER_SUCCESS,
  DEVICE_REGISTER_FAILED,
  GET_DEVICE_LIST_START,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILED,
  NAVIGATE_TO_DEVICE_SCREEN,
  MANAGE_NAVIGATION_STATUS,
  REPLACE_DEVICE_START,
  REPLACE_DEVICE_SUCCESS,
  REPLACE_DEVICE_FAILED,
  CHANNEL_LIST_START,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_FAIELD,
  PROGRAM_LIST_START,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_LIST_FAILED,
  PROFILE_CREATE_START,
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAILED,
  GET_PROFILE_LIST_START,
  GET_PROFILE_LIST_SUCCESS,
  GET_PROFILE_LIST_FAILED,
  PROFILE_UPDATE_START,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILED,

  PROFILE_BLOCK_START,
  PROFILE_BLOCK_SUCCESS,
  PROFILE_BLOCK_FAILED,
  PROFILE_UNBLOCK_START,
  PROFILE_UNBLOCK_SUCCESS,
  PROFILE_UNBLOCK_FAILED,
  PROFILE_SENDEMAIL_START,
  PROFILE_SENDEMAIL_SUCCESS,
  PROFILE_SENDEMAIL_FAILED,
  PROFILEPIC_URL_START,
  PROFILEPIC_URL_SUCCESS,
  PROFILEPIC_URL_FAILED,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_START,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED,
  DEVICE_REGISTER_STATUS,
} = ACTION_TYPES;

/** Add to recent watches */
export const addToRecentWatchesStart = (payload) => ({
  type: ADD_TO_RECENT_WATCHES_START,
  payload,
});

export const addToRecentWatchesSuccess = (payload) => ({
  type: ADD_TO_RECENT_WATCHES_SUCCESS,
  payload,
});

export const addToRecentWatchesFailed = (payload) => ({
  type: ADD_TO_RECENT_WATCHES_FAILED,
  payload,
});

export const broadcastCreateProfile = (payload) => ({
  type: BROADCAST_CREATE_PROFILE,
  payload,
});

export const broadcastDeleteProfileById = (payload) => ({
  type: BROADCAST_DELETE_PROFILE_BY_ID,
  payload,
});

export const broadcastUpdateProfile = (payload) => ({
  type: BROADCAST_UPDATE_PROFILE,
  payload,
});

/** Create Profile */
export const createProfileStart = (payload) => ({
  type: CREATE_PROFILE_START,
  payload,
});

export const createProfileSuccess = (payload) => ({
  type: CREATE_PROFILE_SUCCESS,
  payload,
});

export const createProfileFailed = (payload) => ({
  type: CREATE_PROFILE_FAILED,
  payload,
});

/**  Profile  Create */
export const profileCreateStart = (payload) => ({
  type: PROFILE_CREATE_START,
  payload,
});

export const profileCreateSuccess = (payload) => ({
  type: PROFILE_CREATE_SUCCESS,
  payload,
});

export const profileCreateFailed = (payload) => ({
  type: PROFILE_CREATE_FAILED,
  payload,
});
/** gET PROFILE LIST */
export const getProfileListStart = (payload) => ({
  type: GET_PROFILE_LIST_START,
  payload,
});

export const getProfileListSuccess = (payload) => ({
  type: GET_PROFILE_LIST_SUCCESS,
  payload,
});

export const getProfileListFailed = (payload) => ({
  type: GET_PROFILE_LIST_FAILED,
  payload,
});

/** PROFILE UPDATE*/
export const profileUpdateStart = (payload) => ({
  type: PROFILE_UPDATE_START,
  payload,
});

export const profileUpdateSuccess = (payload) => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload,
});

export const profileUpdateFailed = (payload) => ({
  type: PROFILE_UPDATE_FAILED,
  payload,
});

/** Delete Profile */
export const deleteProfileStart = (payload) => ({
  type: DELETE_PROFILE_START,
  payload,
});

export const deleteProfileSuccess = (payload) => ({
  type: DELETE_PROFILE_SUCCESS,
  payload,
});

export const deleteProfileFailed = (payload) => ({
  type: DELETE_PROFILE_FAILED,
  payload,
});

export const disableProfile = (payload) => ({
  type: DISABLE_PROFILE,
  payload,
});

/** edit profile */
export const editProfileStart = (payload) => ({
  type: EDIT_PROFILE_START,
  payload,
});
export const editProfileSuccess = (payload) => ({
  type: EDIT_PROFILE_SUCCESS,
  payload,
});
export const editProfileFailed = (payload) => ({
  type: EDIT_PROFILE_FAILED,
  payload,
});

/** Login */

export const saveCredentialsForRememberMe = (payload) => ({
  type: SAVE_CREADENTIALS_FOR_REMEMBER_ME,
  payload,
});

/** Logout */

/** Signup */
export const signupStart = (payload) => ({
  type: SIGNUP_START,
  payload,
});

export const signupSuccess = (payload) => ({
  type: SIGNUP_SUCCESS,
  payload,
});

export const signupFailed = (payload) => ({
  type: SIGNUP_FAILED,
  payload,
});
export const signUpStateDataClear = (payload) => ({
  type: SIGNUP_STATE_CLEAR,
  payload,
});

/** Mark reminded movie as read */
export const markRemindedMovieAsReadStart = (payload) => ({
  type: MARK_REMINDED_MOVIE_AS_READ_START,
  payload,
});

export const markRemindedMovieAsReadSuccess = (payload) => ({
  type: MARK_REMINDED_MOVIE_AS_READ_SUCCESS,
  payload,
});

export const markRemindedMovieAsReadFailed = (payload) => ({
  type: MARK_REMINDED_MOVIE_AS_READ_FAILED,
  payload,
});

/** Rate show */
export const rateShowStart = (payload) => ({
  type: RATE_SHOW_START,
  payload,
});

export const rateShowSuccess = (payload) => ({
  type: RATE_SHOW_SUCCESS,
  payload,
});

export const rateShowFailed = (payload) => ({
  type: RATE_SHOW_FAILED,
  payload,
});

/** Rate Recently Watched Movie */
export const rateRecentlyWatchedMovieStart = (payload) => ({
  type: RATE_RECENTLY_WATCHED_MOVIE_START,
  payload,
});

export const rateRecentlyWatchedMovieSuccess = (payload) => ({
  type: RATE_RECENTLY_WATCHED_MOVIE_SUCCESS,
  payload,
});

export const rateRecentlyWatchedMovieFailed = (payload) => ({
  type: RATE_RECENTLY_WATCHED_MOVIE_FAILED,
  payload,
});

/** Remove to recent watches */
export const removeToRecentWatchesStart = (payload) => ({
  type: REMOVE_TO_RECENT_WATCHES_START,
  payload,
});

export const removeToRecentWatchesSuccess = (payload) => ({
  type: REMOVE_TO_RECENT_WATCHES_SUCCESS,
  payload,
});

export const removeToRecentWatchesFailed = (payload) => ({
  type: REMOVE_TO_RECENT_WATCHES_FAILED,
  payload,
});

/** Remove to recent watches nultiple*/
export const clearRecentWatchesStart = (payload) => ({
  type: CLEAR_RECENT_WATCHES_START,
  payload,
});

export const clearRecentWatchesSuccess = (payload) => ({
  type: CLEAR_RECENT_WATCHES_SUCCESS,
  payload,
});

export const clearRecentWatchesFailed = (payload) => ({
  type: CLEAR_RECENT_WATCHES_FAILED,
  payload,
});

/** Select profile */
export const selectProfileStart = (payload) => ({
  type: SELECT_PROFILE_START,
  payload,
});

export const selectProfileSuccess = (payload) => ({
  type: SELECT_PROFILE_SUCCESS,
  payload,
});

export const selectProfileFailed = (payload) => ({
  type: SELECT_PROFILE_FAILED,
  payload,
});

export const setProfileCountToDisable = (payload) => ({
  type: SET_PROFILE_COUNT_TO_DISABLE,
  payload,
});
/** Select  Device*/
export const selectDeviceStart = (payload) => ({
  type: SELECT_DEVICE_START,
  payload,
});

export const selectDeviceSuccess = (payload) => ({
  type: SELECT_DEVICE_SUCCESS,
  payload,
});

export const selectDeviceFailed = (payload) => ({
  type: SELECT_DEVICE_FAILED,
  payload,
});

export const setDeviceCountToDisable = (payload) => ({
  type: SET_DEVICE_COUNT_TO_DISABLE,
  payload,
});

/** Show subscriber */
export const showSubscriberStart = (payload) => ({
  type: SHOW_SUBSCRIBER_START,
  payload,
});

export const showSubscriberSuccess = (payload) => ({
  type: SHOW_SUBSCRIBER_SUCCESS,
  payload,
});

export const showSubscriberFailed = (payload) => ({
  type: SHOW_SUBSCRIBER_FAILED,
  payload,
});

/** Remind me of coming soon show */
export const toggleRemindMeOfComingShowStart = (payload) => ({
  type: TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START,
  payload,
});

export const toggleRemindMeOfComingShowSuccess = (payload) => ({
  type: TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS,
  payload,
});

export const toggleRemindMeOfComingShowFailed = (payload) => ({
  type: TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED,
  payload,
});

/** Toggle add to my list */
export const toggleAddToMyListStart = (payload) => ({
  type: TOGGLE_ADD_TO_MY_LIST_START,
  payload,
});

export const toggleAddToMyListSuccess = (payload) => ({
  type: TOGGLE_ADD_TO_MY_LIST_SUCCESS,
  payload,
});

export const toggleAddToMyListFailed = (payload) => ({
  type: TOGGLE_ADD_TO_MY_LIST_FAILED,
  payload,
});

export const updateRemindMeIsReleasedProperty = (payload) => ({
  type: UPDATE_REMIND_ME_IS_RELEASED_PROPERTY,
  payload,
});

/** Update authenticated profile */
export const updateAuthenticatedProfileStart = (payload) => ({
  type: UPDATE_AUTHENTICATED_PROFILE_START,
  payload,
});

export const updateAuthenticatedProfileSuccess = (payload) => ({
  type: UPDATE_AUTHENTICATED_PROFILE_SUCCESS,
  payload,
});

export const updateAuthenticatedProfileFailed = (payload) => ({
  type: UPDATE_AUTHENTICATED_PROFILE_FAILED,
  payload,
});

/** Update recently watched at position millis */
export const updateRecentlyWatchedAtPositionMillisStart = (payload) => ({
  type: UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START,
  payload,
});

export const updateRecentlyWatchedAtPositionMillisSuccess = (payload) => ({
  type: UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS,
  payload,
});

export const updateRecentlyWatchedAtPositionMillisFailed = (payload) => ({
  type: UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED,
  payload,
});

export const updateUserProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

export const updateSubscriptionDetails = (payload) => ({
  type: UPDATE_SUBSCRIPTION_DETAILS,
  payload,
});

export const clearErrorProperty = (payload) => ({
  type: CLEAR_ERRORS_PROPERTY,
  payload,
});

/** register device */
export const deviceRegisterStart = (payload) => ({
  type: DEVICE_REGISTER_START,
  payload,
});
export const deviceRegisterSuccess = (payload) => ({
  type: DEVICE_REGISTER_SUCCESS,
  payload,
});
export const deviceRegisterFailed = (payload) => ({
  type: DEVICE_REGISTER_FAILED,
  payload,
});

/** get device list */
export const getDeviceListForAddingDeviceStart = (payload) => ({
  type: GET_DEVICE_LIST_FOR_ADDING_DEVICE_START,
  payload,
});
export const getDeviceListForAddingDeviceSuccess = (payload) => ({
  type: GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS,
  payload,
});
export const getDeviceListForAddingDeviceFailed = (payload) => ({
  type: GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED,
  payload,
});

export const getDeviceListStart = (payload) => ({
  type: GET_DEVICE_LIST_START,
  payload,
});
export const getDeviceListSuccess = (payload) => ({
  type: GET_DEVICE_LIST_SUCCESS,
  payload,
});
export const getDeviceListFailed = (payload) => ({
  type: GET_DEVICE_LIST_FAILED,
  payload,
});

export const navigateDeviceScreenSuccess = () => ({
  type: NAVIGATE_TO_DEVICE_SCREEN,
});
export const manageNavigationStatus = () => ({
  type: MANAGE_NAVIGATION_STATUS,
});

/** delete device */
export const replaceDeviceStart = (payload) => ({
  type: REPLACE_DEVICE_START,
  payload,
});
export const replaceDeviceSuccess = (payload) => ({
  type: REPLACE_DEVICE_SUCCESS,
  payload,
});
export const replaceDeviceFailed = (payload) => ({
  type: REPLACE_DEVICE_FAILED,
  payload,
});

/** Channel List */
export const channelListStart = (payload) => ({
  type: CHANNEL_LIST_START,
  payload,
});
export const channelListSuccess = (payload) => ({
  type: CHANNEL_LIST_SUCCESS,
  payload,
});
export const channelListFailed = (payload) => ({
  type: CHANNEL_LIST_FAIELD,
  payload,
});

/** program List */
export const programListStart = (payload) => ({
  type: PROGRAM_LIST_START,
  payload,
});
export const programListSuccess = (payload) => ({
  type: PROGRAM_LIST_SUCCESS,
  payload,
});
export const programListFailed = (payload) => ({
  type: PROGRAM_LIST_FAILED,
  payload,
});
export const profileBlockStart = (payload) => ({
  type: PROFILE_BLOCK_START,
  payload,
});

export const profileBlockSuccess = (payload) => ({
  type: PROFILE_BLOCK_SUCCESS,
  payload,
});

export const profileBlockFailed = (payload) => ({
  type: PROFILE_BLOCK_FAILED,
  payload,
});

/** PROFILE UNBLOCK*/
export const profileUnBlockStart = (payload) => ({
  type: PROFILE_UNBLOCK_START,
  payload,
});

export const profileUnBlockSuccess = (payload) => ({
  type: PROFILE_UNBLOCK_SUCCESS,
  payload,
});

export const profileUnBlockFailed = (payload) => ({
  type: PROFILE_UNBLOCK_FAILED,
  payload,
});

/** PROFILE SENDEMAIL*/
export const profileSendEmailStart = (payload) => ({
  type: PROFILE_SENDEMAIL_START,
  payload,
});

export const profileSendEmailSuccess = (payload) => ({
  type: PROFILE_SENDEMAIL_SUCCESS,
  payload,
});

export const profileSendEmailFailed = (payload) => ({
  type: PROFILE_SENDEMAIL_FAILED,
  payload,
});
export const profilepicUrlStart = (payload) => ({
  type: PROFILEPIC_URL_START,
  payload,
});

export const profilepicUrlSuccess = (payload) => ({
  type: PROFILEPIC_URL_SUCCESS,
  payload,
});

export const profilepicUrlFailed = (payload) => ({
  type: PROFILEPIC_URL_FAILED,
  payload,
});
export const device_rigister_status = (payload) => ({
  type: DEVICE_REGISTER_STATUS,
  payload,
});
