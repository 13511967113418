/** @format */

import { useState } from "react";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { createStructuredSelector } from "reselect";
 import { connect } from "react-redux";
import { newImageIpPath } from "../../App";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const EpgLoadingPage = ({   }) => {
  // let [loading, setLoading] = useState(true);
 
  return (
    <div style={{ zIndex: 500, backgroundColor: "black" }}> {/* Reduced zIndex to 500 */}
      <div
        className="sweet-loading"
        style={{
           backgroundAttachment: "fixed",
          padding: "15%",
          paddingBottom: "16%",

          // backgroundColor: "red",
        }}
      >
        <FadeLoader color="white" loading={true} css={override} size={150} />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
 });

export default connect(mapStateToProps)(EpgLoadingPage);
