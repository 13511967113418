/** @format */

import React, { useEffect } from "react";
import { useState } from "react";
import Iframe from "react-iframe";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as PRIVACY_POLICY_ACTION from "../../Redux/readNotificationRedux/actions";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import { getSaveDeviceTockenSelector } from "../../Redux/SaveDeviceTocken/selectors";
import { useHistory } from "react-router-dom";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  loginDetailsSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";

const PrivacyPolicyScreen = ({
  DEVICE_TOKEN,
  SAVED_DEVICE_TOCKEN,
  setIsVideoPlayer,
  CURRENT_PLATFORM,

  USER_DETAILS,
  AUTHENTICATION,
  LOGIN_DETAILS,
}) => {
  let navigationPath;
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  if (defaultLandingPage == 1) {
    navigationPath = "/container";
  } else if (defaultLandingPage == 2) {
    navigationPath = "/container";
  } else if (defaultLandingPage == 3) {
    navigationPath = "/container";
  } else {
    navigationPath = "/container";
  }
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsVideoPlayer(false);
  }, []);

  const handleSubmit = () => {
    let data = { id: USER_DETAILS?.id, privacyPolicyStatus: 1 };

    // console.log("LOGIN_DETAILS" + JSON.stringify(LOGIN_DETAILS));

    let credentials = {
      username: LOGIN_DETAILS?.data?.username,
      rememberMe: LOGIN_DETAILS?.rememberMe,
      account_id: USER_DETAILS?.id,
      deviceToken: DEVICE_TOKEN,
      newDeviceTocken: SAVED_DEVICE_TOCKEN,
      id: LOGIN_DETAILS?.data.userId,
    };
    // console.log("credentials" + JSON.stringify(credentials));

    dispatch(
      PRIVACY_POLICY_ACTION.privacyPolicyStatusStart({
        data,
        credential: { credentials, history: history, navigationPath },
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        minWidth: "400px",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "60%",
          height: "80%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <div style={{ width: "100%", height: "95%" }}>
          <Iframe
            url={CURRENT_PLATFORM?.privacyUrl}
            width="100%"
            height="98%"
            id=""
            className=""
            display="block"
            position="relative"
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "20%",
            display: "flex",

            flexDirection: "column",
          }}
        >
          <div className="remember-field1 d-flex ml-2">
            <input
              onClick={handleOnChange}
              type="checkbox"
              name=""
              value=""
              style={{
                appearance: "none",
                // backgroundColor: "var(--form-background)",
                marginTop: "5px",
                font: "fontawesome",
                color: "#fff",
                width: "1.0em",
                height: "1.0em",
                border: "2px solid #534040",
                borderRadius: "2px",
                transform: "translateY(-0.075em)",
                display: "grid",
                placeContent: "center",
                backgroundColor: "rgba(255, 239, 239, 0.4)",
              }}
            />
            <label
              className="ml-1"
              style={{ color: "black", fontSize: "15px" }}
            >
              I have read and agree with the privacy policy
            </label>
          </div>
          <div
            style={{
              minWidth: "100px",
              minHeight: "30px",
              width: "30%",
              height: "20%",
              marginLeft: "40%",
            }}
          >
            <div
              className="login-btn2"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                disabled={isChecked ? false : true}
                onClick={handleSubmit}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  DEVICE_TOKEN: getDeviceTokenSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  LOGIN_DETAILS: loginDetailsSelector,
});

export default connect(mapStateToProps)(PrivacyPolicyScreen);
