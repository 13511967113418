import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as VIDEO_ADS_API from "./../../services/Video-Adverticement/videoAdverticement";
// import { Alert } from "react-native";
const { GET_VIDEO_ADVERTICEMENT_START } = ACTION_TYPES;

function* getVideoAdverticementSaga() {
  try {
    const { data } = yield call(VIDEO_ADS_API.getVideoAdverticementAsync);

    yield put(ACTION.getVideoAdverticementSuccess({ data }));
  } catch ({ message }) {
    yield put(ACTION.getVideoAdverticementFailed({ message }));
  }
}

function* getVideoAdverticementWatcher() {
  while (true) {
    yield take(GET_VIDEO_ADVERTICEMENT_START);
    yield call(getVideoAdverticementSaga);
  }
}

export default function*() {
  yield all([getVideoAdverticementWatcher()]);
}
