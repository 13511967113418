import swal from "sweetalert";
import axiosInstance from "../../utils/axiosInstance";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
var qs = require("qs");

// export const forgotPasswordAsync = async (payload) => {
//   //console.log("Genre parameters " + JSON.stringify(payload));
//   const ConstantHeaders = HeadersAcessToken();

//   return await axiosInstance()
//     .post("/resetPassword/user", payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       // swal(response.data.message)

//       //console.log("movie list reasponse data//////////////////////////// " + JSON.stringify(response.data));
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " +JSON.stringify(error.response.message));
//       Promise.reject(error.response.data);
//     });
// };
export const forgotPasswordAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/resetPassword/user", payload);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};
