/** @format */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getProfileSelector } from "./../../Redux/auth/selectors";
import * as LOCK_TYPES from "./../../Redux/auth/actions";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./profile.css";
import UnlockProfile from "./UnlockProfile";
import swal from "sweetalert";
import UpdateProfileModal from "./UpdateProfileModal";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { platformsSelector } from "../../Redux/platforms/selectors";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
let values;

const UpdateProfile = ({
  id,
  UPDATEPROFILE,
  PLATFORM,
  USER_DETAILS,
  AUTHENTICATION,
}) => {
  //console.log("profile details//////", UPDATEPROFILE);
  //console.log("id details//////", id);
  const history = useHistory();
  const dispatch = useDispatch();
  const [updateProfile, setUpdateProfile] = useState(
    UPDATEPROFILE.find((prof) => prof.id === id)
  );
  //console.log("updateProfile details", updateProfile);
  //console.log(JSON.stringify(updateProfile));
  const [profileUrl, setProfileUrl] = useState(updateProfile.profileUrl);
  const [forKid, setForKid] = useState(0);

  const [profile, setProfile] = useState();
  //console.log(profile);
  const handleUpdate = (e) => {
    const value = e.target.value;
    setUpdateProfile({
      ...updateProfile,
      [e.target.name]: value,
    });

    let isChecked = e.target.checked;

    if (isChecked == true) {
      setForKid(1);
    } else {
      setForKid(0);
    }
    //console.log("for kid", forKid);
  };
  values = {
    updatedBy: USER_DETAILS?.id, //manually
    id: updateProfile.id,
    name: updateProfile.name,
    account_id: PLATFORM.currentPlatformId,
    userId: updateProfile.userId,
    isForKids: forKid,
    profileUrl: profile,
  };
  useEffect(() => {
    //console.log(updateProfile)
  }, [updateProfile]);

  const onUpdate = () => {
    //console.log("updateProfile", values);
    // alert(JSON.stringify(values))
    dispatch(LOCK_TYPES.profileUpdateStart(values));
    //window.location.href="//profileList"
    history.push("/profileList");
  };

  let data = {
    profileId: updateProfile.id,

    account_id: PLATFORM.currentPlatformId, //manually
    userId: USER_DETAILS?.id, //manually
  };
  //console.log("secreteKey", updateProfile.secretKey);
  const profileLock = () => {
    confirmAlert({
      title: "Are you sure to lock this profile ??",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //console.log("isLocked", data);
            dispatch(LOCK_TYPES.profileBlockStart(data));
            swal("profile locked successfully");
            history.push("/profileList");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const [openUnlock, setOpenUnlock] = useState(false);
  const onOpenUnlock = () => {
    confirmAlert({
      title: "Are you sure to Unlock this profile ??",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setOpenUnlock(true);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  let deleteData = {
    profileId: updateProfile.id,
    account_id: PLATFORM.currentPlatformId, //manually
    updatedBy: USER_DETAILS?.id, //manually
  };
  const onDeleteProfile = () => {
    confirmAlert({
      title: "Are you sure to delete this  profile ??",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //console.log("isLocked", deleteData);
            dispatch(LOCK_TYPES.deleteProfileStart(deleteData));
            swal("profile deleted succesfully");
            history.push("/profileList");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div style={{ backgroundColor: "black" }}>
      <h3
        style={{
          color: "white",
          textAlign: "center",
          backgroundColor: "black",
        }}
      >
        Update Your Profile
      </h3>
      <div className="form-group">
        {profileUrl.indexOf("http://") >= 0 ? (
          <img
            src={profileUrl}
            className=" mt-4"
            width="150px"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "200px",
            }}
          />
        ) : (
          <img
            src={newImageIpPath + profileUrl}
            className=" mt-4"
            width="150px"
            height="150px"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "150px",
            }}
          />
        )}

        {/* <img
          className=" mt-4"
          src={profileUrl}
          width="150px"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        /> */}
        <div className="overlay">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-pencil-square-align-cente"
              viewBox="0 0 16 16"
              onClick={openModal}
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>

            <UpdateProfileModal
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              setProfile={setProfile}
              setProfileUrl={setProfileUrl}
            />
          </div>
        </div>
        <Grid container justifyContent="center" style={{ marginTop: "1%" }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            name="name"
            value={updateProfile.name}
            onChange={handleUpdate}
          />
        </Grid>
        <div>
          {updateProfile.isForKids == 1 ? (
            <FormGroup style={{ alignItems: "center" }}>
              <FormControlLabel
                label={<b style={{ color: "white" }}>For kids</b>}
                control={
                  <Switch
                    defaultChecked
                    name="isForKids"
                    onChange={handleUpdate}
                    border="solid"
                  />
                }
              />
            </FormGroup>
          ) : (
            <FormGroup style={{ alignItems: "center" }}>
              <FormControlLabel
                label={<b style={{ color: "white" }}>For kids</b>}
                control={<Switch name="isForKids" onChange={handleUpdate} />}
              />
            </FormGroup>
          )}
        </div>

        <Grid container justifyContent="center">
          <Button
            variant="contained"
            style={{ textAlign: "center" }}
            onClick={onUpdate}
          >
            Save
          </Button>
        </Grid>
        <div
          className="trash"
          style={{
            fontWeight: "bold",
            marginTop: "7%",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // marginLeft: "18%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
            onClick={onDeleteProfile}
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>{" "}
          Delete Profile
        </div>
        {updateProfile.secretKey == undefined ? (
          <div
            className="lock"
            style={{
              fontWeight: "bold",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-lock"
              viewBox="0 0 16 16"
              onClick={profileLock}
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>
            Lock User{" "}
          </div>
        ) : (
          <div
            className="lock"
            style={{
              fontWeight: "bold",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-unlock"
              viewBox="0 0 16 16"
              onClick={onOpenUnlock}
            >
              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
            </svg>
            Unlock User
            <UnlockProfile
              openUnlock={openUnlock}
              setOpenUnlock={setOpenUnlock}
              updateProfile={updateProfile}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  UPDATEPROFILE: getProfileSelector,
  PLATFORM: platformsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(UpdateProfile);

////console.log("profileurl", profileUrl);
// const [profileType, setProfileType] = useState();
// const [profileName, setProfileName] = useState();
// //console.log("type///////////////////", profileType);
// //console.log("name///////////////", profileName)
// //console.log("fref", values.uri);
// //console.log("rgrth", values.type);
// //console.log("vehgrrf", values.imgName);
// //console.log("url//////////////////////////////", profileUrl);
