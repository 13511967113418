const ACTION_TYPES = {
  GET_DEFAULT_PLATFORMS_LIST_START: "GET_DEFAULT_PLATFORMS_LIST_START",
  GET_DEFAULT_PLATFORMS_LIST_SUCCESS: "GET_DEFAULT_PLATFORMS_LIST_SUCCESS",
  GET_DEFAULT_PLATFORMS_LIST_FAILED: "GET_DEFAULT_PLATFORMS_LIST_FAILED",

  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START:
    "GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START",
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS:
    "GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS",
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED:
    "GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED",
};

export default ACTION_TYPES;
