// import { Alert } from "react-native";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

// export const getDataVersionAsync = async () => {
//   const ConstantHeaders = HeadersAcessToken();

//   // Alert.alert("platform service is working");
//   return await axiosInstance()
//     .get("/dataVersions", {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       // console.log("pragathi" + JSON.stringify(response));
//       return response.data;
//     })
//     .catch((error) => {
//       // console.log("error response " + error);
//       // alert(JSON.stringify(error));
//       Promise.reject(error.response.data);
//     });
// };
export const getDataVersionAsync = async () => {
  try {
    const response = await axiosInstance().get("/dataVersions");
    return response.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
