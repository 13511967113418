import videojs from "video.js";
import $ from "jquery";

const keywordControlPluginWithIcons = function() {
  const player = this;

  function initialize() {
    const playerEl = player.el();
    if (!playerEl) {
      console.error("Player element not found.");
      return;
    }

    // Create and append the keyword input field
    const keywordInput = document.createElement("input");
    keywordInput.type = "text";
    keywordInput.className = "vjs-keyword-control";
    playerEl.appendChild(keywordInput);

    // Add event listener for keyword commands
    $(keywordInput).on("keydown", function(event) {
      if (event.key === "Enter") {
        const command = keywordInput.value.trim().toLowerCase();
        keywordInput.value = "";
        handleKeywordCommand(command);
      }
    });

    // Create and append the control buttons
    const controlBar = player.controlBar.el();

    const skipForwardButton = createIconButton(
      "vjs-skip-forward",
      getForwardIcon(),
      "Forward 10 seconds"
    );
    controlBar.insertBefore(skipForwardButton, controlBar.firstChild);
    skipForwardButton.addEventListener("click", () => seekVideo(10));

    const playPauseButton = createIconButton(
      "vjs-play-pause",
      getPlayIcon(),
      "Play"
    );
    controlBar.insertBefore(playPauseButton, controlBar.firstChild);
    playPauseButton.addEventListener("click", togglePlayPause);

    const skipBackwardButton = createIconButton(
      "vjs-skip-backward",
      getBackwardIcon(),
      "Backward 10 seconds"
    );
    controlBar.insertBefore(skipBackwardButton, controlBar.firstChild);
    skipBackwardButton.addEventListener("click", () => seekVideo(-10));

    // Update play/pause button based on player state
    player.on("play", () =>
      updatePlayPauseButton(playPauseButton, getPauseIcon(), "Pause")
    );
    player.on("pause", () =>
      updatePlayPauseButton(playPauseButton, getPlayIcon(), "Play")
    );

    // Add keyboard event listeners
    document.addEventListener("keydown", handleKeyboardShortcuts);
  }

  function handleKeyboardShortcuts(event) {
    switch (event.key.toLowerCase()) {
      case "k":
        togglePlayPause();
        break;
      case "m":
        toggleMute();
        break;
      case "f":
        toggleFullscreen();
        break;
      case "i":
        togglePictureInPicture();
        break;
      case "j":
        seekVideo(-10); // 10 seconds backward
        break;
      case "l":
        seekVideo(10); // 10 seconds forward
        break;
      default:
        break;
    }
  }

  function createIconButton(className, svgContent, tooltip) {
    const button = document.createElement("button");
    button.className = `vjs-button ${className}`;
    button.innerHTML = svgContent;
    button.style.fontSize = "16px";
    button.style.padding = "0 10px";
    button.title = tooltip; // Add tooltip
    return button;
  }

  function getForwardIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M100-240v-480l360 240-360 240Zm400 0v-480l360 240-360 240ZM180-480Zm400 0Zm-400 90 136-90-136-90v180Zm400 0 136-90-136-90v180Z"/></svg>`;
  }

  function getBackwardIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M860-240 500-480l360-240v480Zm-400 0L100-480l360-240v480Zm-80-240Zm400 0Zm-400 90v-180l-136 90 136 90Zm400 0v-180l-136 90 136 90Z"/></svg>`;
  }

  function getPlayIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>`;
  }

  function getPauseIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M360-320h80v-320h-80v320Zm160 0h80v-320h-80v320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>`;
  }

  function handleKeywordCommand(command) {
    switch (command) {
      case "play":
        player.play();
        break;
      case "pause":
        player.pause();
        break;
      case "forward":
        seekVideo(10);
        break;
      case "backward":
        seekVideo(-10);
        break;
      default:
        console.warn(`Unknown command: ${command}`);
        break;
    }
  }

  function seekVideo(seconds) {
    if (player.currentTime() != null) {
      let newTime = player.currentTime() + seconds;
      if (newTime < 0) newTime = 0;
      if (newTime > player.duration()) newTime = player.duration();
      player.currentTime(newTime);
    }
  }

  function togglePlayPause() {
    if (player.paused()) {
      player.play();
    } else {
      player.pause();
    }
  }

  function toggleMute() {
    player.muted(!player.muted());
  }

  function toggleFullscreen() {
    if (player.isFullscreen()) {
      player.exitFullscreen();
    } else {
      player.requestFullscreen();
    }
  }

  async function togglePictureInPicture() {
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
    } else if (player.requestPictureInPicture) {
      await player.requestPictureInPicture();
    }
  }

  function updatePlayPauseButton(button, svgContent, tooltip) {
    button.innerHTML = svgContent;
    button.title = tooltip;
  }

  initialize();
};

videojs.registerPlugin(
  "keywordControlPluginWithIcons",
  keywordControlPluginWithIcons
);

export default keywordControlPluginWithIcons;
