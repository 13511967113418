/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./action";
import * as API from "../../services/liveChannelData/liveChannelData";

const {
  GET_LIVE_DETAILS_START,
  GET_MOVIE_DETAILS_START,
  GET_SERIES_DETAILS_START,
} = ACTION_TYPES;
/** Sagas */

function* getLiveChannelDataSaga(payload) {
  try {
    //console.log("get live assets calling");
    const { data } = yield call(API.liveChannelAsync, payload);

    yield put(ACTION.getLiveDetailsSuccess({ data }));
    // if(data.status == true){
    //   yield put(ACTION.getSearchStart(payload))
    // }
    // alert(JSON.stringify(data))
  } catch ({ message }) {
    yield put(ACTION.getLiveDetailsFailed({ message }));
  }
}
function* getMovieDataSaga(payload) {
  try {
    //console.log("get live assets calling");
    const { data } = yield call(API.MovieDataAsync, payload);

    yield put(ACTION.getMovieDetailsSuccess({ data }));
    // if(data.status == true){
    //   yield put(ACTION.getSearchStart(payload))
    // }
    // alert(JSON.stringify(data))
  } catch ({ message }) {
    yield put(ACTION.getMovieDetailsFailed({ message }));
  }
}

function* getSeriesDataSaga(payload) {
  try {
    //console.log("get Banners callingggggggggggggggggggggg",payload);
    const { data } = yield call(API.SeriesDataAsync, payload);
    //console.log("ARUN want to see the response " + JSON.stringify(data.data));

    yield put(ACTION.getSeriesDetailsSuccess({ data }));
  } catch ({ message }) {
    //console.log("error in banner " + message);
    yield put(ACTION.getSeriesDetailsFailed({ message }));
  }
}
/** Watchers or Observers */

function* getLiveChannelDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_LIVE_DETAILS_START);
    yield call(getLiveChannelDataSaga, payload);
  }
}

function* getMovieDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_MOVIE_DETAILS_START);
    yield call(getMovieDataSaga, payload);
  }
}

function* getSeriesDataWatcher() {
  while (true) {
    const { payload } = yield take(GET_SERIES_DETAILS_START);
    yield call(getSeriesDataSaga, payload);
  }
}

export default function*() {
  yield all([
    getLiveChannelDataWatcher(),
    getMovieDataWatcher(),
    getSeriesDataWatcher(),
  ]);
}
