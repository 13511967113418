import ACTION_TYPES from "./action.types";
import swal from "sweetalert";

const {
  ADD_TO_RECENT_WATCHES_START,
  ADD_TO_RECENT_WATCHES_SUCCESS,
  ADD_TO_RECENT_WATCHES_FAILED,
  BROADCAST_CREATE_PROFILE,
  BROADCAST_DELETE_PROFILE_BY_ID,
  BROADCAST_UPDATE_PROFILE,
  CREATE_PROFILE_START,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILED,
  DELETE_PROFILE_START,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAILED,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILED,
  DISABLE_PROFILE,
  LOGIN_FAILED,
  SAVE_CREADENTIALS_FOR_REMEMBER_ME,

  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_STATE_CLEAR,
  MARK_REMINDED_MOVIE_AS_READ_START,
  MARK_REMINDED_MOVIE_AS_READ_SUCCESS,
  MARK_REMINDED_MOVIE_AS_READ_FAILED,
  RATE_SHOW_START,
  RATE_SHOW_SUCCESS,
  RATE_SHOW_FAILED,
  RATE_RECENTLY_WATCHED_MOVIE_START,
  RATE_RECENTLY_WATCHED_MOVIE_SUCCESS,
  RATE_RECENTLY_WATCHED_MOVIE_FAILED,
  CLEAR_RECENT_WATCHES_START,
  CLEAR_RECENT_WATCHES_SUCCESS,
  CLEAR_RECENT_WATCHES_FAILED,
  REMOVE_TO_RECENT_WATCHES_START,
  REMOVE_TO_RECENT_WATCHES_SUCCESS,
  REMOVE_TO_RECENT_WATCHES_FAILED,
  SELECT_PROFILE_START,
  SELECT_PROFILE_SUCCESS,
  SELECT_PROFILE_FAILED,
  SET_PROFILE_COUNT_TO_DISABLE,
  SHOW_SUBSCRIBER_START,
  SHOW_SUBSCRIBER_SUCCESS,
  SHOW_SUBSCRIBER_FAILED,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS,
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED,
  TOGGLE_ADD_TO_MY_LIST_START,
  TOGGLE_ADD_TO_MY_LIST_SUCCESS,
  TOGGLE_ADD_TO_MY_LIST_FAILED,
  UPDATE_REMIND_ME_IS_RELEASED_PROPERTY,
  UPDATE_AUTHENTICATED_PROFILE_START,
  UPDATE_AUTHENTICATED_PROFILE_SUCCESS,
  UPDATE_AUTHENTICATED_PROFILE_FAILED,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS,
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED,
  UPDATE_USER_PROFILE,
  UPDATE_SUBSCRIPTION_DETAILS,
  CLEAR_ERRORS_PROPERTY,
  DEVICE_REGISTER_START,
  DEVICE_REGISTER_SUCCESS,
  DEVICE_REGISTER_FAILED,
  GET_DEVICE_LIST_START,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILED,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_START,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS,
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED,
  NAVIGATE_TO_DEVICE_SCREEN,
  MANAGE_NAVIGATION_STATUS,
  REPLACE_DEVICE_START,
  REPLACE_DEVICE_SUCCESS,
  REPLACE_DEVICE_FAILED,
  CHANNEL_LIST_START,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_FAIELD,
  PROGRAM_LIST_START,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_LIST_FAILED,
  PROFILE_CREATE_START,
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAILED,
  GET_PROFILE_LIST_START,
  GET_PROFILE_LIST_SUCCESS,
  GET_PROFILE_LIST_FAILED,
  PROFILE_UPDATE_START,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILED,
  PROFILE_BLOCK_START,
  PROFILE_BLOCK_SUCCESS,
  PROFILE_BLOCK_FAILED,
  PROFILE_UNBLOCK_START,
  PROFILE_UNBLOCK_SUCCESS,
  PROFILE_UNBLOCK_FAILED,
  PROFILE_SENDEMAIL_START,
  PROFILE_SENDEMAIL_SUCCESS,
  PROFILE_SENDEMAIL_FAILED,
  PROFILEPIC_URL_START,
  PROFILEPIC_URL_SUCCESS,
  PROFILEPIC_URL_FAILED,
  DEVICE_REGISTER_STATUS,
} = ACTION_TYPES;

const CREDENTIALS_DEFAULT_PROPS = {
  id: "",
  username: "",
  password: "",
  loginType: "2",
  remember_me: false,
};

const PROFILE_DEFAULT_PROPS = {
  // id: "",
  // firstName: "",
  // lastName: "",
  // emailId: "",
  // mobileNo: "",
  // username: "",
  // password: "",
  // avatar: null,
  // loginType: "2",
  // previous_avatar: null,
  // is_for_kids: false,
  // is_profiled_locked: false,
  // enabled: 1,
  // pin_code: "",
  // recently_watched_movies: [],
  // my_lists: [],
  // reminded_coming_soon_movies: [],
  // liked_movies: [],
  // liked_coming_soon_movies: [],
};

const DEFAULT_ERROR_MESSAGE_PROPS = {
  name: "",
  password: "",
  username: "",

  loginType: "2",
  avatar: "",
};

const SUBSCRIPTION_DETAILS_DEFAULT_PROPS = {
  id: "",
  user_id: "",
  type: "",
  cost: "",
  is_first_subscription: false,
  is_cancelled: false,
  is_expired: false,
  subscribed_at: null,
  expired_at: null,
  cancelled_at: null,
  status: "subscribed",
};

const DEFAULT_PROFILE_ACCOUNTS = [
  // {
  //   id: "1",
  //   name: "Narendra",
  //   avatar: "https://picsum.photos/200",
  //   is_profile_locked: false,
  //   enabled: true,
  //   is_for_kids: false,
  // },
  // {
  //   id: "2",
  //   name: "Sharath Nadig",
  //   avatar: "https://picsum.photos/200/300?random=1",
  //   is_profile_locked: true,
  //   enabled: true,
  //   is_for_kids: false,
  // },
  // {
  //   id: "3",
  //   name: "Mukesh Verma",
  //   avatar: "https://picsum.photos/200/300?random=1",
  //   is_profile_locked: false,
  //   enabled: true,
  //   is_for_kids: true,
  // },
  // {
  //   id: "4",
  //   name: "Arun Kumar",
  //   avatar: "https://picsum.photos/200/300?random=2",
  //   is_profile_locked: false,
  //   enabled: true,
  //   is_for_kids: true,
  // },
];

const initialState = {
  auth: null,
  devicetoken: [],
  credentials: CREDENTIALS_DEFAULT_PROPS,
  profile: PROFILE_DEFAULT_PROPS,
  numberofdevices: [],
  numberofprofiles: [],
  registerdevice: [],
  getdevicelist: [],
  replacedevice: [],
  createprofile: [],
  deleteprofile: [],
  editproifile: [],
  getprofile: [],
  updateprofile: [],
  profiles: DEFAULT_PROFILE_ACCOUNTS,
  devices: null,
  channelList: null,
  programlist: null,
  profileCountToDisable: 0,
  isAuthenticated: false,
  isLogin: "false",
  deviceToken: "",
  ReplaceDeviceStatus: false,
  isLoading: false,
  isireplaceDeviceLoading: false,
  profieLoading: false,
  errors: DEFAULT_ERROR_MESSAGE_PROPS,
  subscription_details: SUBSCRIPTION_DETAILS_DEFAULT_PROPS,
  profileState: false,
  remember_me: false,
  profilePicLoading: false,
  deviceLoadingStatus: true,
  credentialsForRememberMe: null,

  Device_Status_For_Delete_Device: false,
};

export default (state = initialState, { type, payload }) => {
  const { profiles, profile } = state;
  const { devices, device } = state;

  const isLoading = false;
  const errors = DEFAULT_ERROR_MESSAGE_PROPS;
  const loggedInProfile = null;
  // = profiles.find((profil) => profil.id === profile.id);
  let newProfiles = [];

  switch (type) {
    case ADD_TO_RECENT_WATCHES_START:
    case CLEAR_RECENT_WATCHES_START:
    case DELETE_PROFILE_START:

    case SIGNUP_START:

    case MARK_REMINDED_MOVIE_AS_READ_START:
    case RATE_SHOW_START:
    case RATE_RECENTLY_WATCHED_MOVIE_START:
    case REMOVE_TO_RECENT_WATCHES_START:
    case SELECT_PROFILE_START:
    case SHOW_SUBSCRIBER_START:
    case TOGGLE_ADD_TO_MY_LIST_START:
    case TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START:
    case UPDATE_AUTHENTICATED_PROFILE_START:
    case UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START:
      return {
        ...state,
        isLoading: true,
        errors,
      };

    case CREATE_PROFILE_START:
      return {
        ...state,
        isLoading: false,
        errors,
      };

    case ADD_TO_RECENT_WATCHES_SUCCESS:
      const { movie } = payload;
      const movieIndex = loggedInProfile.recently_watched_movies.findIndex(
        ({ id }) => id === movie.id
      );

      let newLoggedInProfile = loggedInProfile;

      const movie_ = { ...movie, user_ratings: [] };

      if (movieIndex === -1) {
        newLoggedInProfile.recently_watched_movies.unshift(movie_);
      }

      if (movieIndex !== -1) {
        newLoggedInProfile.recently_watched_movies.splice(movieIndex, 1);
        newLoggedInProfile.recently_watched_movies.unshift(movie_);
      }

      newProfiles = profiles.map((prof) =>
        prof.id === newLoggedInProfile.id ? newLoggedInProfile : prof
      );

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case BROADCAST_CREATE_PROFILE:
      newProfiles = [
        ...state.profiles,
        {
          ...PROFILE_DEFAULT_PROPS,
          ...payload.profile,
        },
      ];

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case BROADCAST_UPDATE_PROFILE:
      const payloadProfile = payload.profile;
      newProfiles = profiles.map((profile) =>
        profile.id === payload.profile.id
          ? { ...profile, ...payloadProfile }
          : profile
      );

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case BROADCAST_DELETE_PROFILE_BY_ID:
      newProfiles = profiles.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case CREATE_PROFILE_SUCCESS:
      const newProfile = {
        ...PROFILE_DEFAULT_PROPS,
        ...payload.profile,
      };

      return {
        ...state,
        profiles: [...profiles, newProfile],
        isLoading,
        errors,
      };

    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: profiles.filter(({ id }) => id !== payload.profileID),
        isLoading,
        errors,
      };

    case DISABLE_PROFILE:
      newProfiles = profiles.map((profile) => {
        return payload.profileIds.includes(profile.id)
          ? { ...profile, enabled: 0 }
          : { ...profile, enabled: 1 };
      });

      return {
        ...state,
        profileCountToDisable: 0,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case RATE_SHOW_SUCCESS:
      const { model_type } = payload;
      let newRatedMovies = [];

      if (model_type === "Movie") {
        let isMovieLiked = loggedInProfile.liked_movies.find(
          ({ movie_id }) => movie_id === payload.movie.id
        );

        if (!isMovieLiked) {
          newRatedMovies.push({ ...payload.movie, movie_id: payload.movie.id });
        } else {
          newRatedMovies = loggedInProfile.liked_movies.filter(
            ({ movie_id }) => movie_id !== payload.movie.id
          );
        }
      } else {
        let isMovieLiked = loggedInProfile.liked_coming_soon_movies.find(
          ({ movie_id }) => movie_id === payload.movie.id
        );

        if (!isMovieLiked) {
          newRatedMovies.push({ ...payload.movie, movie_id: payload.movie.id });
        } else {
          newRatedMovies = loggedInProfile.liked_coming_soon_movies.filter(
            ({ movie_id }) => movie_id !== payload.movie.id
          );
        }
      }

      newProfiles = profiles.map((profile) => {
        if (profile.id !== loggedInProfile.id) return profile;

        if (model_type === "Movie") {
          return {
            ...profile,
            liked_movies: newRatedMovies,
          };
        }

        return {
          ...profile,
          liked_coming_soon_movies: newRatedMovies,
        };
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case RATE_RECENTLY_WATCHED_MOVIE_SUCCESS:
      const currentLikedMovies = [...loggedInProfile.liked_movies];
      let updatedLikedMovies = [];

      const isMovieLiked = currentLikedMovies.find(
        ({ movie_id }) => movie_id === payload.movie.id
      );

      if (!isMovieLiked) {
        updatedLikedMovies.push(payload.movie);
      }

      if (isMovieLiked) {
        updatedLikedMovies = currentLikedMovies.filter(
          ({ movie_id }) => movie_id !== payload.movie.id
        );
      }

      const mapRecentlyWatchedMovies = (movie) => {
        if (movie.id === payload.movie.id) {
          if (!movie.rate || movie.rate) {
            return {
              ...movie,
              user_ratings: [
                {
                  movie_id: movie.id,
                  model_type: payload.model,
                  rate: payload.rate,
                },
              ],
            };
          }

          return { ...movie, user_ratings: [] };
        }

        return movie;
      };

      const recentlyWatchedMovies = loggedInProfile.recently_watched_movies.map(
        mapRecentlyWatchedMovies
      );

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? {
              ...prof,
              recently_watched_movies: recentlyWatchedMovies,
              liked_movies: updatedLikedMovies,
            }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case SAVE_CREADENTIALS_FOR_REMEMBER_ME:
      return {
        ...state,
        credentialsForRememberMe: payload,
      };

    case SIGNUP_START:
      return {
        ...state,
        auth: payload.auth,
        isAuthenticated: true,
        isLoading,
        errors,
        subscription_details: payload.subscription_details,
        profileState: false,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        auth: null,
        profiles: [],
        profile: payload.data,
        isAuthenticated: false,
        isLoading: false,
        errors,
        profileState: payload.status,
      };

    case SIGNUP_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        errors: payload.message,
        profileState: false,
      };
    case SIGNUP_STATE_CLEAR:
      return {
        ...state,
        profileState: payload,
      };

    case MARK_REMINDED_MOVIE_AS_READ_SUCCESS:
      const remindedComingSoonMovies = loggedInProfile.reminded_coming_soon_movies.map(
        (movie) =>
          movie.coming_soon_movie_id === payload.coming_soon_movie_id
            ? {
                ...movie,
                read_at: new Date(),
              }
            : movie
      );

      newProfiles = profiles.map((profile) =>
        profile.id === payload.user_profile_id
          ? {
              ...profile,
              reminded_coming_soon_movies: remindedComingSoonMovies,
            }
          : profile
      );

      return {
        ...state,
        profiles: newProfiles,
        errors,
        isLoading,
      };

    case CLEAR_RECENT_WATCHES_SUCCESS:
      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, recently_watched_movies: [] }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case REMOVE_TO_RECENT_WATCHES_SUCCESS:
      const filteredRecentlyWatchedMovies = loggedInProfile.recently_watched_movies.filter(
        ({ id }) => id !== payload.movie_id
      );

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, recently_watched_movies: filteredRecentlyWatchedMovies }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case SELECT_PROFILE_SUCCESS:
      const { profile: profile_ } = payload;

      newProfiles = profiles.map((prof) =>
        prof.id === profile_.id
          ? {
              ...prof,
              ...profile_,
            }
          : prof
      );

      return {
        ...state,
        profile: profile_,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case SET_PROFILE_COUNT_TO_DISABLE:
      return {
        ...state,
        profileCountToDisable: payload.profileCount,
        isLoading,
        errors,
      };

    case SHOW_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        auth: {
          user: {
            ...payload.auth.user,
            password: state.auth.user.password,
          },
        },
        profiles: payload.profiles.map((profile) => ({
          ...PROFILE_DEFAULT_PROPS,
          ...profile,
        })),
        isAuthenticated: true,
        isLoading,
        errors,
        subscription_details: payload.subscription_details,
      };

    case TOGGLE_ADD_TO_MY_LIST_SUCCESS:
      const currentMyList = loggedInProfile.my_lists;
      const movieExistsInMyList = currentMyList.find(
        ({ movie_id }) => movie_id === payload.movie.id
      );

      let newMyList = [];

      if (!movieExistsInMyList) {
        newMyList = [
          ...currentMyList,
          {
            movie: payload.movie,
            movie_id: payload.movie.id,
          },
        ];
      } else {
        newMyList = currentMyList.filter(
          ({ movie_id }) => movie_id !== payload.movie.id
        );
      }

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, my_lists: newMyList }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS:
      const { movieID } = payload;
      let remindedMovies = loggedInProfile.reminded_coming_soon_movies;

      let isReminded = remindedMovies.find(
        ({ coming_soon_movie_id }) => coming_soon_movie_id === movieID
      );

      if (!isReminded) {
        remindedMovies.push({
          coming_soon_movie_id: movieID,
          id: new Date().toLocaleString(),
          is_released: 0,
          read_at: null,
          reminded_at: new Date().toLocaleString(),
          user_id: state.auth?.user.id,
          user_profile_id: loggedInProfile.id,
        });
      } else {
        remindedMovies = remindedMovies.filter(
          ({ coming_soon_movie_id }) => coming_soon_movie_id !== movieID
        );
      }

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, reminded_coming_soon_movies: remindedMovies }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case UPDATE_REMIND_ME_IS_RELEASED_PROPERTY:
      let remindedComingSoonMovies_ = [
        ...loggedInProfile.reminded_coming_soon_movies,
      ];

      remindedComingSoonMovies_ = remindedComingSoonMovies_.map(
        (remindedComingSoonMovie) => {
          return remindedComingSoonMovie.coming_soon_movie_id === payload.id
            ? { ...remindedComingSoonMovie, is_released: 1 }
            : remindedComingSoonMovie;
        }
      );

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, reminded_coming_soon_movies: remindedComingSoonMovies_ }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case UPDATE_AUTHENTICATED_PROFILE_SUCCESS:
      newProfiles = profiles.map((prevProfile) => {
        return prevProfile.id === payload.profile.id
          ? { ...prevProfile, ...payload.profile }
          : prevProfile;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS:
      const recentlyWatchedMovies_ = loggedInProfile.recently_watched_movies.map(
        (movie) => {
          return movie.id === payload.movieId
            ? {
                ...movie,
                last_played_position_millis: payload.positionMillis,
                duration_in_millis: payload.duration_in_millis,
              }
            : movie;
        }
      );

      newProfiles = profiles.map((prof) => {
        return prof.id === loggedInProfile.id
          ? { ...prof, recently_watched_movies: recentlyWatchedMovies_ }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
      };

    case UPDATE_SUBSCRIPTION_DETAILS:
      newProfiles = profiles.map((profile) => ({ ...profile, enabled: 1 }));

      return {
        ...state,
        profiles: newProfiles,
        isLoading,
        errors,
        subscription_details: {
          ...state.subscription_details,
          ...payload.subscription_details,
        },
      };

    case UPDATE_USER_PROFILE:
      newProfiles = profiles.map((prof) => {
        return prof.id === payload.user_profile_id
          ? { ...prof, ...payload }
          : prof;
      });

      return {
        ...state,
        profiles: newProfiles,
      };

    case CLEAR_ERRORS_PROPERTY:
      return {
        ...state,
        errors,
      };

    case ADD_TO_RECENT_WATCHES_FAILED:
    case CLEAR_RECENT_WATCHES_FAILED:
    case CREATE_PROFILE_FAILED:
    case DELETE_PROFILE_FAILED:
    case MARK_REMINDED_MOVIE_AS_READ_FAILED:
    case SELECT_PROFILE_FAILED:
    case SHOW_SUBSCRIBER_FAILED:
    case RATE_SHOW_FAILED:
    case RATE_RECENTLY_WATCHED_MOVIE_FAILED:
    case REMOVE_TO_RECENT_WATCHES_FAILED:
    case TOGGLE_ADD_TO_MY_LIST_FAILED:
    case TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED:
    case UPDATE_AUTHENTICATED_PROFILE_FAILED:
    case UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    case DEVICE_REGISTER_START:
      // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

      return {
        ...state,
        registereddevice: payload.data,
        isLoading,
        errors: payload.message,
      };
    case DEVICE_REGISTER_SUCCESS:
      return {
        ...state,
        registerdevice: payload.data,
        isLoading,
        errors,
      };

    case DEVICE_REGISTER_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };
    case GET_DEVICE_LIST_START:
      // console.log(
      //   "packages success data/////////////////////////////////////////////////////////////////////////" +
      //   JSON.stringify(payload)
      // );

      return {
        ...state,

        isLoading: true,
        deviceLoadingStatus: true,
      };
    case GET_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        devices: payload.data.data,
        deviceLoadingStatus: false,
        isLoading,
        errors,
      };

    case GET_DEVICE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        deviceLoadingStatus: true,
        errors: payload.message,
      };

    case GET_DEVICE_LIST_FOR_ADDING_DEVICE_START:
      // console.log(
      //   "packages success data/////////////////////////////////////////////////////////////////////////" +
      //     JSON.stringify(payload.data)
      // );

      return {
        ...state,

        isLoading: true,
        deviceLoadingStatus: true,
      };
    case GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS:
      return {
        ...state,
        devices: payload.data.data,
        deviceLoadingStatus: false,
        isLoading,
        errors,
      };

    case GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED:
      return {
        ...state,
        isLoading: false,
        deviceLoadingStatus: true,
        errors: payload.message,
      };

    case REPLACE_DEVICE_START:
      return {
        ...state,
        // replacedevice: payload.data,
        isLoading,
        errors: payload.message,
        isireplaceDeviceLoading: true,
      };
    case REPLACE_DEVICE_SUCCESS:
      return {
        ...state,
        // replacedevice: payload.data,
        isLoading,
        errors,
        isireplaceDeviceLoading: false,
      };

    case REPLACE_DEVICE_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        isireplaceDeviceLoading: false,
      };
    case NAVIGATE_TO_DEVICE_SCREEN:
      return {
        ...state,
        ReplaceDeviceStatus: true,
      };
    case MANAGE_NAVIGATION_STATUS:
      return {
        ...state,
        ReplaceDeviceStatus: false,
      };
    case CHANNEL_LIST_START:
      return {
        ...state,
        channelList: null,
        isLoading,
        errors: payload.message,
      };
    case PROGRAM_LIST_START:
      return {
        ...state,
        programlist: null,
        isLoading,
        errors: payload.message,
      };
    case PROFILE_CREATE_START:
      // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

      return {
        ...state,
        profile: payload.data,
        isLoading,
        errors: payload.message,
      };
    case PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        profile: payload.data,
        isLoading,
        errors,
      };

    case PROFILE_CREATE_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };
    case GET_PROFILE_LIST_START:
      // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

      return {
        ...state,
        profiles: [],
        isLoading: true,
        errors: payload.message,
        profieLoading: true,
      };
    case GET_PROFILE_LIST_SUCCESS:
      var profilesList = payload.data.map((item) => {
        item.enabled = true;
        return item;
      });
      return {
        ...state,
        profiles: profilesList,
        isLoading,
        profieLoading: false,
        errors,
      };

    case GET_PROFILE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: payload.message,
        profieLoading: false,
      };
    case PROFILE_UPDATE_START:
      return {
        ...state,
        updateprofile: payload.data,
        isLoading,
        errors: payload.message,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updateprofile: payload.data,
        isLoading,
        errors,
      };

    case PROFILE_UPDATE_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    // case CREATE_PROFILE_START:
    //   // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

    //   return {
    //     ...state,
    //     profile: payload.data,
    //     isLoading,
    //     errors: payload.message,
    //   };
    // case CREATE_PROFILE_SUCCESS:
    //   //console.log("createprofile success data/////////////////////////////////////////////////////////////////////////" + JSON.stringify(payload.data));
    //   return {
    //     ...state,
    //     profile: payload.data,
    //     isLoading,
    //     errors,
    //   };

    // case CREATE_PROFILE_FAILED:
    //   return {
    //     ...state,
    //     isLoading,
    //     errors: payload.message,
    //   };

    case DELETE_PROFILE_START:
      // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

      return {
        ...state,
        deleteprofile: payload.data,
        isLoading,
        errors: payload.message,
      };
    case DELETE_PROFILE_SUCCESS:
      //console.log("deleteprofile success data/////////////////////////////////////////////////////////////////////////" + JSON.stringify(payload.data));

      return {
        ...state,
        deleteprofile: payload.data,
        isLoading,
        errors,
      };

    case DELETE_PROFILE_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    // case EDIT_PROFILE_START:
    //   // //console.log("packages success data/////////////////////////////////////////////////////////////////////////"+JSON.stringify(payload.data));

    //   return {
    //     ...state,
    //     editproifile: payload.data,
    //     isLoading,
    //     errors: payload.message,
    //   };
    // case EDIT_PROFILE_SUCCESS:
    //   //console.log("editproifile success data/////////////////////////////////////////////////////////////////////////" + JSON.stringify(payload.data));
    //   return {
    //     ...state,
    //     editproifile: payload.data,
    //     isLoading,
    //     errors,
    //   };

    // case EDIT_PROFILE_FAILED:
    //   return {
    //     ...state,
    //     isLoading,
    //     errors: payload.message,
    //   };

    case GET_PROFILES_SUCCESS:
      //console.log("getprofile success data/////////////////////////////////////////////////////////////////////////" + JSON.stringify(payload.data));
      return {
        ...state,
        getprofile: payload.data,
        isLoading,
        errors,
      };

    case GET_PROFILES_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    //Block unblock send mailll///////////////////////////

    case PROFILE_BLOCK_START:
      // //console.log(
      //   "packages success data/////////////////////////////////////////////////////////////////////////" +
      //     JSON.stringify(payload.data)
      // );

      return {
        ...state,
        blockprofile: payload.data,
        isLoading: true,
        errors: payload.message,
      };
    case PROFILE_BLOCK_SUCCESS:
      return {
        ...state,
        blockprofile: payload.data,
        isLoading,
        errors,
        profileLocked: true,
      };

    case PROFILE_BLOCK_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        profileLocked: false,
      };

    case PROFILE_UNBLOCK_START:
      return {
        ...state,
        unblockprofile: payload.data,
        isLoading: true,
        errors: payload.message,
      };
    case PROFILE_UNBLOCK_SUCCESS:
      return {
        ...state,
        unblockprofile: payload.data,
        isLoading,
        errors,
      };

    case PROFILE_UNBLOCK_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    case PROFILE_SENDEMAIL_START:
      return {
        ...state,
        sendemailprofile: payload.data,
        isLoading: true,
        errors: payload.message,
      };
    case PROFILE_SENDEMAIL_SUCCESS:
      return {
        ...state,
        sendemailprofile: payload.data,
        isLoading,
        errors,
      };

    case PROFILE_SENDEMAIL_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    case PROFILEPIC_URL_START:
      return {
        ...state,
        profilepicurl: [],
        isLoading: true,
        errors: payload.message,
        profilePicLoading: true,
      };
    case PROFILEPIC_URL_SUCCESS:
      ////console.log(
      //     "//////////////////////////////////////////",
      //     JSON.stringify(payload)
      //   );

      return {
        ...state,
        profilepicurl: payload.data,
        profiles: [],
        isLoading,
        profilePicLoading: false,
        errors,
      };

    case PROFILEPIC_URL_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        profilePicLoading: false,
      };

    case DEVICE_REGISTER_STATUS:
      return {
        ...state,
        Device_Status_For_Delete_Device: payload,
      };
    default:
      return state;
  }
};
