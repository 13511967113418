import React from "react";

const LoadingPage = () => {
  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "black",
        height: "600px",
        width: "100%",
      }}
    >
      
      <img src="images/login/gif1.gif" style={{ marginLeft: "200px" }} />
    </div>
  );
};

export default LoadingPage;
