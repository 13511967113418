import types from "./actiontype";

export const update_personal_info_start = (payload) => {
  //console.log(" personal information calling");
  return {
    type: types.UPDATE_PERSONAL_INFO_START,
    payload,
  };
};

export const update_personal_info_success = (payload) => {
  //console.log("personal information success");
  return {
    type: types.UPDATE_PERSONAL_INFO_SUCCESS,
    payload,
  };
};

export const update_personal_info_failure = (payload) => {
  //console.log("personal information failure");
  return {
    type: types.UPDATE_PERSONAL_INFO_FAILURE,
    payload,
  };
};
