import { createSelector } from "reselect";

const getPackageDetails = (state) => {
  return state.packageDetailsReducer.packageDetails;
};
const getAlacarteDetails = (state) => {
  return state.packageDetailsReducer.alacarteDetails;
};
const getPayPerViewDetails = (state) => {
  return state.packageDetailsReducer.payPerViewDetails;
};
const getPayPerViewAssetDetails = (state) => {
  return state.packageDetailsReducer.payPerViewAssetDetails;
};

const getPackageArrayDetails = (state) => {
  return state.packageDetailsReducer.packageOrderedData;
};
export const getPackageDetailsSelector = createSelector(
  getPackageDetails,
  (packageDetails) => packageDetails
);
export const getAlacarteDetailsSelector = createSelector(
  getAlacarteDetails,
  (alacarteDetails) => alacarteDetails
);
export const getPayPerViewDetailsSelector = createSelector(
  getPayPerViewDetails,
  (payPerViewDetails) => payPerViewDetails
);
export const getPayPerViewAssetDetailsSelector = createSelector(
  getPayPerViewAssetDetails,
  (payPerViewAssetDetails) => payPerViewAssetDetails
);
export const getPackageArraySelector = createSelector(
  getPackageArrayDetails,
  (packageOrderedData) => packageOrderedData
);
