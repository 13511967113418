/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_DELETE_DEVICES_START,
  GET_DELETE_DEVICES_SUCCESS,
  GET_DELETE_DEVICES_FAILED,

  CHECK_EXISTANCE_OF_DEVICE_START,
  CHECK_EXISTANCE_OF_DEVICE_SUCCESS,
  CHECK_EXISTANCE_OF_DEVICE_FAILED,
  DEVICE_STATUS,
} = ACTION_TYPES;

export const getDeleteDevicesStart = (payload) => ({
  type: GET_DELETE_DEVICES_START,
  payload,
});

export const getDeleteDevicesSuccess = (payload) => ({
  type: GET_DELETE_DEVICES_SUCCESS,
  payload,
});
export const getDeleteDevicesFailed = (payload) => ({
  type: GET_DELETE_DEVICES_FAILED,
  payload,
});

export const checkExistanceOfDeviceStart = (payload) => ({
  type: CHECK_EXISTANCE_OF_DEVICE_START,
  payload,
});

export const checkExistanceOfDeviceSuccess = (payload) => ({
  type: CHECK_EXISTANCE_OF_DEVICE_SUCCESS,
  payload,
});
export const checkExistanceOfDeviceFailed = (payload) => ({
  type: CHECK_EXISTANCE_OF_DEVICE_FAILED,
  payload,
});

export const deviceStatus = (payload) => ({
  type: DEVICE_STATUS,
  payload,
});
