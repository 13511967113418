import { all, call, put, take } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as PACKAGE_API from "./../../services/package&subscription/packageDetails";
import * as ACTIONS from "./actions";

const { GET_PACKAGE_DETAILS_START } = ACTION_TYPES;

function* getPackageDetails(payload) {
  try {
    const { data } = yield call(PACKAGE_API.packageDetails, payload);
    if (data.status == true) {
      yield put(ACTIONS.getPackageDetailsSuccess({ data: data?.data }));
    }
  } catch ({ message }) {
    yield put(ACTIONS.getPackageDetailsFailed({ message }));
  }
}

function* getPackageDetailsWatcher() {
  while (true) {
    const { payload } = yield take(GET_PACKAGE_DETAILS_START);
    yield call(getPackageDetails, payload);
  }
}
export default function*() {
  yield all([getPackageDetailsWatcher()]);
}
