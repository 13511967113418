import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ProgressBar from "@ramonak/react-progress-bar";

const StyledDiv = styled("div")`
  background-color: grey;
  border-radius: 50px; /* Curved border */
  overflow: hidden; /* Clip the children within the rounded border */
`;

const StyledBar = styled("div")`
  background-color: red;
  height: 5px;
  width: ${({ completed }) => completed}%;
  border-radius: 50px; /* Curved border */
`;

const ProgressBarr = (props) => {
  return (
    <StyledDiv>
      <StyledBar completed={props.completed} />
    </StyledDiv>
  );
};

ProgressBarr.propTypes = {
  completed: PropTypes.number.isRequired,
};

export default ProgressBarr;
