import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const searchDataAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  //console.log("live assets parameters " + JSON.stringify(payload));
  return await axiosInstance()
    .get("/search/" + payload.key + "/" + payload.userId, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
