/** @format */

const ACTION_TYPES = {
  GET_SERIES_GENRES_START: "GET_SERIES_GENRES_START",
  GET_SERIES_GENRES_SUCCESS: "GET_SERIES_GENRES_SUCCESS",
  GET_SERIES_GENRES_FAILED: "GET_SERIES_GENRES_FAILED",
  GET_SERIES_GENRE_ASSETS_FOR_LISTING: "GET_SERIES_GENRE_ASSETS_FOR_LISTING",
  GET_SERIES_EPISODE_DETAILS: "GET_SERIES_EPISODE_DETAILS",
  MAKE_SERIES_LOADING_FALSE: "MAKE_SERIES_LOADING_FALSE",
};

export default ACTION_TYPES;
