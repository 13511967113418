const ACTION_TYPES = {
  GET_EPG_DETAILS_START: "GET_EPG_DETAILS_START",
  GET_EPG_DETAILS_SUCCESS: "GET_EPG_DETAILS_SUCCESS",
  GET_EPG_DETAILS_FAILED: "GET_EPG_DETAILS_FAILED",
  GET_EPG_STATE_SUCCESS: "GET_EPG_STATE_SUCCESS",
  CLEAR_EPG_SUCCESS: "CLEAR_EPG_SUCCESS",
  EPG_DATE: "EPG_DATE",
  EPG_UPPEND_SUCCESS: "EPG_UPPEND_SUCCESS",

};

export default ACTION_TYPES;
