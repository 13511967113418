import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as NOTIFICATION_API from "./../../services/PushNotification/PushNotification";
// import { Alert } from "react-native";
const { GET_PUSH_NOTIFICATION_START } = ACTION_TYPES;

function* getPushNotificationSaga(payload) {
  try {
    const { data } = yield call(
      NOTIFICATION_API.getPushNotificationAsync,
      payload
    );

    yield put(ACTION.getPushNotificationSuccess({ data }));
  } catch ({ message }) {
    yield put(ACTION.getPushNotificationFailed({ message }));
  }
}

function* getPushNotificationWatcher() {
  while (true) {
    const { payload } = yield take(GET_PUSH_NOTIFICATION_START);
    yield call(getPushNotificationSaga, payload);
  }
}

export default function*() {
  yield all([getPushNotificationWatcher()]);
}
