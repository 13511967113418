/** @format */

import ACTION_TYPES from "./actionType";

const {
  GET_SERIES_GENRES_START,
  GET_SERIES_GENRES_SUCCESS,
  GET_SERIES_GENRES_FAILED,
  GET_SERIES_GENRE_ASSETS_FOR_LISTING,

  GET_SERIES_EPISODE_DETAILS,
  MAKE_SERIES_LOADING_FALSE,
} = ACTION_TYPES;

export const getSriesGenresStart = (payload) => ({
  type: GET_SERIES_GENRES_START,

  payload,
});

export const getSeriesGenresSuccess = (payload) => ({
  type: GET_SERIES_GENRES_SUCCESS,
  payload,
});

export const getSeriesGenresFailed = (payload) => ({
  type: GET_SERIES_GENRES_FAILED,
  payload,
});


export const getSeriesGenreAssetsForListing = (payload) => ({
  type: GET_SERIES_GENRE_ASSETS_FOR_LISTING,
  payload,
});

export const getSeriesEpisodeDetails = (payload) => ({
  type: GET_SERIES_EPISODE_DETAILS,
  payload,
});
export const makeSeriesLoadingFalse = (payload) => ({
  type: MAKE_SERIES_LOADING_FALSE,
  payload,
});
