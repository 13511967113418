/** @format */

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import LiveGenreCategory from "./LiveGenreCategory";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import {
  getGenreDetailsPropsSelector,
  getOfffsetLengthSelector,
} from "../../../Redux/live/selectors";
import { getAllLiveGenreAssetsForListingSelector } from "../../../Redux/AllLiveDetails/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";

const GenreContainer = ({
  OFFSET,
  LIVE_GENRE_ASSET_FOR_LISTING,
  GENRE_PROPS,
  setIsVideoPlayer,
  DEVICE_STATUS,
  AUTHENTICATION,
  setSelectedCategory,
}) => {
  document.title = "DishHome GO";
  const history = useHistory();
  useEffect(() => {
    setIsVideoPlayer(false);
  }, []);
  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      requestAnimationFrame(() => {
        myRef?.current?.scrollIntoView({
          // behavior: "smooth",
          block: "start",
        });
      });
    }
  }, [myRef]);
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  return (
    <div
      ref={myRef}
      className="main-content p-0 wrapper"
      style={{
        minHeight: "600px",
        backgroundSize: "cover",
        // backgroundImage: `url(${backgroundUrl})`,
        backgroundAttachment: "fixed",
        //// backgroundBlendMode: "hard-light",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {" "}
      {/* <ScrollToTop /> */}
      <section id="iq-favorites">
        {" "}
        <div
          className="container-fluid"
          // onClick={handleModalClose}
        >
          <div
            className="row"
            //onClick={handleModalClose}
          >
            <LiveGenreCategory
              OFFSET={OFFSET}
              LIVE_GENRE_ASSET_FOR_LISTING={LIVE_GENRE_ASSET_FOR_LISTING}
              GENRE_PROPS={GENRE_PROPS}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        </div>
      </section>
      {/* <div style={{ height: "80px" }}>
        <FooterPage />
      </div> */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  OFFSET: getOfffsetLengthSelector,

  LIVE_GENRE_ASSET_FOR_LISTING: getAllLiveGenreAssetsForListingSelector,
  GENRE_PROPS: getGenreDetailsPropsSelector,
  USER_DETAILS: userDetailsSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});
export default connect(mapStateToProps)(GenreContainer);
