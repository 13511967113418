import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "50%",
    height: "50%"
  }
};
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: "none"
  }
}));

const setAppElement = "#yourAppElement";

function CreateProfileoModal(props) {
  const classes = useStyles();
  let file;

  function closeModal() {
    props.setOpen(false);
  }
  const [img, setImg] = useState();
  const [imgUrl, setimgUrl] = useState();

  const onImageChange = e => {
    file = URL.createObjectURL(e.target.files[0]);
    //console.log("url", file);
    setImg(file);
    setimgUrl(e.target.files[0]);
    //console.log(e.target.files[0]);
  };
  const onUpload = e => {
    props.setImageUpload(img);
    props.setOpen(false);
    props.setImage(imgUrl);
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          color="white"
          onClick={closeModal}
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onImageChange}
        />
        <Grid container justify="center">
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Choose file
            </Button>
          </label>
        </Grid>
        <img
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          src={img}
          alt=""
        />
        {img != undefined ? (
          <div>
            <Grid container justify="center">
              <Button style={{ color: "white" }} onClick={onUpload}>
                Upload
              </Button>
            </Grid>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
export default CreateProfileoModal;

// const fd = new FormData();
// fd.append = ("image", file);
// //console.log("abc", fd);
// axios
//   .post("http://10.0.90.25:3000/pixfixws/update/profilePic/", fd)
//   .then((res) => {
//     //console.log(res);
//   });
