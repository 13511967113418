/** @format */

import { call, put, all, take } from "redux-saga/effects";
import { getPersonalInfoAsync } from "./../../../services/manageprofile/manageProfileService";
import {
  update_personal_info_success,
  update_personal_info_failure,
} from "./actions";
import types from "./actiontype";
// import * as PROFILE_ACION from "./../../ProfileDetails/actions";
import * as AUTHENTICATION_ACTIONS from "./../../authentication/actions";

import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
function* personalInfoSaga(payload) {
  //console.log("response....////", JSON.stringify(payload));

  try {
    const { data } = yield call(getPersonalInfoAsync, payload);

    //console.log("personal data" + JSON.stringify(data));

    yield put(update_personal_info_success(payload));
    yield put(AUTHENTICATION_ACTIONS.userDetailsAction({ id: payload.id }));

    // alert(payload.status)
    if (data.status == true) {
      // Swal.fire({
      //   icon: "success",
      //   // position: "top-end",
      //   text: "updated successfully",
      //   allowOutsideClick: () => !swal.isLoading(),
      //   confirmButtonColor: "#3085d6",
      //   button: "OK",
      // });
      swal({
        text: "Updated successfully",
        icon: "success",
        closeOnClickOutside: false,
        confirmButtonColor: "#3085d6",
        // dangerMode: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Udation Failed",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }

    // dispatch(PROFILE_ACION.getProfileDetailsStart(data));

    //
  } catch ({ error }) {
    yield put(update_personal_info_failure({ error }));
  }
}

function* watcherSaga() {
  while (true) {
    const { payload } = yield take(types.UPDATE_PERSONAL_INFO_START);
    yield call(personalInfoSaga, payload);
  }
}

export default function*() {
  yield all([watcherSaga()]);
}
