import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Button, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import { createStructuredSelector } from "reselect";
import { platformsSelector } from "../../Redux/platforms/selectors";
import { connect, useDispatch } from "react-redux";
import * as UNLOCK_TYPES from "../../Redux/auth/actions";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { userDetailsSelector } from "../../Redux/authentication/selectors";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50%",
    width: "50%",
    backgroundColor: "black",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
const setAppElement = "#yourAppElement";

function OpenLockProfile({
  modalIsOpen,
  setIsOpen,
  PLATFORM,
  secretKey,
  id,
  USER_DETAILS,
}) {
  ////console.log("secretKey" + JSON.stringify(secretKey));
  //alert("id" + JSON.stringify(id));
  const dispatch = useDispatch();
  const history = useHistory();
  function closeModal() {
    setIsOpen(false);
  }
  const [textField, setTextField] = useState();
  const [textFieldError, setTextFieldError] = useState();
  const handleTextField = (e) => {
    setTextField(e.target.value);
  };
  //console.log("input feilds", textField);

  //console.log("is unLocked", unlockProfileData);

  const onHandleClick = (e) => {
    e.preventDefault();
    setTextFieldError(false);
    if (textField == undefined || textField.length == 0) {
      setTextFieldError(true);
    } else if (textField != secretKey) {
      setTextFieldError(true);
    } else {
      // dispatch(UNLOCK_TYPES.profileUnBlockStart(unlockProfileData));

      setIsOpen(false);
      // swal("profile unlocked successfully");
      history.push("/container");
    }
  };

  let profileMailData = {
    account_id: PLATFORM.currentPlatformId,
    userId: USER_DETAILS?.id,
    profileId: id,
  };

  const handleMail = () => {
    setIsOpen(false);
    confirmAlert({
      title: "we will sent secret key to your mail",
      message: "check your mail",

      buttons: [
        {
          label: "OK",
          onClick: () => {
            //console.log("send request", profileMailData);
            dispatch(UNLOCK_TYPES.profileSendEmailStart(profileMailData));
          },
        },

        {
          label: "Cancel",
        },
      ],
    });
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          color="white"
          onClick={closeModal}
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
        <div>
          <h5 style={{ textAlign: "center", color: "white" }}>
            {" "}
            Please enter the secret key
          </h5>
          <Grid
            container
            justify="center"
            style={{ marginTop: "2%", border: "black solid" }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={textField}
              style={{ margin: "0 auto" }}
              onChange={handleTextField}
              error={textFieldError}
              helperText="pleaase enetr secrete key correctly!!"
            />
          </Grid>

          <div className="row mt-1">
            <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={onHandleClick}
              >
                OK
              </Button>
            </Grid>
          </div>
          <Grid container justify="center">
            <Button style={{ color: "white" }} onClick={handleMail}>
              {" "}
              Forgot Key ??
            </Button>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  USER_DETAILS: userDetailsSelector,
});

export default connect(mapStateToProps)(OpenLockProfile);
