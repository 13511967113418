/** @format */

import { all, take, put, call, push } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as AUTH_ACTION from "../auth/actions";

import * as ADD_DEVICE_API from "./../../services/addDeviceService/addDevice";
import * as DEVICE_STATUS from "./../Delete Device/actions";
const { GET_ADD_DEVICES_START } = ACTION_TYPES;

/** Sagas */

function* addDeviceDataSaga(payload) {
  let deviceId = payload?.addDeviceData?.deviceId;
  let history = payload?.addDeviceData?.history;

  try {
    //console.log("get live assets calling");
    const { data } = yield call(
      ADD_DEVICE_API.addDeviceAsync,
      payload?.addDeviceData
    );
    if (data.status == true) {
      yield put(ACTION.saveDeviceTocken(deviceId));
      // yield put(DEVICE_STATUS.deviceStatus(false));
      history.replace(payload?.navigationPath);
      //window.location.href = "//container";
    }
    yield put(ACTION.getAddDevicesSuccess({ data }));

    yield put(AUTH_ACTION.device_rigister_status(true));
  } catch ({ message }) {
    yield put(ACTION.getAddDevicesFailed({ message }));
  }
}

/** Watchers or Observers */

function* addDeviceWatcher() {
  while (true) {
    const { payload } = yield take(GET_ADD_DEVICES_START);
    yield call(addDeviceDataSaga, payload);
  }
}

export default function*() {
  yield all([addDeviceWatcher()]);
}
