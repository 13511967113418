import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ManageProfile from "./ManageProfile";
// import * as PROFILE_ACION from "./../../Redux/ProfileDetails/actions";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { platformsSelector } from "../../Redux/platforms/selectors";
import * as NOTIFICATION_ACTION from "./../../Redux/PushNotification/actions";
import * as EPG_ACTION from "./../../Redux/EPG/action";
import { getPackageDetailsSelector } from "../../Redux/package&subscriptions/selector";
import { userDetailsSelector } from "../../Redux/authentication/selectors";
import MainHeader from "../MainHeader";
const ProfileContainer = ({
  USER_DETAILS,
  PLATFORM,
  PACKAGE_DETAILS,
  setSearchState,
  setSearchKey,
  setClearSearchState,
  clearSearchState,
  selectedCategory,
  setSelectedCategory,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  setSelectedCategory(null);

  let data;
  if (USER_DETAILS != undefined && USER_DETAILS != null) {
    data = {
      id: USER_DETAILS?.id,
      account_id: PLATFORM.currentPlatformId,
    };
  }

  useEffect(() => {
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
    var arr2 = todayDate.split("/");
    //console.log("dghdsh ......", arr2);
    var arr3 = arr2[2] + "/" + arr2[1];
    //console.log("concatinated array", arr3);
    let programMonth = arr2[1];
    let programmeDate = arr2[2];
    if (parseInt(programMonth) <= 9) {
      programMonth = "0" + programMonth;
    }
    if (parseInt(programmeDate) <= 9) {
      programmeDate = "0" + programmeDate;
    }
    // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

    let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
    let startTimeStamp = new Date(
      programMonth + "/" + programmeDate + "/" + arr2[0]
    ).setHours(0, 0, 0, 0);
    let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
    let data1 = {
      startTimeTimestamp: startTimeStamp,
      stopTimeTimestamp: stopTimeStamp,
      account_id: PLATFORM?.currentPlatformId,
      offset: 0,
      limit: 1000,
      a: false,
      programmeDate: date,
    };

    // dispatch(EPG_ACTION.getEpgDetailsStart(data1));
  }, []);

  let keysArray;
  if (PACKAGE_DETAILS != undefined && PACKAGE_DETAILS != null) {
    keysArray = Object.keys(PACKAGE_DETAILS);
  }
  // useEffect(() => {
  //   dispatch(
  //     NOTIFICATION_ACTION.getPushNotificationStart({
  //       packageId: JSON.stringify(keysArray),
  //     })
  //   );

  //   // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
  // }, []);

  return (
    <div
      style={{ backgroundColor: "black", height: "610px", overflowX: "scroll" }}
    >
      <MainHeader
        setSearchState={setSearchState}
        setSearchKey={setSearchKey}
        setClearSearchState={setClearSearchState}
        clearSearchState={clearSearchState}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />{" "}
      <span>
        <ManageProfile setSelectedCategory={setSelectedCategory} />
        {/* <div
          style={{ cursor: "pointer", marginRight: "10px" }}
          onClick={handleClick}
        >
          <span
            style={{
              padding: "10px",
              backgroundColor: "red",
              float: "right",
              marginBottom: "10px",
              borderRadius: "10px"
            }}
          >
            Go Back
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </span>
          </span>
        </div> */}
      </span>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,

  USER_DETAILS: userDetailsSelector,
});

export default connect(mapStateToProps)(ProfileContainer);
