import { createSelector } from "reselect";

const getLiveAssetsData = (state) => state.detailsReducer.liveAssetsData;

const getLiveLoading = (state) => state.detailsReducer.liveLoading;
const getMovieLoading = (state) => state.detailsReducer.movieLoading;

export const getLiveAssetsDataSelector = createSelector(
  getLiveAssetsData,
  (liveAssetsData) => liveAssetsData
);

export const getLiveLoadingSelector = createSelector(
  getLiveLoading,
  (liveLoading) => liveLoading
);

export const getMovieLoadingSelector = createSelector(
  getMovieLoading,
  (movieLoading) => movieLoading
);
