import { createSelector } from "reselect";

const getSearchData = (state) => state.searchReducer.searchData;

const getSearchLoading = (state) => state.searchReducer.searchLoading;

const getSearchSeriesData = (state) => state.searchReducer.searchSeriesData;



export const getSearchDataSelector = createSelector(
  getSearchData,
  (searchData) => searchData
);



export const getSearchLoadingSelector = createSelector(
  getSearchLoading,
  (searchLoading) => searchLoading
);

export const getSearchSeriesDataSelector = createSelector(
  getSearchSeriesData,
  (searchSeriesData) => searchSeriesData
);

