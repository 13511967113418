import types from "./actiontypes";

export const update_email_info_start = (payload) => {
  //console.log("email info start");
  return {
    type: types.UPDATE_EMAIL_INFO_START,
    payload,
  };
};

export const update_email_info_success = (payload) => {
  //console.log("email info success");
  return {
    type: types.UPDATE_EMAIL_INFO_SUCCESS,
    payload,
  };
};

export const update_email_info_failure = (payload) => {
  //console.log("email info failure");
  return {
    type: types.UPDATE_EMAIL_INFO_FAILURE,
    payload,
  };
};
