/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";

var qs = require("qs");

export const loginAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();
  return await axiosInstance()
    .post("/loginV3", payload, {
      headers: ConstantHeaders,
    })
    .then((response) => {
      return response;
    })

    .catch((error) => {
      //alert(JSON.stringify(error))
      if (error.message == "Network Error") {
        return Promise.reject(error);
      } else {
        return Promise.reject(error?.response?.data);
      }
    });
};

export const signupAsync = async (payload) => {
  const data = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    emailId: payload.emailId,
    mobile: payload.mobile,
    password: payload.confirmPassword,
  };

  try {
    const response = await axiosInstance().post("/user/register", data);
    return response;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};
export const logoutAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/logoutV1", payload);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userDetailsAsync = async (payload) => {
  console.log("payload" + JSON.stringify(payload));
  try {
    var ciphertext = encryptData(Number(payload?.id));
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");
    const userInfoResponse = await axiosInstance().post(
      "/user/userInfoV1/" + urlSafeCiphertext
    );

    console.log("ressss");
    return userInfoResponse?.data;
  } catch (error) {
    console.log("erre" + JSON.stringify(error));
    return Promise.reject(error);
  }
};
