/** @format */

import axiosInstance from "../../utils/axiosInstance";
import { HeadersAcessToken } from "./../../components/HeadersAcessToken.js";

var qs = require("qs");
export const activeAccessTokenAsync = async (payload) => {
  // alert(JSON.stringify(payload));
  try {
    const response = await axiosInstance().post(
      "/active-access-token",
      payload,
      {
        headers: HeadersAcessToken(),
      }
    );
    return response;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};
