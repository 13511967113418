export const checkSubscription = ({
  ASSET_DATA,
  PACKAGE_DETAILS,
  categoryId,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
}) => {
  console.log("!!!!  ASSET_DATA  " + JSON.stringify(ASSET_DATA));
  console.log("!!!!  PACKAGE_DETAILS  " + JSON.stringify(PACKAGE_DETAILS));
  console.log("!!!!  categoryId  " + JSON.stringify(categoryId));
  console.log("!!!!  ALACARTE_DETAILS  " + JSON.stringify(ALACARTE_DETAILS));
  console.log(
    "!!!!  PAY_PER_VIEW_DETAILS  " + JSON.stringify(PAY_PER_VIEW_DETAILS)
  );

  let date = new Date();
  let timestamp = date.getTime();
  let flag = 0;

  if (categoryId == 3) {
    // ************PACKAGE_DETAILS***********************
    for (let p = 0; p < ASSET_DATA?.seriespackages?.length; p++) {
      let packageId = ASSET_DATA?.seriespackages[p]?.assetGroupId;
      let stopDate = PACKAGE_DETAILS?.[packageId]?.stopDate;
      console.log(
        "!!!!!!!!!!!!!!!!!!!!!!!!!!!" + packageId + "    " + stopDate
      );
      if (stopDate) {
        let stopDateTimeStamp = Date.parse(stopDate);
        console.log(
          "!!!!!!!!!!!!!!    " +
            packageId +
            "     " +
            PACKAGE_DETAILS?.[packageId]?.packageId +
            "      " +
            timestamp +
            "    " +
            stopDateTimeStamp
        );
        if (
          packageId == PACKAGE_DETAILS?.[packageId]?.packageId &&
          timestamp < stopDateTimeStamp
        ) {
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!000000000        " + flag);

          flag = 1;
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!" + flag);
          break;
        }
      }
    }

    // ************ALACARTE_DETAILS***********************
    let stopDateAlacarte = ALACARTE_DETAILS?.[ASSET_DATA?.id]?.expiryDate;
    if (stopDateAlacarte) {
      let stopDateTimeStampAlacarte = Date.parse(stopDateAlacarte);
      if (
        ASSET_DATA?.id === ALACARTE_DETAILS?.[ASSET_DATA?.id]?.assetId &&
        ALACARTE_DETAILS?.[ASSET_DATA?.id]?.categoryId === 3 &&
        timestamp < stopDateTimeStampAlacarte
      ) {
        flag = 1;
      }
    }

    // *****************PAY_PER_VIEW_DETAILS*************
    let stopDatePPV = PAY_PER_VIEW_DETAILS?.[3]?.[ASSET_DATA?.id]?.expiryDate;
    if (stopDatePPV) {
      let stopDateTimeStampPPV = Date.parse(stopDatePPV);
      if (
        ASSET_DATA?.id ===
          PAY_PER_VIEW_DETAILS?.[3]?.[ASSET_DATA?.id]?.assetId &&
        timestamp < stopDateTimeStampPPV
      ) {
        flag = 1;
      }
    }
  }
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!! @@@@@@@@@@@@@@@@@@@@@@" + flag);

  // alert(flag)
  return flag;
};
