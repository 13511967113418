import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ProgressBar from "@ramonak/react-progress-bar";

const StyledDiv = styled("div")`
  background-color: #bcbcbc;

  align-item: center;
`;

const StyledBar = styled("div")`
  background-color: red;
  height: 4px;
  width: ${({ completed }) => completed}%;
`;

const TimeProgressBarr = props => {
  var today1 = new Date();
  var time =
    today1.getHours() + ":" + today1.getMinutes() + ":" + today1.getSeconds();
  //alert(time)
  let start_time = (parseFloat(time) / 24) * 100;
  //alert (start_time);

  return (
    <StyledDiv
      style={{
        borderBottomLeftRadius: "50px",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px"
      }}
    >
      {/* <StyledBar completed={start_time}  /> */}
      <ProgressBar
        completed={start_time}
        bgColor="white"
        height="2px"
        isLabelVisible={false}
        animateOnRender={true}
        borderRadius="50px"
        baseBgColor="black"
        width="100%"
      />
    </StyledDiv>
  );
};

TimeProgressBarr.propTypes = {
  completed: PropTypes.number.isRequired
};

export default TimeProgressBarr;
