/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_ALL_LIVE_ASSETS_START,
  GET_ALL_LIVE_ASSETS_SUCCESS,
  GET_ALL_LIVE_ASSETS_FAILED,

  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START,
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS,
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED,

  GET_GENERAL_DETAILS_START,
  GET_GENERAL_DETAILS_SUCCESS,
  GET_GENERAL_DETAILS_FAILED,

  GET_ALL_LIVE_ASSETS_FOR_LISTING,
  GET_LIVE_ASSETS_OFFSET_WISE,
} = ACTION_TYPES;

/****GENRES *****/
export const getAllLiveAssetsStart = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_START,
  payload,
});

export const getAllLiveAssetsSucces = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_SUCCESS,
  payload,
});

export const getAllLiveAssetsFailed = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_FAILED,
  payload,
});

export const getAllLiveAsetsBasedOnKeyStart = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START,
  payload,
});

export const getAllLiveAsetsBasedOnKeySucces = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS,
  payload,
});

export const getAllLiveAsetsBasedOnKeyFailed = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED,
  payload,
});

export const getAllLiveAssetsForListing = (payload) => ({
  type: GET_ALL_LIVE_ASSETS_FOR_LISTING,
  payload,
});

export const getLiveAssetsOffsetWise = (payload) => ({
  type: GET_LIVE_ASSETS_OFFSET_WISE,
  payload,
});

export const getGeneralDetailsStart = (payload) => ({
  type: GET_GENERAL_DETAILS_START,
  payload,
});

export const getGeneralDetailsSucces = (payload) => ({
  type: GET_GENERAL_DETAILS_SUCCESS,
  payload,
});

export const getGeneralDetailsFailed = (payload) => ({
  type: GET_GENERAL_DETAILS_FAILED,
  payload,
});
