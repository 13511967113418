/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { createStructuredSelector } from "reselect";
import {
  epgDateSelector,
  epgSelector,
  getEpgLoadingSelector,
} from "../../Redux/EPG/seleector";
import { platformsSelector } from "../../Redux/platforms/selectors";
import * as ACTION_TYPE from "./../../Redux/EPG/action";
import "./epgTab.css";
import * as ACTION_TYPE_EPG from "./../../Redux/EPG/action";

import TimeProgressBarr from "./TimeProgressBar";
const EpgTabScroll = ({
  PLATFORM_DATA,
  EPG_CATCHE,
  EPG_LOADING,
  onScrollToNow,
  EPG_DATE,
}) => {
  const [prevIndex, setPrevIndex] = useState(101);
  const [nextIndex, setNextIndex] = useState(99);

  // const [prevDoubleIndex, setPrevDoubleIndex] = useState(103);
  // const [nextDoubleIndex, setNextDoubleIndex] = useState(101);
  const [currentDateArray, setCurrentDateArray] = useState([]);
  const [classState, setClassState] = useState(101);
  const [currentNextId, setCurrentNextId] = useState(102);
  const [currentPrevId, setCurrentPrevId] = useState(100);
  let dateArray = [];
  let dates;
  let today = new Date();
  let days = 86400000;
  let index = 0;
  for (let i = 100; i > 0; i--) {
    index++;
    let fiveDaysAgo = new Date(today - i * days).toLocaleDateString(
      "zh-Hans-CN"
    );

    let fiveDaysAgoTitle = fiveDaysAgo?.split("/");
    let monthTitle;
    if (fiveDaysAgoTitle[1] == 1) {
      monthTitle = "Jan";
    } else if (fiveDaysAgoTitle[1] == 2) {
      monthTitle = "Feb";
    } else if (fiveDaysAgoTitle[1] == 3) {
      monthTitle = "Mar";
    } else if (fiveDaysAgoTitle[1] == 4) {
      monthTitle = "Apr";
    } else if (fiveDaysAgoTitle[1] == 5) {
      monthTitle = "May";
    } else if (fiveDaysAgoTitle[1] == 6) {
      monthTitle = "Jun";
    } else if (fiveDaysAgoTitle[1] == 7) {
      monthTitle = "Jul";
    } else if (fiveDaysAgoTitle[1] == 8) {
      monthTitle = "Aug";
    } else if (fiveDaysAgoTitle[1] == 9) {
      monthTitle = "Sep";
    } else if (fiveDaysAgoTitle[1] == 10) {
      monthTitle = "Oct";
    } else if (fiveDaysAgoTitle[1] == 11) {
      monthTitle = "Nov";
    } else if (fiveDaysAgoTitle[1] == 12) {
      monthTitle = "Dec";
    }

    let fiveDaysAgoTitle1 = fiveDaysAgoTitle[2] + " " + monthTitle;
    //console?.log("five days ago title", fiveDaysAgoTitle1);

    dates = fiveDaysAgo;
    dateArray?.push({ date: fiveDaysAgo, id: index, title: fiveDaysAgoTitle1 });
  }

  let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

  var arr2 = todayDate.split("/");
  //console.log("dghdsh ......", arr2);
  let montharrTitle;
  if (arr2[1] == 1) {
    montharrTitle = "Jan";
  } else if (arr2[1] == 2) {
    montharrTitle = "Feb";
  } else if (arr2[1] == 3) {
    montharrTitle = "Mar";
  } else if (arr2[1] == 4) {
    montharrTitle = "Apr";
  } else if (arr2[1] == 5) {
    montharrTitle = "May";
  } else if (arr2[1] == 6) {
    montharrTitle = "Jun";
  } else if (arr2[1] == 7) {
    montharrTitle = "Jul";
  } else if (arr2[1] == 8) {
    montharrTitle = "Aug";
  } else if (arr2[1] == 9) {
    montharrTitle = "Sep";
  } else if (arr2[1] == 10) {
    montharrTitle = "Oct";
  } else if (arr2[1] == 11) {
    montharrTitle = "Nov";
  } else if (arr2[1] == 12) {
    montharrTitle = "Dec";
  }

  var arr3 = arr2[2] + " " + montharrTitle;
  //console.log("concatinated array", arr3);

  index++;
  dateArray.push({ date: todayDate, id: index, title: arr3 });

  var tomorrow = new Date();
  for (let j = 1; j < 101; j++) {
    index++;
    var fiveDaysInTheFuture = new Date(
      new Date().setDate(tomorrow.getDate() + j)
    ).toLocaleDateString("zh-Hans-CN");

    let fiveDaysInTheFutureTitle = fiveDaysInTheFuture?.split("/");

    let monthNextTitle;
    if (fiveDaysInTheFutureTitle[1] == 1) {
      monthNextTitle = "Jan";
    } else if (fiveDaysInTheFutureTitle[1] == 2) {
      monthNextTitle = "Feb";
    } else if (fiveDaysInTheFutureTitle[1] == 3) {
      monthNextTitle = "Mar";
    } else if (fiveDaysInTheFutureTitle[1] == 4) {
      monthNextTitle = "Apr";
    } else if (fiveDaysInTheFutureTitle[1] == 5) {
      monthNextTitle = "May";
    } else if (fiveDaysInTheFutureTitle[1] == 6) {
      monthNextTitle = "Jun";
    } else if (fiveDaysInTheFutureTitle[1] == 7) {
      monthNextTitle = "Jul";
    } else if (fiveDaysInTheFutureTitle[1] == 8) {
      monthNextTitle = "Aug";
    } else if (fiveDaysInTheFutureTitle[1] == 9) {
      monthNextTitle = "Sep";
    } else if (fiveDaysInTheFutureTitle[1] == 10) {
      monthNextTitle = "Oct";
    } else if (fiveDaysInTheFutureTitle[1] == 11) {
      monthNextTitle = "Nov";
    } else if (fiveDaysInTheFutureTitle[1] == 12) {
      monthNextTitle = "Dec";
    }

    let fiveDaysInTheFutureTitle1 =
      fiveDaysInTheFutureTitle[2] + " " + monthNextTitle;

    dateArray.push({
      date: fiveDaysInTheFuture,
      id: index,
      title: fiveDaysInTheFutureTitle1,
    });
  }
  //console.log("date poooja", JSON.stringify(dateArray));
  useEffect(() => {
    let threeDateArray = [];
    threeDateArray.push(
      dateArray[nextIndex],
      dateArray[nextIndex + 1],
      dateArray[nextIndex + 2]
    );
    setCurrentDateArray(threeDateArray);
  }, []);

  useEffect(() => {
    let dateArray1 = [];
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

    var arr2 = todayDate.split("/");
    //console.log("dghdsh ......", arr2);
    var arr3 = arr2[2] + "/" + arr2[1];
    //console.log("concatinated array", arr3);
    index++;
    dateArray1.push({ date: todayDate, id: index, title: arr3 });
    if (
      dateArray1 !== undefined &&
      dateArray1 !== null &&
      dateArray1.date !== undefined
    ) {
      dispatch(ACTION_TYPE.getEpgStateSuccess(dateArray1.date));
    }
  }, []);

  const [id, setId] = useState(1);
  const dispatch = useDispatch();

  var dataObj = currentDateArray[id];
  let i = 1;
  let classNextIndex = 0;
  const handleLeft = () => {
    if (EPG_LOADING == false && prevIndex > dateArray?.[93]?.id) {
      // if (nextIndex >= 0) {
      let prevArrayValues = [];

      prevArrayValues = [
        dateArray[nextIndex - 1],
        dateArray[nextIndex],
        dateArray[nextIndex + 1],
      ];

      setCurrentDateArray(prevArrayValues);

      setNextIndex(nextIndex - 1);
      setPrevIndex(nextIndex + 1);
      setClassState(classState - 1);
      setCurrentPrevId(currentPrevId - 1);
      setCurrentNextId(currentNextId - 1);
      dataObj = prevArrayValues[id];
      if (dataObj != undefined && dataObj != null) {
        var arr2 = dataObj.date.split("/");
        //console.log("dghdsh ......", arr2);
        var arr3 = arr2[2] + "/" + arr2[1];
        //console.log("concatinated array", arr3);
        let programMonth = arr2[1];
        let programmeDate = arr2[2];
        if (parseInt(programMonth) <= 9) {
          programMonth = "0" + programMonth;
        }
        if (parseInt(programmeDate) <= 9) {
          programmeDate = "0" + programmeDate;
        }
        // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

        let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
        let startTimeStamp = new Date(
          programMonth + "/" + programmeDate + "/" + arr2[0]
        ).setHours(0, 0, 0, 0);
        let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
        let data = {
          startTimeTimestamp: startTimeStamp,
          stopTimeTimestamp: stopTimeStamp,
          account_id: PLATFORM_DATA?.currentPlatformId,
          offset: 0,
          limit: 1000,
          a: false,
          programmeDate: date,
        };
        // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
        //   // alert(JSON.stringify(item));

        //   if (Object.keys(item).includes(date)) {
        //     return item;
        //   }
        // });
        // alert(JSON.stringify(oldData));
        // if (oldData.length > 0) {
        //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
        //   //alert(JSON.stringify(oldData[0][date]));
        // } else {
        dispatch(ACTION_TYPE.getEpgDetailsStart(data));
        // }

        dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      }

      //   } else if (nextIndex >= 1 && prevIndex > 3) {
      //     prevArrayValues = [
      //       dateArray[nextIndex - 1],
      //       dateArray[nextIndex],
      //       dateArray[nextIndex + 1]
      //     ];
      //     // alert(JSON.stringify(prevArrayValues))
      //     setCurrentDateArray(prevArrayValues);

      //     setNextIndex(nextIndex - 1);
      //     setPrevIndex(nextIndex + 1);
      //     setClassState(classState - 1);
      //     setCurrentPrevId(currentPrevId - 1)

      //     dataObj = prevArrayValues[id];
      //     if (dataObj != undefined && dataObj != null) {
      //       dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      //     }
      //   } else {
      //     //  alert("hello")
      //     setNextIndex(nextIndex - 1);
      //     setPrevIndex(nextIndex + 1);
      //     setCurrentPrevId(currentPrevId - 1)

      //     dataObj = dateArray[0];

      //     if (dataObj != undefined && dataObj != null) {
      //       dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      //     }
      //   }

      //   if (classNextIndex == 0) {
      //     setClassState(classState - 1);
      //     classNextIndex--;
      //   }
      // }
    }
  };
  const handleRight = () => {
    if (EPG_LOADING == false && prevIndex < dateArray?.[107]?.id) {
      let nextArrayValues = [];

      // if (prevIndex >= 101 && nextIndex >= 99) {
      nextArrayValues = [
        dateArray[prevIndex - 1],
        dateArray[prevIndex],
        dateArray[prevIndex + 1],
      ];
      setCurrentDateArray(nextArrayValues);

      setNextIndex(prevIndex - 1);
      setPrevIndex(prevIndex + 1);
      setClassState(classState + 1);
      setCurrentNextId(currentNextId + 1);
      setCurrentPrevId(currentPrevId + 1);
      i++;
      dataObj = nextArrayValues[1];
      if (dataObj != undefined && dataObj != null) {
        var arr2 = dataObj.date.split("/");
        //console.log("dghdsh ......", arr2);
        var arr3 = arr2[2] + "/" + arr2[1];
        //console.log("concatinated array", arr3);
        let programMonth = arr2[1];
        let programmeDate = arr2[2];
        if (parseInt(programMonth) <= 9) {
          programMonth = "0" + programMonth;
        }
        if (parseInt(programmeDate) <= 9) {
          programmeDate = "0" + programmeDate;
        }
        // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

        let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
        let startTimeStamp = new Date(
          programMonth + "/" + programmeDate + "/" + arr2[0]
        ).setHours(0, 0, 0, 0);
        let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
        let data = {
          startTimeTimestamp: startTimeStamp,
          stopTimeTimestamp: stopTimeStamp,
          account_id: PLATFORM_DATA?.currentPlatformId,
          offset: 0,
          limit: 1000,
          a: false,
          programmeDate: date,
        };
        // let oldData = EPG_CATCHE?.epgCatchup?.filter((item) => {
        //   // alert(JSON?.stringify(item));

        //   if (Object.keys(item).includes(date)) {
        //     return item;
        //   }
        // });
        // alert(JSON.stringify(oldData));
        // if (oldData?.length > 0) {
        //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
        //   //alert(JSON.stringify(oldData[0][date]));
        // } else {
        dispatch(ACTION_TYPE.getEpgDetailsStart(data));
        // }

        dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj?.date));
      }

      // else{
      //   alert("ppppppppppp")
      // }
    }
  };

  const handleLeftDouble = () => {
    if (EPG_LOADING == false && nextIndex > dateArray?.[97]?.id) {
      let prevArrayValues = [];

      if (nextIndex <= 99) {
        // alert(nextIndex)
        // alert(prevIndex)
        // if (nextIndex < 1 && nextIndex < 12) {
        // alert("hii")
        prevArrayValues = [
          dateArray[nextIndex - 7],
          dateArray[nextIndex - 6],
          dateArray[nextIndex - 5],
        ];
        // alert(JSON.stringify(prevArrayValues))
        setCurrentDateArray(prevArrayValues);

        setNextIndex(nextIndex - 7);
        setPrevIndex(nextIndex - 5);
        setClassState(classState - 7);
        setCurrentPrevId(currentPrevId - 7);
        setCurrentNextId(currentNextId - 7);
        dataObj = prevArrayValues[id];
        if (dataObj != undefined && dataObj != null) {
          var arr2 = dataObj.date.split("/");
          //console.log("dghdsh ......", arr2);
          var arr3 = arr2[2] + "/" + arr2[1];
          //console.log("concatinated array", arr3);
          let programMonth = arr2[1];
          let programmeDate = arr2[2];
          if (parseInt(programMonth) <= 9) {
            programMonth = "0" + programMonth;
          }
          if (parseInt(programmeDate) <= 9) {
            programmeDate = "0" + programmeDate;
          }
          // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

          let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
          let startTimeStamp = new Date(
            programMonth + "/" + programmeDate + "/" + arr2[0]
          ).setHours(0, 0, 0, 0);
          let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
          let data = {
            startTimeTimestamp: startTimeStamp,
            stopTimeTimestamp: stopTimeStamp,
            account_id: PLATFORM_DATA?.currentPlatformId,
            offset: 0,
            limit: 1000,
            a: false,
            programmeDate: date,
          };
          // let oldData = EPG_CATCHE?.epgCatchup?.filter((item) => {
          //   // alert(JSON.stringify(item));

          //   if (Object.keys(item).includes(date)) {
          //     return item;
          //   }
          // });
          // alert(JSON.stringify(oldData));
          // if (oldData.length > 0) {
          //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
          //   //alert(JSON.stringify(oldData[0][date]));
          // } else {
          dispatch(ACTION_TYPE.getEpgDetailsStart(data));
          // }

          dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj?.date));
        }
      } else {
        // alert(nextIndex)
        // alert(prevIndex)
        // if (nextIndex < 1 && nextIndex < 12) {
        // alert("hii")
        prevArrayValues = [
          dateArray[nextIndex - 7],
          dateArray[nextIndex - 6],
          dateArray[nextIndex - 5],
        ];
        // alert(JSON.stringify(prevArrayValues))
        setCurrentDateArray(prevArrayValues);

        setNextIndex(nextIndex - 7);
        setPrevIndex(nextIndex - 5);
        setClassState(classState - 7);
        setCurrentPrevId(currentPrevId - 7);
        setCurrentNextId(currentNextId - 7);
        dataObj = prevArrayValues[id];
        if (dataObj != undefined && dataObj != null) {
          var arr2 = dataObj.date.split("/");
          //console.log("dghdsh ......", arr2);
          var arr3 = arr2[2] + "/" + arr2[1];
          //console.log("concatinated array", arr3);
          let programMonth = arr2[1];
          let programmeDate = arr2[2];
          if (parseInt(programMonth) <= 9) {
            programMonth = "0" + programMonth;
          }
          if (parseInt(programmeDate) <= 9) {
            programmeDate = "0" + programmeDate;
          }
          // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

          let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
          let startTimeStamp = new Date(
            programMonth + "/" + programmeDate + "/" + arr2[0]
          ).setHours(0, 0, 0, 0);
          let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
          let data = {
            startTimeTimestamp: startTimeStamp,
            stopTimeTimestamp: stopTimeStamp,
            account_id: PLATFORM_DATA?.currentPlatformId,
            offset: 0,
            limit: 1000,
            a: false,
            programmeDate: date,
          };
          // let oldData = EPG_CATCHE?.epgCatchup?.filter((item) => {
          //   // alert(JSON.stringify(item));

          //   if (Object.keys(item).includes(date)) {
          //     return item;
          //   }
          // });
          // alert(JSON.stringify(oldData));
          // if (oldData.length > 0) {
          //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
          //   //alert(JSON.stringify(oldData[0][date]));
          // } else {
          dispatch(ACTION_TYPE.getEpgDetailsStart(data));
          // }

          dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj?.date));
        }
      }
    }
  };
  // alert(nextIndex+"dvh"+prevIndex)

  const handleRightDouble = () => {
    // if (prevIndex >= dateArray[108]?.id) {
    //   setHandleRightDisable(true);

    if (EPG_LOADING == false && prevIndex < dateArray[101]?.id) {
      let nextArrayValues = [];
      // alert("fffffffffffffnext"+nextIndex+"prev"+prevIndex)

      if (nextIndex >= 99 && prevIndex >= 101) {
        nextArrayValues = [
          dateArray[prevIndex + 5],
          dateArray[prevIndex + 6],
          dateArray[prevIndex + 7],
        ];
        setCurrentDateArray(nextArrayValues);

        setNextIndex(prevIndex + 5);
        setPrevIndex(prevIndex + 7);
        setClassState(classState + 7);
        setCurrentNextId(currentNextId + 7);
        setCurrentPrevId(currentPrevId + 7);

        i++;
        dataObj = nextArrayValues[1];
        if (dataObj != undefined && dataObj != null) {
          var arr2 = dataObj.date.split("/");
          //console.log("dghdsh ......", arr2);
          var arr3 = arr2[2] + "/" + arr2[1];
          //console.log("concatinated array", arr3);
          let programMonth = arr2[1];
          let programmeDate = arr2[2];
          if (parseInt(programMonth) <= 9) {
            programMonth = "0" + programMonth;
          }
          if (parseInt(programmeDate) <= 9) {
            programmeDate = "0" + programmeDate;
          }
          // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

          let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
          let startTimeStamp = new Date(
            programMonth + "/" + programmeDate + "/" + arr2[0]
          ).setHours(0, 0, 0, 0);
          let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
          let data = {
            startTimeTimestamp: startTimeStamp,
            stopTimeTimestamp: stopTimeStamp,
            account_id: PLATFORM_DATA?.currentPlatformId,
            offset: 0,
            limit: 1000,
            a: false,
            programmeDate: date,
          };
          // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
          //   // alert(JSON.stringify(item));

          //   if (Object.keys(item).includes(date)) {
          //     return item;
          //   }
          // });
          // alert(JSON.stringify(oldData));
          // if (oldData.length > 0) {
          //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
          //   //alert(JSON.stringify(oldData[0][date]));
          // } else {
          dispatch(ACTION_TYPE.getEpgDetailsStart(data));
          // }

          dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj?.date));
        }
      } else {
        // alert("dhbhj")
        // alert(nextIndex+"jbdcjbj"+prevIndex)
        nextArrayValues = [
          dateArray[prevIndex + 5],
          dateArray[prevIndex + 6],
          dateArray[prevIndex + 7],
        ];
        setCurrentDateArray(nextArrayValues);

        setNextIndex(prevIndex + 5);
        setPrevIndex(prevIndex + 7);
        setClassState(classState + 7);
        setCurrentNextId(currentNextId + 7);
        setCurrentPrevId(currentPrevId + 7);
        i++;
        dataObj = nextArrayValues[1];
        if (dataObj != undefined && dataObj != null) {
          var arr2 = dataObj.date.split("/");
          //console.log("dghdsh ......", arr2);
          var arr3 = arr2[2] + "/" + arr2[1];
          //console.log("concatinated array", arr3);
          let programMonth = arr2[1];
          let programmeDate = arr2[2];
          if (parseInt(programMonth) <= 9) {
            programMonth = "0" + programMonth;
          }
          if (parseInt(programmeDate) <= 9) {
            programmeDate = "0" + programmeDate;
          }
          // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

          let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
          let startTimeStamp = new Date(
            programMonth + "/" + programmeDate + "/" + arr2[0]
          ).setHours(0, 0, 0, 0);
          let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
          let data = {
            startTimeTimestamp: startTimeStamp,
            stopTimeTimestamp: stopTimeStamp,
            account_id: PLATFORM_DATA?.currentPlatformId,
            offset: 0,
            limit: 1000,
            a: false,
            programmeDate: date,
          };
          // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
          //   // alert(JSON.stringify(item));

          //   if (Object.keys(item).includes(date)) {
          //     return item;
          //   }
          // });
          // alert(JSON.stringify(oldData));
          // if (oldData.length > 0) {
          //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
          //   //alert(JSON.stringify(oldData[0][date]));
          // } else {
          dispatch(ACTION_TYPE.getEpgDetailsStart(data));
          // }

          dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj?.date));
        }
      }
    }
  };
  // alert("fffffffffffffnext"+nextIndex+"prev"+prevIndex)

  //   } else {
  //     dataObj = dateArray[14];
  //     setNextIndex(prevIndex - 1);
  //     setPrevIndex(prevIndex + 1);
  //     setCurrentNextId(currentNextId + 1)
  //     if (dataObj != undefined && dataObj != null) {
  //       dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
  //     }
  //   }

  //   setClassState(classState + 1);
  // };

  const handleClick = (item) => {
    if (item.date == todayDate1) {
      // alert("today");
      dispatch(ACTION_TYPE.getEpgStateSuccess(todayDate1));

      var arr2 = todayDate1.split("/");
      //console.log("dghdsh ......", arr2);
      var arr3 = arr2[2] + "/" + arr2[1];
      //console.log("concatinated array", arr3);
      let programMonth = arr2[1];
      let programmeDate = arr2[2];
      if (parseInt(programMonth) <= 9) {
        programMonth = "0" + programMonth;
      }
      if (parseInt(programmeDate) <= 9) {
        programmeDate = "0" + programmeDate;
      }
      // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

      let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
      let startTimeStamp = new Date(
        programMonth + "/" + programmeDate + "/" + arr2[0]
      ).setHours(0, 0, 0, 0);
      let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
      let data = {
        startTimeTimestamp: startTimeStamp,
        stopTimeTimestamp: stopTimeStamp,
        account_id: PLATFORM_DATA?.currentPlatformId,
        offset: 0,
        limit: 1000,
        a: false,
        programmeDate: date,
      };
      // alert(date);
      // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
      //   // alert(JSON.stringify(item));

      //   if (Object.keys(item).includes(date)) {
      //     return item;
      //   }
      // });
      ////console.log(
      //   "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
      //   JSON.stringify(oldData)
      // );
      // if (oldData.length > 0) {
      //   var oldDataLen = oldData.length - 1;

      //   // alert(JSON.stringify(oldData[oldDataLen][date]));

      //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[oldDataLen][date]));

      //   //alert(JSON.stringify(oldData[0][date]));
      // } else {
      dispatch(ACTION_TYPE.getEpgDetailsStart(data));
      // }

      // alert("hiiiiiiii" + JSON.stringify(data));
    }
    if (item.id == currentNextId) {
      //right
      setCurrentNextId(currentNextId + 1);
      setCurrentPrevId(currentNextId - 1);
      let nextArrayValues = [];
      // if (nextIndex < 13 && prevIndex < 14) {
      nextArrayValues = [
        dateArray[prevIndex - 1],
        dateArray[prevIndex],
        dateArray[prevIndex + 1],
      ];
      setCurrentDateArray(nextArrayValues);
      setNextIndex(prevIndex - 1);
      setPrevIndex(prevIndex + 1);
      setClassState(classState + 1);
      i++;
      dataObj = nextArrayValues[1];
      if (dataObj != undefined && dataObj != null) {
        var arr2 = dataObj.date.split("/");
        //console.log("dghdsh ......", arr2);
        var arr3 = arr2[2] + "/" + arr2[1];
        //console.log("concatinated array", arr3);
        let programMonth = arr2[1];
        let programmeDate = arr2[2];
        if (parseInt(programMonth) <= 9) {
          programMonth = "0" + programMonth;
        }
        if (parseInt(programmeDate) <= 9) {
          programmeDate = "0" + programmeDate;
        }
        // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

        let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
        let startTimeStamp = new Date(
          programMonth + "/" + programmeDate + "/" + arr2[0]
        ).setHours(0, 0, 0, 0);
        let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
        let data = {
          startTimeTimestamp: startTimeStamp,
          stopTimeTimestamp: stopTimeStamp,
          account_id: PLATFORM_DATA?.currentPlatformId,
          offset: 0,
          limit: 1000,
          a: false,
          programmeDate: date,
        };
        // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
        //   // alert(JSON.stringify(item));

        //   if (Object.keys(item).includes(date)) {
        //     return item;
        //   }
        // });
        // alert(JSON.stringify(oldData));
        // if (oldData.length > 0) {
        //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
        //   //alert(JSON.stringify(oldData[0][date]));
        // } else {
        dispatch(ACTION_TYPE.getEpgDetailsStart(data));
        // }

        dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      }
      // } else if (prevIndex >= 14 && prevIndex > 12) {
      //   nextArrayValues = [
      //     dateArray[prevIndex - 2],
      //     dateArray[prevIndex - 1],
      //     dateArray[prevIndex]
      //   ];
      //   // alert(JSON.stringify(prevArrayValues))
      //   setCurrentDateArray(nextArrayValues);

      //   setNextIndex(prevIndex - 1);
      //   setPrevIndex(prevIndex + 1);
      //   setClassState(classState + 1);

      //   dataObj = nextArrayValues[id];
      //   if (dataObj != undefined && dataObj != null) {
      //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      //   }
      // } else {
      //   // alert("hello")
      //   setNextIndex(prevIndex - 1);
      //   setPrevIndex(prevIndex + 1);
      //   dataObj = dateArray[0];

      //   if (dataObj != undefined && dataObj != null) {
      //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      //   }
      // }

      // if (classNextIndex == 0) {
      //   setClassState(classState - 1);
      //   classNextIndex--;
      // } else {
      //   dataObj = dateArray[14];

      //   if (dataObj != undefined && dataObj != null) {
      //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      //   }
      // }

      // setCurrentPrevId(currentPrevId + 1);
      // setClassState(classState + 1);
    } else if (item.id == currentPrevId) {
      //left

      let prevArrayValues = [];
      // if (nextIndex > 0 && prevIndex < 200) {
      prevArrayValues = [
        dateArray[nextIndex - 1],
        dateArray[nextIndex],
        dateArray[nextIndex + 1],
      ];
      // alert(JSON.stringify(prevArrayValues))
      setCurrentDateArray(prevArrayValues);

      setNextIndex(nextIndex - 1);
      setPrevIndex(nextIndex + 1);
      setClassState(classState - 1);
      i++;
      dataObj = prevArrayValues[1];
      if (dataObj != undefined && dataObj != null) {
        var arr2 = dataObj.date.split("/");
        //console.log("dghdsh ......", arr2);
        var arr3 = arr2[2] + "/" + arr2[1];
        //console.log("concatinated array", arr3);
        let programMonth = arr2[1];
        let programmeDate = arr2[2];
        if (parseInt(programMonth) <= 9) {
          programMonth = "0" + programMonth;
        }
        if (parseInt(programmeDate) <= 9) {
          programmeDate = "0" + programmeDate;
        }
        // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

        let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
        let startTimeStamp = new Date(
          programMonth + "/" + programmeDate + "/" + arr2[0]
        ).setHours(0, 0, 0, 0);
        let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
        let data = {
          startTimeTimestamp: startTimeStamp,
          stopTimeTimestamp: stopTimeStamp,
          account_id: PLATFORM_DATA?.currentPlatformId,
          offset: 0,
          limit: 1000,
          a: false,
          programmeDate: date,
        };
        // let oldData = EPG_CATCHE.epgCatchup.filter((item) => {
        //   // alert(JSON.stringify(item));

        //   if (Object.keys(item).includes(date)) {
        //     return item;
        //   }
        // });
        // alert(JSON.stringify(oldData));
        // if (oldData.length > 0) {
        //   dispatch(ACTION_TYPE.getEpgUppendSuccess(oldData[0][date]));
        //   //alert(JSON.stringify(oldData[0][date]));
        // } else {
        dispatch(ACTION_TYPE.getEpgDetailsStart(data));
        // }

        dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
      }

      setCurrentNextId(currentPrevId + 1);
      setCurrentPrevId(currentPrevId - 1);
    }

    // else if (nextIndex >= 1 && prevIndex > 3) {
    //   prevArrayValues = [
    //     dateArray[nextIndex - 1],
    //     dateArray[nextIndex],
    //     dateArray[nextIndex + 1]
    //   ];
    //   // alert(JSON.stringify(prevArrayValues))
    //   setCurrentDateArray(prevArrayValues);

    //   setNextIndex(nextIndex - 1);
    //   setPrevIndex(nextIndex + 1);
    //   setClassState(classState - 1);

    //   dataObj = prevArrayValues[id];
    //   if (dataObj != undefined && dataObj != null) {
    //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
    //   }
    // } else {
    //   //  alert("hello")
    //   setNextIndex(nextIndex - 1);
    //   setPrevIndex(nextIndex + 1);
    //   dataObj = dateArray[0];

    //   if (dataObj != undefined && dataObj != null) {
    //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
    //   }
    // }

    // if (classNextIndex == 0) {
    //   setClassState(classState - 1);
    //   classNextIndex--;
    // } else {
    //   dataObj = dateArray[0];

    //   if (dataObj != undefined && dataObj != null) {
    //     dispatch(ACTION_TYPE.getEpgStateSuccess(dataObj.date));
    //   }
    // }
    // setCurrentNextId(currentNextId - 1);
    // setCurrentPrevId(currentPrevId - 1);
    // setClassState(classState - 1);
  };

  let todayDate1 = new Date().toLocaleDateString("zh-Hans-CN");
  let tomm = new Date();
  let tommorow1 = new Date(
    new Date().setDate(tomm.getDate() + 1)
  ).toLocaleDateString("zh-Hans-CN");

  let yesterday = new Date(tomm - 1 * days).toLocaleDateString("zh-Hans-CN");
  let completed = 0;

  const dateTabRef = useRef({});
  window.onload = () => {
    dateTabRef?.current[todayDate1]?.click();
  };
  const handleOnNow = (e) => {
    if (EPG_LOADING == false) {
      let dateArray1 = [];
      let todayDate1 = new Date().toLocaleDateString("zh-Hans-CN");

      var arr2 = todayDate1.split("/");
      var arr3 = arr2[2] + "/" + arr2[1];
      // alert(todayDate);
      dateArray1.push({ date: todayDate1 });

      dispatch(ACTION_TYPE_EPG.getEpgStateSuccess(todayDate1));

      let dateArray = [];
      let dates;
      let today = new Date();
      let days = 86400000;
      let index = 0;
      for (let i = 100; i > 0; i--) {
        index++;
        let fiveDaysAgo = new Date(today - i * days).toLocaleDateString(
          "zh-Hans-CN"
        );

        let fiveDaysAgoTitle = fiveDaysAgo.split("/");

        let monthTitle;
        if (fiveDaysAgoTitle[1] == 1) {
          monthTitle = "Jan";
        } else if (fiveDaysAgoTitle[1] == 2) {
          monthTitle = "Feb";
        } else if (fiveDaysAgoTitle[1] == 3) {
          monthTitle = "Mar";
        } else if (fiveDaysAgoTitle[1] == 4) {
          monthTitle = "Apr";
        } else if (fiveDaysAgoTitle[1] == 5) {
          monthTitle = "May";
        } else if (fiveDaysAgoTitle[1] == 6) {
          monthTitle = "Jun";
        } else if (fiveDaysAgoTitle[1] == 7) {
          monthTitle = "Jul";
        } else if (fiveDaysAgoTitle[1] == 8) {
          monthTitle = "Aug";
        } else if (fiveDaysAgoTitle[1] == 9) {
          monthTitle = "Sep";
        } else if (fiveDaysAgoTitle[1] == 10) {
          monthTitle = "Oct";
        } else if (fiveDaysAgoTitle[1] == 11) {
          monthTitle = "Nov";
        } else if (fiveDaysAgoTitle[1] == 12) {
          monthTitle = "Dec";
        }

        let fiveDaysAgoTitle1 = fiveDaysAgoTitle[2] + " " + monthTitle;
        //console.log("five days ago title", fiveDaysAgoTitle1);

        dates = fiveDaysAgo;
        dateArray.push({
          date: fiveDaysAgo,
          id: index,
          title: fiveDaysAgoTitle1,
        });
      }

      let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

      var arr2 = todayDate.split("/");
      //console.log("dghdsh ......", arr2);
      var arr3 = arr2[2] + "/" + arr2[1];
      //console.log("concatinated array", arr3);
      let programMonth = arr2[1];
      let programmeDate = arr2[2];
      if (parseInt(programMonth) <= 9) {
        programMonth = "0" + programMonth;
      }
      if (parseInt(programmeDate) <= 9) {
        programmeDate = "0" + programmeDate;
      }
      // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

      let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
      let startTimeStamp = new Date(
        programMonth + "/" + programmeDate + "/" + arr2[0]
      ).setHours(0, 0, 0, 0);
      let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
      let dataEpg = {
        startTimeTimestamp: startTimeStamp,
        stopTimeTimestamp: stopTimeStamp,
        account_id: PLATFORM_DATA?.currentPlatformId,
        offset: 0,
        limit: 1000,
        a: false,
        programmeDate: date,
      };
      // alert(EPG_DATE + "   " + todayDate);
      if (EPG_DATE != todayDate) {
        dispatch(ACTION_TYPE.getEpgDetailsStart(dataEpg));
      }

      var arr2 = todayDate.split("/");
      //console.log("dghdsh ......", arr2);

      let montharrTitle;
      if (arr2[1] == 1) {
        montharrTitle = "Jan";
      } else if (arr2[1] == 2) {
        montharrTitle = "Feb";
      } else if (arr2[1] == 3) {
        montharrTitle = "Mar";
      } else if (arr2[1] == 4) {
        montharrTitle = "Apr";
      } else if (arr2[1] == 5) {
        montharrTitle = "May";
      } else if (arr2[1] == 6) {
        montharrTitle = "Jun";
      } else if (arr2[1] == 7) {
        montharrTitle = "Jul";
      } else if (arr2[1] == 8) {
        montharrTitle = "Aug";
      } else if (arr2[1] == 9) {
        montharrTitle = "Sep";
      } else if (arr2[1] == 10) {
        montharrTitle = "Oct";
      } else if (arr2[1] == 11) {
        montharrTitle = "Nov";
      } else if (arr2[1] == 12) {
        montharrTitle = "Dec";
      }

      var arr3 = arr2[2] + " " + montharrTitle;
      //console.log("concatinated array", arr3);

      index++;
      dateArray.push({ date: todayDate, id: index, title: arr3 });

      var tomorrow = new Date();
      for (let j = 1; j < 101; j++) {
        index++;
        var fiveDaysInTheFuture = new Date(
          new Date().setDate(tomorrow.getDate() + j)
        ).toLocaleDateString("zh-Hans-CN");

        let fiveDaysInTheFutureTitle = fiveDaysInTheFuture.split("/");

        let monthNextTitle;
        if (fiveDaysInTheFutureTitle[1] == 1) {
          monthNextTitle = "Jan";
        } else if (fiveDaysInTheFutureTitle[1] == 2) {
          monthNextTitle = "Feb";
        } else if (fiveDaysInTheFutureTitle[1] == 3) {
          monthNextTitle = "Mar";
        } else if (fiveDaysInTheFutureTitle[1] == 4) {
          monthNextTitle = "Apr";
        } else if (fiveDaysInTheFutureTitle[1] == 5) {
          monthNextTitle = "May";
        } else if (fiveDaysInTheFutureTitle[1] == 6) {
          monthNextTitle = "Jun";
        } else if (fiveDaysInTheFutureTitle[1] == 7) {
          monthNextTitle = "Jul";
        } else if (fiveDaysInTheFutureTitle[1] == 8) {
          monthNextTitle = "Aug";
        } else if (fiveDaysInTheFutureTitle[1] == 9) {
          monthNextTitle = "Sep";
        } else if (fiveDaysInTheFutureTitle[1] == 10) {
          monthNextTitle = "Oct";
        } else if (fiveDaysInTheFutureTitle[1] == 11) {
          monthNextTitle = "Nov";
        } else if (fiveDaysInTheFutureTitle[1] == 12) {
          monthNextTitle = "Dec";
        }

        let fiveDaysInTheFutureTitle1 =
          fiveDaysInTheFutureTitle[2] + " " + monthNextTitle;

        dateArray.push({
          date: fiveDaysInTheFuture,
          id: index,
          title: fiveDaysInTheFutureTitle1,
        });
      }
      let threeDateArray = [];
      threeDateArray.push(
        dateArray[nextIndex],
        dateArray[nextIndex + 1],
        dateArray[nextIndex + 2]
      );

      setCurrentDateArray(threeDateArray);
      onScrollToNow();
    }
  };

  return (
    <div className="containerr1 col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12">
      <div
        className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-2"
        style={{ color: "red", cursor: "pointer" }}
      >
        <div
          className="btnButton"
          style={{
            borderRadius: "8px",
            textAlign: "center",
            padding: "2%",
            cursor: EPG_LOADING == false ? "pointer" : "progress",
          }}
          onClick={handleOnNow}
        >
          Jump to Now
        </div>
      </div>
      <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          color="white"
          viewBox="0 0 24 24"
          fill="none"
          cursor={
            nextIndex <= dateArray?.[97]?.id
              ? "not-allowed"
              : EPG_LOADING == false
                ? "pointer"
                : "progress"
          }
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevrons-left hideClass"
          style={{ marginRight: "35px", marginBottom: "10px" }}
          onClick={handleLeftDouble}
          data-tip="Prev Week"
        >
          <polyline points="11 17 6 12 11 7"></polyline>
          <polyline points="18 17 13 12 18 7"></polyline>
        </svg>
        <ReactTooltip type="dark" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          color="white"
          cursor={
            prevIndex <= dateArray?.[93]?.id
              ? "not-allowed"
              : EPG_LOADING == false
                ? "pointer"
                : "progress"
          }
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevron-left"
          style={{ marginRight: "35px", marginBottom: "10px" }}
          onClick={handleLeft}
          data-tip="Prev Day"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
        <ReactTooltip type="dark" />
      </div>

      <div
        className="col-6 col-sm-6 col-xs-6 col-md-6 col-lg-6"
        style={
          {
            // alignItems: "center",
            // justifyContent: "center",
            // width: "100%",
            // display: "flex"
          }
        }
      >
        {currentDateArray != undefined &&
          currentDateArray != null &&
          currentDateArray.length > 0
          ? currentDateArray.map((item, index) => (
            <button
              disabled={EPG_LOADING == true ? true : false}
              ref={(ref) => (dateTabRef.current[item.date] = ref)}
              key={index}
              className={
                item != undefined && item != null
                  ? item.id == classState
                    ? "offset-bottom-right-shadow"
                    : "box"
                  : ""
              }
              style={{
                color: "white",
                textAlign: "center",
                padding: "2%",
                fontFamily: "monospace",
                fontWeight: "bold",
                cursor: "pointer",
                fontFamily: "Montserrat,sans-serif",
                border: "1px solid #003166",
              }}
              onClick={() => {
                if (
                  item?.id < dateArray?.[108]?.id &&
                  item?.id > dateArray?.[92]?.id
                ) {
                  handleClick(item);
                }
              }}
            >
              <div
                className="css-1old884-ProgramTitle e1f8qikj3"
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat,sans-serif",
                  cursor:
                    item?.id >= dateArray?.[108]?.id ||
                      item?.id <= dateArray?.[92]?.id
                      ? "not-allowed"
                      : EPG_LOADING == false
                        ? "pointer"
                        : "progress",
                }}
              >
                {item != undefined && item != null && todayDate1 == item.date
                  ? "Today"
                  : item != undefined &&
                    item != null &&
                    tommorow1 == item.date
                    ? "Tomorrow"
                    : item != undefined &&
                      item != null &&
                      yesterday == item.date
                      ? "Yesterday"
                      : item != undefined && item != null
                        ? item.title
                        : ""}
              </div>
              {item.id == dateArray[100].id && item.id == classState ? (
                <div style={{ marginTop: "25px" }}>
                  {" "}
                  <TimeProgressBarr completed={completed} />{" "}
                </div>
              ) : (
                ""
              )}
            </button>
          ))
          : ""}
        {/* <div class="offset-bottom-right-shadow" style={{color:"black",textAlign:"center",padding:"10px"}}>31/05</div>
        <div class="box" style={{color:"black",textAlign:"center",padding:"10px"}}>01/06</div> */}
      </div>
      <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          color="white"
          cursor={
            prevIndex >= dateArray[107]?.id
              ? "not-allowed"
              : EPG_LOADING == false
                ? "pointer"
                : "progress"
          }
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          disabled
          className="feather feather-chevron-right"
          style={{
            // marginLeft: "30px",
            marginBottom: "10px",
            fontFamily: "Montserrat,sans-serif",
          }}
          onClick={handleRight}
          data-tip="Next Day"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
        <ReactTooltip type="dark" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          color="white"
          viewBox="0 0 24 24"
          fill="none"
          cursor={
            prevIndex >= dateArray[101]?.id
              ? "not-allowed"
              : EPG_LOADING == false
                ? "pointer"
                : "progress"
          }
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevrons-right hideClass"
          style={{
            marginLeft: "30px",
            marginBottom: "10px",
            fontFamily: "Montserrat,sans-serif",
          }}
          onClick={handleRightDouble}
          data-tip="Next Week"
        >
          <polyline points="13 17 18 12 13 7"></polyline>
          <polyline points="6 17 11 12 6 7"></polyline>
        </svg>
        <ReactTooltip type="dark" />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM_DATA: platformsSelector,
  EPG_CATCHE: epgSelector,
  EPG_LOADING: getEpgLoadingSelector,
  EPG_DATE: epgDateSelector,
});

export default connect(mapStateToProps)(EpgTabScroll);
