const ACTION_TYPES = {
  ADD_TO_RECENT_WATCHES_START: "ADD_TO_RECENT_WATCHES_START",
  ADD_TO_RECENT_WATCHES_SUCCESS: "ADD_TO_RECENT_WATCHES_SUCCESS",
  ADD_TO_RECENT_WATCHES_FAILED: "ADD_TO_RECENT_WATCHES_FAILED",

  BROADCAST_CREATE_PROFILE: "BROADCAST_CREATE_PROFILE",
  BROADCAST_DELETE_PROFILE_BY_ID: "BROADCAST_DELETE_PROFILE_BY_ID",
  BROADCAST_UPDATE_PROFILE: "BROADCAST_UPDATE_PROFILE",

  CREATE_PROFILE_START: "CREATE_PROFILE_START",
  CREATE_PROFILE_SUCCESS: "CREATE_PROFILE_SUCCESS",
  CREATE_PROFILE_FAILED: "CREATE_PROFILE_FAILED",

  PROFILE_CREATE_START: "PROFILE_CREATE_START",
  PROFILE_CREATE_SUCCESS: "PROFILE_CREATE_SUCCESS",
  PROFILE_CREATE_FAILED: "PROFILE_CREATE_FAILED",

  GET_PROFILE_LIST_START: "GET_PROFILE_LIST_START",
  GET_PROFILE_LIST_SUCCESS: "GET_PROFILE_LIST_SUCCESS",
  GET_PROFILE_LIST_FAILED: "GET_PROFILE_LIST_FAILED",

  PROFILE_UPDATE_START: "PROFILE_UPDATE_START",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILED: "PROFILE_UPDATE_FAILED",

  DELETE_PROFILE_START: "DELETE_PROFILE_START",
  DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_FAILED: "DELETE_PROFILE_FAILED",

  EDIT_PROFILE_START: "EDIT_PROFILE_START",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILED: "EDIT_PROFILE_FAILED",

  DISABLE_PROFILE: "DISABLE_PROFILE",

  SAVE_CREADENTIALS_FOR_REMEMBER_ME: "SAVE_CREADENTIALS_FOR_REMEMBER_ME",

  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  SIGNUP_STATE_CLEAR: "SIGNUP_STATE_CLEAR",

  DEVICE_REGISTER_START: "DEVICE_REGISTER_START",
  DEVICE_REGISTER_SUCCESS: "DEVICE_REGISTER_SUCCESS",
  DEVICE_REGISTER_FAILED: "DEVICE_REGISTER_FAILED",

  GET_DEVICE_LIST_START: "GET_DEVICE_LIST_START",
  GET_DEVICE_LIST_SUCCESS: "GET_DEVICE_LIST_SUCCESS",
  GET_DEVICE_LIST_FAILED: "GET_DEVICE_LIST_FAILED",

  GET_DEVICE_LIST_FOR_ADDING_DEVICE_START:
    "GET_DEVICE_LIST_FOR_ADDING_DEVICE_START",
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS:
    "GET_DEVICE_LIST_FOR_ADDING_DEVICE_SUCCESS",
  GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED:
    "GET_DEVICE_LIST_FOR_ADDING_DEVICE_FAILED",

  NAVIGATE_TO_DEVICE_SCREEN: "NAVIGATE_TO_DEVICE_SCREEN",
  MANAGE_NAVIGATION_STATUS: "MANAGE_NAVIGATION_STATUS",

  REPLACE_DEVICE_START: "REPLACE_DEVICE_START",
  REPLACE_DEVICE_SUCCESS: "REPLACE_DEVICE_SUCCESS",
  REPLACE_DEVICE_FAILED: "REPLACE_DEVICE_FAILED",

  CHANNEL_LIST_START: "CHANNEL_LIST_START",
  CHANNEL_LIST_SUCCESS: "CHANNEL_LIST_SUCCESS",
  CHANNEL_LIST_FAIELD: "CHANNEL_LIST_FAILED",

  PROGRAM_LIST_START: "PROGRAM_LIST_START",
  PROGRAM_LIST_SUCCESS: "PROGRAM_LIST_SUCCESS",
  PROGRAM_LIST_FAILED: "PROGRAM_LIST_FAILED",

  RATE_SHOW_START: "RATE_SHOW_START",
  RATE_SHOW_SUCCESS: "RATE_SHOW_SUCCESS",
  RATE_SHOW_FAILED: "RATE_SHOW_FAILED",

  RATE_RECENTLY_WATCHED_MOVIE_START: "RATE_RECENTLY_WATCHED_MOVIE_START",
  RATE_RECENTLY_WATCHED_MOVIE_SUCCESS: "RATE_RECENTLY_WATCHED_MOVIE_SUCCESS",
  RATE_RECENTLY_WATCHED_MOVIE_FAILED: "RATE_RECENTLY_WATCHED_MOVIE_FAILED",

  REMOVE_TO_RECENT_WATCHES_START: "REMOVE_TO_RECENT_WATCHES_START",
  REMOVE_TO_RECENT_WATCHES_SUCCESS: "REMOVE_TO_RECENT_WATCHES_SUCCESS",
  REMOVE_TO_RECENT_WATCHES_FAILED: "REMOVE_TO_RECENT_WATCHES_FAILED",

  CLEAR_RECENT_WATCHES_START: "CLEAR_RECENT_WATCHES_START",
  CLEAR_RECENT_WATCHES_SUCCESS: "CLEAR_RECENT_WATCHES_SUCCESS",
  CLEAR_RECENT_WATCHES_FAILED: "CLEAR_RECENT_WATCHES_FAILED",

  MARK_REMINDED_MOVIE_AS_READ_START: "MARK_REMINDED_MOVIE_AS_READ_START",
  MARK_REMINDED_MOVIE_AS_READ_SUCCESS: "MARK_REMINDED_MOVIE_AS_READ_SUCCESS",
  MARK_REMINDED_MOVIE_AS_READ_FAILED: "MARK_REMINDED_MOVIE_AS_READ_FAILED",

  SELECT_PROFILE_START: "SELECT_PROFILE_START",
  SELECT_PROFILE_SUCCESS: "SELECT_PROFILE_SUCCESS",
  SELECT_PROFILE_FAILED: "SELECT_PROFILE_FAILED",
  SET_PROFILE_COUNT_TO_DISABLE: "SET_PROFILE_COUNT_TO_DISABLE",

  SELECT_DEVICE_START: "SELECT_DEVICE_START",
  SELECT_DEVICE_SUCCESS: "SELECT_DEVICE_SUCCESS",
  SELECT_DEVICE_FAILED: "SELECT_DEVICE_FAILED",
  SET_DEVICE_COUNT_TO_DISABLE: "SET_DEVICE_COUNT_TO_DISABLE",

  SHOW_SUBSCRIBER_START: "SHOW_SUBSCRIBER_START",
  SHOW_SUBSCRIBER_SUCCESS: "SHOW_SUBSCRIBER_SUCCESS",
  SHOW_SUBSCRIBER_FAILED: "SHOW_SUBSCRIBER_FAILED",

  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START:
    "TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_START",
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS:
    "TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_SUCCESS",
  TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED:
    "TOGGLE_REMIND_ME_OF_COMING_SOON_SHOW_FAILED",

  TOGGLE_ADD_TO_MY_LIST_START: "TOGGLE_ADD_TO_MY_LIST_START",
  TOGGLE_ADD_TO_MY_LIST_SUCCESS: "TOGGLE_ADD_TO_MY_LIST_SUCCESS",
  TOGGLE_ADD_TO_MY_LIST_FAILED: "TOGGLE_ADD_TO_MY_LIST_FAILED",

  UPDATE_REMIND_ME_IS_RELEASED_PROPERTY:
    "UPDATE_REMIND_ME_IS_RELEASED_PROPERTY",

  UPDATE_AUTHENTICATED_PROFILE_START: "UPDATE_AUTHENTICATED_PROFILE_START",
  UPDATE_AUTHENTICATED_PROFILE_SUCCESS: "UPDATE_AUTHENTICATED_PROFILE_SUCCESS",
  UPDATE_AUTHENTICATED_PROFILE_FAILED: "UPDATE_AUTHENTICATED_PROFILE_FAILED",

  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START:
    "UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_START",
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS:
    "UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_SUCCESS",
  UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED:
    "UPDATE_RECENTLY_WATCHED_AT_POSITION_MILLIS_FAILED",

  CLEAR_ERRORS_PROPERTY: "CLEAR_ERRORS_PROPERTY",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_SUBSCRIPTION_DETAILS: "UPDATE_SUBSCRIPTION_DETAILS",

  PROFILE_BLOCK_START: "PROFILE_BLOCK_START",
  PROFILE_BLOCK_SUCCESS: "PROFILE_BLOCK_SUCCESS",
  PROFILE_BLOCK_FAILED: "PROFILE_BLOCK_FAILED",

  PROFILE_UNBLOCK_START: "PROFILE_UNBLOCK_START",
  PROFILE_UNBLOCK_SUCCESS: "PROFILE_UNBLOCK_SUCCESS",
  PROFILE_UNBLOCK_FAILED: "PROFILE_UNBLOCK_FAILED",

  PROFILE_SENDEMAIL_START: " PROFILE_SENDEMAIL_START",
  PROFILE_SENDEMAIL_SUCCESS: " PROFILE_SENDEMAIL_SUCCESS",
  PROFILE_SENDEMAIL_FAILED: "PROFILE_SENDEMAIL_FAILED",

  PROFILEPIC_URL_START: "PROFILEPIC_URL_START",
  PROFILEPIC_URL_SUCCESS: "PROFILEPIC_URL_SUCCESS",
  PROFILEPIC_URL_FAILED: "PROFILEPIC_URL_FAILED",

  DEVICE_REGISTER_STATUS: "DEVICE_REGISTER_STATUS",
};

export default ACTION_TYPES;
