/** @format */

import ACTION_TYPES from "./actionTypes";
const {
  SET_LIVE_VIDEO_URL,
  SET_MOVIE_VIDEO_URL,
  SET_TIMESTAMP,
  EPG_VIDEO_URL,
  GET_DRM_CATCHUP_REQUIRED,
  GET_LIVE_API_DATA,
  GET_LIVE_DETAILS_START,
  GET_LIVE_DETAILS_SUCESS,
  GET_LIVE_DETAILS_FAILED,
  LIVE_CHANNEL_DATA,
  GENRE_CHANNELS,
  GET_MOVIE_DETAILS_START,
  GET_MOVIE_DETAILS_SUCESS,
  GET_MOVIE_DETAILS_FAILED,
  MOVIE_DATA,

  GET_SERIES_DETAILS_START,
  GET_SERIES_DETAILS_SUCESS,
  GET_SERIES_DETAILS_FAILED,
  SERIES_DATA,
} = ACTION_TYPES;

const initialState = {
  liveVideoUrl: null,
  movieVideoUrl: null,
  seriesVideoUrl: null,
  drmId: null,
  loading: false,
  error: "",
  timeStamp: "",
  drmContentId: "",
  epgVideoUrl: "",
  isDrmCatchupRequired: null,
  liveApiData: null,
  liveChannelData: null,
  liveChannel: null,
  genreChannels: null,

  movieData: null,
  seriesData: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;

  switch (type) {
    case SET_LIVE_VIDEO_URL:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        liveVideoUrl: payload?.videoUrl,
        isLoading,
      };
    case SET_MOVIE_VIDEO_URL:
      return {
        ...state,
        movieVideoUrl: payload,
        isLoading,
      };

    case EPG_VIDEO_URL:
      return {
        ...state,
        epgVideoUrl: payload,
        isLoading,
      };

    case SET_TIMESTAMP:
      return {
        ...state,
        timeStamp: payload,
      };
    case GET_DRM_CATCHUP_REQUIRED:
      return {
        ...state,
        isDrmCatchupRequired: payload,
      };
    case GET_LIVE_DETAILS_START:
      return {
        ...state,
      };

    case GET_LIVE_DETAILS_SUCESS:
      return {
        ...state,
        liveChannelData: payload?.data?.data?.[0],
        liveChannel: payload?.data?.data?.[0],
      };
    case GET_LIVE_DETAILS_FAILED:
      return {
        ...state,
      };
    case LIVE_CHANNEL_DATA:
      return {
        ...state,
        liveChannel: payload,
        liveChannelData: payload,
      };

    case GET_LIVE_API_DATA:
      return {
        ...state,
        liveApiData: payload,
      };

    case GENRE_CHANNELS:
      return {
        ...state,
        genreChannels: payload,
      };

    case GET_MOVIE_DETAILS_START:
      return {
        ...state,
      };

    case GET_MOVIE_DETAILS_SUCESS:
      return {
        ...state,
        movieData: payload?.data?.data?.[0],
      };
    case GET_MOVIE_DETAILS_FAILED:
      return {
        ...state,
      };
    case MOVIE_DATA:
      return {
        ...state,
        movieData: payload,
      };

    case GET_SERIES_DETAILS_START:
      return {
        ...state,
      };

    case GET_SERIES_DETAILS_SUCESS:
      return {
        ...state,
        seriesData: payload?.data?.data?.[0],
      };
    case GET_SERIES_DETAILS_FAILED:
      return {
        ...state,
      };
    case SERIES_DATA:
      return {
        ...state,
        seriesData: payload,
      };

    default:
      return state;
  }
};
