import ACTION_TYPES from "./actionType";
const {
  GET_FAVOURITES_DATA_START,
  GET_FAVOURITES_DATA_SUCCESS,
  GET_FAVOURITES_DATA_FAILED,
} = ACTION_TYPES;

// get favourite data

export const getFavouriteDataStart = (payload) => ({
  type: GET_FAVOURITES_DATA_START,
  payload,
});

export const getFavouriteDataSuccess = (payload) => ({
  type: GET_FAVOURITES_DATA_SUCCESS,
  payload,
});

export const getFavouriteDataFailed = (payload) => ({
  type: GET_FAVOURITES_DATA_FAILED,
  payload,
});
