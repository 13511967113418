import React, { useState } from "react";
import Modal from "react-modal";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import * as LOCK_TYPES from "./../../Redux/auth/actions";
import { connect, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as REQUEST_TYPES from "./../../Redux/auth/actions";
import "./Alert.css";
import { createStructuredSelector } from "reselect";
import { platformsSelector } from "../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "50%",
    height: "50%",
    overflowY: "hidden",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
const setAppElement = "#yourAppElement";

const UnlockProfile = ({
  openUnlock,
  setOpenUnlock,
  updateProfile,
  USER_DETAILS,
  AUTHENTICATION,
  PLATFORM,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputFeild, setInputFeild] = useState();
  const [inputFeildError, setInputFeildError] = useState();
  const handleInput = (e) => {
    const value = e.target.value;
    setInputFeild(e.target.value);
  };
  //console.log("input feilds", inputFeild);
  const onCloseUnlock = () => setOpenUnlock(false);

  let mailData = {
    account_id: PLATFORM.currentPlatformId,
    userId: USER_DETAILS?.id,
    profileId: updateProfile.id,
  };

  const forgotKey = () => {
    setOpenUnlock(false);
    confirmAlert({
      title: "we will sent secret key to your mail",
      message: "check your mail",

      buttons: [
        {
          label: "OK",
          onClick: () => {
            //console.log("send request", mailData);
            dispatch(REQUEST_TYPES.profileSendEmailStart(mailData));
          },
        },

        {
          label: "Cancel",
        },
      ],
    });
  };
  let unlockData = {
    profileId: updateProfile.id,
    secretKey: updateProfile.secretKey,
    account_id: PLATFORM.currentPlatformId,
    userId: USER_DETAILS?.id, //manually
  };
  //console.log("is unLocked", unlockData);

  const profileUnLock = (e) => {
    e.preventDefault();
    setInputFeildError(false);
    if (inputFeild == undefined || inputFeild.length == 0) {
      setInputFeildError(true);
    } else if (inputFeild != updateProfile.secretKey) {
      setInputFeildError(true);
    } else {
      dispatch(LOCK_TYPES.profileUnBlockStart(unlockData));
      setOpenUnlock(false);
      swal("profile unlocked successfully");
      history.push("/profileList");
    }
  };

  return (
    <div>
      <Modal
        isOpen={openUnlock}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          color="white"
          onClick={onCloseUnlock}
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
        <div>
          <h5 style={{ textAlign: "center", color: "white" }}>
            {" "}
            Please enter the secret key
          </h5>
          <Grid
            container
            justify="center"
            style={{ marginTop: "2%", border: "black solid" }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputFeild}
              style={{ margin: "0 auto" }}
              onChange={handleInput}
              error={inputFeildError}
              helperText="pleaase enetr secrete key correctly!!"
            />
          </Grid>

          <div className="row mt-1">
            <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={profileUnLock}
              >
                OK
              </Button>
            </Grid>
          </div>
          <Grid container justify="center">
            <Button onClick={forgotKey} style={{ color: "white" }}>
              {" "}
              Forgot Key ??
            </Button>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(UnlockProfile);
