import { createSelector } from "reselect";
const getActiveState = (state) => state.emailActivation.isActivated;
const getActiveUserId = (state) => state.emailActivation.activeUserId;

export const getActiveStateSelector = createSelector(
    getActiveState,
  (isActivated) => isActivated
);

export const getActiveUserIdSelector = createSelector(
  getActiveUserId,
  (activeUserId) => activeUserId
);
