import types from "./actiontype";

const initialState = {
  loading: false,
  personalInfo: [],
  error: "",
};

const personalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PERSONAL_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        manageProfile: action.payload,
      };
    case types.UPDATE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default personalInfoReducer;
