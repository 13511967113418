/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_DELETE_DEVICES_START,
  GET_DELETE_DEVICES_SUCCESS,
  GET_DELETE_DEVICES_FAILED,

  CHECK_EXISTANCE_OF_DEVICE_START,
  CHECK_EXISTANCE_OF_DEVICE_SUCCESS,
  CHECK_EXISTANCE_OF_DEVICE_FAILED,
  DEVICE_STATUS,
} = ACTION_TYPES;

const initialState = {
  deleteDevice: null,

  deleteDeviceLoading: false,
  deviceStatus: true,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;

  switch (type) {
    case GET_DELETE_DEVICES_START:
      return {
        ...state,
        deleteDeviceLoading: true,
      };

    case GET_DELETE_DEVICES_SUCCESS:
      return {
        ...state,
        deleteDevice: payload,

        deleteDeviceLoading: false,
        errors,
      };

    case GET_DELETE_DEVICES_FAILED:
      return {
        ...state,

        deleteDeviceLoading: false,
        errors: payload.message,
      };

    case CHECK_EXISTANCE_OF_DEVICE_START:
      // console.log("uiujiunhiu");
      return {
        ...state,
      };

    case CHECK_EXISTANCE_OF_DEVICE_SUCCESS:
      return {
        ...state,
      };

    case CHECK_EXISTANCE_OF_DEVICE_FAILED:
      return {
        ...state,
      };
    case DEVICE_STATUS:
      // console.log(payload + "deviceStatus arun Red");
      return {
        ...state,
        deviceStatus: payload,
      };

    default:
      return state;
  }
};
