import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getDeviceDetailsSelector } from "../../Redux/deviceRedux/selectors";
import { getActiveStateSelector } from "../../Redux/email-activation/selector";
import * as ACTION from "./../../Redux/email-activation/action";
import EmailLoadingPage from "./EmailLoadingPage";
import * as ACTION_TYPES from "./../../Redux/platforms/actions";
import * as LIVE_ACTION from "./../../Redux/live/actions";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";

import * as AUTHENTICATION_ACTIONS from "./../../Redux/authentication/actions";

const ActivationPage = ({
  ACTIVE_STATE,
  DEVICE_DETAILS,
  AUTHENTICATION,
  USER_DETAILS,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ACTION_TYPES.getPlatformsListStart());
    dispatch(LIVE_ACTION.getGeneralSettingsStart({ account_id: 0 }));
  }, []);

  useEffect(() => {
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      AUTHENTICATION != undefined &&
      AUTHENTICATION != null
    ) {
      dispatch(AUTHENTICATION_ACTIONS.loginStausFalse());
    }
  }, []);
  var userId;
  window.onload = function() {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      userId = url.searchParams.get("userId");

      //console.log(" and " + userId);
    } catch (err) {
      //console.log("Issues with Parsing URL Parameter's - " + err);
    }

    // alert(userId);
    dispatch(ACTION.activeEmailStart({ id: userId }));
    dispatch(ACTION.getActiveUserId(userId));
  };
  const handleResetPassword = () => {
    history.push("/resetPassword");
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "black",
          opacity: "0.8",
          height: "600px",
          padding: "200px",
        }}
      >
        {ACTIVE_STATE == true ? (
          <div
            style={{
              width: "300px",
              height: "100px",
              color: "white",
              marginLeft: "300px",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "8px",
              backgroundColor: "#537895",
              backgroundImage:
                "linear-gradient(315deg, #537895 0%, #09203f 74%)",
            }}
          >
            Activated Succesfully
            <div style={{ fontSize: "18px", marginTop: "5px" }}>
              <a
                // href="//resetPassword"
                style={{ color: "black", marginTop: "30px", cursor: "pointer" }}
                onClick={handleResetPassword}
              >
                ResetPassword
              </a>
            </div>
          </div>
        ) : (
          <EmailLoadingPage />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  ACTIVE_STATE: getActiveStateSelector,
  DEVICE_DETAILS: getDeviceDetailsSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(ActivationPage);
