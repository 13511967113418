import React, { useEffect, useRef, useState } from "react";
import "videojs-contrib-eme";
import "./../../LiveVideoPlayerComponent/video.css";
import videojs from "video.js";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import "../../loader/loadingPlugin.js"; // Import the loading plugin
import "../../retryPlugin/retry.js"; // Import the loading plugin
import "../../LatestVideoJsPlugin/SerisPlugin/SeriesPlugin.js"; // Import the loading plugin
import "../../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../../LatestVideoJsPlugin/keywordControlPluginWithIcons.js";

import "./../../skins/chrome/videojs.min.css";
import "./../../skins/chrome/videojs.css";
import "./../../skins/chrome/ima.min.css";
import "./../../skins/chrome/ima.css";
import { createStructuredSelector } from "reselect";
import {
  getseriesEpisodeDetailsSelector,
  seriesAssetSelector,
} from "../../../Redux/SeriesGenre/selector";
import { connect, useDispatch } from "react-redux";
import { baseName, subscribeUrl } from "../../IpConstantFile/IpConstantFile";
import * as DATA_ANALYTICS from "./../../../Redux/DATA-analytics/actions.js";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../../Redux/platforms/selectors";
import { useHistory, useLocation } from "react-router-dom";
import {
  getGeneralAdsDetailSelector,
  getgeneralVideoAdsDetailsSelector,
} from "../../../Redux/AllLiveDetails/selectors";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../../Redux/deviceRedux/selectors";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";
import * as PLAYLIST from "./chapterData";
import { getDrmDetailsSelector } from "../../../Redux/DRM integration/selectors";
import * as SERIES_ACTION from "../../../Redux/SeriesGenre/actions";
import {
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../../Redux/package&subscriptions/selector";
import {
  getCurrentTimeZone,
  getPiPStateFromStorage,
  newImageIpPath,
} from "../../../App.js";
import {
  configurationSelector,
  continueWatchingSelector,
  watchedHistorySelector,
} from "../../../Redux/DATA-analytics/selector.js";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors.js";
import { getDefaultPlatformsSelector } from "../../../Redux/DefaultPlatform/selectors.js";
import { getSaveDeviceTockenSelector } from "../../../Redux/SaveDeviceTocken/selectors.js";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../../services/datAnalytics/dataAnalytics.js";

const SeriesVideoJs = ({
  EPISODE_DETAILS,
  CURRENT_PLATFORM,
  VIDEO_ADS,
  DEVICE_NAME,
  DEVICE_TOKEN,
  DEVICE_STATUS,
  DRM_DETAILS,
  GENERAl_POST_ADS,
  PAY_PER_VIEW_DETAILS,
  CONFIGURATION_DAS,
  SERIES_ASSETS,
  CONTINUE_WATCHING_DATA,
  USER_DETAILS,
  AUTHENTICATION,
  PACKAGE_DETAILS,
  DEFAULT_PLATFORM,
  SAVED_DEVICE_TOCKEN,
  setSelectedCategory,
  WATCHES_HISTORY_DATA,
}) => {
  let time;
  let location = useLocation().state;
  // let isCwRequired = 0;
  const history = useHistory();
  const dispatch = useDispatch();
  const [videoAdsState, setVideoAdsState] = useState(null);
  let startOver = location?.startOver;
  const timeZone = getCurrentTimeZone();

  // alert(location?.from);
  const [isVideoAdsAvailable, setIsVideoAdAvailable] = useState(null);
  const [signInMessage, setSignInMessage] = useState(false);
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  let platformLogo = newImageIpPath + CURRENT_PLATFORM?.productLogo;
  var lastVidioEnded = false;
  var currentTrackNo = 0;
  let videoAdStatus = null;

  let adsEnabledForDefaultPlatform;
  if (DEFAULT_PLATFORM != undefined && DEFAULT_PLATFORM != null) {
    adsEnabledForDefaultPlatform = DEFAULT_PLATFORM?.data?.data?.isAdsEnabled;
  }
  const adsEnabled = AUTHENTICATION
    ? Object.values(PACKAGE_DETAILS).some(
        (item) => item?.assetGroup?.isAdsEnabled === 1
      )
    : adsEnabledForDefaultPlatform;
  const isCwRequired = AUTHENTICATION === true && location?.flag === 1 ? 1 : 0;

  let dataAnalytics = {};

  const calculatePercentageViewed = (viewedDuration, totalDuration) => {
    const viewedDurationParts = viewedDuration.split(":");
    const totalDurationParts = totalDuration.split(":");

    const viewedDurationInSeconds =
      parseInt(viewedDurationParts[0]) * 3600 +
      parseInt(viewedDurationParts[1]) * 60 +
      parseInt(viewedDurationParts[2]);

    const totalDurationInSeconds =
      parseInt(totalDurationParts[0]) * 3600 +
      parseInt(totalDurationParts[1]) * 60 +
      parseInt(totalDurationParts[2]);

    const percentageViewed =
      (viewedDurationInSeconds / totalDurationInSeconds) * 100;
    const percentageViewedInteger = Math.floor(percentageViewed);

    return percentageViewedInteger;
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,

    preload: "auto",
    qualitySelector: false, // Disable quality selector

    aspectRatio: "16:8",
    playlistPicker: true,

    controlBar: {
      volumePanel: {
        inline: false, // Horizontal volume control
      },
    },
    // autoplay: true,
    // controls: true,
    // responsive: true,

    // preload: "auto",
    // qualitySelector: false, // Disable quality selector

    // aspectRatio: "16:8",
    // playlistPicker: true,
    // controlBar: {
    //   children: [
    //     "skipBackward", // Add a skip backward button (custom)
    //     "playToggle", // Play/pause button
    //     "skipForward", // Add a skip forward button (custom)
    //     "currentTimeDisplay",
    //     "progressControl",
    //     "durationDisplay",
    //     "muteToggle",
    //     "volumeControl",
    //     "pictureInPictureToggle", // Add Picture-in-Picture button

    //     "fullscreenToggle",
    //   ],
    // },
  };

  let navigateLocation = null;
  let navPath = null;
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  if (
    USER_DETAILS != undefined &&
    USER_DETAILS != null &&
    AUTHENTICATION == true
  ) {
    if (location?.from == "mylist") {
      navigateLocation = baseName + "mylistcontainer";
      navPath = "/mylistcontainer";
    } else if (location?.from == "genreCategory") {
      navigateLocation = baseName + "seriesGenreContainer";
      navPath = "/seriesGenreContainer";
    } else {
      navigateLocation = baseName + "Container";
      navPath = "/Container";
    }
  } else {
    if (location?.from == "genreCategory") {
      navigateLocation = baseName + "seriesGenreContainer";
      navPath = "/seriesGenreContainer";
    } else {
      navigateLocation = baseName + "Container";
      setSelectedCategory(3);
      if (defaultLandingPage == 3) {
        navPath = "";
        navigateLocation = baseName;
        setSelectedCategory(3);
      } else {
        navPath = "/Container";
        navigateLocation = baseName + "Container";
        setSelectedCategory(3);
      }
    }
  }

  let player;
  const videoNode = useRef(null);
  let matchedHistory =
    WATCHES_HISTORY_DATA?.length > 0 &&
    WATCHES_HISTORY_DATA?.some((item) => {
      return (
        Number(item.assetId) ===
          Number(EPISODE_DETAILS?.episodes?.[0].seriesId) &&
        Number(item.categoryId) === 3
      );
    });

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1 &&
      location?.flag == 1
    ) {
      let SeriesIntervalForSendingDataToLocal = setInterval(
        () => {
          // alert(dataAnalytics.duration);
          let dataObject =
            JSON.parse(localStorage.getItem("MovieDataToLocal")) || {};
          console.log("videoAdStatus" + videoAdStatus);

          if (
            dataAnalytics &&
            typeof dataAnalytics === "object" &&
            JSON.parse(localStorage.getItem("isPlaying")) == true &&
            // videoAdsState != "start"
            // &&
            videoAdStatus != "playing"
          ) {
            if (
              dataAnalytics.assetId !== undefined &&
              dataAnalytics.categoryId !== undefined
            ) {
              // Create a unique key for the current dataAnalytics
              console.log("videoAdStatusArun" + videoAdStatus);

              const uniqueKey =
                dataAnalytics?.assetId + "_" + dataAnalytics.categoryId;

              // Update or add the new data to the object
              dataObject[uniqueKey] = dataAnalytics;

              if (matchedHistory == true) {
                // console.log(
                //   "pragathiiiiiiiiiiiii",
                //   EPISODE_DETAILS?.episodes?.[0].seriesId
                // );
                matchedHistory = false;
                dispatch(
                  DATA_ANALYTICS.removeWatchesHistoryStart({
                    dataForRemoveContinueWatching: {
                      platformId: CURRENT_PLATFORM?.orgId,

                      profileId: 1,
                      userId: USER_DETAILS?.id,
                      assetId: Number(EPISODE_DETAILS?.episodes?.[0].seriesId),
                      categoryId: 3,
                      type: "single",
                    },
                    ServerIp: CONFIGURATION_DAS?.analyticsIp,
                  })
                );
              }
              console.log("matchedHistory" + matchedHistory);

              dispatch(DATA_ANALYTICS.fetchPlayerDataSuccess(dataAnalytics));

              // console.log(
              //   "ARUN FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS" +
              //     JSON.stringify(dataAnalytics)
              // );
              // Update local storage with the modified object
              localStorage.setItem(
                "MovieDataToLocal",
                JSON.stringify(dataObject)
              );
            }
          }
          // alert("in SERIES Player sending local" + JSON.stringify(dataObject));

          // Get existing data from localStorage
        },
        CONFIGURATION_DAS?.frequencyForStoringDataInApp == undefined
          ? 30000
          : CONFIGURATION_DAS?.frequencyForStoringDataInApp
      );

      // ;;;;;;;;;;;;;;;;;;;

      return () => {
        clearInterval(SeriesIntervalForSendingDataToLocal);
      };
    }
  }, []);
  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let interval = setInterval(
        () => {
          let dataAnalyticsForAPI = {};
          dataAnalyticsForAPI = {
            platformId: CURRENT_PLATFORM?.orgId,
            profileId: 1,
            deviceId: DEVICE_TOKEN,
            userId: USER_DETAILS?.id,
            timeZone: timeZone,

            assets: {},
          };

          let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));

          // Check if dataObject is not undefined or null
          if (dataObject && typeof dataObject === "object") {
            const keys = Object.keys(dataObject);
            let finalData = [];

            keys.forEach((a) => {
              finalData?.push(dataObject?.[a]);
            });

            // console.log("dataObject" + JSON.stringify(finalData));
            if (finalData != undefined && finalData != null) {
              dataAnalyticsForAPI.assets = JSON.stringify(finalData);
            }

            // console.log("MovieDataToLocal" + JSON.stringify(MovieData));
            // alert(
            //   "in SERIES Player sending Server" + JSON.stringify(dataObject)
            // );

            let data2;
            if (
              AUTHENTICATION == true &&
              CONFIGURATION_DAS != null &&
              CONFIGURATION_DAS != undefined
            ) {
              data2 = {
                dataAnalyticsForAPI: dataAnalyticsForAPI,
                ServerIp: CONFIGURATION_DAS?.analyticsIp,
              };
            }
            dispatch(DATA_ANALYTICS.sendingDataToDasStart(data2));
          }
        },
        CONFIGURATION_DAS?.frequencyForSendingDataToServer == undefined
          ? 300000
          : Number(CONFIGURATION_DAS?.frequencyForSendingDataToServer) + 6500
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    let currentEpisodeData;
    let CurrentEpisodeIndexData;

    console.log("abcdf1" + JSON.stringify(EPISODE_DETAILS.index));

    EPISODE_DETAILS?.episodes?.map((item, index) => {
      console.log("abcdf1" + JSON.stringify(item.id));

      if (item.id == EPISODE_DETAILS?.index) {
        currentEpisodeData = item;
        CurrentEpisodeIndexData = index;
      }
    });
    if (currentEpisodeData != null) {
      player = videojs(
        videoNode.current,
        videoJsOptions,

        function onPlayerReady() {
          var myPlayer = this;

          myPlayer.on("error", function() {
            var error = player.error();
            localStorage.setItem("isPlaying", false);

            player.pause();
            // console.error("Video.js Error:", error);

            if (error.code != 3) {
              // player.vjs_error_page(player, navigateLocation);
            }
          });
        }
      );

      function setPiPStateInStorage(isPiPMode) {
        localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
      }

      document.addEventListener("enterpictureinpicture", () => {
        // PiP mode entered
        setPiPStateInStorage(true);
      });

      document.addEventListener("leavepictureinpicture", () => {
        // PiP mode exited
        setPiPStateInStorage(false);
      });
      player.loadingPlugin();
      player.keywordControlPluginWithIcons();

      player.eme();
      player.errorOverlayPlugin(navigateLocation);
      player.displayPlatFormLogo(platformLogo);
      let filteredContinueWatchingData = [];
      if (location.startOver == 0) {
        CONTINUE_WATCHING_DATA?.filter((a) => {
          if (a.categoryId == 3 && a.episodeId != location.episodeId) {
            filteredContinueWatchingData.push(a);
          }
        });
      } else {
        filteredContinueWatchingData = CONTINUE_WATCHING_DATA;
      }
      const cwDataForPLaylist = filteredContinueWatchingData?.filter(
        (item) =>
          Number(item?.categoryId) === 3 &&
          Number(item?.assetId) ===
            Number(EPISODE_DETAILS?.episodes?.[0].seriesId) &&
          Number(item?.isVideoEnded) === 0 &&
          Number(item?.videoDeleted) === 0 &&
          AUTHENTICATION === true &&
          Number(CURRENT_PLATFORM?.viewing_analytics_enabled) == 1 &&
          // startOver == 1 &&
          Number(USER_DETAILS?.viewing_analytics_status) == 1 &&
          location?.flag === 1
      );

      let playListDetails;
      if (window.WebKitMediaKeys) {
        playListDetails = PLAYLIST.getPlayListForFairplay({
          EPISODE_DETAILS,
          DRM_DETAILS,
          SAVED_DEVICE_TOCKEN,
          DEVICE_NAME,
          cwDataForPLaylist,
          GENERAl_POST_ADS,
          VIDEO_ADS,
          adsEnabled,
          AUTHENTICATION,
          USER_DETAILS,
          isCwRequired,
        });
      } else if (window.MediaKeys) {
        playListDetails = PLAYLIST.getPlayList({
          EPISODE_DETAILS,
          DRM_DETAILS,
          SAVED_DEVICE_TOCKEN,
          DEVICE_NAME,
          cwDataForPLaylist,
          GENERAl_POST_ADS,
          VIDEO_ADS,
          adsEnabled,
          AUTHENTICATION,
          USER_DETAILS,
          isCwRequired,
        });
      } else {
        playListDetails = PLAYLIST.getPlayList({
          EPISODE_DETAILS,
          DRM_DETAILS,
          SAVED_DEVICE_TOCKEN,
          DEVICE_NAME,
          cwDataForPLaylist,
          GENERAl_POST_ADS,
          VIDEO_ADS,
          adsEnabled,
          AUTHENTICATION,
          USER_DETAILS,
          isCwRequired,
        });
      }
      // console.log("playListDetailsinPLayer" + JSON.stringify(playListDetails));
      player.playlistPlugin(playListDetails, CurrentEpisodeIndexData);

      const seekButtonStyle = document.createElement("style");
      document.head.appendChild(seekButtonStyle);
      const progressControl = player.controlBar.getChild("progressControl");

      if (AUTHENTICATION === true && location.flag == 1) {
        if (progressControl) {
          progressControl.enable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
      } else {
        const progressControl = player.controlBar.getChild("progressControl");
        if (progressControl) {
          progressControl.disable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
      }

      player.on("playlistEnded", function() {
        history.goBack();
      });

      player.on("posterAdplaying", function(event) {
        // Access the data in the event's detail property

        const adData = event.detail;

        if (AUTHENTICATION) {
          let data2 = {
            adsAnalyticsData: {
              addId: adData.id,

              userId: USER_DETAILS?.id,

              platformId: CURRENT_PLATFORM?.orgId,

              profileId: 1,

              deviceId: DEVICE_TOKEN,

              categoryId: 3,
              assetId: Number(EPISODE_DETAILS?.episodes?.[0].seriesId),
              timeZone: timeZone,
            },
            ServerIp: CONFIGURATION_DAS?.analyticsIp,
          };
          getSendingDataOfPosteradsAsync(data2);
        }
      });
      player.on("adplaying", function(event) {
        videoAdStatus = "playing";

        // Access the data in the event's detail property
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";

        setVideoAdsState("start");
        // console.log("abcdefgh adplaying");

        localStorage.setItem("adStatus", 1);
      });
      player.on("adended", function(event) {
        videoAdStatus = "ended";

        if (AUTHENTICATION) {
          const adData = event.detail;

          let data2 = {
            adsAnalyticsData: {
              addId: adData.id,

              userId: USER_DETAILS?.id,

              platformId: CURRENT_PLATFORM?.orgId,

              profileId: 1,

              deviceId: DEVICE_TOKEN,

              categoryId: 3,
              assetId: Number(EPISODE_DETAILS?.episodes?.[0].seriesId),
              timeZone: timeZone,
            },
            ServerIp: CONFIGURATION_DAS?.analyticsIp,
          };

          getSendingDataOfVideoadsAsync(data2);
        }
        setVideoAdsState("ended");

        setIsVideoAdAvailable(false);

        localStorage.setItem("adStatus", 0);

        if (AUTHENTICATION === true && location.flag == 1) {
          if (progressControl) {
            progressControl.enable();
          }
          seekButtonStyle.textContent =
            ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
        } else {
          if (progressControl) {
            progressControl.disable();
          }
          seekButtonStyle.textContent =
            ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
        }
      });

      player.on("episodechange", function() {
        EPISODE_DETAILS?.episodes?.filter((a, index) => {
          if (a?.id == player.getCurrentPlaylistItem().id) {
            dispatch(
              SERIES_ACTION.getSeriesEpisodeDetails({
                episodes: EPISODE_DETAILS?.episodes,
                index: player.getCurrentPlaylistItem().id,
                assetTitle: EPISODE_DETAILS?.assetTitle,
                allseasons: EPISODE_DETAILS?.allseasons,
              })
            );
            return index;
          }
        });
      });
      let videoAdsArray = player?.getCurrentPlaylistItem().adConfig;
      const hasPreroll = videoAdsArray.some((ad) => parseInt(ad.time) == 0);

      if (videoAdsArray?.length > 0 && hasPreroll) {
        setIsVideoAdAvailable(true);
        console.log("abcdefgh hasPreroll");
        localStorage.setItem("adStatus", 1);

        // console.log("hasPreroll" + hasPreroll);
      } else {
        console.log("abcdefgh no ads");

        setIsVideoAdAvailable(false);
        localStorage.setItem("adStatus", 0);
      }
      let i = 0;
      let time;

      player.on("loadeddata", function() {
        console.log("startOver" + startOver);
        // startOver = 1;
        // time = undefined;
        // player.posterAds(player, null, null, false);
      });

      player.on("loadedmetadata", function() {
        let title = player?.getCurrentPlaylistItem().name;

        let Episode = player?.getCurrentPlaylistItem().index + 1;
        let assetTitle =
          title +
          " " +
          "S" +
          (EPISODE_DETAILS?.assetTitle?.match(/\d+/) || [""])[0] +
          " / " +
          "E" +
          Episode;

        document.title = assetTitle;
        document.title =
          SERIES_ASSETS?.assetDetails[
            EPISODE_DETAILS?.episodes?.[0].seriesId
          ]?.title;
        if (
          AUTHENTICATION === true &&
          location?.flag != 0 &&
          lastVidioEnded != true
        ) {
          document.addEventListener("keydown", (event) => {
            if (event.key === " " && lastVidioEnded != true) {
              if (player.paused()) {
                player.play();
              } else {
                player.pause();
              }
            }
          });
        }
        localStorage.setItem("isPlaying", true);

        // alert("meta data loaded");
        var audioTracks = player.audioTracks();
        if (audioTracks.length == 1) {
          audioTracks[0].enabled = true;
        } else if (audioTracks.length > 1) {
          audioTracks[currentTrackNo].enabled = true;
        }

        audioTracks.addEventListener("change", function() {
          for (var i = audioTracks.length - 1; i >= 0; i--) {
            var track = audioTracks[i];
            if (track.enabled) {
              currentTrackNo = i;
            }
          }
        });
      });

      player.on("timeupdate", function(e) {
        if (lastVidioEnded == true) {
          player.pause();
        }
        player.on("play", function() {
          localStorage.setItem("isPlaying", true);

          // Additional actions when the video starts playing
        });

        player.on("pause", function() {
          localStorage.setItem("isPlaying", false);
          // Additional actions when the video is paused
        });
        const formatTime = (timeInSeconds) => {
          const hours = Math.floor(timeInSeconds / 3600);
          const minutes = Math.floor((timeInSeconds % 3600) / 60);
          const seconds = Math.floor(timeInSeconds % 60);

          return `${hours
            .toString()
            .padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        };

        const totalDuration = player.duration();
        const currentTime = player.currentTime();

        const formattedTotalDuration = formatTime(totalDuration);
        const formattedCurrentTime = formatTime(currentTime);
        const dataToInsert = {
          duration: formattedCurrentTime, // replace with your actual duration
          totalDuration: formattedTotalDuration, // replace with your actual total duration
        };

        const percentageViewed = calculatePercentageViewed(
          dataToInsert.duration,
          dataToInsert.totalDuration
        );
        // console.log(
        //   "shankru" + JSON.stringify(player.getCurrentPlaylistItem())
        // );
        // console.log("shankru" + JSON.stringify(EPISODE_DETAILS));

        const currentPlaylistItemId = player.getCurrentPlaylistItem().id;

        // Find and return the episode object with the matching ID
        const matchingEpisode = EPISODE_DETAILS.episodes.find(
          (episode) => episode.id === currentPlaylistItemId
        );

        if (
          EPISODE_DETAILS?.episodes?.[0].seriesId != "undefined" &&
          EPISODE_DETAILS?.episodes?.[0].seriesId != undefined &&
          EPISODE_DETAILS?.episodes?.[0].seriesId != null &&
          matchingEpisode.seasonId != undefined &&
          matchingEpisode.seasonId != null &&
          player.getCurrentPlaylistItem().id != null &&
          player.getCurrentPlaylistItem().id != undefined
        ) {
          dataAnalytics = {
            assetId: Number(EPISODE_DETAILS?.episodes?.[0].seriesId),
            categoryId: 3,

            duration: isInvalidDuration(formattedCurrentTime)
              ? "00:00:00"
              : formattedCurrentTime,

            isEligibleForView: 0,
            isVideoEnded: 0,
            totalDuration: isInvalidDuration(formattedTotalDuration)
              ? "00:00:00"
              : formattedTotalDuration,

            seasonId: Number(matchingEpisode.seasonId),
            episodeId: player.getCurrentPlaylistItem().id,
            episodeLength: location?.total,
            videoDeleted: 0,
            index: player.getCurrentPlaylistItem().index,
          };
        }

        function isInvalidDuration(duration) {
          // Check if duration is in "NaN:NaN:NaN" format or any other invalid format
          return /^NaN:NaN:NaN$/.test(duration);
        }
        let percentageGiven =
          CONFIGURATION_DAS?.vodViewUnit == undefined
            ? 30
            : CONFIGURATION_DAS?.vodViewUnit;
        if (percentageViewed > percentageGiven) {
          dataAnalytics.isEligibleForView = 1;
        }
        if (percentageViewed > 85) {
          dataAnalytics.isVideoEnded = 1;
        } else {
          dataAnalytics.isVideoEnded = 0;
        }
        let stopDatePPV =
          PAY_PER_VIEW_DETAILS?.[3]?.[EPISODE_DETAILS?.episodes?.[0].seriesId]
            ?.expiryDate;
        let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));
        if (
          EPISODE_DETAILS?.episodes?.[0].seriesId ==
          PAY_PER_VIEW_DETAILS?.[3]?.[EPISODE_DETAILS?.episodes?.[0].seriesId]
            ?.assetId
          //     &&
          // PAY_PER_VIEW_DETAILS?.[EPISODE_DETAILS?.episodes?.[0].seriesId]
          //   ?.categoryId == 3
        ) {
          if (
            EPISODE_DETAILS?.episodes?.[0].seriesId ==
              PAY_PER_VIEW_DETAILS?.[3]?.[
                EPISODE_DETAILS?.episodes?.[0].seriesId
              ]?.assetId &&
            // PAY_PER_VIEW_DETAILS?.[EPISODE_DETAILS?.episodes?.[0].seriesId]
            //   ?.categoryId == 3 &&
            new Date()?.getTime() > stopDateTimeStampPPV
          ) {
            // setSignInMessage(true);

            player.pause();
            if (player?.isFullscreen()) {
              player?.exitFullscreen();
            }
            const isPiPMode = getPiPStateFromStorage();

            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            // history.replace(navPath);

            Swal.fire({
              icon: "warning",
              text: "Your subscription for this content has expired",
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
              // cancelButtonText: "May be later",
              // showConfirmButton:
              //   SELECTED_MOVIE_DATA?.isPPVRequired == 1 ? true : false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = navigateLocation;
              }
            });
          }
        }

        player.on("waiting", function() {
          this.addClass("vjs-waiting");
          localStorage.setItem("isPlaying", false);
        });
        player.on("playing", function() {
          this.removeClass("vjs-waiting");
          localStorage.setItem("isPlaying", true);
        });
      });
    }
    return () => {
      if (player) {
        const seekButtonStyle = document.createElement("style");

        document.head.appendChild(seekButtonStyle);
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
        player.pause();
        player?.dispose();
        videoNode.current = null;
      }
    };
  }, [videoNode]);

  useEffect(() => {
    player = videojs(videoNode.current, videoJsOptions);

    let i = 0;
    let timeForAlert;

    player.on("timeupdate", function(e) {
      let adsStatus = localStorage.getItem("adStatus");

      if (player.currentTime() < 1) {
        // console.log(" ooooooooooookmk  it is zerooooo" + player.currentTime());
        timeForAlert = 0;
        i = 0;
      }
      player.on("loadstart", () => {});

      if (
        i == 0 &&
        adsStatus == 0 &&
        player.currentTime() > 0
        //  &&         isVideoAdsAvailable != true &&
        // isVideoAdsAvailable != null
      ) {
        // console.log("!!!!!!!!!!!!     insilde ifff");
        timeForAlert = player.currentTime() + 30;

        i++;
      }
      // });
      let initialDeffrence =
        Number(timeForAlert) - Number(player.currentTime());
      if (initialDeffrence > 30) {
        let deffrence = initialDeffrence - 30;
        timeForAlert = timeForAlert - deffrence;
      }
      let j = 1;
      // console.log(
      //   "time" + timeForAlert + "currunt    time    " + player.currentTime()
      // );
      // console.log("isVideoAdsAvailable" + isVideoAdsAvailable);

      if (timeForAlert != 0 && player.currentTime() > timeForAlert) {
        // alert("jii");
        // console.log(
        //   "ooooooooooookmk" +
        //     player.currentTime() +
        //     "                   " +
        //     timeForAlert
        // );

        if (AUTHENTICATION === true) {
          if (location?.flag == 0) {
            player.pause();
            let text = "";
            if (player?.isFullscreen()) {
              player?.exitFullscreen();
            }
            const isPiPMode = getPiPStateFromStorage();
            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            if (location?.isPPVRequired == 1) {
              // text =
              //   "This is a  Pay Per View Asset. You need to buy it on DishHome Portal to consume it";
              text = "Please subscribe to watch.";
            } else {
              text =
                "Please subscribe or upgrade your package to view this content.";
            }
            Swal.fire({
              icon: "warning",
              text: text,
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe",
              cancelButtonText: "May be later",
              showCancelButton: true,
              showCloseButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(subscribeUrl);
              }
            });

            // document.removeEventListener("keydown", spaceKeyHandler);

            history.replace(navPath);
          }
        }

        if (AUTHENTICATION === false) {
          // console.log("pooja");
          if (player?.isFullscreen()) {
            player?.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          player?.pause();
          // document.removeEventListener("keydown", spaceKeyHandler);
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text:
              "You are not Signed in. Please Sign in to continue watching the video.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          }).then((OK) => {
            if (OK) {
              player.pause();
              // document.removeEventListener("keydown", spaceKeyHandler);
              setSelectedCategory(3);
              history.replace(navPath);
            }
          });
        }

        if (AUTHENTICATION === false) {
        }
      }
    });
  }, [isVideoAdsAvailable, videoAdsState]);
  useEffect(() => {
    if (signInMessage == true) {
      if (AUTHENTICATION == true) {
        if (location?.flag == 0) {
          let text = "";
          const isPiPMode = getPiPStateFromStorage();

          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          if (location?.isPPVRequired != null && location?.isPPVRequired == 1) {
            // text =
            //   "This is a  Pay Per View Asset. You need to buy it on DishHome Portal to consume it";
            text = "Please subscribe to watch.";
          } else {
            text =
              "Please subscribe or upgrade your package to view this content.";
          }

          Swal.fire({
            icon: "warning",
            text: text,
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe",
            cancelButtonText: "May be later",
            showCancelButton: true,
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(subscribeUrl);
            }
          });
        }
      } else if (AUTHENTICATION == false) {
        const isPiPMode = getPiPStateFromStorage();

        if (isPiPMode === true) {
          document.exitPictureInPicture(); // Exit PiP mode when navigating back
        }
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "You are not Signed in. Please Sign in to continue watching the video.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
      }
    }
  }, [signInMessage]);

  return (
    <div className="container">
      <div data-vjs-player>
        <video
          ref={videoNode}
          className="video-js vjs-fluid autoplay"
          controls
          autoplay
        ></video>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  SERIES_ASSETS: seriesAssetSelector,

  EPISODE_DETAILS: getseriesEpisodeDetailsSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  VIDEO_ADS: getgeneralVideoAdsDetailsSelector,
  PLATFORM_ID: platformsSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  DRM_DETAILS: getDrmDetailsSelector,
  GENERAl_POST_ADS: getGeneralAdsDetailSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CONFIGURATION_DAS: configurationSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  WATCHES_HISTORY_DATA: watchedHistorySelector,
});

export default connect(mapStateToProps)(SeriesVideoJs);
