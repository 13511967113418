import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCurrentPlatformSelector } from "../Redux/platforms/selectors";
import { newImageIpPath } from "../App";

const AndroidOs = ({ setIsVideoPlayer, CURRENT_PLATFORM }) => {
  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);
  let product_logo = newImageIpPath + CURRENT_PLATFORM?.productLogo;
  let backgroundUrl = newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage;
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        backgroundImage: `url(${backgroundUrl})`,
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className="col-12"
        style={{ borderTop: "1px solid gray", borderBottom: "1px solid gray" }}
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img src={product_logo} />
        </div>
      </div>
      <div
        style={{
          width: "100%",

          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "white",
          fontSize: "20px",
        }}
      >
        <div style={{ display: "block" }}>
          <div>
            <strong>For use on mobile device</strong> <br />
          </div>
          <div style={{ textAlign: "center" }}>
            <strong>Please use our app</strong>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",

          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        onClick={() =>
          window.open(
            "https://www.google.com/url?sa=t&source=web&rct=j&url=https://play.google.com/store/apps/details%3Fid%3Dcom.conax.golive.dishnepal%26hl%3Den_AU%26referrer%3Dutm_source%253Dgoogle%2526utm_medium%253Dorganic%2526utm_term%253Dcom.conax.golive.dishnepal&ved=2ahUKEwiglL_ZwdX9AhXMg2MGHdFUA4oQFnoECBAQAQ&usg=AOvVaw36YYnFW0_Ctv0POM94TOCAhas context menu"
          )
        }
      >
        {/* <img src="images/playStore1.png" /> */}
        <img src="images/googlePlay.svg" />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
});

export default connect(mapStateToProps)(AndroidOs);
