import types from "./actiontype";

export const update_mobile_info_start = (payload) => {
  //console.log("mobile information calling");
  return {
    type: types.UPDATE_MOBILE_INFO_START,
    payload,
  };
};

export const update_mobile_info_success = (payload) => {
  //console.log("mobile information success");
  return {
    type: types.UPDATE_MOBILE_INFO_SUCCESS,
    payload,
  };
};

export const update_mobile_info_failure = (payload) => {
  //console.log("mobile information failed");
  return {
    type: types.UPDATE_MOBILE_INFO_FAILURE,
    payload,
  };
};
