import ACTION_TYPES from "./actionTypes";
const {
  ADD_FAVOURITES_START,
  ADD_FAVOURITES_SUCCESS,
  ADD_FAVOURITES_FAILED,
} = ACTION_TYPES;

export const addFavouritesStart = (payload) => ({
  type: ADD_FAVOURITES_START,
  payload,
});

export const addFavouritesSuccess = (payload) => ({
  type: ADD_FAVOURITES_SUCCESS,
  payload,
});

export const addFavouritesFailed = (payload) => ({
  type: ADD_FAVOURITES_FAILED,
  payload,
});

// get favourite data
