/** @format */

const ACTION_TYPES = {
  GET_MOVIES_DATA_START: "GET_MOVIES_DATA_START",
  GET_MOVIES_DATA_SUCCESS: "GET_MOVIES_DATA_SUCCESS",
  GET_MOVIES_DATA_FAILED: "GET_MOVIES_DATA_FAILED",


  GET_MOVIE_GENRE_ASSETS_FOR_LISTING: "GET_MOVIE_GENRE_ASSETS_FOR_LISTING",
};

export default ACTION_TYPES;
