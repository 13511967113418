const ACTION_TYPES = {
  GET_ADD_DEVICES_START: "GET_ADD_DEVICES_START",
  GET_ADD_DEVICES_SUCCESS: "GET_ADD_DEVICES_SUCCESS",
  GET_ADD_DEVICES_FAILED: "GET_ADD_DEVICES_FAILED",
  CLEAR_DEVICE_TOCKEN: "CLEAR_DEVICE_TOCKEN",
  SAVE_DEVICE_TOCKEN: "SAVE_DEVICE_TOCKEN",
  SAVE_DEVICE_NAME: "SAVE_DEVICE_NAME",
  CLEAR_DEVICE_STATUS: "CLEAR_DEVICE_STATUS",
};

export default ACTION_TYPES;
