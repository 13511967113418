import { useState } from "react";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { createStructuredSelector } from "reselect";
import { getCurrentPlatformSelector } from "../Redux/platforms/selectors";
import { connect } from "react-redux";
import { newImageIpPath } from "../App";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const NewLoadingPage = ({ CURRENT_PLATFORM }) => {
  // let [loading, setLoading] = useState(true);
  let backgroundUrl = newImageIpPath + CURRENT_PLATFORM?.brandBackgroundImage;

  return (
    <div
      className="sweet-loading"
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundAttachment: "fixed",
        padding: 200,
      }}
    >
      <FadeLoader color="white" loading={true} css={override} size={150} />
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
});

export default connect(mapStateToProps)(NewLoadingPage);
