/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./action";
import * as ACTIVE_API from "./../../services/forgotpasswordService/forgotPassword";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
const { FORGOT_RESET_PASSWORD_START } = ACTION_TYPES;

function* forgotResetPasswordSaga(payload) {
  try {
    const { data } = yield call(ACTIVE_API.forgotPasswordAsync, payload.data);
    // alert(JSON.stringify(data));
    if (data.status == true) {
      // Swal.fire({
      //   icon: "success",
      //   // position: "top-end",
      //   text: data.message,
      //   allowOutsideClick: () => !swal.isLoading(),
      //   confirmButtonColor: "#3085d6",
      //   button: "OK",
      // }).then((OK) => {
      //   if (OK) {
      //     // alert("hhhhh");
      //     payload.history.push("/login");
      //   }
      // });

      swal({
        text: data.message,
        icon: "success",
        closeOnClickOutside: false,
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          payload.history.push("/login");
        }
      });
    } else {
      // swal(data.message);
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: data.message,
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }

    yield put(ACTION.forgotResetPasswordSuccess({ data }));
  } catch ({ message }) {
    // swal(message)
    yield put(ACTION.forgotResetPasswordFailed({ message }));
  }
}

function* forgotResetWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(FORGOT_RESET_PASSWORD_START);
    yield call(forgotResetPasswordSaga, payload);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([forgotResetWatcher()]);
}
