/** @format */

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ProgressBar from "@ramonak/react-progress-bar";

const StyledDiv = styled("div")`
  background-color: gary;

  align-item: center;
`;

const StyledBar = styled("div")`
  background-color: red;
  height: 4px;
  width: ${({ completed }) => completed}%;
`;

//
const ProgressBarr = (props) => {
  return (
    <StyledDiv
      style={{
        borderBottomLeftRadius: "50px",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
      }}
    >
      {/* <StyledBar completed={start_time}  /> */}
      <ProgressBar
        completed={props.completed}
        bgColor="red"
        height="3px"
        isLabelVisible={false}
        animateOnRender={true}
        borderRadius="50px"
        baseBgColor="white"
        width="100%"
      />
    </StyledDiv>
  );
};

ProgressBarr.propTypes = {
  completed: PropTypes.number.isRequired,
};

export default ProgressBarr;
