import ACTION_TYPES from "./actionTypes";
const {
    FORGOT_RESET_PASSWORD_START,
    FORGOT_RESET_PASSWORD_SUCCESS,
    FORGOT_RESET_PASSWORD_FAILED,
    FORGOT_RESET_PASSWORD_STATUS,
} = ACTION_TYPES;

export const forgotResetPasswordStart = (payload) => ({
  type: FORGOT_RESET_PASSWORD_START,
  payload,
});

export const forgotResetPasswordSuccess = (payload) => ({
  type: FORGOT_RESET_PASSWORD_SUCCESS,
  payload,
});

export const forgotResetPasswordFailed = (payload) => ({
  type: FORGOT_RESET_PASSWORD_FAILED,
  payload,
});
export const forgotResetPasswordSTATUS = (payload) => ({
  type: FORGOT_RESET_PASSWORD_STATUS,
  payload,
});