import ACTION_TYPES from "./action.types";

const {
  GET_DRM_DETAILS_START,
  GET_DRM_DETAILS_SUCCESS,
  GET_DRM_DETAILS_FAILED
} = ACTION_TYPES;

/** Platform List */
export const getDrmDetailsStart = () => ({
  type: GET_DRM_DETAILS_START
});

export const getDrmDetailsSuccess = payload => ({
  type: GET_DRM_DETAILS_SUCCESS,
  payload
});

export const getDrmDetailsFailed = payload => ({
  type: GET_DRM_DETAILS_FAILED,
  payload
});
