import ACTION_TYPES from "./action.types";

const {
  GET_PUSH_NOTIFICATION_START,
  GET_PUSH_NOTIFICATION_SUCCESS,
  GET_PUSH_NOTIFICATION_FAILED,
  CURRENT_NOTIFICATIONS,
} = ACTION_TYPES;

/** Platform List */
export const getPushNotificationStart = (payload) => ({
  type: GET_PUSH_NOTIFICATION_START,
  payload,
});

export const getPushNotificationSuccess = (payload) => ({
  type: GET_PUSH_NOTIFICATION_SUCCESS,
  payload,
});

export const getPushNotificationFailed = (payload) => ({
  type: GET_PUSH_NOTIFICATION_FAILED,
  payload,
});

export const getCurrentNotification = (payload) => ({
  type: CURRENT_NOTIFICATIONS,
  payload,
});
