/** @format */

import { all } from "redux-saga/effects";

/** Sagas */
import authSaga from "./auth/sagas";
import platformsSaga from "./platforms/sagas";
import epgSaga from "./EPG/saga";
import liveSaga from "./live/sagas";
import passwordInfoSaga from "./manageProfile/passwordInfo/saga";
import emailInfoSaga from "./manageProfile/emailInfo/saga";
import mobileInfoSaga from "./manageProfile/mobileInfo/saga";
import personalInfoSaga from "./manageProfile/personalInfo/saga";
import favouriteSaga from "./favourites/saga";
import getFavouriteDataSaga from "./FavouteDataRedux/saga";
import movieSaga from "./MoviesRedux/saga";
import activeEmailSaga from "./email-activation/saga";
import resetPasswordSaga from "./resetPassword/saga";
import forgotPasswordSaga from "./forgot-password/saga";
import VideoAdsSaga from "./video-adverticement/sagas";
import PushNotificationSaga from "./PushNotification/sagas";
import AssetDetailsSaga from "./Notication Assets redux/sagas";
import SearchSaga from "./Search/sagas";
import addDeviceSaga from "./deviceRedux/sagas";
import seriesGenreSaga from "./SeriesGenre/saga";
import deleteDeviceSaga from "./Delete Device/sagas";
import DefaultPlatformSaga from "./DefaultPlatform/sagas";
import DrmDetailsSaga from "./DRM integration/sagas";
import checkMailValiditySaga from "./Checking the mail validity/sagas";
import readNotificationSaga from "./readNotificationRedux/sagas";
import channelDataSaga from "./VideoRedux/saga";
import AllLiveAssetSaga from "./AllLiveDetails/sagas";
import packageDetails from "./package&subscriptions/saga";
import latestEPGDetails from "./LatestEPG/saga";
import dataVersionSaga from "./Dataversion/saga";
import DataAnalyticsSaga from "./DATA-analytics/sagas";

import authenticationsaga from "./authentication/sagas";

export default function*() {
  yield all([
    authSaga(),
    platformsSaga(),
    epgSaga(),

    liveSaga(),
    passwordInfoSaga(),
    emailInfoSaga(),
    mobileInfoSaga(),
    personalInfoSaga(),
    favouriteSaga(),
    getFavouriteDataSaga(),
    movieSaga(),
    seriesGenreSaga(),

    activeEmailSaga(),
    resetPasswordSaga(),
    forgotPasswordSaga(),
    VideoAdsSaga(),
    PushNotificationSaga(),
    AssetDetailsSaga(),
    SearchSaga(),
    addDeviceSaga(),
    deleteDeviceSaga(),
    DefaultPlatformSaga(),
    DrmDetailsSaga(),
    checkMailValiditySaga(),
    readNotificationSaga(),
    channelDataSaga(),
    AllLiveAssetSaga(),
    packageDetails(),
    latestEPGDetails(),
    dataVersionSaga(),
    DataAnalyticsSaga(),
    authenticationsaga(),
  ]);
}
