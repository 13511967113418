/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionType";
import * as ACTION from "./actions";
import * as MOVIE_DATA_API from "./../../services/moviesService/movieService";
import * as ACTION_DATA_VERSIONING from "../Dataversion/actions";

const {
  GET_MOVIES_DATA_START,
} = ACTION_TYPES;

function* getMovieDataSaga(payload) {
  try {
    const { data } = yield call(MOVIE_DATA_API.getMovieDataAsync, payload);

    yield put(ACTION.getMoviesDataSuccess(data));
    yield put(ACTION_DATA_VERSIONING.nullDataVersions("movies"));
  } catch ({ message }) {
    yield put(ACTION.getMoviesDataFailed({ message }));
  }
}



function* getMovieDataWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(GET_MOVIES_DATA_START);
    yield call(getMovieDataSaga, payload);
  }
}


export default function* () {
  //console.log("root  saga is calling");
  yield all([
    getMovieDataWatcher(),
    // getBannerWatcher(),
  ]);
}
