import React from "react";
import { CloseButton, Modal } from "react-bootstrap";
const AlertModal = ({ setOpen, open, text }) => {
  // import './examples/custom-styling.css';

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={handleClose}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {" "}
          <CloseButton variant="white" onClick={() => setOpen(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className=" row">
              <div className="col-2" style={{ textAlign: "right" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 24 24"
                  fill="none"
                  color="red"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-alert-triangle"
                >
                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
              </div>
              <div
                className="col-9"
                // style={{
                //   // backgroundColor: "blue",
                //   alignItems: "center",
                //   justifyContent: "center",
                //   // display: "flex",
                //   width: "100%",
                // }}
              >
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                    color: "white",
                    fontSize: "20px",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}
                >
                  {text}
                  {/* Please subscribe or upgrade your package to make it as
                  favourite. */}
                </div>
                {/* <div
                style={{
                  display: "block",
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              >
                Please Sign in to continue watching the video
              </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertModal;
