/** @format */

import ACTION_TYPES from "./actionTypes";

const { GET_SELECTED_MOVIE_DATA_FOR_PLAYER } = ACTION_TYPES;

const initialState = {
  selectMovieForPlayer: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SELECTED_MOVIE_DATA_FOR_PLAYER:
      return {
        ...state,
        selectMovieForPlayer: payload,
      };

    default:
      return state;
  }
};
