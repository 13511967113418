import ACTION_TYPES from "./actionTypes";
const {
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_STATUS,
} = ACTION_TYPES;


const initialState = {
 passwordInfo:{},
 reserPasswordStatus:false,
  isLoading: false,
  errors: "",
  
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  //console.log("reducer is calling");

  switch (type) {
    case RESET_PASSWORD_START:
     

      return {
        ...state,
        isLoading: true,
        errors: "",
      };
    case RESET_PASSWORD_SUCCESS:
    
     
      return {
        ...state,
        passwordInfo:payload,
        reserPasswordStatus:true,

        isLoading,
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        reserPasswordStatus:false,
      };

      case RESET_PASSWORD_STATUS:
        return {
          ...state,
          isLoading,
          reserPasswordStatus:true
          
        };
     
     
    default:
      return state;
  }
};
