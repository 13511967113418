import React, { useEffect, useRef, useState } from "react";
import "./../skins/chrome/videojs.min.css";
import "./../skins/chrome/ima.css";
import "./../skins/chrome/videojs.css";
import "./../skins/chrome/ima.min.css";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "../loader/loadingPlugin.js";
import "../retryPlugin/retry.js";
import "../LatestVideoJsPlugin/LivePlugin/playlistLivePlugin.js"; // Import the loading plugin
import "../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../LatestVideoJsPlugin/keywordControlPluginWithIcons.js";
import "videojs-contrib-eme";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import * as GET_PLAY_LIST from "./getPlayList";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch } from "react-redux";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { getDrmDetailsSelector } from "../../Redux/DRM integration/selectors";
import { getLiveChannelDataSelector } from "../../Redux/VideoRedux/selector";
import {
  getAllAssetsIdSelector,
  getAllLiveAssetDetailsSelector,
  getGeneralAdsDetailSelector,
  getgeneralVideoAdsDetailsSelector,
} from "../../Redux/AllLiveDetails/selectors";
import { baseName, subscribeUrl } from "../IpConstantFile/IpConstantFile";
import { useHistory, useLocation } from "react-router-dom";
import { getDeviceStatusSelector } from "../../Redux/Delete Device/selectors";
import * as VIDEO_ACTION from "../../Redux/VideoRedux/action";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../Redux/package&subscriptions/selector";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../Redux/package&subscriptions/actions";
import {
  getCurrentTimeZone,
  getPiPStateFromStorage,
  newImageIpPath,
} from "../../App.js";
import { configurationSelector } from "../../Redux/DATA-analytics/selector.js";
// import "./DisableProgressBar.js";

import { wsLive } from "../../App.js";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors.js";
import { getLatestEpgDetailsSelector } from "../../Redux/LatestEPG/selector.js";
import { getDefaultPlatformsSelector } from "../../Redux/DefaultPlatform/selectors.js";
import { getSaveDeviceTockenSelector } from "../../Redux/SaveDeviceTocken/selectors.js";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../services/datAnalytics/dataAnalytics.js";
const NewVideoPlayerJs = ({
  DEVICE_NAME,
  DEVICE_TOKEN,
  DRM_DETAILS,
  CHANNEL_DATA,
  ASSET_DETAILS,
  ASSET_IDs_BASED_ON_LCN,
  CURRENT_PLATFORM,
  VIDEO_ADS,
  DEVICE_STATUS,
  GENERAl_POST_ADS,
  PACKAGE_DETAILS,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  CONFIGURATION_DAS,
  USER_DETAILS,
  AUTHENTICATION,
  LATEST_EPG_DETAILS,
  DEFAULT_PLATFORM,
  SAVED_DEVICE_TOCKEN,
  setSelectedCategory,
}) => {
  const history = useHistory();
  let errorOccurred = false;
  const timeZone = getCurrentTimeZone();
  const flagRef = useRef(false);
  let platformLogo = newImageIpPath + CURRENT_PLATFORM?.productLogo;

  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  var currentTrackNo = 0;
  let adsEnabledForDefaultPlatform;
  if (DEFAULT_PLATFORM != undefined && DEFAULT_PLATFORM != null) {
    adsEnabledForDefaultPlatform = DEFAULT_PLATFORM?.data?.data?.isAdsEnabled;
  }
  const adsEnabled = AUTHENTICATION
    ? Object.values(PACKAGE_DETAILS).some(
        (item) => item?.assetGroup?.isAdsEnabled === 1
      )
    : adsEnabledForDefaultPlatform;

  const dispatch = useDispatch();
  const [isVideoAdsAvailable, setIsVideoAdAvailable] = useState(null);
  useEffect(() => {
    // console.log(
    //   "isVideoAdsAvailable99 !" + JSON.stringify(isVideoAdsAvailable)
    // );
  }, [isVideoAdsAvailable]);
  // console.log("PLATFORM_ID" + JSON.stringify(PLATFORM_ID));
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);

  const [errorMessage, setErrorMessage] = useState(false);
  var startTime, endTime;

  let indexOfSelectedChannel;
  ASSET_IDs_BASED_ON_LCN?.filter((item, index) => {
    if (item == CHANNEL_DATA?.id) {
      indexOfSelectedChannel = index;
      return;
    }
  });

  useEffect(() => {
    let pooja = isVideoAdsAvailable;
    // console.log("ooooooooooookmk" + isVideoAdsAvailable);
  }, [isVideoAdsAvailable]);
  let location = useLocation().state;
  let navigateLocation = null;
  let navPath = null;
  console.log("location.from" + location.from);
  useEffect(() => {
    if (AUTHENTICATION === true) {
      if (location.from == "mylist") {
        navigateLocation = baseName + "mylistcontainer";
        navPath = "/mylistcontainer";
      } else if (location.from == "liveGenreCatogory") {
        navigateLocation = baseName + "genreContainer";
        navPath = "/genreContainer";
      } else {
        navigateLocation = baseName + "container";
        navPath = "/container";
      }
    } else {
      if (location.from == "liveGenreCatogory") {
        navigateLocation = baseName + "genreContainer";
        navPath = "/genreContainer";
      } else {
        if (defaultLandingPage == 1) {
          navPath = "";
          navigateLocation = baseName;
        } else {
          navPath = "/container";
          navigateLocation = baseName + "container";
        }
      }
    }
  }, []);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    // liveui: "true",
    liveui: AUTHENTICATION === true ? true : false,
    fluid: true,
    preload: "auto",
    buttonForward: true,
    aspectRatio: "16:8",
    playlistPicker: true,
    qualitySelector: false, // Disable quality selector

    controlBar: {
      volumePanel: {
        inline: false, // Horizontal volume control
      },
    },
    // controls: true,
    // autoplay: true,
    // controlBar: {
    //   playToggle: true, // Play/Pause button
    //   currentTimeDisplay: true, // Current time
    //   durationDisplay: true, // Duration
    //   remainingTimeDisplay: false, // Remaining time
    //   progressControl: true, // Seek bar
    //   volumePanel: {
    //     inline: false, // Horizontal volume control
    //   },
    //   fullscreenToggle: true, // Fullscreen button
    //   pictureInPictureToggle: true, // PiP button
    //   liveDisplay: true, // Display 'Live' label
    //   liveui: true, // UI for live video
    //   seekForward: true, // Custom seek forward button (requires custom implementation)
    //   seekBackward: true, // Custom seek backward button (requires custom implementation)
    // },
    // autoplay: true,
    // controls: true,
    // responsive: true,
    // controlBar: {
    //   volumePanel: {
    //     inline: false, // Set to true for a horizontal volume control
    //   },
    // },
    // // liveui: "true",
    // liveui: AUTHENTICATION === true && IsSuscribed === true ? true : false,
    // fluid: true,
    // preload: "auto",
    // buttonForward: true,
    // buttonbackward: true,
    // aspectRatio: "16:8",
    // playlistPicker: true,
    // qualitySelector: false, // Disable quality selector

    // controlBar: {
    //   children: [
    //     "skipBackward", // Add a skip backward button (custom)
    //     "playToggle", // Play/pause button
    //     "skipForward", // Add a skip forward button (custom)
    //     "currentTimeDisplay",
    //     "progressControl",
    //     "durationDisplay",
    //     "muteToggle",
    //     "volumeControl",
    //     "pictureInPictureToggle", // Add Picture-in-Picture button

    //     "fullscreenToggle",
    //   ],
    // },
  };

  let player;

  const videoNode = useRef(null);
  const spaceKeyHandler = (event) => {
    if (event.key === " ") {
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    }
  };

  useEffect(() => {
    player = videojs(
      videoNode.current,
      videoJsOptions,

      function onPlayerReady() {
        var myPlayer = this;
        dispatch(
          VIDEO_ACTION.getLiveChannelData(
            ASSET_DETAILS[player.getCurrentPlaylistItem().id]
          )
        );
        myPlayer.on("error", function() {
          errorOccurred = true;

          var error = myPlayer.error();
          console.log("Video.js Error:", error);
          var playlistButton = document.getElementsByClassName(
            "vjs-playlist-icon-button"
          );

          if (playlistButton.length > 0) {
            playlistButton[0].style.display = "block";
          }

          player.pause();
          document.removeEventListener("keydown", spaceKeyHandler);

          // if (error.code == 4) {
          // player.vjs_error_page(player, navigateLocation);
          // }
        });
      }
    );
    let time;
    let i = 0;

    function setPiPStateInStorage(isPiPMode) {
      localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
    }

    document.addEventListener("enterpictureinpicture", () => {
      // PiP mode entered
      setPiPStateInStorage(true);
    });

    document.addEventListener("leavepictureinpicture", () => {
      // PiP mode exited
      setPiPStateInStorage(false);
    });

    player.eme();
    player.keywordControlPluginWithIcons();

    player.loadingPlugin();
    player.errorOverlayPlugin(navigateLocation);
    player.displayPlatFormLogo(platformLogo);

    let playListDetails;

    if (window.WebKitMediaKeys) {
      playListDetails = GET_PLAY_LIST.getPlayListForFairplay({
        ASSET_DETAILS,
        ASSET_IDs_BASED_ON_LCN,
        DRM_DETAILS,
        PACKAGE_DETAILS,
        ALACARTE_DETAILS,
        PAY_PER_VIEW_DETAILS,
        LATEST_EPG_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        VIDEO_ADS,
        adsEnabled,
        AUTHENTICATION,
        USER_DETAILS,
      });
    } else if (window.MediaKeys) {
      playListDetails = GET_PLAY_LIST.getPlayList({
        ASSET_DETAILS,
        ASSET_IDs_BASED_ON_LCN,
        DRM_DETAILS,
        PACKAGE_DETAILS,
        ALACARTE_DETAILS,
        PAY_PER_VIEW_DETAILS,
        LATEST_EPG_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        VIDEO_ADS,
        adsEnabled,
        AUTHENTICATION,
        USER_DETAILS,
      });
    } else {
      playListDetails = GET_PLAY_LIST.getPlayList({
        ASSET_DETAILS,
        ASSET_IDs_BASED_ON_LCN,
        DRM_DETAILS,
        PACKAGE_DETAILS,
        ALACARTE_DETAILS,
        PAY_PER_VIEW_DETAILS,
        LATEST_EPG_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        VIDEO_ADS,
        adsEnabled,
        AUTHENTICATION,
        USER_DETAILS,
      });
    }
    // console.log("playListDetailsinPLayer23" + JSON.stringify(playListDetails));

    player.playlistLivePlugin(playListDetails, indexOfSelectedChannel);
    console.log(
      "abcdefgh hasPreroll" + JSON.stringify(player?.getCurrentPlaylistItem())
    );

    const seekButtonStyle = document.createElement("style");
    document.head.appendChild(seekButtonStyle);
    const progressControl = player.controlBar.getChild("progressControl");

    if (
      AUTHENTICATION === true &&
      player.getCurrentPlaylistItem().flag == 1 &&
      player.getCurrentPlaylistItem().catchup == 1
    ) {
      if (progressControl) {
        progressControl.enable();
      }
      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
    } else {
      const progressControl = player.controlBar.getChild("progressControl");
      if (progressControl) {
        progressControl.disable();
      }
      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
    }

    // Add the dispose event listener

    player.on("posterAdplaying", function(event) {
      // Access the data in the event's detail property

      const adData = event.detail;
      if (AUTHENTICATION) {
        let data2 = {
          adsAnalyticsData: {
            addId: adData.id,

            userId: USER_DETAILS?.id,

            platformId: CURRENT_PLATFORM?.orgId,

            profileId: 1,

            deviceId: DEVICE_TOKEN,

            categoryId: 1,
            assetId: player.getCurrentPlaylistItem().id,
            timeZone: timeZone,
          },
          ServerIp: CONFIGURATION_DAS?.analyticsIp,
        };

        getSendingDataOfPosteradsAsync(data2);
      }
    });
    player.on("adplaying", function(event) {
      console.log("adplaying"); // This will log the ad id

      // Access the data in the event's detail property
      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";

      localStorage.setItem("adStatus", 1);
    });
    player.on("adended", function(event) {
      const adData = event.detail;
      console.log("adendedadendedadended" + adData.id); // This will log the ad id

      if (AUTHENTICATION) {
        let data2 = {
          adsAnalyticsData: {
            addId: adData.id,

            userId: USER_DETAILS?.id,

            platformId: CURRENT_PLATFORM?.orgId,

            profileId: 1,

            deviceId: DEVICE_TOKEN,

            categoryId: 1,
            assetId: player.getCurrentPlaylistItem().id,
            timeZone: timeZone,
          },
          ServerIp: CONFIGURATION_DAS?.analyticsIp,
        };

        getSendingDataOfVideoadsAsync(data2);
      }
      if (
        AUTHENTICATION === true &&
        player.getCurrentPlaylistItem().flag == 1 &&
        player.getCurrentPlaylistItem().catchup == 1
      ) {
        if (progressControl) {
          progressControl.enable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
      } else {
        if (progressControl) {
          progressControl.disable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
      }

      setIsVideoAdAvailable(false);

      localStorage.setItem("adStatus", 0);
    });
    player.on("playlist_change", function() {
      console.log(
        "player.getCurrentPlaylistItem()" +
          JSON.stringify(player.getCurrentPlaylistItem())
      );

      dispatch(
        VIDEO_ACTION.getLiveChannelData(
          ASSET_DETAILS[player.getCurrentPlaylistItem().id]
        )
      );
      if (
        AUTHENTICATION === true &&
        player.getCurrentPlaylistItem().flag == 1 &&
        player.getCurrentPlaylistItem().catchup == 1
      ) {
        if (progressControl) {
          progressControl.enable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
      } else {
        if (progressControl) {
          progressControl.disable();
        }
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
      }
    });

    let videoAdsArray = player?.getCurrentPlaylistItem().adConfig;
    const hasPreroll = videoAdsArray.some((ad) => parseInt(ad.time) == 0);
    console.log("videoAdsArray" + JSON.stringify(videoAdsArray));
    if (videoAdsArray?.length > 0 && hasPreroll) {
      setIsVideoAdAvailable(true);
      // console.log("abcdefgh hasPreroll");
      localStorage.setItem("adStatus", 1);

      // console.log("hasPreroll" + hasPreroll);
    } else {
      // console.log("abcdefgh no ads");

      setIsVideoAdAvailable(false);
      localStorage.setItem("adStatus", 0);
    }

    player.on("loadeddata", function() {
      let documetTitle =
        player.getCurrentPlaylistItem().epgTitle != undefined &&
        player.getCurrentPlaylistItem().epgTitle != null
          ? " - " + player.getCurrentPlaylistItem().epgTitle
          : "";
      document.title = player.getCurrentPlaylistItem().title + documetTitle;

      let liveAssetId = localStorage.getItem("liveAssetId");

      localStorage.setItem("inLiveVidioPlayer", "liveVideoPlayer");

      if (wsLive != null && player.getCurrentPlaylistItem().flag == 1) {
        if (liveAssetId == null) {
          localStorage.setItem(
            "liveAssetId",
            player.getCurrentPlaylistItem().id
          );
          // console.log(
          //   "ArunKumaraaaa" +
          //     player.getCurrentPlaylistItem().id
          // );

          wsLive?.send(
            JSON.stringify({
              type: "open",
              deviceId: DEVICE_TOKEN,
              assetId: player.getCurrentPlaylistItem().id,
            })
          );
          // console.log(
          //   "ArunKumarbbbb" +
          //     player.getCurrentPlaylistItem().id
          // );
          localStorage.setItem(
            "ConcurrentliveAssetId",
            player.getCurrentPlaylistItem().id
          );
        }
        // console.log("aaaa" + liveAssetId);
        else if (liveAssetId != player.getCurrentPlaylistItem().id) {
          wsLive.send(
            JSON.stringify({
              type: "close",
              deviceId: DEVICE_TOKEN,
              assetId: liveAssetId,
            })
          );
          localStorage.removeItem("ConcurrentliveAssetId");
          // console.log("ArunKumarcccc" + liveAssetId);
          localStorage.setItem(
            "liveAssetId",
            player.getCurrentPlaylistItem().id
          );

          var id = player.getCurrentPlaylistItem().id;
          // console.log("DEVICE_TOKEN", DEVICE_TOKEN);
          var timer = setTimeout(() => {
            wsLive.send(
              JSON.stringify({
                type: "open",
                deviceId: DEVICE_TOKEN,
                assetId: id,
              })
            );
            localStorage.setItem("ConcurrentliveAssetId", id);
            // console.log("ArunKumardddd" + id);
          }, 2000);
          return () => {
            clearTimeout(timer);
          };
        }
      }
    });

    player.ready(function() {
      player.on("loadedmetadata", function() {
        localStorage.setItem("isPlaying", true);

        startTime = new Date().getTime();

        // alert("meta data loaded");
        // var audioTracks = player.audioTracks();
        // if (audioTracks.length == 1) {
        //   audioTracks[0].enabled = true;
        // } else if (audioTracks.length > 1) {
        //   audioTracks[currentTrackNo].enabled = true;
        // }
        // //alert(audioTracks.length);
        // //audioTracks[currentTrackNo].enabled = true;
        // audioTracks.addEventListener("change", function() {
        //   for (var i = audioTracks.length - 1; i >= 0; i--) {
        //     var track = audioTracks[i];
        //     if (track.enabled) {
        //       currentTrackNo = i;
        //     }
        //   }
        // });
      });
      let n = 0;
      player.on("timeupdate", function(e) {
        if (AUTHENTICATION === true && flagRef.current != false) {
          document.addEventListener("keydown", spaceKeyHandler);
        }
        if (player.getCurrentPlaylistItem()?.flag == 1) {
          flagRef.current = true;
        }
        if (player.getCurrentPlaylistItem()?.flag == 0) {
          flagRef.current = false;
        }

        if (
          player.getCurrentPlaylistItem()?.id ==
            PAY_PER_VIEW_DETAILS?.[1]?.[player.getCurrentPlaylistItem()?.id]
              ?.assetId &&
          // PAY_PER_VIEW_DETAILS?.[
          //   player.getCurrentPlaylistItem()?.id
          // ]?.categoryId == 1 &&
          n == 0
        ) {
          let stopDatePPV =
            PAY_PER_VIEW_DETAILS?.[1]?.[player.getCurrentPlaylistItem()?.id]
              ?.expiryDate;
          let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

          if (
            player.getCurrentPlaylistItem()?.id ==
              PAY_PER_VIEW_DETAILS?.[1]?.[player.getCurrentPlaylistItem()?.id]
                ?.assetId &&
            // PAY_PER_VIEW_DETAILS?.[
            //   player.getCurrentPlaylistItem()?.id
            // ]?.categoryId == 1 &&
            new Date()?.getTime() > stopDateTimeStampPPV &&
            n == 0
          ) {
            player.pause();
            document.removeEventListener("keydown", spaceKeyHandler);

            history.replace(navPath);
            setSelectedCategory(1);
          }
          if (
            player.getCurrentPlaylistItem()?.id ==
              PAY_PER_VIEW_DETAILS?.[1]?.[player.getCurrentPlaylistItem()?.id]
                ?.assetId &&
            // PAY_PER_VIEW_DETAILS?.[
            //   player.getCurrentPlaylistItem()?.id
            // ]?.categoryId == 1 &&
            new Date()?.getTime() > stopDateTimeStampPPV &&
            n == 0
          ) {
            n = 1;
            const isPiPMode = getPiPStateFromStorage();

            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            if (player?.isFullscreen()) {
              player?.exitFullscreen();
            }

            Swal.fire({
              icon: "warning",
              text: "Your subscription for this content has expired",
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
              // cancelButtonText: "May be later",
              // showConfirmButton:
              //   SELECTED_MOVIE_DATA?.isPPVRequired == 1 ? true : false,
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(
                  PACKAGE_SUBSCRIPTION_ACTION.getPackageDetailsStart({
                    userId: USER_DETAILS?.id,
                  })
                );
                // history.replace("/container");
                setSelectedCategory(1);
                window.location.href = navigateLocation;
              }
            });
          }
        }

        // --------

        player.on("waiting", function() {
          sessionStorage.setItem("buffering", true);

          // console.log("waiiiiiiiiiiiiiiiiiiiiting");
          this.addClass("vjs-waiting");
        });
        // player.on("waiting", function() {
        //   this.addClass("vjs-waiting");
        //   // player.reconnect();
        // });
        player.on("playing", function() {
          this.removeClass("vjs-waiting");
          sessionStorage.setItem("buffering", false);
        });
      });
    });

    return () => {
      if (player) {
        document.removeEventListener("keydown", spaceKeyHandler);

        const seekButtonStyle = document.createElement("style");

        document.head.appendChild(seekButtonStyle);
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
        // console.log("player disposeddddddddddddd !!!!!!!!!!!!!!!");
        // ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";

        player?.pause();
        // player.src(""); // empty string

        player?.dispose();

        videoNode.current = null;
      }
    };
  }, [videoNode]);
  let dataAnalytics = {};

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let LiveIntervalForSendingDataToLocal = setInterval(
        () => {
          // Check if sessionStorage.getItem("buffering") is false
          if (sessionStorage.getItem("buffering") === "false") {
            let dataObject =
              JSON.parse(localStorage.getItem("MovieDataToLocal")) || {};

            if (
              dataAnalytics &&
              typeof dataAnalytics === "object" &&
              JSON.parse(localStorage.getItem("isPlaying")) == true
            ) {
              // console.log("dataAnalytics.duration" + dataAnalytics.duration);

              if (
                dataAnalytics.assetId !== undefined &&
                dataAnalytics.categoryId !== undefined
              ) {
                const uniqueKey =
                  dataAnalytics.assetId + "_" + dataAnalytics.categoryId;

                // If the data already exists in local storage, add the new duration to the existing one
                if (dataObject[uniqueKey]) {
                  dataObject[uniqueKey].duration += dataAnalytics.duration;
                } else {
                  // Otherwise, create a new entry in the object
                  dataObject[uniqueKey] = dataAnalytics;
                }

                // Update local storage with the modified object
                localStorage.setItem(
                  "MovieDataToLocal",
                  JSON.stringify(dataObject)
                );
              }
              startTime = new Date().getTime();
            }
          }
        },
        CONFIGURATION_DAS?.frequencyForStoringDataInApp == undefined
          ? 30000
          : CONFIGURATION_DAS?.frequencyForStoringDataInApp
      );

      return () => {
        clearInterval(LiveIntervalForSendingDataToLocal);
      };
    }
  }, []);

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let interval = setInterval(
        () => {
          let dataAnalyticsForAPI = {};
          if (
            CURRENT_PLATFORM != undefined &&
            CURRENT_PLATFORM != null &&
            USER_DETAILS != undefined &&
            USER_DETAILS != null &&
            DEVICE_TOKEN != null &&
            DEVICE_TOKEN != undefined
          ) {
            dataAnalyticsForAPI = {
              platformId: CURRENT_PLATFORM?.orgId,
              profileId: 1,
              deviceId: DEVICE_TOKEN,
              timeZone: timeZone,
              userId: USER_DETAILS?.id,
              assets: {},
            };
          }
          let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));
          if (dataObject && typeof dataObject === "object") {
            const keys = Object.keys(dataObject);
            let finalData = [];

            keys.forEach((a) => {
              finalData?.push(dataObject?.[a]);
            });

            // console.log("dataObject" + JSON.stringify(finalData));
            if (finalData != undefined && finalData != null) {
              dataAnalyticsForAPI.assets = JSON.stringify(finalData);
            }

            // console.log("MovieDataToLocal" + JSON.stringify(MovieData));
            // alert("in LIVE Player sending Server" + JSON.stringify(dataObject));

            let data2;
            if (
              USER_DETAILS != undefined &&
              USER_DETAILS != null &&
              CONFIGURATION_DAS != null &&
              CONFIGURATION_DAS != undefined
            ) {
              data2 = {
                dataAnalyticsForAPI: dataAnalyticsForAPI,
                ServerIp: CONFIGURATION_DAS?.analyticsIp,
              };
            }
            dispatch(DATA_ANALYTICS.sendingDataToDasStart(data2));
            // startTime = new Date().getTime();
          }
        },
        CONFIGURATION_DAS?.frequencyForSendingDataToServer == undefined
          ? 300000
          : Number(CONFIGURATION_DAS?.frequencyForSendingDataToServer) + 6500
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    player = videojs(videoNode.current, videoJsOptions);

    let i = 0;
    let timeForAlert;

    player.on("timeupdate", function(e) {
      player.on("play", function() {
        localStorage.setItem("isPlaying", true);

        // Additional actions when the video starts playing
      });

      player.on("pause", function() {
        localStorage.setItem("isPlaying", false);
        // Additional actions when the video is paused
      });

      endTime = new Date().getTime();
      var timeSpent;
      var analyticsDataArray = [];
      if (startTime !== null) {
        timeSpent = (endTime - startTime) / 1000;
        // console.log("timeSpent" + timeSpent);
        // Check if timeSpent is a valid number
        if (!isNaN(timeSpent) && isFinite(timeSpent)) {
          if (
            player.getCurrentPlaylistItem().id !== null &&
            player.getCurrentPlaylistItem().id !== undefined &&
            player.getCurrentPlaylistItem().flag == 1
          ) {
            dataAnalytics = {
              assetId: player.getCurrentPlaylistItem().id,
              categoryId: 1,
              duration: timeSpent,
            };
          }

          // Push the data or save it as needed
          // For example, assuming you have an array to store multiple analytics data
          analyticsDataArray.push(dataAnalytics);
        }
      }
      let adsStatus = localStorage.getItem("adStatus");

      if (player.currentTime() < 1) {
        // console.log(" ooooooooooookmk  it is zerooooo" + player.currentTime());
        timeForAlert = 0;
        i = 0;
      }
      player.on("loadstart", () => {});

      if (
        i == 0 &&
        adsStatus == 0 &&
        player.currentTime() > 0
        //  &&         isVideoAdsAvailable != true &&
        // isVideoAdsAvailable != null
      ) {
        // console.log("!!!!!!!!!!!!     insilde ifff");
        timeForAlert = player.currentTime() + 30;

        i++;
      }
      // });
      let initialDeffrence =
        Number(timeForAlert) - Number(player.currentTime());
      if (initialDeffrence > 30) {
        let deffrence = initialDeffrence - 30;
        timeForAlert = timeForAlert - deffrence;
      }
      let j = 1;
      // console.log(
      //   "time" + timeForAlert + "currunt    time    " + player.currentTime()
      // );
      // console.log("isVideoAdsAvailable" + isVideoAdsAvailable);

      if (timeForAlert != 0 && player.currentTime() > timeForAlert) {
        // alert("jii");
        // console.log(
        //   "ooooooooooookmk" +
        //     player.currentTime() +
        //     "                   " +
        //     timeForAlert
        // );

        if (AUTHENTICATION === true) {
          if (player.getCurrentPlaylistItem().flag == 0) {
            player.pause();
            let text = "";
            const isPiPMode = getPiPStateFromStorage();

            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            if (
              ASSET_DETAILS[player.getCurrentPlaylistItem().id].isPPVRequired ==
              1
            ) {
              // text =
              //   "This is a  Pay Per View Asset. You need to buy it on DishHome Portal to consume it";
              text = "Please subscribe to watch.";
            } else {
              text =
                "Please subscribe or upgrade your package to view this content.";
            }
            Swal.fire({
              icon: "warning",
              text: text,
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe",
              cancelButtonText: "May be later",
              showCancelButton: true,
              showCloseButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(subscribeUrl);
              }
            });

            document.removeEventListener("keydown", spaceKeyHandler);

            history.replace(navPath);
          }
        }

        if (AUTHENTICATION === false) {
          // console.log("pooja");
          player.pause();
          if (player.isFullscreen()) {
            player.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          document.removeEventListener("keydown", spaceKeyHandler);
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text:
              "You are not Signed in. Please Sign in to continue watching the video.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          }).then((OK) => {
            if (OK) {
              player.pause();
              document.removeEventListener("keydown", spaceKeyHandler);
              setSelectedCategory(1);
              history.replace(navPath);
            }
          });
        }

        if (AUTHENTICATION === false) {
        }
      }
    });
  }, [isVideoAdsAvailable]);

  useEffect(() => {
    if (errorMessage == true) {
      if (AUTHENTICATION === true) {
        history.replace(navPath);
        setSelectedCategory(1);
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "Please subscribe or upgrade your package to view this content.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        }).then((OK) => {
          if (OK) {
            // alert("hhhhh");
            // player.pause();

            player.pause();
            document.removeEventListener("keydown", spaceKeyHandler);
            setSelectedCategory(1);
            history.replace(navPath);
          }
        });
      }

      if (AUTHENTICATION === false) {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "You are not Signed in. Please Sign in to continue watching the video.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        }).then((OK) => {
          if (OK) {
            player.pause();
            document.removeEventListener("keydown", spaceKeyHandler);
            setSelectedCategory(1);
            history.replace(navPath);
          }
        });
      }
    }
  }, [errorMessage]);

  return (
    <div className="container">
      <div data-vjs-player>
        <video
          liveui
          ref={videoNode}
          className="video-js vjs-fluid liveui"
          controls
        ></video>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM_ID: platformsSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DRM_DETAILS: getDrmDetailsSelector,
  CHANNEL_DATA: getLiveChannelDataSelector,
  ASSET_DETAILS: getAllLiveAssetDetailsSelector,
  ASSET_IDs_BASED_ON_LCN: getAllAssetsIdSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  VIDEO_ADS: getgeneralVideoAdsDetailsSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  GENERAl_POST_ADS: getGeneralAdsDetailSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CONFIGURATION_DAS: configurationSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  LATEST_EPG_DETAILS: getLatestEpgDetailsSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
});
export default connect(mapStateToProps)(NewVideoPlayerJs);
