import types from "./actiontype";

export const update_password_info_start = (payload) => {
  //console.log("password information is calling");
  return {
    type: types.UPDATE_PASSWORD_INFO_START,
    payload,
  };
};

export const update_password_info_success = (payload) => {
  //console.log("password information is success");
  return {
    type: types.UPDATE_PASSWORD_INFO_SUCCESS,
    payload,
  };
};

export const update_password_info_failure = (payload) => {
  //console.log("password is failure");
  return {
    type: types.UPDATE_PASSWORD_INFO_FAILURE,
    payload,
  };
};
