/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import { getEmailInfoAsync } from "./../../services/manageprofile/manageProfileService";
import * as AUTHENTICATION_ACTIONS from "../../Redux/authentication/actions";

// import { Alert } from "react-native";
const { READ_NOTIFICATION_START } = ACTION_TYPES;

function* getReadNotificationSaga(payload) {
  try {
    const { data } = yield call(getEmailInfoAsync, payload);
    //console.log("default platform data       ", JSON.stringify(data.data));
    if (data.status === true) {
      yield put(ACTION.getReadNotificationSuccess(data));
      yield put(AUTHENTICATION_ACTIONS.userDetailsAction({ id: payload.id }));
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.getReadNotificationFailed({ message }));
  }
}

function* getReadNotificationWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(READ_NOTIFICATION_START);
    yield call(getReadNotificationSaga, payload);
  }
}

function* getPrivacyPolicyStatusSaga(payload) {
  try {
    const { data } = yield call(getEmailInfoAsync, payload?.data);
    //console.log("default platform data       ", JSON.stringify(data.data));

    if (data.status === true) {
      yield put(ACTION.privacyPolicyStatusSuccess(data));

      // yield put(LOGIN_ACTION.loginStart(payload?.credential));
      yield put(
        AUTHENTICATION_ACTIONS.userDetailsStart({
          id: payload?.credential?.credentials?.id,
          history: payload?.credential?.history,
          navigationPath: payload?.credential?.navigationPath,

          newDeviceTocken: payload?.credential?.credentials?.newDeviceTocken,
          oldDeviceToken: payload?.credential?.credentials?.deviceToken,
        })
      );

      //  dispatch(
      //    AUTHENTICATION_ACTIONS.userDetailsStart({
      //      id: USER_DETAILS?.id,
      //      history: history,
      //      navigationPath: navigationPath,

      //      newDeviceTocken: SAVED_DEVICE_TOCKEN,
      //      oldDeviceToken: SAVED_DEVICE_TOCKEN,
      //    })
      //  );
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.privacyPolicyStatusFailed({ message }));
  }
}

function* getPrivacyPolicyWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(ACTION_TYPES.PRIVACY_POLICY_STATUS_START);
    yield call(getPrivacyPolicyStatusSaga, payload);
  }
}

export default function*() {
  yield all([getReadNotificationWatcher(), getPrivacyPolicyWatcher()]);
}
