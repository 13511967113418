import ACTION_TYPES from "./action.types";

const { SAVE_DEVICE_TCOKEN } = ACTION_TYPES;

const initialState = {
  deviceTocken: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_DEVICE_TCOKEN:
      return {
        ...state,
        deviceTocken: payload,
      };

    default:
      return state;
  }
};
