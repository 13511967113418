import ACTION_TYPES from "./action.types";

const {
  GET_VIDEO_ADVERTICEMENT_START,
  GET_VIDEO_ADVERTICEMENT_SUCCESS,
  GET_VIDEO_ADVERTICEMENT_FAILED,
 
} = ACTION_TYPES;

/** Platform List */
export const getVideoAdverticementStart = () => ({
  type: GET_VIDEO_ADVERTICEMENT_START,
  
});

export const getVideoAdverticementSuccess = (payload) => ({
  type: GET_VIDEO_ADVERTICEMENT_SUCCESS,
  payload,
 
});

export const getVideoAdverticementFailed = (payload) => ({
  type: GET_VIDEO_ADVERTICEMENT_FAILED,
  payload,
});

