/** @format */

import { createSelector } from "reselect";

const getSeriesAssets = (state) => state.seriesGenre;
const getSeriesAssetsGenres = (state) => state.seriesGenre.genreDetails;
const getSeriesAssetsFOrListing = (state) =>
  state.seriesGenre.seriesGenreAssetForListing;
const getSeriesAssetsLoading = (state) => state.seriesGenre.seriesLoading;

const getseriesEpisodeDetails = (state) =>
  state.seriesGenre.seriesEpisodeDetails;

const getAssetLength = (state) => state.seriesGenre.AssetLength;
export const getAssetLengthSelector = createSelector(
  getAssetLength,
  (AssetLength) => AssetLength
);

export const getseriesEpisodeDetailsSelector = createSelector(
  getseriesEpisodeDetails,
  (seriesEpisodeDetails) => seriesEpisodeDetails
);

export const getSeriesAssetsLoadingSelector = createSelector(
  getSeriesAssetsLoading,
  (seriesLoading) => seriesLoading
);

export const getSeriesAssetsFOrListingSelector = createSelector(
  getSeriesAssetsFOrListing,
  (seriesGenreAssetForListing) => seriesGenreAssetForListing
);

export const seriesAssetSelector = createSelector(
  getSeriesAssets,
  (seriesGenre) => seriesGenre
);

export const seriesGenresSelector = createSelector(
  getSeriesAssetsGenres,
  (genreDetails) => genreDetails
);
