/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const signupAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post("/registerByUser", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error.response.data);
    });
};

export const logoutAsync = async () => {
  return await axiosInstance()
    .post("/logout")
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};
