/** @format */

import ACTION_TYPES from "./actionType";
const {
  GET_MOVIES_DATA_START,
  GET_MOVIES_DATA_SUCCESS,
  GET_MOVIES_DATA_FAILED,

  GET_MOVIE_GENRE_ASSETS_FOR_LISTING,
} = ACTION_TYPES;

const CATEGORY_DEFAULT_PROPS = [
  {
    title: "",
    movieliveAssets: [],
  },
];

const initialState = {
  movieliveAssets: [],
  moviegenres: [],
  movieliveAssetDetails: [],
  epgDetails: [],
  lastEpgDetails: [],
  nextEpgDetails: [],
  banners: [],
  liveAssetNotifications: [],
  categories: CATEGORY_DEFAULT_PROPS,
  isLoading: false,
  errors: null,
  movieGenreAssetForListing: [],
  testingData: [],
  movieLoading: false,
  vodAssetLength: null,
  selectedMovieData: null,
  newSelectedMovieData: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  switch (type) {
    case GET_MOVIES_DATA_START:
      // alert("GET_MOVIES_DATA_START");
      return {
        ...state,
        isLoading: true,
        errors: "",
        movieLoading: true,
      };
    case GET_MOVIES_DATA_SUCCESS:
      // console.log("moviegenres" + JSON.stringify(payload.data[0].genreDetails));

      return {
        ...state,
        movieliveAssetDetails: payload.data[1].AssetDetails,
        moviegenres: payload.data[0].genreDetails,

        isLoading,
        vodAssetLength: payload?.assetlength,
        movieLoading: false,
      };

    case GET_MOVIES_DATA_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
        movieLoading: false,
      };

    case GET_MOVIE_GENRE_ASSETS_FOR_LISTING:
      return {
        ...state,
        isLoading,
        movieGenreAssetForListing: payload,
        testingData: { name: "poooja", id: 4 },
      };

    default:
      return state;
  }
};
