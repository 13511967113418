import ACTION_TYPES from "./action.types";

const {
  CHECKING_MAIL_VALIDITY_START,
  CHECKING_MAIL_VALIDITY_SUCCESS,
  CHECKING_MAIL_VALIDITY_FAILED,
  CLEAR_MAIL_VALIDITY_STATE,
} = ACTION_TYPES;

/** Platform List */
export const checkingMailValidityStart = (payload) => ({
  type: CHECKING_MAIL_VALIDITY_START,
  payload,
});

export const checkingMailValiditySuccess = (payload) => ({
  type: CHECKING_MAIL_VALIDITY_SUCCESS,
  payload,
});

export const checkingMailValidityFailed = (payload) => ({
  type: CHECKING_MAIL_VALIDITY_FAILED,
  payload,
});
export const clearMAilValidityState = () => ({
  type: CLEAR_MAIL_VALIDITY_STATE,
});
