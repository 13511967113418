import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as ACTION_TYPES from "./../../Redux/auth/actions";
import { createStructuredSelector } from "reselect";
import { getProfileSelector } from "./../../Redux/auth/selectors";
import UpdateProfile from "./UpdateProfile";
import "./profile.css";
import OpenLockProfile from "./OpenLockProfile";
import EditlockProfileModal from "./EditlockProfileModal";
import { newImageIpPath } from "../../App";
import { userDetailsSelector } from "../../Redux/authentication/selectors";
const HandleProfile = ({ PROFILELIST, USER_DETAILS }) => {
  const dispatch = useDispatch();
  let id = USER_DETAILS?.id;
  useEffect(() => {
    dispatch(ACTION_TYPES.getProfileListStart({ userId: id }));
  }, []);
  const history = useHistory();
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const [profileId, setProfileId] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [secreteKey, setSecreteKey] = useState();
  const [loockedId, setLockedId] = useState();

  const handleUpdateProfile = (item) => {
    //console.log( item.id );
    setProfileId(item.id);
    if (item.isLocked == 0) {
      setOpenUpdateProfile(true);
    }
    if (item.isLocked == 1) {
      setSecreteKey(item.secretKey);
      setLockedId(item.id);

      setIsOpen(true);
    }
  };
  if (modalIsOpen == true) {
    return (
      <EditlockProfileModal
        id={loockedId}
        secretKey={secreteKey}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        setOpenUpdateProfile={setOpenUpdateProfile}
        profileId={profileId}
        openUpdateProfile={openUpdateProfile}
      />
    );
  }

  if (openUpdateProfile == true) {
    return <UpdateProfile id={profileId} />;
  }
  const handleNewProfile = () => {
    history.push("/CreateProfile");
  };

  return (
    <div style={{ marginLeft: "30%" }}>
      <h3 style={{ color: "white", marginLeft: "12%" }}> Manage Profiles</h3>

      <div style={{ marginLeft: "45%", marginTop: "10px", color: "white" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-person-plus-fill"
          viewBox="0 0 16 16"
          onClick={handleNewProfile}
        >
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          <path
            fillRule="evenodd"
            d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
          />
        </svg>
      </div>
      {PROFILELIST != undefined && PROFILELIST != null
        ? PROFILELIST.map((item, index) => (
            <div
              key={index}
              className="row"
              style={{
                marginTop: "20px",
                display: "inline-block",
              }}
            >
              <div className="form-group col-md-5">
                {item.profileUrl.indexOf("http://") >= 0 ? (
                  <span>
                    <img
                      src={item.profileUrl}
                      width="350px"
                      style={{
                        height: "150px",
                        borderRadius: "4px",
                        opacity: "0.3",
                      }}
                    />
                    <div
                      style={{ color: "white", fontSize: "14px" }}
                      key={index}
                    >
                      {item.name.toUpperCase()}

                      <div className="editOverlay">
                        <div className="editIcon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                            onClick={() => handleUpdateProfile(item)}
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>{" "}
                        </div>
                      </div>
                      {item.secretKey != undefined ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-lock"
                          viewBox="0 0 16 16"
                          color="white"
                          style={{ marginLeft: "10%" }}
                        >
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                ) : (
                  <span>
                    <img
                      src={newImageIpPath + item.profileUrl}
                      width="350px"
                      style={{
                        height: "150px",
                        borderRadius: "4px",
                        opacity: "0.3",
                      }}
                    />
                    <div
                      style={{ color: "white", fontSize: "14px" }}
                      key={index}
                    >
                      {item.name.toUpperCase()}

                      <div className="editOverlay">
                        <div className="editIcon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                            onClick={() => handleUpdateProfile(item)}
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>{" "}
                        </div>
                      </div>
                      {item.secretKey != undefined ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-lock"
                          viewBox="0 0 16 16"
                          color="white"
                          style={{ marginLeft: "10%" }}
                        >
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                )}
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  PROFILELIST: getProfileSelector,
  USER_DETAILS: userDetailsSelector,
});

export default connect(mapStateToProps)(HandleProfile);
