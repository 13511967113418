import types from "./actiontype";

const initialState = {
    loading: false,
    passwordInfo: [],
    error: "",
};

const passwordInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_PASSWORD_INFO_START:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_PASSWORD_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                manageProfile: action.payload
            }
        case types.UPDATE_PASSWORD_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default:
            return state;
    }
};
export default passwordInfoReducer; 