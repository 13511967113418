import ACTION_TYPES from "./action.types";

const {
  READ_NOTIFICATION_START,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILED,

  PRIVACY_POLICY_STATUS_START,
  PRIVACY_POLICY_STATUS_SUCCESS,
  PRIVACY_POLICY_STATUS_FAILED,
} = ACTION_TYPES;

/** Platform List */
export const getReadNotificationStart = (payload) => ({
  type: READ_NOTIFICATION_START,
  payload,
});

export const getReadNotificationSuccess = (payload) => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload,
});

export const getReadNotificationFailed = (payload) => ({
  type: READ_NOTIFICATION_FAILED,
  payload,
});

/**privacy policy */
export const privacyPolicyStatusStart = (payload) => ({
  type: PRIVACY_POLICY_STATUS_START,
  payload,
});
export const privacyPolicyStatusSuccess = (payload) => ({
  type: PRIVACY_POLICY_STATUS_SUCCESS,
  payload,
});
export const privacyPolicyStatusFailed = (payload) => ({
  type: PRIVACY_POLICY_STATUS_FAILED,
  payload,
});
