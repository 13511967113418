const ACTION_TYPES = {
  GET_FAVOURITES_DATA_SUCCESS: "GET_FAVOURITES_DATA_SUCCESS",
  GET_FAVOURITES_DATA_FAILED: "GET_FAVOURITES_DATA_FAILED",

  GET_FAVOURITES_DATA_START: "GET_FAVOURITES_DATA_START",
  GET_FAVOURITES_DATA_SUCCESS: "GET_FAVOURITES_DATA_SUCCESS",
  GET_FAVOURITES_DATA_FAILED: "GET_FAVOURITES_DATA_FAILED",
};

export default ACTION_TYPES;
