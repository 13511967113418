import { createSelector } from "reselect";

const getConfiguration = (state) => state.DataAnalytics.configuresForDas;
const getContinueWatching = (state) => state.DataAnalytics.continueWatching;
const getWatchedHistory = (state) => state.DataAnalytics.watchedHistory;

export const configurationSelector = createSelector(
  getConfiguration,
  (configuresForDas) => configuresForDas
);
export const continueWatchingSelector = createSelector(
  getContinueWatching,
  (continueWatching) => continueWatching
);
export const watchedHistorySelector = createSelector(
  getWatchedHistory,
  (WatchedHistory) => WatchedHistory
);
