import ACTION_TYPES from "./action.types";

const {
  GET_PLATFORMS_LIST_START,
  GET_PLATFORMS_LIST_SUCCESS,
  GET_PLATFORMS_LIST_FAILED,
  GET_CURRENT_PLATFORM_ID,
  CLEAR_PLATFORM_DATA,
  GET_SELECTED_PLATFORM,
} = ACTION_TYPES;

/** Platform List */
export const getPlatformsListStart = () => ({
  type: GET_PLATFORMS_LIST_START,
});

export const getPlatformsListSuccess = (payload) => ({
  type: GET_PLATFORMS_LIST_SUCCESS,
  payload,
});

export const getPlatformsListFailed = (payload) => ({
  type: GET_PLATFORMS_LIST_FAILED,
  payload,
});
export const getCurrentPlatformsId = (payload) => ({
  type: GET_CURRENT_PLATFORM_ID,
  payload,
});
export const getSelectedPlatform = (payload) => ({
  type: GET_SELECTED_PLATFORM,
  payload,
});

export const clearPlatformData = () => ({
  type: CLEAR_PLATFORM_DATA,
});
