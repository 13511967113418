import swal from "sweetalert";
import axiosInstance from "../../utils/axiosInstance";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
var qs = require("qs");

export const resetPasswordAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  //console.log("Genre parameters " + JSON.stringify(payload));
  return await axiosInstance()
    .post("/user/changePassword", payload.data, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      //console.log("movie list reasponse data//////////////////////////// " + JSON.stringify(response.data));
      return response;
    })
    .catch((error) => {
      //console.log("error response " +JSON.stringify(error.response.message));
      Promise.reject(error.response.data);
    });
};
