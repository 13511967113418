import React from "react";

const SearchMessagePage = () => {
  return (
    <div
      className="mt-5"
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}
    >
      <div style={{ color: "white" }}>
        <div>Your search didn't match</div>
        <div>suggestions</div>
        <pre>
          {" "}
          <ul style={{ color: "white" }}>
            <li>try diffrent keywords</li>
            <li>Looking for Movie or Tv shows ?</li>
            <li>try using movie or shows title or genre </li>
          </ul>
        </pre>
      </div>
    </div>
  );
};

export default SearchMessagePage;
