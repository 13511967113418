/** @format */

import axiosInstance, { encryptData } from "../../utils/axiosInstance";
export const addDeviceAsync = async (payload) => {
  try {
    // Encrypt userId and make it URL-safe
    var ciphertext = encryptData(payload?.userId);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    // Make the API request with the encrypted userId
    const response = await axiosInstance().post(
      "/register/deviceV1/" + urlSafeCiphertext,
      payload
    );

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
