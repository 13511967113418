import { createSelector } from "reselect";

const getSignUpLoading = (state) => state.authenticationReducer.singupLoading;
export const signUpLoadingSelector = createSelector(
  getSignUpLoading,
  (singupLoading) => singupLoading
);

const getUserDetails = (state) => state.authenticationReducer.userDetails;
export const userDetailsSelector = createSelector(
  getUserDetails,
  (userDetails) => userDetails
);

const getLoginDetails = (state) => state.authenticationReducer.loginDetails;
export const loginDetailsSelector = createSelector(
  getLoginDetails,
  (loginDetails) => loginDetails
);
const getLoginLoading = (state) => state.authenticationReducer.loginLoading;
export const loginLoadingSelector = createSelector(
  getLoginLoading,
  (loginLoading) => loginLoading
);

const getAuthenticatedStatus = (state) =>
  state.authenticationReducer.isAuthenticated;
export const authenticatedStatusSelector = createSelector(
  getAuthenticatedStatus,
  (isAuthenticated) => isAuthenticated
);

const getrememberMeLoginLoding = (state) =>
  state.authenticationReducer.rememberMeLoginLoding;
export const rememberMeLoginLodingSelector = createSelector(
  getrememberMeLoginLoding,
  (rememberMeLoginLoding) => rememberMeLoginLoding
);
