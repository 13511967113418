/** @format */

const ACTION_TYPES = {
  GET_DELETE_DEVICES_START: "GET_DELETE_DEVICES_START",
  GET_DELETE_DEVICES_SUCCESS: "GET_DELETE_DEVICES_SUCCESS",
  GET_DELETE_DEVICES_FAILED: "GET_DELETE_DEVICES_FAILED",

  CHECK_EXISTANCE_OF_DEVICE_START: "CHECK_EXISTANCE_OF_DEVICE_START",
  CHECK_EXISTANCE_OF_DEVICE_SUCCESS: "CHECK_EXISTANCE_OF_DEVICE_SUCCESS",
  CHECK_EXISTANCE_OF_DEVICE_FAILED: "CHECK_EXISTANCE_OF_DEVICE_FAILED",

  DEVICE_STATUS: "DEVICE_STATUS",
};

export default ACTION_TYPES;
