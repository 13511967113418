import ACTION_TYPES from "./action.types";

const {
  GET_LIVE_ASSETS_START,
  GET_LIVE_ASSETS_SUCCESS,
  GET_LIVE_ASSETS_FAILED,
  GET_FINAL_LIVE_ASSET_DETAILS,
  GET_BANNERS_START,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILED,
  GET_GENERAL_SETTINGS_START,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAILED,
  CLEAR_LIVE_ASSETS,
  GET_GENRE_DETAILS_PROPS,
  GET_LIVE_GENRE_ASSETS_FOR_LISTING,
  GET_OFFSET_LENGTH,
  GET_ASSET_OFFSET_LENGTH,
} = ACTION_TYPES;

export const getLiveAssetsStart = (payload) => ({
  type: GET_LIVE_ASSETS_START,
  payload,
});
export const getOffsetLength = (payload) => ({
  type: GET_OFFSET_LENGTH,
  payload,
});

export const getLiveAssetsSucces = (payload) => ({
  type: GET_LIVE_ASSETS_SUCCESS,
  payload,
});

export const getLiveAssetsFailed = (payload) => ({
  type: GET_LIVE_ASSETS_FAILED,
  payload,
});

/****GENRES *****/

export const getFinalLiveAssetDetails = (payload) => ({
  type: GET_FINAL_LIVE_ASSET_DETAILS,
  payload,
});

export const getBannersStart = (payload) => ({
  type: GET_BANNERS_START,
  payload,
});

export const getBannersSuccess = (payload) => ({
  type: GET_BANNERS_SUCCESS,
  payload,
});

export const getBannersFailed = (payload) => ({
  type: GET_BANNERS_FAILED,
  payload,
});

export const getGeneralSettingsStart = (payload) => ({
  type: GET_GENERAL_SETTINGS_START,
  payload,
});

export const getGeneralSettingsSuccess = (payload) => ({
  type: GET_GENERAL_SETTINGS_SUCCESS,
  payload,
});

export const getGeneralSettingsFailed = (payload) => ({
  type: GET_GENERAL_SETTINGS_FAILED,
  payload,
});
export const clearLiveAssets = () => ({
  type: CLEAR_LIVE_ASSETS,
});

export const getLiveGenreAssetsForListing = (payload) => ({
  type: GET_LIVE_GENRE_ASSETS_FOR_LISTING,
  payload,
});
export const getGenreDetailsProps = (payload) => ({
  type: GET_GENRE_DETAILS_PROPS,
  payload,
});

export const getAssetOffsetLength = (payload) => ({
  type: GET_ASSET_OFFSET_LENGTH,
  payload,
});
