/** @format */

import "bootstrap/dist/css/bootstrap.css";
import "./manageprofile.css";
import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
// import * as PROFILE_ACION from "./../../Redux/ProfileDetails/actions";
import moment from "moment/moment";
import { update_password_info_start } from "../../Redux/manageProfile/passwordInfo/action";
import React, { useEffect, useState } from "react";
import { update_email_info_start } from "../../Redux/manageProfile/emailInfo/action";
import { update_personal_info_start } from "../../Redux/manageProfile/personalInfo/actions";
import { update_mobile_info_start } from "../../Redux/manageProfile/mobileInfo/action";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FadeLoader from "react-spinners/FadeLoader";
import { compareAsc, format } from "date-fns";
import { createStructuredSelector } from "reselect";
import Moment from "moment";

import {
  authSelector,
  getProfilePicLoadingSelector,
} from "../../Redux/auth/selectors";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import "@sweetalert2/themes/dark/dark.scss";
import ManageProfileModal from "./ManageProfileModal";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import { css } from "@emotion/react";

import { useHistory } from "react-router-dom";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { height } from "@mui/system";
import * as CHECK_DEVICES from "./../../Redux/Delete Device/actions";
import NewLoadingPage from "../NewLoadingPage";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  loginDetailsSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";

import axios from "axios";
import qs from "qs";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
function PersonalInfo({
  AUTH_CREDENCIAL,
  profileDetails,
  PLATFORM,
  profilePicUrl,
  PROFILE_PIC_LOADING,

  USER_DETAILS,
  AUTHENTICATION,
}) {
  //console.log("authhhhh detttaaaillllssss", JSON.stringify(profileDetails));
  // console.log("authhhhh detttaaaillllssss", JSON.stringify(USER_DETAILS));
  let date = require("date-and-time");
  var current_date = date.format(new Date(), "YYYY-MM-DD");
  const dispatch = useDispatch();
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
  });
  //alert(JSON.stringify(personalInfo));

  // alert(Moment(personalInfo?.dob).format("YYYY-MM-DD"));

  useEffect(() => {
    if (profileDetails != undefined && profileDetails != null) {
      //console.log("dob", JSON.stringify(profileDetails));

      setPersonalInfo({
        firstName: profileDetails.firstName,
        lastName: profileDetails.lastName,
        dob: profileDetails.dob,

        gender: profileDetails.gender,
        id: profileDetails.id,
        updatedBy: profileDetails.id,
        account_id: PLATFORM.currentPlatformId,
        userId: USER_DETAILS?.id,
      });
    }
  }, [profileDetails]);
  const [displayDob, setDisplayDob] = useState();
  useEffect(() => {
    if (profileDetails && profileDetails?.dob) {
      let datePart;
      if (profileDetails.dob.includes(" ")) {
        // Assuming profileDetails.dob is in "YYYY-MM-DD HH:MM:SS" format
        [datePart] = profileDetails.dob.split(" ");
      } else if (profileDetails.dob.includes("-")) {
        // Assuming profileDetails.dob is in "DD-MM-YYYY" format
        let [day, month, year] = profileDetails?.dob.split("-");
        datePart = `${year}-${month}-${day}`;
      }
      if (datePart) {
        setDisplayDob(datePart);
      }
    }
  }, [profileDetails]);
  const handlePersonalInfo = (e) => {
    // alert("hii", e);
    // alert(JSON.stringify(e.target.value));

    const value = e.target.value;

    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: value,
    });
  };
  const handleDOBInfo = (e) => {
    const value = e.target.value; // This is already in "YYYY-MM-DD" format

    // Split the date to store it in "DD-MM-YYYY" format in personalInfo
    let [year, month, day] = value.split("-");

    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: `${day}-${month}-${year}`,
    });

    setDisplayDob(value); // Keep displayDob in "YYYY-MM-DD" format
  };
  const handleGender = (e) => {
    // alert(e.target.value);
    setPersonalInfo({
      ...personalInfo,
      gender: e.target.value,
    });
  };
  const handleDetails = () => {
    let profileData = {
      firstName: personalInfo.firstName?.trim(),
      lastName: personalInfo.lastName?.trim(),
      dob: personalInfo.dob?.trim(),
      gender: personalInfo.gender,
      id: profileDetails.id,
      updatedBy: profileDetails.id,
      account_id: PLATFORM.currentPlatformId,
      userId: USER_DETAILS?.id,
    };
    if (profileData.firstName.length < 1) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "First name should not be empty",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else if (profileData.firstName.length < 3) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "First name should have 3 characters",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else if (
      profileData.lastName == undefined ||
      profileData.lastName.length == 0
    ) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Last name should not be empty",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else if (profileData.lastName.length < 1) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please enter last name",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else {
    }
    if (displayDob == undefined || displayDob.length == 0) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please select date of birth",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else {
      if (current_date < displayDob) {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text: "Please select valid date of birth",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
        return;
      }
    }
    if (profileData.gender == undefined || profileData.gender == 0) {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please select gender",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
      return;
    } else {
      dispatch(update_personal_info_start(profileData));
      // dispatch(ACTION_TYPES.loginStart(credentials));
      // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
      // window.location.reload();
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  const [profilePic, setProfilePic] = useState(() => {
    if (profilePicUrl != undefined && profilePicUrl != null) {
      return newImageIpPath + profilePicUrl;
    } else {
      return "images/user/user.jpg";
    }
  });

  return (
    <div>
      <div
        className="sign-user_card"
        style={{
          height: "70%",
          width: "70%",
          marginTop: "10%",
          backgroundColor: "rgb(50,50,50,0.8)",
          borderRadius: "10px",
        }}
      >
        <div className="row">
          <div className="col-lg-2">
            <div className="upload_profile d-inline-block ">
              {PROFILE_PIC_LOADING == false ? (
                <img
                  src={profilePic}
                  style={{
                    borderRadius: "48%",
                    height: "80px",
                    width: "80px",
                  }}
                />
              ) : (
                <div style={{ height: "80px", width: "80px" }}>
                  <FadeLoader
                    color="white"
                    loading={true}
                    css={override}
                    size={50}
                  />
                </div>
              )}

              {/* <img
                src="images/login/gif1.gif"
                style={{ height: "80px", width: "80px" }}
              /> */}
              <div
                className="p-image"
                style={{
                  backgroundColor: " rgb(400, 7, 7)",
                }}
              >
                <i
                  className="ri-pencil-line upload-button"
                  name="profile_url"
                  onClick={openModal}
                ></i>

                <ManageProfileModal
                  modalIsOpen={modalIsOpen}
                  setIsOpen={setIsOpen}
                  setProfilePic={setProfilePic}
                />

                {/* <input className="file-upload" type="file" accept="image/*" /> */}
              </div>
            </div>
          </div>

          <div className="col-lg-10 device-margin">
            <div className="profile-from">
              <h4
                className="mb-3"
                style={{ fontFamily: "Montserrat,sans-serif", color: "white" }}
              >
                Manage Profile
              </h4>
              <div className="mt-4">
                <div className="row">
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      First Name{" "}
                      <span
                        className="ml-1"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        color: "white",
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        border: "1px solid white",
                      }}
                      type="text"
                      className="form-control mb-0"
                      id="exampleInputl1"
                      placeholder="Enter your first name"
                      autocomplete="off"
                      required
                      name="firstName"
                      value={personalInfo.firstName}
                      onChange={handlePersonalInfo}
                      maxLength="25"
                    />
                  </div>
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      Last Name
                      <span
                        className="ml-1"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        color: "white",
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        border: "1px solid white",
                      }}
                      type="text"
                      className="form-control mb-0"
                      id="exampleInputl2"
                      placeholder="Enter Your Last Name"
                      autocomplete="off"
                      required
                      name="lastName"
                      value={personalInfo.lastName}
                      onChange={handlePersonalInfo}
                      maxLength="25"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group  col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      User ID{" "}
                      <span
                        className="ml-1"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    {USER_DETAILS != undefined && USER_DETAILS != null ? (
                      <input
                        style={{
                          fontFamily: "Montserrat,sans-serif",
                          color: "white",
                          backgroundColor: "transparent",
                          borderRadius: "5px",
                          border: "1px solid white",
                        }}
                        type="text"
                        className="form-control mb-0"
                        id="exampleInputl3"
                        value={USER_DETAILS?.userId}
                        placeholder="Enter User ID"
                        autocomplete="off"
                        required
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      DOB
                      <span
                        className="ml-1"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      required
                      name="dob"
                      autoComplete="date"
                      value={displayDob}
                      style={{
                        color: "white",
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        border: "1px solid white",
                      }}
                      max={moment().format("YYYY-MM-DD")}
                      onChange={handleDOBInfo}
                      color={"white"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className=" form-group col-md-5 w-40">
                    <FormControl>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{ color: "white" }}
                      >
                        Gender{" "}
                        <span
                          className="ml-1"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <RadioGroup
                        style={{ color: "white" }}
                        defaultValue={profileDetails?.gender?.toLowerCase()}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          style={{ color: "white" }}
                          value="female"
                          control={<Radio />}
                          label="Female"
                          onChange={handleGender}
                        />
                        <FormControlLabel
                          style={{ color: "white" }}
                          value="male"
                          control={<Radio />}
                          label="Male"
                          onChange={handleGender}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <button
                  // className="btn"T
                  className="newBtn"
                  style={{
                    width: "40%",
                    marginLeft: "100px",
                    fontFamily: "Montserrat,sans-serif",
                    backgroundColor:
                      "linear-gradient(to right, #220703, #b90909, #8b0a08e0)",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "white",
                    borderColor: "transparent",
                  }}
                  onClick={handleDetails}
                >
                  Update Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PasswordInfo = ({
  USER_DETAILS,
  PLATFORM,
  newAuth,
  DEVICE_TOKEN,
  AUTH,
}) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    password: "",
    currentPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      // console.log(
      //   "USER_DETAILSUSER_DETAILS" +
      //     JSON.stringify(AUTH?.credentialsForRememberMe?.remember_me)
      // );
      setPassword({
        password: "",
        currentPassword: "",
        confirmPassword: "",
        updatedBy: USER_DETAILS?.id,
        account_id: PLATFORM.currentPlatformId,
        userId: USER_DETAILS?.userId,
        deviceId: DEVICE_TOKEN,
        packageId: USER_DETAILS.packageId,
        rememberMe: AUTH?.credentialsForRememberMe?.remember_me,
      });
    }
  }, []);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword({
      ...password,
      [e.target.name]: value,
      //id: AUTH.userId,
    });
  };
  const handleClick = () => {
    if (password.currentPassword?.trim().length != 0) {
      if (password.password?.trim().length != 0) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
        const result = pattern.test(password?.password);
        if (result == true) {
          if (password.confirmPassword?.trim().length != 0) {
            if (password.currentPassword == newAuth?.password) {
              if (password.password !== password.currentPassword) {
                if (password.password == password.confirmPassword) {
                  dispatch(update_password_info_start(password));
                } else {
                  Swal.fire({
                    icon: "warning",
                    // position: "top-end",
                    text: "Password doesn't match",
                    allowOutsideClick: () => !swal.isLoading(),
                    confirmButtonColor: "#3085d6",
                    button: "OK",
                  });
                }
              } else {
                Swal.fire({
                  icon: "warning",
                  // position: "top-end",
                  text: "New password cannot same as old password",
                  allowOutsideClick: () => !swal.isLoading(),
                  confirmButtonColor: "#3085d6",
                  button: "OK",
                });
              }
            } else {
              Swal.fire({
                icon: "warning",
                // position: "top-end",
                text: "Current password doesn't match",
                allowOutsideClick: () => !swal.isLoading(),
                confirmButtonColor: "#3085d6",
                button: "OK",
              });
            }
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Confirm password should not be Empty",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text:
              "Password must contain at least one number, one uppercase ,one lowercase letter, one special character, and at least six or more characters",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text: "New password should not be empty",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Current password should not be empty",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }
    // if (password.password.length == 0 && password.password.length < 8) {
    //   swal("wrong input! password must be 8 letters");
    //   return;
    // }
    // else if (password.password !== password.confirmPassword) {
    //   swal("password doesn't match");
    // } else {
    //   dispatch(update_password_info_start(password));
    // }

    // if (password.currentPassword?.trim().length != 0) {
    //   if (password.password?.trim().length != 0) {
    //     if (password.confirmPassword?.trim().length != 0) {
    //       if (password.currentPassword != newAuth.credentials.password) {
    //         swal("Current password is wrong");
    //       }
    //       if (password.password !== password.currentPassword)
    //         if (password.password == password.confirmPassword) {
    //           // alert(JSON.stringify(password));
    //           dispatch(update_password_info_start(password));
    //         } else {
    //           swal("Password not matching");
    //         }
    //     } else {
    //       swal("Please enter confirm password");
    //     }
    //   } else {
    //     swal("Please enter new password");
    //   }
    // } else {
    //   swal("Please enter current password");
    // }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [NewPasswordType, setNewPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const toggleNewPassword = () => {
    if (NewPasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };
  return (
    <div>
      <div
        className="sign-user_card"
        style={{
          height: "70%",
          width: "70%",
          marginTop: "10%",
          backgroundColor: "rgb(50,50,50,0.8)",
          borderRadius: "10px",
        }}
      >
        <div className="row">
          <div className="col-12 device-margin">
            <div className="profile-from">
              <div
                className="col-12"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <h4
                  className="mb-5 mt-3"
                  style={{
                    color: "white",
                    fontFamily: "Montserrat,sans-serif",
                  }}
                >
                  Change Password
                </h4>
              </div>
              {/* <form className="mt-4" action="" style={{ marginLeft: "100px" }}> */}
              <div
                className="row p-2"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                {/* <div className="form-group col-5">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginTop: "8px",
                      fontFamily: "Montserrat,sans-serif"
                    }}
                  >
                    Current Password *
                  </label>
                </div> */}

                <div
                  className="input-single1 input-pass1"
                  style={{ position: "relative", color: "black", width: "50%" }}
                >
                  <input
                    type={passwordType}
                    name="currentPassword"
                    value={password.currentPassword}
                    onChange={handlePasswordChange}
                    required
                    placeholder="Current Password"
                    style={{
                      width: "100%",
                      height: "48px",
                      backgroundColor: "transparent",
                      outline: 0,
                      border: "1px solid white",
                      borderRadius: "5px",
                      transition: "all 0.3s ease-in-out",
                      padding: "15px 15px 15px 44px",
                      fontSize: "14px",
                      color: "white",
                    }}
                    maxLength="25"
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: "17px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></span>
                  <span
                    className="pass-eye1"
                    style={{
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {passwordType === "password" ? (
                      <i
                        className="bi bi-eye-slash"
                        style={{ color: "gray", float: "right" }}
                        onClick={togglePassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye"
                        onClick={togglePassword}
                        style={{ color: "gray", float: "right" }}
                      ></i>
                    )}
                  </span>
                </div>
              </div>

              <div
                className="row p-2"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  className="input-single1 input-pass1"
                  style={{ position: "relative", color: "black", width: "50%" }}
                >
                  <input
                    type={NewPasswordType}
                    //className="form-control date-input basicFlatpickr mb-0"
                    placeholder="New Password"
                    //id="exampleInputPassword2"
                    maxLength="25"
                    required
                    name="password"
                    value={password.password}
                    onChange={handlePasswordChange}
                    style={{
                      width: "100%",
                      height: "48px",
                      backgroundColor: "transparent",
                      outline: 0,
                      border: "1px solid white",
                      borderRadius: "5px",
                      transition: "all 0.3s ease-in-out",
                      padding: "15px 15px 15px 44px",
                      fontSize: "14px",
                      color: "white",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: "17px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></span>
                  <span
                    className="pass-eye1"
                    style={{
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {NewPasswordType === "password" ? (
                      <i
                        className="bi bi-eye-slash"
                        style={{ color: "gray", float: "right" }}
                        onClick={toggleNewPassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye"
                        onClick={toggleNewPassword}
                        style={{ color: "gray", float: "right" }}
                      ></i>
                    )}
                  </span>
                </div>
              </div>

              <div
                className="row p-2"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                {/* <div className="form-group col-md-5">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginTop: "8px",
                      fontFamily: "Montserrat,sans-serif"
                    }}
                  >
                    {" "}
                    Confirm Password *
                  </label>
                </div> */}

                <div
                  className="input-single1 input-pass1"
                  style={{ position: "relative", color: "black", width: "50%" }}
                >
                  <input
                    type={confirmPasswordType}
                    //className="form-control date-input basicFlatpickr mb-0"
                    placeholder="Re-enter New Password"
                    //id="exampleInputPassword2"
                    maxLength="25"
                    name="confirmPassword"
                    required
                    value={password.confirmPassword}
                    onChange={handlePasswordChange}
                    style={{
                      width: "100%",
                      height: "48px",
                      backgroundColor: "transparent",
                      outline: 0,
                      border: "1px solid white",
                      borderRadius: "5px",
                      transition: "all 0.3s ease-in-out",
                      padding: "15px 15px 15px 44px",
                      fontSize: "14px",
                      color: "white",
                    }}
                  />
                  {/* <span
                    style={{
                      position: "absolute",
                      left: "17px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></span> */}
                  {/* <span
                    className="pass-eye1"
                    style={{
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {confirmPasswordType === "password" ? (
                      <i
                        className="bi bi-eye-slash"
                        style={{ color: "gray", float: "right" }}
                        onClick={toggleConfirmPassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye"
                        onClick={toggleConfirmPassword}
                        style={{ color: "gray", float: "right" }}
                      ></i>
                    )}
                  </span> */}
                </div>
              </div>

              <div
                className="d-flex mt-4"
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <button
                  className="newBtn"
                  style={{
                    width: "40%",

                    fontFamily: "Montserrat,sans-serif",
                    backgroundColor: "red",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "white",
                    borderColor: "transparent",
                  }}
                  onClick={handleClick}
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PhoneInfo = ({ USER_DETAILS, PLATFORM, profileDetails }) => {
  //alert(JSON.stringify(PLATFORM.currentPlatformId));
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ emailId: "", mobileNumber: "" });
  const [error, setError] = useState(null);
  useEffect(() => {
    if (profileDetails != undefined && profileDetails != null) {
      setEmail({
        emailId: "",
        id: profileDetails.id,
        updatedBy: profileDetails.id,
        account_id: PLATFORM.currentPlatformId,
      });
    }
  }, []);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email.emailId);
  }
  const handleEmailChange = (e) => {
    // setEmail({
    //   emailId: "",
    //   id: profileDetails?.id,
    //   updatedBy: profileDetails?.id,
    //   account_id: PLATFORM?.currentPlatformId,
    // });
    const value = e.target.value.trim();
    setEmail({
      ...email,
      [e.target.name]: value,
    });
  };

  const [mobile, setMobile] = useState({ mobileNumber: "" });

  useEffect(() => {
    if (profileDetails != undefined && profileDetails != null) {
      setMobile({
        mobileNumber: "",
        id: profileDetails.id,
        updatedBy: profileDetails.id,
        account_id: PLATFORM.currentPlatformId,
      });
    }
  }, []);

  let data;
  if (
    profileDetails != undefined &&
    profileDetails != null &&
    PLATFORM != undefined
  ) {
    data = {
      id: profileDetails.id,
      account_id: PLATFORM.currentPlatformId,
    };
  }

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobile({
      ...mobile,
      [e.target.name]: value,
    });
  };
  const handleSubmit = () => {
    // alert(JSON.stringify(data));
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const result = pattern.test(email.emailId);

    if (
      email?.emailId?.length !== 0 ||
      (email?.mobileNumber !== undefined && email?.mobileNumber?.length !== 0)
    ) {
      // alert(email?.emailId?.length + "   " + email?.mobileNumber);
      if (
        (email?.emailId?.length > 0 && email?.mobileNumber?.length <= 0) ||
        email?.mobileNumber == undefined
      ) {
        // alert(email.emailId.length);
        if (email?.emailId?.length !== 0 && email?.emailId?.length > 5) {
          if (result == true) {
            if (email?.emailId !== profileDetails?.emailId) {
              let data = {
                emailId: email.emailId,
                // mobileNumber: email.mobileNumber,
                id: profileDetails.id,
                updatedBy: profileDetails.id,
                account_id: PLATFORM.currentPlatformId,
              };
              dispatch(update_email_info_start(data));
              setEmail({ emailId: "" });
              // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
            } else {
              Swal.fire({
                icon: "warning",
                // position: "top-end",
                text: "New email should not be same as old email",
                allowOutsideClick: () => !swal.isLoading(),
                confirmButtonColor: "#3085d6",
                button: "OK",
              });
            }
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Please enter valid email",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text: "Email should have minimum five characters",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      } else if (
        (email?.mobileNumber?.length > 0 && email?.emailId?.length <= 0) ||
        email.emailId == undefined
      ) {
        if (
          email?.mobileNumber !== undefined &&
          email?.mobileNumber?.length !== 0
        ) {
          if (email?.mobileNumber?.length == 10) {
            if (email?.mobileNumber?.match("[0-9]{10}")) {
              if (email?.mobileNumber !== profileDetails?.mobileNumber) {
                // dispatch(update_email_info_start(email));
                let data = {
                  // emailId: email.emailId,
                  mobileNumber: email.mobileNumber,
                  id: profileDetails.id,
                  updatedBy: profileDetails.id,
                  account_id: PLATFORM.currentPlatformId,
                };
                dispatch(update_email_info_start(data));
                setEmail({ mobileNumber: "" });
                // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
              } else {
                Swal.fire({
                  icon: "warning",
                  // position: "top-end",
                  text: "New phone number cannot be same as old phone number.",
                  allowOutsideClick: () => !swal.isLoading(),
                  confirmButtonColor: "#3085d6",
                  button: "OK",
                });
              }
            } else {
              Swal.fire({
                icon: "warning",
                // position: "top-end",
                text: "Please provide valid phone number",
                allowOutsideClick: () => !swal.isLoading(),
                confirmButtonColor: "#3085d6",
                button: "OK",
              });
            }
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Please provide a valid mobile number",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text: "Wrong input! mobile number cannot be empty",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      } else if (
        email?.emailId?.length > 0 &&
        email?.mobileNumber?.length > 0
      ) {
        if (email?.emailId?.length !== 0 && email?.emailId?.length > 5) {
          if (result == true) {
            if (email?.emailId !== profileDetails?.emailId) {
              if (
                email?.mobileNumber !== undefined &&
                email?.mobileNumber?.length !== 0
              ) {
                if (email?.mobileNumber?.length == 10) {
                  if (email?.mobileNumber?.match("[0-9]{10}")) {
                    if (email?.mobileNumber !== profileDetails?.mobileNumber) {
                      let data = {
                        emailId: email.emailId,
                        mobileNumber: email.mobileNumber,
                        id: profileDetails.id,
                        updatedBy: profileDetails.id,
                        account_id: PLATFORM.currentPlatformId,
                      };
                      dispatch(update_email_info_start(data));
                      // dispatch(update_mobile_info_start(mobile));
                      setEmail({ emailId: "", mobileNumber: "" });
                      // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
                    } else {
                      Swal.fire({
                        icon: "warning",
                        // position: "top-end",
                        text:
                          "New phone number cannot be same as old phone number",
                        allowOutsideClick: () => !swal.isLoading(),
                        confirmButtonColor: "#3085d6",
                        button: "OK",
                      });
                    }
                  } else {
                    Swal.fire({
                      icon: "warning",
                      // position: "top-end",
                      text: "Please provide valid phone number",
                      allowOutsideClick: () => !swal.isLoading(),
                      confirmButtonColor: "#3085d6",
                      button: "OK",
                    });
                  }
                } else {
                  Swal.fire({
                    icon: "warning",
                    // position: "top-end",
                    text: "Please provide a valid mobile number",
                    allowOutsideClick: () => !swal.isLoading(),
                    confirmButtonColor: "#3085d6",
                    button: "OK",
                  });
                }
              } else {
                Swal.fire({
                  icon: "warning",
                  // position: "top-end",
                  text: "Wrong input! mobile number cannot be empty",
                  allowOutsideClick: () => !swal.isLoading(),
                  confirmButtonColor: "#3085d6",
                  button: "OK",
                });
              }
            } else {
              Swal.fire({
                icon: "warning",
                // position: "top-end",
                text: "New email cannot be same as old email",
                allowOutsideClick: () => !swal.isLoading(),
                confirmButtonColor: "#3085d6",
                button: "OK",
              });
            }
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Please enter valid email",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text: "Email should have minimum five characters",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please update email or mobile number",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }
  };

  return (
    <div>
      <div
        className="sign-user_card"
        style={{
          height: "70%",
          width: "70%",
          marginTop: "10%",
          backgroundColor: "rgb(50,50,50,0.8)",
          borderRadius: "10px",
        }}
      >
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10 device-margin">
            <div className="profile-from">
              <h4
                className="mb-3"
                style={{ color: "white", fontFamily: "Montserrat,sans-serif" }}
              >
                Manage e-mail and phone-no
              </h4>
              <div className="mt-4">
                <div className="row">
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      Old Email
                    </label>
                  </div>
                  <div className="form-group col-md-5">
                    {profileDetails != undefined && profileDetails != null ? (
                      <marquee direction="left">
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            fontFamily: "Montserrat,sans-serif",
                          }}
                        >
                          {" "}
                          {profileDetails.emailId}
                        </label>{" "}
                      </marquee>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginTop: "8px",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      New Email
                    </label>
                  </div>
                  <div className="form-group col-md-5">
                    <input
                      type="text"
                      //className="form-control date-input basicFlatpickr mb-0"
                      placeholder="Enter New Email"
                      //id="exampleInputPassword2"
                      // maxLength="25"
                      required
                      name="emailId"
                      value={email.emailId}
                      onChange={handleEmailChange}
                      style={{
                        color: "white",
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        fontFamily: "Montserrat,sans-serif",
                        border: "1px solid white",
                      }}
                    />
                    {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      Old Phone No
                    </label>
                  </div>
                  <div className="form-group col-md-5">
                    {profileDetails != undefined && profileDetails != null ? (
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "white",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        {" "}
                        {profileDetails.mobileNumber}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginTop: "8px",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      {" "}
                      New Phone No
                    </label>
                  </div>
                  <div className="form-group col-md-5">
                    <input
                      type="text"
                      className="form-control date-input basicFlatpickr mb-0"
                      placeholder="Enter New Phone No"
                      id="exampleInputPassword2"
                      maxLength="10"
                      required
                      name="mobileNumber"
                      value={email.mobileNumber}
                      onChange={handleEmailChange}
                      style={{
                        color: "white",
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        fontFamily: "Montserrat,sans-serif",
                        border: "1px solid white",
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="d-flex">
                  <button
                    className="newBtn"
                    style={{
                      width: "40%",
                      marginLeft: "100px",
                      fontFamily: "Montserrat,sans-serif",
                      backgroundColor: "red",
                      padding: "10px",
                      borderRadius: "5px",
                      color: "white",
                      borderColor: "transparent",
                    }}
                    onClick={handleSubmit}
                  >
                    Update Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ManageProfile = ({
  PLATFORM,
  USER_DETAILS,
  DEVICE_TOKEN,
  PLATFORM_DATA,
  DEVICE_NAME,
  PROFILE_PIC_LOADING,
  LOGIN_DETAILS,

  setSelectedCategory,
  AUTHENTICATION,
  AUTH,
}) => {
  //alert("P"+JSON.stringify(PLATFORM.currentPlatformId))
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.replace("/login");
    }
  }, [AUTHENTICATION]);
  let brandBackgroundImage;
  if (PLATFORM_DATA != undefined && PLATFORM_DATA != null) {
    brandBackgroundImage = newImageIpPath + PLATFORM_DATA.webappBackgroundImage;
  }

  let defaultLandingPage = PLATFORM_DATA?.defaultLandingPage;

  const handleClick = () => {
    history.push("/container");

    if (defaultLandingPage == 1) {
      setSelectedCategory(1);
    }

    if (defaultLandingPage == 2) {
      setSelectedCategory(2);
    }

    if (defaultLandingPage == 3) {
      setSelectedCategory(3);
    }
  };

  return (
    <div
      className=""
      style={{
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${brandBackgroundImage})`,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "fixed",
        overflowY: "scroll",
      }}
    >
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <div
              style={{
                backgroundColor: "rgba(52, 53, 53, 0.9)",
                width: "100%",
                height: "100%",
                position: "absolute",
                // filter: "blur(1px)",
                zIndex: 1,
                marginTop: "25%",
                borderRadius: "10px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            ></div>
            <Nav
              variant="pills"
              className="flex-column  "
              style={{
                marginTop: "25%",

                borderRadius: "10px",
                cursor: "pointer",
                // backgroundColor: "rgba(52, 53, 53, 0.9)",
                height: "90%",
                display: "flex",
                flexDirection: "column",
                zIndex: 3,
              }}
            >
              <Nav.Item
                style={{
                  padding: "7px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  zIndex: 3,
                  borderRadius: "10px",
                }}
              >
                <Nav.Link
                  eventKey="first"
                  style={{
                    marginTop: "0",
                    // marginLeft: "50px",
                    color: "white",
                    fontWeight: "bold",
                    width: "100%",
                    padding: "20px",

                    fontFamily: "Montserrat,sans-serif",
                    borderRadius: "10px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-person-circle mr-2"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "20px" }}
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                  Personal Information
                </Nav.Link>
              </Nav.Item>

              <Nav.Item
                style={{
                  padding: "7px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  zIndex: 3,
                  borderRadius: "10px",
                }}
              >
                <Nav.Link
                  eventKey="Third"
                  style={{
                    color: "white",
                    // marginLeft: "50px",
                    fontWeight: "bold",
                    // backgroundColor: "gray",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "0",
                    cursor: "pointer",
                    fontFamily: "Montserrat,sans-serif",
                    borderRadius: "10px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone mr-2"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "20px" }}
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                  Email And PhoneNo
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  padding: "7px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  zIndex: 3,
                  borderRadius: "10px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <Nav.Link
                  eventKey="second"
                  style={{
                    color: "white",
                    // marginLeft: "50px",
                    fontWeight: "bold",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "0",
                    cursor: "pointer",
                    fontFamily: "Montserrat,sans-serif",
                    borderRadius: "10px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-key mr-2"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "20px" }}
                  >
                    <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                    <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                  Password
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  padding: "7px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  zIndex: 3,
                  borderRadius: "10px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              ></Nav.Item>

              <Nav.Item
                style={{
                  padding: "7px",
                  marginBottom: "-10px",
                  marginTop: "-10px",
                  zIndex: 3,
                  borderRadius: "10px",
                }}
              >
                <Nav.Link
                  onClick={handleClick}
                  eventKey="Four"
                  style={{
                    color: "white",
                    // marginLeft: "50px",
                    fontWeight: "bold",
                    // backgroundColor: "gray",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "0",
                    cursor: "pointer",
                    fontFamily: "Montserrat,sans-serif",
                    borderRadius: "10px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    color="white"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-left"
                    style={{ marginRight: "10px", fontWeight: "bold" }}
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                  Back
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <PersonalInfo
                  USER_DETAILS={USER_DETAILS}
                  PLATFORM={PLATFORM}
                  AUTH_CREDENCIAL={USER_DETAILS}
                  profileDetails={USER_DETAILS}
                  profilePicUrl={USER_DETAILS?.profilePictureUrl}
                  PROFILE_PIC_LOADING={PROFILE_PIC_LOADING}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <PasswordInfo
                  USER_DETAILS={USER_DETAILS}
                  PLATFORM={PLATFORM}
                  newAuth={LOGIN_DETAILS}
                  DEVICE_TOKEN={DEVICE_TOKEN}
                  AUTH={AUTH}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="Third">
                <PhoneInfo
                  USER_DETAILS={USER_DETAILS}
                  PLATFORM={PLATFORM}
                  AUTH_CREDENCIAL={USER_DETAILS}
                  profileDetails={USER_DETAILS}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  PLATFORM_DATA: getCurrentPlatformSelector,
  DEVICE_NAME: getDeviceNameSelector,
  PROFILE_PIC_LOADING: getProfilePicLoadingSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  LOGIN_DETAILS: loginDetailsSelector,
  AUTH: authSelector,
});

export default connect(mapStateToProps)(ManageProfile);
