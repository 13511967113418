import ACTION_TYPES from "./actionTypes";
const {
  ADD_FAVOURITES_START,
  ADD_FAVOURITES_SUCCESS,
  ADD_FAVOURITES_FAILED,
} = ACTION_TYPES;

const initialState = {
  favorites: {},
  isLoading: false,
  errors: "",
  currentState: [],
  favState: false,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  //console.log("reducer is calling");

  switch (type) {
    case ADD_FAVOURITES_START:
      return {
        ...state,
        isLoading: true,
        errors: "",
      };
    case ADD_FAVOURITES_SUCCESS:
      return {
        ...state,
        favorites: payload,

        isLoading,
      };

    case ADD_FAVOURITES_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    default:
      return state;
  }
};
