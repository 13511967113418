/** @format */

import base64 from "base-64";
import videojs from "video.js";
import { newImageIpPath } from "../../../App";
function timeStringToSeconds(timeString) {
  let totalSeconds = 0;

  if (timeString != undefined) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    totalSeconds = hours * 3600 + minutes * 60 + seconds;
  }
  return totalSeconds;
}

export const getPlayList = (payload) => {
  let allChannelList = [];

  let selectedDrmCategory = null;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }
  // console.log(
  //   "payload?.EPISODE_DETAILS" +
  //     JSON.stringify(payload?.EPISODE_DETAILS?.allseasons)
  // );

  var keyServer;
  var url1;
  // const getSeasonTitle = (seasonId) => {
  //   const season = payload?.EPISODE_DETAILS.allseasons?.find(
  //     (season) => season.seasonId === seasonId
  //   );
  //   return season ? season.seasonTitle : null;
  // };
  payload?.EPISODE_DETAILS.episodes?.length > 0 &&
    payload?.EPISODE_DETAILS.episodes?.map((a, index) => {
      if (selectedDrmCategory == 1) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let widewineLicense = payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
        let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName.trim();
        let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
        let drmId = a?.drmId;
        let PlayState = "1";
        let DrmSystem = "Widevine";
        let date = new Date();
        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        url1 = firstPartUrl + ".mpd";
        let month1 = date.getMonth() + 1;
        let nowStampString =
          date.getFullYear() +
          "-" +
          month1 +
          "-" +
          date.getDate() +
          " " +
          0 +
          ":" +
          date.getHours() +
          ":" +
          date.getMinutes();

        let ContentUrl = url1;
        let UniqueDeviceId = payload.SAVED_DEVICE_TOCKEN;
        let DeviceTypeName = payload.DEVICE_NAME;
        let LoginName = base64.encode(loginName);
        let Password = base64.encode(loginPassword);
        let KeyId = base64.encode(drmId);
        let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);

        let ContentUrlEncode = base64.encode(ContentUrl);
        let NowStamp = base64.encode(nowStampString);
        let DeviceTypeNameEncode = base64.encode(DeviceTypeName);

        keyServer =
          widewineLicense +
          "?" +
          "PlayState=" +
          PlayState +
          "&" +
          "DrmSystem=" +
          DrmSystem +
          "&" +
          "LoginName=" +
          LoginName +
          "&" +
          "Password=" +
          Password +
          "&" +
          "KeyId=" +
          KeyId +
          "&" +
          "UniqueDeviceId=" +
          UniqueDeviceIdEncode +
          "&" +
          "ContentUrl=" +
          ContentUrlEncode +
          "&" +
          "NowStamp=" +
          NowStamp +
          "&" +
          "DeviceTypeName=" +
          DeviceTypeNameEncode;
      } else if (selectedDrmCategory == 2) {
        // "https://soapboxstreama.riversilica.com/Movies/demo.mp4/index.mpd";
        var pos = a?.assetUrl?.lastIndexOf(".");

        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        url1 = firstPartUrl + ".mpd";

        keyServer = payload?.AUTHENTICATION
          ? payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim() +
            "?assetId=" +
            a?.id +
            "&categoryId=3&userId=" +
            payload.USER_DETAILS?.id
          : payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
        // "https://drmproxy.riversilica.com/fpe/fpe_wv.php?assetId=1&categoryId=1&userId=12";
      }

      let data = {
        // src: url1,

        // type: "application/dash+xml",
        // keySystems: {
        //   "com.widevine.alpha": keyServer,
        // },

        src: url1,
        type: "application/dash+xml",
        keySystems: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",

            getLicense: (emeOptions, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",

                  url: keyServer,

                  responseType: "arraybuffer",

                  body: keyMessage,
                },

                (err, response, responseBody) => {
                  if (err) {
                    callback(err);

                    return;
                  }

                  callback(null, responseBody);
                }
              );
            },
          },
        },

        name: a?.title,
        id: a?.id,
      };
      let title = a?.title;

      let name = a?.title;
      const filteredData = payload.cwDataForPLaylist.filter(
        (item) => item.episodeId === a.id
      );
      let dataForPoster = {};
      if (payload?.GENERAl_POST_ADS[a?.episodePostAdv[0]?.adId] != undefined) {
        dataForPoster = {
          id: a?.episodePostAdv[0]?.adId,

          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.episodePostAdv[0]?.adId]?.adv_url,
          time: Number(a?.episodePostAdv[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }
      let VideoadConfig = [];

      a?.episodeVideoAdv?.forEach((a) => {
        let videoUrl = payload?.VIDEO_ADS[a?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          }
        }
        if (payload?.VIDEO_ADS[a?.adId] != undefined) {
          // if (a?.duration > timeStringToSeconds(filteredData[0]?.duration)) {

          let data;
          if (Number(a?.isSkipRequired) !== 0) {
            data = {
              id: Number(a?.adId),
              src: videoUrl,
              time: Number(a?.duration),
              skip: Number(a?.skipBeyond), // Corrected inequality operator
            };
          } else {
            data = {
              id: Number(a?.adId),

              src: videoUrl,
              time: Number(a?.duration),
            };
          }

          if (
            payload?.VIDEO_ADS[a?.adId] !== undefined &&
            payload?.VIDEO_ADS[a?.adId] !== null &&
            payload.adsEnabled
          ) {
            VideoadConfig?.push(data);
          }
          // }
        }
      });

      let PosteradConfig = [dataForPoster];
      let adConfig = VideoadConfig;
      let sources = [data];
      allChannelList.push({
        sources,
        logo: newImageIpPath + a?.thumbnailUrl,
        poster: newImageIpPath + a?.thumbnailUrl,
        cw_duration: timeStringToSeconds(filteredData[0]?.duration),
        name,
        title,
        duration: a?.duration,
        id: a?.id,
        seasonId: a?.seasonId,
        seasonTitle: payload?.EPISODE_DETAILS?.allseasons[a?.seasonId], // Added seasonTitle

        index,
        PosteradConfig: PosteradConfig,
        adConfig: adConfig,
        isCwRequired: payload.isCwRequired,
      });
    });
  return allChannelList;
};

export const getPlayListForFairplay = (payload) => {
  function licenseRequestLoaded(stringbuffer) {
    const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));

    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
    }

    return bytes;
  }
  let allChannelList = [];

  payload?.EPISODE_DETAILS.episodes?.length > 0 &&
    payload?.EPISODE_DETAILS.episodes?.map((a, index) => {
      var selectedDrmCategory = 2;
      if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
        selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
      }

      var url2;

      var keyServer1;
      var fairplayCertificate;
      if (selectedDrmCategory == 1) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let fairplayLicense = payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim();
        fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate.trim();
        let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName.trim();
        let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
        let drmId = a?.drmId;
        let PlayState = "1";
        let DrmSystem2 = "Fairplay";
        let date = new Date();
        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        url2 = firstPartUrl + ".m3u8";
        let month1 = date.getMonth() + 1;
        let nowStampString =
          date.getFullYear() +
          "-" +
          month1 +
          "-" +
          date.getDate() +
          " " +
          0 +
          ":" +
          date.getHours() +
          ":" +
          date.getMinutes();

        let ContentUrl2 = url2;
        let UniqueDeviceId1 = payload.SAVED_DEVICE_TOCKEN;
        let DeviceTypeName1 = payload.DEVICE_NAME;
        let LoginName = base64.encode(loginName);
        let Password = base64.encode(loginPassword);
        let KeyId = base64.encode(drmId);
        let UniqueDeviceIdEncode1 = base64.encode(UniqueDeviceId1);

        let ContentUrlEncode1 = base64.encode(ContentUrl2);
        let NowStamp = base64.encode(nowStampString);
        let DeviceTypeNameEncode1 = base64.encode(DeviceTypeName1);

        keyServer1 =
          fairplayLicense +
          "?" +
          "PlayState=" +
          PlayState +
          "&" +
          "DrmSystem=" +
          DrmSystem2 +
          "&" +
          "LoginName=" +
          LoginName +
          "&" +
          "Password=" +
          Password +
          "&" +
          "KeyId=" +
          KeyId +
          "&" +
          "UniqueDeviceId=" +
          UniqueDeviceIdEncode1 +
          "&" +
          "ContentUrl=" +
          ContentUrlEncode1 +
          "&" +
          "NowStamp=" +
          NowStamp +
          "&" +
          "DeviceTypeName=" +
          DeviceTypeNameEncode1;
      } else if (selectedDrmCategory == 2) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        url2 = firstPartUrl + ".m3u8";
        // url2 =
        //   "https://soapboxstreama.riversilica.com/Movies/demo.mp4/index.mpd";

        keyServer1 = payload?.AUTHENTICATION
          ? payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim() +
            "?assetId=" +
            a?.id +
            "&categoryId=3&userId=" +
            payload.USER_DETAILS?.id
          : payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim();
        fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate.trim();

        // "https://drmproxy.riversilica.com/fpe/fpe_wv.php?assetId=1&categoryId=1&userId=12";
      }

      let data = {
        src: url2,

        type: "application/x-mpegURL",
        keySystems: {
          "com.apple.fps.1_0": {
            videoRobustness: "SW_SECURE_CRYPTO",
            getCertificate: (emeOptions, callback) => {
              videojs.xhr(
                {
                  method: "GET",
                  url: fairplayCertificate,
                  responseType: "arraybuffer",
                  Pragma: "no-cache",
                  CacheControl: "no-cache",
                  ContentType: "text/xml; charset=utf-8",
                },
                (err, response, responseBody) => {
                  if (err) {
                    callback(err);
                    return;
                  }
                  callback(null, new Uint8Array(responseBody));
                }
              );
            },
            getLicense: (emeOptions, contentId, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",
                  url: keyServer1,
                  responseType: "string",
                  body: keyMessage,
                  headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                  },
                },
                (err, response, responseBody) => {
                  if (err) {
                    callback(err);
                    return;
                  }
                  callback(null, licenseRequestLoaded(responseBody));
                }
              );
            },
          },
        },
        name: a?.title,
        id: a?.id,
      };
      let title = a?.title;

      let name = a?.title;
      const filteredData = payload.cwDataForPLaylist.filter(
        (item) => item.episodeId === a.id
      );
      let dataForPoster = {};
      if (payload?.GENERAl_POST_ADS[a?.episodePostAdv[0]?.adId] != undefined) {
        dataForPoster = {
          id: a?.episodePostAdv[0]?.adId,
          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.episodePostAdv[0]?.adId]?.adv_url,
          time: Number(a?.episodePostAdv[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }
      let VideoadConfig = [];

      a?.episodeVideoAdv?.forEach((a) => {
        let videoUrl = payload?.VIDEO_ADS[a?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          }
        }
        if (payload?.VIDEO_ADS[a?.adId] != undefined) {
          // if (a?.duration > timeStringToSeconds(filteredData[0]?.duration)) {

          let data;
          if (Number(a?.isSkipRequired) !== 0) {
            data = {
              id: Number(a?.adId),

              src: videoUrl,
              time: Number(a?.duration),
              skip: Number(a?.skipBeyond), // Corrected inequality operator
            };
          } else {
            data = {
              id: Number(a?.adId),

              src: videoUrl,
              time: Number(a?.duration),
            };
          }

          if (
            payload?.VIDEO_ADS[a?.adId] !== undefined &&
            payload?.VIDEO_ADS[a?.adId] !== null &&
            payload.adsEnabled
          ) {
            VideoadConfig?.push(data);
          }
          // }
        }
      });
      let PosteradConfig = [dataForPoster];
      let adConfig = VideoadConfig;
      let sources = [data];
      allChannelList.push({
        sources,
        logo: newImageIpPath + a?.thumbnailUrl,
        poster: newImageIpPath + a?.thumbnailUrl,
        cw_duration: timeStringToSeconds(filteredData[0]?.duration),
        name,
        title,
        duration: a?.duration,
        id: a?.id,
        index,
        seasonId: a?.seasonId,
        seasonTitle: payload?.EPISODE_DETAILS?.allseasons[a?.seasonId], // Added seasonTitle

        PosteradConfig: PosteradConfig,
        adConfig: adConfig,
        isCwRequired: payload.isCwRequired,
      });
    });
  return allChannelList;
};
