/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_LIVE_ASSETS_START,
  GET_LIVE_ASSETS_SUCCESS,
  GET_LIVE_ASSETS_FAILED,

  GET_FINAL_LIVE_ASSET_DETAILS,
  GET_BANNERS_START,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILED,
  GET_LIVE_GENRE_ASSETS_FOR_LISTING,
  GET_GENERAL_SETTINGS_START,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAILED,
  GET_GENRE_DETAILS_PROPS,
  GET_ASSET_OFFSET_LENGTH,
  GET_OFFSET_LENGTH,
} = ACTION_TYPES;

const initialState = {
  offset: 3,

  genres: [],
  liveAssetDetails: [],
  tagDetails: [],
  languageDetails: [],
  epgDetails: [],
  lastEpgDetails: [],
  nextEpgDetails: [],
  adsDetails: [],
  banners: [],
  liveAssetNotifications: [],

  isLoading: false,
  errors: null,
  liveVideoDetails: [],
  generalData: [],
  finaleLiveAssetDetails: [],
  genreId: null,
  newFinalData: [],
  data: [],
  newDataForRefreshing: [],
  changedGenreIndex: null,
  liveGenreAssetForListing: [],
  genreDetailsProps: {},
  assetOffsetLengthData: 5,
  genreOffsetLength: null,
  offsetLoading: false,
  generalPath: [],
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;

  switch (type) {
    case GET_LIVE_ASSETS_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_LIVE_ASSETS_SUCCESS:
      //console.log("payload details " + JSON.stringify(payload));
      const assetDetails = state.genres.filter(
        (genre) => (genre.id = payload.genreDetails.genreId)
      );

      const genreDetails = assetDetails[0].genreassetassociation;

      return {
        ...state,
        isLoading,
        errors,
      };

    case GET_LIVE_ASSETS_FAILED:
      return {
        ...state,

        isLoading,
        errors: payload.message,
      };

    case GET_OFFSET_LENGTH:
      return {
        ...state,

        offset: payload,
      };

    case GET_GENRE_DETAILS_PROPS:
      return {
        ...state,
        isLoading,
        genreDetailsProps: payload,
      };

    case GET_LIVE_GENRE_ASSETS_FOR_LISTING:
      return {
        ...state,
        isLoading,
        liveGenreAssetForListing: payload,
      };

    case GET_ASSET_OFFSET_LENGTH:
      return {
        ...state,
        assetOffsetLength: payload,
      };
    case GET_FINAL_LIVE_ASSET_DETAILS:
      // alert("hii iam comming");
      return {
        ...state,

        newFinalData: state.newDataForRefreshing,
      };

    case GET_BANNERS_START:
      return {
        ...state,

        isLoading,
        errors: payload.message,
      };

    case GET_BANNERS_SUCCESS:
      // alert(JSON.stringify(payload.data[0]));
      let bannerDetails;
      if (
        payload != undefined &&
        payload != null &&
        payload.data != undefined &&
        payload.data != null &&
        payload.data.length > 0
      ) {
        bannerDetails = payload.data;
      }
      return {
        ...state,

        banners: bannerDetails,
        isLoading,
        errors: payload.message,
      };

    case GET_BANNERS_FAILED:
      return {
        ...state,
        isLoading,
        // banners: [],
        errors: payload.message,
      };
    case GET_GENERAL_SETTINGS_START:
      return {
        ...state,
        // generalData: [],
        isLoading,
        errors: payload.message,
      };

    case GET_GENERAL_SETTINGS_SUCCESS:
      // console.log("arun ()(((())))" + JSON.stringify(payload));

      return {
        ...state,
        generalData: payload.data,
        isLoading,
        generalPath: payload?.data?.genesisAccessPath,

        errors: payload.message,
      };

    case GET_GENERAL_SETTINGS_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };

    default:
      return state;
  }
};
