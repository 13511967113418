/** @format */

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as SERIES_ACTION from "./../../../Redux/SeriesGenre/actions";
import * as SERIES_VIDEO_URL from "./../../../Redux/VideoRedux/action";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { newImageIpPath } from "../../../App";
import ProgressBarr from "../../progress-bar/ProgressBar";
import { continueWatchingSelector } from "../../../Redux/DATA-analytics/selector";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors";
import ReactTooltip from "react-tooltip";
import "./SeriesTabs.css";

const tabStyle = {
  default_tab: {
    color: "white",
    fontFamily: "Montserrat,sans-serif",
    fontSize: 15,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SeriesTabs = ({
  seriesSeasons,
  // setThumbnailUrl,
  flag,

  isPPVRequired,
  CONTINUE_WATCHING_DATA,
  CURRENT_PLATFORM_DATA,

  USER_DETAILS,
  AUTHENTICATION,
  from,
}) => {
  let total = 0;
  seriesSeasons?.forEach((season) => {
    total += season.seasonepisodes.length; // Assuming each season object has an 'episodes' array
  });
  console.log("arunvn123" + JSON.stringify(seriesSeasons));
  // let allEpisodes = [];

  // // Loop through each season in the seriesSeasons array
  // seriesSeasons.forEach((season) => {
  //   // Concatenate the seasonepisodes from each season to the allEpisodes array
  //   allEpisodes = allEpisodes.concat(season.seasonepisodes);
  // });
  // console.log("arunvn123allEpisodes" + JSON.stringify(allEpisodes));

  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const [value, setValue] = React.useState(0);
  const [episodeIndex, setEpisodeIndex] = useState(5);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    setTimeout(() => {
      setValue(0);
    }, 400);
  }, []);

  const handleClickSeason = (item) => {
    // setTeaserUrl(item.teaserUrl);
    // setThumbnailUrl(item.thumbnailUrl);
  };

  const handleVideoPlay = ({ startOver, episodeId }) => {
    history.push({
      pathname: "/seriesEpisodeVideoPlayer",
      state: {
        flag,
        isPPVRequired,
        startOver,
        total,
        from,
        episodeId,
      },
    });
  };

  const playIconRef = React.useRef({});
  // alert(seriesSeasons?.[0]?.seasonepisodes?.length);
  return (
    <div>
      <div>
        <div style={{ color: "black", top: "100px", zIndex: 990 }}>.</div>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              color: "white",
              marginLeft: "5%",
              top: "100px",
              zIndex: 10,
            }}
          >
            {/* <div style={{
          fontSize: "28px",
          width: "120px",
          color: "white",
          fontWeight: "bold",
          fontFamily:
            "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
          borderBottom: "2px solid white"
        }}>Episodes</div> */}
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
            >
              {seriesSeasons != undefined &&
              seriesSeasons != null &&
              seriesSeasons?.length > 0
                ? seriesSeasons?.map((item, indexx) => {
                    return (
                      <Tab
                        key={indexx}
                        label={item?.title}
                        {...a11yProps(indexx)}
                        onClick={() => handleClickSeason(item)}
                        style={tabStyle.default_tab}
                      />
                    );
                  })
                : ""}
            </Tabs>
          </Box>
          {}
          {seriesSeasons != undefined &&
          seriesSeasons != null &&
          seriesSeasons?.length > 0
            ? seriesSeasons?.map((item1, indexx) => {
                let allEpisodes = [];
                // Loop through each season in the seriesSeasons array
                seriesSeasons.forEach((season) => {
                  // Concatenate the seasonepisodes from each season to the allEpisodes array
                  allEpisodes = allEpisodes.concat(season.seasonepisodes);
                });

                const getSeasonsById = (seriesSeasons) => {
                  const seasonsById = {};

                  seriesSeasons.forEach((season) => {
                    seasonsById[season.id] = season.title;
                  });

                  return seasonsById;
                };
                const seasons = getSeasonsById(seriesSeasons);

                console.log("allseasons" + JSON.stringify(seasons));

                // console.log("allEpisodes" + JSON.stringify(allEpisodes));

                return (
                  <div>
                    <TabPanel value={value} index={indexx} key={indexx}>
                      {item1?.seasonepisodes != undefined &&
                      item1?.seasonepisodes != null &&
                      item1?.seasonepisodes?.length > 0
                        ? item1?.seasonepisodes?.map((item, index) => {
                            const episodeData = CONTINUE_WATCHING_DATA?.find(
                              (k) =>
                                Number(k.categoryId) === 3 &&
                                Number(k.assetId) === Number(item.seriesId) &&
                                Number(k.episodeId) === Number(item.id) &&
                                // Number(k?.isVideoEnded) === 0 &&
                                Number(k?.videoDeleted) === 0
                            );
                            const itemsWithVideoEnded = CONTINUE_WATCHING_DATA?.find(
                              (k) =>
                                Number(k.categoryId) === 3 &&
                                Number(k.assetId) === Number(item.seriesId) &&
                                Number(k.episodeId) === Number(item.id) &&
                                Number(k?.videoDeleted) === 0
                            );

                            const duration = episodeData
                              ? episodeData.duration
                              : "00:00:00";
                            const totalDuration = episodeData
                              ? episodeData.totalDuration
                              : "00:00:00";

                            const totalDurationInSeconds = convertTimeToSeconds(
                              totalDuration
                            );
                            const durationInSeconds = convertTimeToSeconds(
                              duration
                            );

                            const percentageViewed =
                              (durationInSeconds / totalDurationInSeconds) *
                              100;

                            return (
                              index < episodeIndex && (
                                <div key={index}>
                                  <div
                                    className="row col-lg-12 episode-tab-container "
                                    onLoad={() => {
                                      playIconRef.current[index].style.display =
                                        "none";
                                    }}
                                    onMouseOver={() => {
                                      playIconRef.current[index].style.display =
                                        "inline-block";
                                    }}
                                    onMouseLeave={() => {
                                      playIconRef.current[index].style.display =
                                        "none";
                                    }}
                                  >
                                    <div className="col-lg-12 row">
                                      <div
                                        className="col-lg-3 p-3"
                                        style={{
                                          height: "100%",

                                          alignItems: "center",
                                          justifyContent: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderRadius: "10px",
                                            minHeight: "100px",
                                            maxHeight: "150px",
                                            minWidth: "200px",
                                            objectFit: "contain",

                                            position: "relative",
                                          }}
                                        >
                                          <img
                                            style={{
                                              borderRadius: "10px",
                                              minHeight: "100px",
                                              maxHeight: "150px",
                                              minWidth: "200px",
                                              objectFit: "contain",
                                            }}
                                            src={
                                              newImageIpPath +
                                              item?.thumbnailUrl
                                            }
                                            className="img-fluid"
                                            alt=""
                                          />

                                          {/* {CONTINUE_WATCHING_DATA?.filter(
                                            (k) =>
                                              Number(k.categoryId) === 3 &&
                                              k.assetId === item.seriesId &&
                                              k.episodeId === item.seriesId
                                          )} */}
                                          {/* {duration} */}
                                          <div
                                            style={{
                                              zIndex: 200,
                                              position: "absolute",
                                              left: "3%",
                                              right: "3%",
                                              bottom: 2,
                                              width: "94%",
                                              height: "2%",
                                            }}
                                          >
                                            {" "}
                                            {AUTHENTICATION === true &&
                                            Number(
                                              CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                            ) == 1 &&
                                            Number(
                                              USER_DETAILS?.viewing_analytics_status
                                            ) == 1 &&
                                            percentageViewed != undefined &&
                                            percentageViewed != "NaN" &&
                                            !isNaN(percentageViewed) &&
                                            percentageViewed != null ? (
                                              <ProgressBarr
                                                completed={percentageViewed}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <div
                                            style={{
                                              zIndex: 200000000,
                                              bottom: 0,
                                              right: 0,
                                              position: "absolute",
                                              backgroundColor:
                                                "rgb(50,50,50,0.8)",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                borderBottom: "1px dotted gray",
                                                color: "white",
                                                fontFamily:
                                                  "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",

                                                textAlign: "right",
                                              }}
                                            >
                                              {item?.duration}
                                            </span>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            zIndex: 200000000,
                                            fontFamily:
                                              "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                            fontSize: "40px",
                                            position: "absolute",
                                          }}
                                          onClick={() => {
                                            dispatch(
                                              SERIES_ACTION.getSeriesEpisodeDetails(
                                                {
                                                  episodes: allEpisodes,
                                                  index: item.id,
                                                  assetTitle: item1?.title,
                                                  allseasons: seasons,
                                                }
                                              )
                                            );
                                            handleVideoPlay({
                                              startOver:
                                                AUTHENTICATION === true &&
                                                Number(
                                                  CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                                ) == 1 &&
                                                Number(
                                                  USER_DETAILS?.viewing_analytics_status
                                                ) == 1 &&
                                                percentageViewed != undefined &&
                                                percentageViewed != "NaN" &&
                                                !isNaN(percentageViewed) &&
                                                percentageViewed != null
                                                  ? 1
                                                  : 0,
                                              episodeId: item.id,
                                            });
                                          }}
                                        >
                                          <div
                                            ref={(ref) =>
                                              (playIconRef.current[index] = ref)
                                            }
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              border: "1px solid white",
                                              borderRadius: "50px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              backgroundColor: "black",
                                              display: "flex",
                                              opacity: "0.8",
                                            }}
                                          >
                                            <img
                                              src="images/button/playIcon.png"
                                              style={{
                                                height: "20px",
                                                cursor: "pointer",
                                                zIndex: 330000000,
                                                position: "absolute",
                                                top: 13,
                                                left: 10,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-9 p-3">
                                        <div className="row">
                                          <div className="col-lg-10">
                                            <h5
                                              style={{
                                                fontFamily:
                                                  "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                                fontSize: "25px",
                                                fontWeight: "bold",
                                                color: "white",
                                              }}
                                            >
                                              {item?.title}
                                            </h5>
                                          </div>

                                          <div
                                            className="col-lg-2"
                                            style={{
                                              color: "white",
                                              fontFamily:
                                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",

                                              fontSize: "17px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontFamily:
                                                  "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                              }}
                                            >
                                              <span>
                                                S{indexx + 1}/E{index + 1}
                                              </span>
                                              {/* StartOver Icon Condition */}
                                              <ReactTooltip type="dark" />

                                              {AUTHENTICATION === true &&
                                              Number(
                                                CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                              ) == 1 &&
                                              Number(
                                                USER_DETAILS?.viewing_analytics_status
                                              ) == 1 &&
                                              itemsWithVideoEnded !=
                                                undefined &&
                                              itemsWithVideoEnded != null &&
                                              itemsWithVideoEnded?.isVideoEnded ==
                                                0 ? (
                                                <div
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    data-tip="Start over"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      dispatch(
                                                        SERIES_ACTION.getSeriesEpisodeDetails(
                                                          {
                                                            episodes: allEpisodes,
                                                            index: item.id,
                                                            assetTitle:
                                                              item1?.title,
                                                            allseasons: seasons,
                                                          }
                                                        )
                                                      );
                                                      handleVideoPlay({
                                                        startOver: 0,
                                                        episodeId: item.id,
                                                      });
                                                    }}
                                                  >
                                                    <path
                                                      d="M0 0h24v24H0z"
                                                      fill="none"
                                                    />
                                                    <path
                                                      d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"
                                                      fill="#FFFFFF"
                                                    />
                                                  </svg>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            color: "#aaa",
                                            columnGap: "24px",
                                            width: "80%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "white",
                                              fontFamily:
                                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                            }}
                                          >
                                            {item?.shortDescription}
                                          </div>

                                          <div
                                            style={{
                                              color: "white",
                                              fontFamily:
                                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                            }}
                                          >
                                            {item?.longDescription}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })
                        : ""}

                      {item1?.seasonepisodes?.length > 5 &&
                      episodeIndex !== item1?.seasonepisodes?.length ? (
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setEpisodeIndex(item1?.seasonepisodes?.length)
                          }
                        >
                          <div
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "50px",
                              backgroundColor: "white",
                              opacity: "0.5",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="36px"
                              viewBox="0 0 24 24"
                              width="36px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                            </svg>
                          </div>
                        </div>
                      ) : item1?.seasonepisodes?.length > 5 &&
                        episodeIndex === item1?.seasonepisodes?.length ? (
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => setEpisodeIndex(5)}
                        >
                          <div
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "50px",
                              backgroundColor: "white",
                              opacity: "0.5",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="36px"
                              viewBox="0 0 24 24"
                              width="36px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPanel>
                  </div>
                );
              })
            : ""}
        </Box>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});
export default connect(mapStateToProps)(SeriesTabs);
