/** @format */

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as READ_NOTIFICATION_ACTION from "./../../Redux/readNotificationRedux/actions";
import "./../SeariesPages/seriesPageModal.css";
import { getNotificationsSelector } from "../../Redux/PushNotification/selectors";
import * as NOTIFICATION_ACTION from "./../../Redux/PushNotification/actions";
import { getMovieDetaisSelector } from "../../Redux/MoviesRedux/selector";
import "./Notification.css";
import { newImageIpPath } from "../../App";
import { seriesAssetSelector } from "../../Redux/SeriesGenre/selector";
import { allLiveAssetSelector } from "../../Redux/AllLiveDetails/selectors";
import { userDetailsSelector } from "../../Redux/authentication/selectors";
import ReactTooltip from "react-tooltip";
import * as VIDEO_ACTION from "../../Redux/VideoRedux/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";

const Notification = ({
  NOTIFICATION,
  LIVEASSETS,
  MOVIE_DETAILS,
  SERIES_ASSETS,
  USER_DETAILS,
  CURRENT_PLATFORM,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let currentNotifications = [];
  if (
    NOTIFICATION != undefined &&
    NOTIFICATION != null &&
    NOTIFICATION.length > 0
  ) {
    // for (let i = 0; i < 20; i++) {
    NOTIFICATION.map((item) => {
      currentNotifications.push(item);
    });

    // }
  }
  console.log(
    "USER_DETAILS?.notificationReadStatus " +
      USER_DETAILS?.notificationReadStatus
  );
  console.log("currentNotifications ", currentNotifications);

  const handleNotification = () => {
    let data = { id: USER_DETAILS?.id, notificationReadStatus: 1 };
    if (USER_DETAILS?.notificationReadStatus == 0) {
      dispatch(READ_NOTIFICATION_ACTION.getReadNotificationStart(data));
    }

    dispatch(NOTIFICATION_ACTION.getCurrentNotification(currentNotifications));
  };
  const handleModal = (item) => {
    if (
      item?.categoryId == 1 &&
      item?.assetId != undefined &&
      item?.assetId != null
    ) {
      let requestData = {
        id: item?.assetId,
        account_id: 1,
      };
      if (
        LIVEASSETS?.liveAssetDetails?.[item?.assetId] != undefined &&
        LIVEASSETS?.liveAssetDetails?.[item?.assetId] != null
      ) {
        dispatch(
          VIDEO_ACTION.getLiveChannelData(
            LIVEASSETS?.liveAssetDetails?.[item?.assetId]
          )
        );
      } else {
        dispatch(VIDEO_ACTION.getLiveDetailsStart(requestData));
      }

      history.push({
        pathname: "/liveDetailsPage",
        state: {
          from: "Notification",
        },
      });
    } else if (
      item?.categoryId == 2 &&
      item?.assetId != undefined &&
      item?.assetId != null
    ) {
      const element = MOVIE_DETAILS?.movieliveAssetDetails[item?.assetId];

      if (element != undefined && element != null) {
        dispatch(VIDEO_ACTION.getMovieData(element));
      } else {
        let requestData = {
          id: item?.assetId,
          account_id: 1,
        };

        dispatch(VIDEO_ACTION.getMovieDetailsStart(requestData));
      }

      history.push({
        pathname: "/MovieDetailsPage",
        state: {
          from: "Notification",
        },
      });
    } else if (
      item?.categoryId == 3 &&
      item?.assetId != undefined &&
      item?.assetId != null
    ) {
      const element = SERIES_ASSETS?.assetDetails[item?.assetId];

      if (element != undefined && element != null) {
        dispatch(VIDEO_ACTION.getSeriesData(element));
      } else {
        let requestData = {
          id: item?.assetId,
          account_id: 1,
        };

        dispatch(VIDEO_ACTION.getSeriesDetailsStart(requestData));
      }
      history.push({
        pathname: "/SeriesDetailsPage",
        state: {
          from: "Notification",
        },
      });
    }
  };

  return (
    <div>
      {currentNotifications != undefined &&
      currentNotifications != null &&
      currentNotifications.length > 0 ? (
        <li className="nav-item nav-icon">
          <a
            className="search-toggle"
            data-toggle="search-toggle"
            onClick={handleNotification}
          >
            <button className="button">
              <svg
                viewBox="0 0 448 512"
                data-tip="Notification"
                className="bell"
              >
                <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path>
              </svg>
              {USER_DETAILS?.notificationReadStatus == 0 ? (
                // <span
                //   style={{
                //     color: "white",
                //     backgroundColor: "red",
                //     borderRadius: "5px",
                //     paddingRight: "1px",
                //     paddingLeft: "1px",
                //     float: "right",
                //     marginBottom: "10px",
                //     fontWeight: "bold",
                //     fontSize: "10px",
                //   }}
                // >
                //   {currentNotifications.length}
                // </span>
                <span className="bg-danger dots"></span>
              ) : (
                ""
              )}
            </button>
          </a>

          <div
            className="iq-sub-dropdown"
            style={{
              overflowX: "hidden",
              height: "300px",
              overflowY: "scroll",
            }}
          >
            <ReactTooltip type="dark" />

            {/* new--- */}
            <div className="iq-card shadow-none m-0">
              <div className="iq-card-body">
                {currentNotifications != undefined &&
                currentNotifications != null &&
                currentNotifications.length > 0
                  ? currentNotifications.map((item, index) => (
                      <div
                        className="input"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          // class=""
                          key={index}
                          className="iq-sub-card value"
                          onClick={() => handleModal(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="media align-items-center">
                            <div className="right-icon pl-1">
                              {" "}
                              <div className="media align-items-center col-12">
                                <div
                                  className="col-3"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  {item != undefined &&
                                  item != null &&
                                  item.imageUrl != undefined &&
                                  item.imageUrl != null &&
                                  item.imageUrl?.indexOf("undefined") < 0 &&
                                  item.imageUrl?.indexOf("null") < 0 ? (
                                    <img
                                      src={newImageIpPath + item.imageUrl}
                                      className="img-fluid mr-3"
                                      style={{
                                        height: "50px",
                                        maxWidth: "60px",
                                      }}
                                      height={50}
                                      width={60}
                                    />
                                  ) : (
                                    <img
                                      src="images/login/noimage.jpg"
                                      className="img-fluid mr-3"
                                      style={{
                                        maxHeight: "50px",
                                        maxWidth: "60px",
                                      }}
                                      height={50}
                                      width={60}
                                    />

                                    // <div
                                    //   style={{ height: "50px", width: "60px" }}
                                    // ></div>
                                  )}
                                </div>
                                <div className="media-body col-9">
                                  {item != undefined &&
                                  item != null &&
                                  item.title != undefined &&
                                  item.title != null ? (
                                    item.title.length > 10 ? (
                                      <h6
                                        style={{
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                      >
                                        <marquee direction="left">
                                          {item.title}
                                        </marquee>
                                      </h6>
                                    ) : (
                                      <h6
                                        style={{
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {item.title}
                                      </h6>
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {item != undefined &&
                                  item != null &&
                                  item.message != undefined &&
                                  item.message != null ? (
                                    item.message.length > 10 ? (
                                      <h6 style={{ color: "white" }}>
                                        <marquee direction="left">
                                          {item.message}
                                        </marquee>
                                      </h6>
                                    ) : (
                                      <h6 style={{ color: "white" }}>
                                        {item.message}
                                      </h6>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  NOTIFICATION: getNotificationsSelector,
  LIVEASSETS: allLiveAssetSelector,
  MOVIE_DETAILS: getMovieDetaisSelector,
  SERIES_ASSETS: seriesAssetSelector,
  USER_DETAILS: userDetailsSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
});

export default connect(mapStateToProps)(Notification);
