/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionType";
import * as ACTION from "./actions";
import * as SERIES_API from "./../../services/seriesServices/series";
import { getSeriesGenresSuccess, getSeriesGenresFailed } from "./actions";
import * as ACTION_DATA_VERSIONING from "../Dataversion/actions";

const {
  GET_SERIES_GENRES_START,
} = ACTION_TYPES;

/** Sagas */

function* getSeriesGenresSaga(payload) {
  try {
    const { data } = yield call(SERIES_API.seriesGenreAsync, payload);
    yield put(getSeriesGenresSuccess(data));
    yield put(ACTION_DATA_VERSIONING.nullDataVersions("series"));
  } catch ({ message }) {
    yield put(getSeriesGenresFailed({ message }));
  }
}



/** Watchers or Observers */

function* getSeriesGenresWatcher() {
  while (true) {
    const { payload } = yield take(GET_SERIES_GENRES_START);
    yield call(getSeriesGenresSaga, payload);
  }
}


export default function* () {
  yield all([getSeriesGenresWatcher()]);
}
