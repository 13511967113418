import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./action";
import * as CHECK_MAIL_STATUS from "./../Checking the mail validity/actions";
import * as ACTIVE_API from "./../../services/email-activation/emailActivation";
const { ACTIVE_EMAIL_START } = ACTION_TYPES;

function* emailActivationSaga(payload) {
  // alert("poooooooooja" + JSON.stringify(payload));
  try {
    const { data } = yield call(ACTIVE_API.activeEmailAsync, payload);

    if (data.status == true) {
      // yield put(CHECK_MAIL_STATUS.clearMAilValidityState(false));
    }
    yield put(ACTION.activeEmailSuccess({ data }));
  } catch ({ message }) {
    yield put(ACTION.activeEmailFailed({ message }));
  }
}

function* activeEmailWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    const { payload } = yield take(ACTIVE_EMAIL_START);
    yield call(emailActivationSaga, payload);
  }
}

export default function*() {
  //console.log("root  saga is calling");
  yield all([activeEmailWatcher()]);
}
