import { createSelector } from "reselect";

const getLatestEpgDetails = (state) => {
  return state.latestEpgReducer.latestEpgDetails;
};

export const getLatestEpgDetailsSelector = createSelector(
  getLatestEpgDetails,
  (latestEpgDetails) => latestEpgDetails
);
