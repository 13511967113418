const ACTION_TYPES = {
  LOGIN_SUCCESS_NEW: "LOGIN_SUCCESS_NEW",
  LOGIN_FAILED: "LOGIN_FAILED",
  VERIFY_LOGIN_START: "VERIFY_LOGIN_START",

  LOGOUT_START: "LOGOUT_START",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILED: "LOGOUT_FAILED",

  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",

  USER_DETAILS_START: "USER_DETAILS_START",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILED: " USER_DETAILS_FAILED",

  LOGIN_STATUS_FALSE: "LOGIN_STATUS_FALSE",

  USER_DETAILS_ACTION: "USER_DETAILS_ACTION",
  UPDATE_ACCESS_TOKEN: "UPDATE_ACCESS_TOKEN",
};

export default ACTION_TYPES;
