import ACTION_TYPES from "./actionTypes";
const {
  GET_EPG_DETAILS_START,
  GET_EPG_DETAILS_SUCCESS,
  GET_EPG_DETAILS_FAILED,


  GET_EPG_STATE_SUCCESS,
  CLEAR_EPG_SUCCESS,
  EPG_UPPEND_SUCCESS,
  EPG_DATE,

} = ACTION_TYPES;

/** Platform List */
export const getEpgDetailsStart = (payload) => ({
  type: GET_EPG_DETAILS_START,
  payload,
});

export const getEpgDetailsSuccess = (payload) => ({
  type: GET_EPG_DETAILS_SUCCESS,
  payload,
});
export const getEpgUppendSuccess = (payload) => ({
  type: EPG_UPPEND_SUCCESS,
  payload,
});

export const getEpgDetailsFailed = (payload) => ({
  type: GET_EPG_DETAILS_FAILED,
  payload,
});
export const getEpgStateSuccess = (payload) => ({
  type: GET_EPG_STATE_SUCCESS,
  payload,
});
export const getEpgDate = (payload) => ({
  type: EPG_DATE,
  payload,
});
export const clearEpgSuccess = () => ({
  type: CLEAR_EPG_SUCCESS,
});

