import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");
// encription not needed
// export const activeEmailAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   // console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .get("/user/activate/" + payload.id, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       Promise.reject(error.response.data);
//     });
// };
export const activeEmailAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    var ciphertext = encryptData(payload?.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().get(
      "/user/activate/" + urlSafeCiphertext,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    );
    return response;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
