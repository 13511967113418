import videojs from "video.js";

const replayButtonPlugin = function() {
  const player = this;

  function initialize() {
    const playerEl = player.el();

    // Create the replay button
    const replayButton = document.createElement("button");
    replayButton.className = "vjs-replay-button";
    replayButton.innerHTML = getReplayIcon();
    replayButton.title = "Replay";
    replayButton.style.display = "none"; // Hide the button initially
    replayButton.style.position = "absolute";
    replayButton.style.top = "50%";
    replayButton.style.left = "50%";
    replayButton.style.transform = "translate(-50%, -50%)";
    replayButton.style.fontSize = "48px";
    replayButton.style.background = "none";
    replayButton.style.border = "none";
    replayButton.style.cursor = "pointer";
    replayButton.style.color = "#ffffff";
    replayButton.style.zIndex = "10"; // Ensure it appears above the video

    playerEl.appendChild(replayButton);

    // Replay button click handler
    replayButton.addEventListener("click", () => {
      player.currentTime(0);
      player.play();
    });

    // Show replay button when the video ends
    player.on("ended", () => {
      console.log("arun ended reply shown");
      var replayButton = document.querySelector(".vjs-replay-button");

      if (replayButton) {
        replayButton.style.display = "block";
      }
    });

    // Hide replay button when the video starts playing
    player.on("play", () => {
      var replayButton = document.querySelector(".vjs-replay-button");

      if (replayButton) {
        replayButton.style.display = "none";
      }
    });

    player.on("timeupdate", () => {
      player.on("playing", () => {
        var replayButton = document.querySelector(".vjs-replay-button");

        if (replayButton) {
          replayButton.style.display = "none";
        }
      });
    });
  }

  function getReplayIcon() {
    return ``;
  }

  initialize();
};

videojs.registerPlugin("replayButtonPlugin", replayButtonPlugin);

export default replayButtonPlugin;
