/** @format */

import { createSelector } from "reselect";

const getMovieDataForPlayer = (state) => {
  return state.vodAsset.selectMovieForPlayer;
};
export const getMovieDataForPlayerSelector = createSelector(
  getMovieDataForPlayer,
  (selectMovieForPlayer) => selectMovieForPlayer
);
