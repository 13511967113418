/** @format */

import ACTION_TYPES from "./actionType";

const {
  GET_SERIES_GENRES_START,
  GET_SERIES_GENRES_SUCCESS,
  GET_SERIES_GENRES_FAILED,
  GET_SERIES_GENRE_ASSETS_FOR_LISTING,
  GET_SERIES_EPISODE_DETAILS,
  MAKE_SERIES_LOADING_FALSE,
} = ACTION_TYPES;

const initialState = {
  genreDetails: [],
  assetDetails: [],
  isLoading: false,
  errors: null,
  seriesGenreAssetForListing: [],
  changedGenreIndex: null,
  seriesLoading: false,
  seriesEpisodeDetails: null,
  AssetLength: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  switch (type) {
    case GET_SERIES_GENRES_START:
      return {
        ...state,
        isLoading: true,
        errors: "",
        seriesLoading: true,
      };
    case GET_SERIES_GENRES_SUCCESS:
      // console.log(
      //   "payload.data[1].AssetDetails" +
      //     JSON.stringify(payload.data[1].AssetDetails)
      // );
      return {
        ...state,
        genreDetails: payload.data[0].genreDetails,
        assetDetails: payload.data[1].AssetDetails,
        AssetLength: payload?.assetlength,

        isLoading,
        errors: payload.message,
        seriesLoading: false,
      };

    case GET_SERIES_GENRES_FAILED:
      return {
        ...state,
        isLoading,
        seriesLoading: false,

        // genreDetails: [],
        // assetDetails: [],
        errors: payload.message,
      };

    case GET_SERIES_GENRE_ASSETS_FOR_LISTING:
      return {
        ...state,
        isLoading,
        seriesGenreAssetForListing: payload,
        testingData: { name: "poooja", id: 4 },
      };

    case GET_SERIES_EPISODE_DETAILS:
      console.log("GET_SERIES_EPISODE_DETAILS", payload);

      return {
        ...state,
        seriesEpisodeDetails: payload,
      };

    case MAKE_SERIES_LOADING_FALSE:
      return {
        ...state,
        seriesLoading: false,
      };

    default:
      return state;
  }
};
