import { all, call, put, take } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as LATEST_API from "./../../services/LatestEpg/latestEpg";
import * as ACTIONS from "./actions";

const { LATEST_EPG_START } = ACTION_TYPES;

function* getLatestEpgDetails(payload) {
  try {
    const { data } = yield call(LATEST_API.latestEpgDetails, payload);
    if (data.status == true) {
      yield put(ACTIONS.getLatestEpgSuccess({ data: data?.data }));
    }
  } catch ({ message }) {
    yield put(ACTIONS.getLatestEpgFailed({ message }));
  }
}

function* getLAtestEpgDetailsWatcher() {
  while (true) {
    const { payload } = yield take(LATEST_EPG_START);
    yield call(getLatestEpgDetails, payload);
  }
}
export default function*() {
  yield all([getLAtestEpgDetailsWatcher()]);
}
