import ACTION_TYPES from "./action.types";

const {
  GET_DEFAULT_PLATFORMS_LIST_START,
  GET_DEFAULT_PLATFORMS_LIST_SUCCESS,
  GET_DEFAULT_PLATFORMS_LIST_FAILED,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED,
} = ACTION_TYPES;

/** Platform List */
export const getDefaultPlatformsListStart = () => ({
  type: GET_DEFAULT_PLATFORMS_LIST_START,
});

export const getDefaultPlatformsListSuccess = (payload) => ({
  type: GET_DEFAULT_PLATFORMS_LIST_SUCCESS,
  payload,
});

export const getDefaultPlatformsListFailed = (payload) => ({
  type: GET_DEFAULT_PLATFORMS_LIST_FAILED,
  payload,
});

/** Platform List for Route */

export const getDefaultPlatformsListForRouteStart = () => ({
  type: GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START,
});

export const getDefaultPlatformsListForRouteSuccess = (payload) => ({
  type: GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_SUCCESS,
  payload,
});

export const getDefaultPlatformsListForRouteFailed = (payload) => ({
  type: GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_FAILED,
  payload,
});
