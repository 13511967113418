/** @format */

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { signupStart } from "../Redux/auth/actions";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { createStructuredSelector } from "reselect";
import { Alert } from "react-st-modal";
import { platformsSelector } from "../Redux/platforms/selectors";
import { getProfileAuthSelector } from "../Redux/auth/selectors";

const SignUp = ({ PLATFORM_ID, orgId, SIGNUP }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNumber: "",
    userId: "",
    password: "",
  });
  let account_id = PLATFORM_ID.currentPlatformId;
  const handleProfileChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
      account_id,
    });
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    //console.log("user Details ", user);

    const isValid = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    if (user.firstName == undefined || user.firstName.length == 0) {
      swal("First Name cannot be empty", "Wrong Input");
      return;
    } else if (user.firstName.length < 2) {
      swal("Wrong Input!  First Name must to be greaterthan two.");
      return;
    } else {
    }

    if (user.lastName == undefined || user.lastName.length == 0) {
      swal("Wrong Input! Last Name cannot be empty.");
      return;
    } else {
    }

    // if(handleChangeEmail(user.emailId.length==0)){
    //     Alert.alert('Wrong Input!', AUTH_ERROR_MESSAGE.emailId, [
    //         {text: 'Okay'},
    //     ]);
    //     return;
    // }
    if (user.emailId == undefined || user.emailId.length == 0) {
      swal("Wrong Input! Email  Id cannot be empty.");
      return;
    } else if (user.emailId.length && user.emailId.length < 5) {
      swal("Email should have minimum five characters");
      return;
    } else if (user.emailId.length >= 5 && !user.emailId.match(isValid)) {
      swal("Wrong Input! Please enter a valid email address.");
      return;
    } else {
    }
    if (user.mobileNumber == undefined || user.mobileNumber.length == 0) {
      swal("Wrong Input! Mobile number cannot be empty.");
      return;
    } else if (user.mobileNumber.length && user.mobileNumber.length < 10) {
      swal("Wrong Input! Mobile number must to be ten numbers");
      return;
    } else {
    }

    if (
      user.userId !== undefined &&
      user.userId.length !== 0 &&
      user.userId.length < 5
    ) {
      // Alert.alert('Wrong Input!', 'User name cannot be empty.', [
      //     { text: 'Okay' },
      // ]);
      swal("Wrong Input!", "User name must to  5 characters.");

      return;
    }
    // else if (user.userId.length && user.userId.length <5) {
    //     Alert.alert('Wrong Input!', 'User name must to  5 characters.', [
    //         { text: 'Okay' },
    //     ]);
    //     return;
    // }
    else {
    }
    if (
      user.password !== undefined &&
      user.password.length !== 0 &&
      user.password.length < 8
    ) {
      // Alert.alert('Wrong Input!', 'password cannot be empty.', [
      //     { text: 'Okay' },
      // ]);
      swal("Wrong Input!", "password must to be eight characters.");
      return;
    }
    // if (user.password.length && user.password.length <8) {
    //     Alert.alert('Wrong Input!', 'password must to be eight letters.', [
    //         { text: 'Okay' },
    //     ]);
    //     return;
    // }
    else {
    }

    // //console.log("user Details " + JSON.stringify(user));
    // user.createdBy = 94;
    // dispatch(AUTH_ACTION.signupStart(user));
    //alert(JSON.stringify(user))
    dispatch(signupStart(user));
    // history.push("/login");
  };
  //alert("hhhhhhhhhhhhhh"+JSON.stringify(SIGNUP.status))
  if (SIGNUP == true) {
    history.push("/login");
  }

  //alert("sign iupppp"+JSON.stringify(SIGNUP))

  const handleLogin = () => {
    history.push("/login");
  };
  return (
    <div>
      <section className="sign-in-page">
        <div className="container">
          <div className="row justify-content-center align-items-center height-self-center">
            <div className="col-lg-7 col-md-12 align-self-center">
              <div className="sign-user_card">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control mb-0"
                            style={{ color: "white" }}
                            id="exampleInputEmail2"
                            placeholder="enter your name"
                            autocomplete="off"
                            value={user.firstName}
                            name="firstName"
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            style={{ color: "white" }}
                            className="form-control mb-0"
                            id="exampleInputEmail3"
                            placeholder="last name"
                            autocomplete="off"
                            name="lastName"
                            value={user.lastName}
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email Id</label>
                          <input
                            type="email"
                            style={{ color: "white" }}
                            className="form-control mb-0"
                            id="exampleInputEmail2"
                            placeholder="enter your email"
                            autocomplete="off"
                            value={user.emailId}
                            name="emailId"
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mobile No</label>
                          <input
                            type="text"
                            style={{ color: "white" }}
                            className="form-control mb-0"
                            id="exampleInputEmail3"
                            placeholder="mobille no"
                            autocomplete="off"
                            value={user.mobileNumber}
                            name="mobileNumber"
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>User Name</label>
                          <input
                            type="text"
                            style={{ color: "white" }}
                            className="form-control mb-0"
                            id="exampleInputPassword2"
                            placeholder="userName"
                            value={user.userId}
                            name="userId"
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="password"
                            style={{ color: "white" }}
                            className="form-control mb-0"
                            id="exampleInputPassword2"
                            placeholder="password"
                            value={user.password}
                            name="password"
                            required
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-hover my-2"
                      onClick={handleClick}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <div
                    className="d-flex justify-content-center links"
                    style={{ color: "white" }}
                  >
                    Already have an account?
                    <a
                      className="text-primary ml-2"
                      onClick={handleLogin}
                      style={{ cursor: "pointer" }}
                    >
                      Sign In
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM_ID: platformsSelector,
  SIGNUP: getProfileAuthSelector,
});

export default connect(mapStateToProps)(SignUp);
