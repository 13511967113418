import { call, put, all, take } from "redux-saga/effects";
import { getMobileInfoAsync } from "./../../../services/manageprofile/manageProfileService";
// import * as PROFILE_ACION from "./../../ProfileDetails/actions";
import * as AUTHENTICATION_ACTIONS from "../../authentication/actions";

import {
  update_mobile_info_success,
  update_mobile_info_failure,
} from "./action";
import types from "./actiontype";

function* mobileInfoSaga(payload) {
  //console.log("response from mblsaga", JSON.stringify(payload));

  try {
    const { data } = yield call(getMobileInfoAsync, payload);
    //console.log("mobile data" + JSON.stringify(data));
    yield put(update_mobile_info_success(payload));

    yield put(AUTHENTICATION_ACTIONS.userDetailsAction({ id: payload.id }));
  } catch ({ error }) {
    yield put(update_mobile_info_failure({ error }));
  }
}

function* watcherSaga() {
  while (true) {
    const { payload } = yield take(types.UPDATE_MOBILE_INFO_START);
    yield call(mobileInfoSaga, payload);
  }
}
export default function*() {
  yield all([watcherSaga()]);
}
