import ACTION_TYPES from "./actionTypes";
const {
  LATEST_EPG_FAILED,
  LATEST_EPG_START,
  LATEST_EPG_SUCCESS,
} = ACTION_TYPES;

const initialState = {
  latestEpgDetails: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LATEST_EPG_START:
      return {
        ...state,
      };
    case LATEST_EPG_SUCCESS:
      return {
        ...state,

        latestEpgDetails: payload?.data,
      };

    case LATEST_EPG_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
