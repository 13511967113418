/** @format */

import React from "react";
import { newImageIpPath } from "../App";
const AdsPage = ({ ads }) => {
  let src = null;

  if (ads != undefined && ads != null && ads?.length > 0) {
    src = newImageIpPath + ads[0]?.adv_url;
  }

  return (
    <div>
      <div>
        {src != undefined && src != null ? (
          <img
            src={src}
            style={{
              marginTop: "10px",
              maxHeight: "100px",
              // maxWidth:"700px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AdsPage;
