import ACTION_TYPES from "./action.types";

const {
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_SUCCESS,
  GET_SEARCH_DATA_FAILED,
  GET_SEARCH_SERIES_DATA_FAILED,
} = ACTION_TYPES;

const initialState = {
  searchData: [],
  searchLoading: false,
  searchSeriesData: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;

  switch (type) {
    case GET_SEARCH_DATA_START:
      return {
        ...state,
        searchLoading: true,
      };

    case GET_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        searchData: payload?.data,
        searchLoading: false,
        errors,
      };

    case GET_SEARCH_DATA_FAILED:
      return {
        ...state,
        searchData: [],
        searchLoading: false,
        errors: payload?.message,
      };

    case GET_SEARCH_SERIES_DATA_FAILED:
      return {
        ...state,
        searchSeriesData: payload,
      };

    default:
      return state;
  }
};
