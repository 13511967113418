import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as ACTION_TYPES from "../../Redux/auth/actions";
import * as ACTION_TYPESS from "./../../Redux/platforms/actions";
import { createStructuredSelector } from "reselect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Carousel from "react-bootstrap/Carousel";

import { authSelector } from "../../Redux/auth/selectors";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import "./loginScreen.css";
import * as DEVICE_ACTION from "./../../Redux/Delete Device/actions";
import * as EPG_ACTION from "./../../Redux/EPG/action";
import * as LOGOUT_ACTION from "./../../Redux/auth/actions";
import * as CURRENT_PLATFORM_ACTION from "./../../Redux/platforms/actions";
import {
  getDeviceDetailsSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { getSaveDeviceTockenSelector } from "../../Redux/SaveDeviceTocken/selectors";
import { newImageIpPath } from "../../App";

import * as LOGIN_ACTION from "../../Redux/authentication/actions";

const LoginScreen = ({
  AUTH,
  PLATFORM_ID,
  CURRENT_PLATFORM,
  setOrgId,
  DEVICE_TOKEN,
  DEVICE_DETAILS,
  SAVED_DEVICE_TOCKEN,
  setIsVideoPlayer,
  setSelectedCategory,
}) => {
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  let navigationPath = "/container";

  if (defaultLandingPage == 1) {
    setSelectedCategory(1);
  } else if (defaultLandingPage == 2) {
    setSelectedCategory(2);
  } else if (defaultLandingPage == 3) {
    setSelectedCategory(3);
  } else {
    setSelectedCategory(1);
  }

  useEffect(() => {
    setIsVideoPlayer(true);
    // dispatch(DEVICE_ACTION.deviceStatus(false));
  }, []);

  const [loginCredential, setLoginCredential] = useState({
    username: null,
    password: null,
    remember_me: true,
  });
  document.title = "DishHome GO";

  // useEffect(() => {
  //   const script2 = document.createElement("script");

  //   script2.async = true;

  //   script2.src = "js/Login/script.js";
  //   const script3 = document.createElement("script");

  //   script3.async = true;

  //   script3.src = "js/Login/bootstrap.bundle.min.js";
  //   const script4 = document.createElement("script");

  //   script4.async = true;

  //   script4.src = "js/Login/custom.js";
  //   const script5 = document.createElement("script");

  //   script5.async = true;

  //   script5.src = "js/Login/jquery-ui.min.js";
  //   const script6 = document.createElement("script");

  //   script6.async = true;

  //   script6.src = "js/Login/jquery.min.js";
  //   const script7 = document.createElement("script");

  //   script7.async = true;

  //   script7.src = "js/Login/slick.min.js";

  //   document.body.appendChild(script2);
  // }, []);

  let platformData;
  if (
    PLATFORM_ID != undefined &&
    PLATFORM_ID != null &&
    PLATFORM_ID.platforms != undefined &&
    PLATFORM_ID.platforms != null &&
    PLATFORM_ID?.platforms?.length > 0
  ) {
    platformData = PLATFORM_ID?.platforms?.filter(({ status }) => status == 1);
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const [checkBox, setCheckBox] = useState(true);

  const [brandBackgroundImage, setBrandBackgroundImage] = useState(
    newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage
  );
  const [splashImage, setSplashImage] = useState(
    newImageIpPath + CURRENT_PLATFORM?.webapp_splashscreen
  );
  const [currentOrgId, setCurrentOrgId] = useState(CURRENT_PLATFORM?.orgId);
  useEffect(() => {
    setSplashImage(newImageIpPath + CURRENT_PLATFORM?.webapp_splashscreen);
    setCurrentOrgId(CURRENT_PLATFORM?.orgId);
    setBrandBackgroundImage(
      newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage
    );
  }, [CURRENT_PLATFORM]);
  const backgroundImageRef = useRef({});

  useEffect(() => {
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
    var arr2 = todayDate?.split("/");
    var arr3 = arr2[2] + "/" + arr2[1];
    let programMonth = arr2[1];
    let programmeDate = arr2[2];
    if (parseInt(programMonth) <= 9) {
      programMonth = "0" + programMonth;
    }
    if (parseInt(programmeDate) <= 9) {
      programmeDate = "0" + programmeDate;
    }

    let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
    let startTimeStamp = new Date(
      programMonth + "/" + programmeDate + "/" + arr2[0]
    ).setHours(0, 0, 0, 0);
    let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
    let data1 = {
      startTimeTimestamp: startTimeStamp,
      stopTimeTimestamp: stopTimeStamp,
      account_id: PLATFORM_ID?.currentPlatformId,
      offset: 0,
      limit: 10,
      a: false,
      programmeDate: date,
    };

    dispatch(EPG_ACTION.getEpgDetailsStart(data1));
  }, []);

  useEffect(() => {
    dispatch(
      CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(CURRENT_PLATFORM?.orgId)
    );
    dispatch(ACTION_TYPESS.getPlatformsListStart());
    dispatch(ACTION_TYPES.signUpStateDataClear(false));
  }, []);

  let osName = null;

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      osName = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      osName = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      osName = "Windows";
    } else if (/Android/.test(userAgent)) {
      osName = "Android";
    } else if (!os && /Linux/.test(platform)) {
      osName = "Linux";
    }

    return osName;
  }
  getOS();

  let browserName;
  function fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    // if (/Edg/.test(window.navigator.userAgent)) {
    // }
    let firefox = userAgent.indexOf("Edg");
    if (/edg|Edg/i.test(userAgent)) {
      browserName = "edge";
    } else if (/chrome|chromium|crios/i.test(userAgent)) {
      browserName = "chrome";
    } else if (/firefox|fxios/i.test(userAgent)) {
      browserName = "firefox";
    } else if (/safari/i.test(userAgent)) {
      browserName = "safari";
    } else if (/opr\//i.test(userAgent)) {
      browserName = "opera";
    } else {
      browserName = "No browser detection";
    }
  }

  fnBrowserDetect();
  const [credentials, setCrecentials] = useState(AUTH.credentials, {
    username: AUTH?.credentialsForRememberMe?.username,
    password: AUTH?.credentialsForRememberMe?.password,
    remember_me: true,
    deviceName: "device",
    newDeviceTocken: SAVED_DEVICE_TOCKEN,
    deviceToken: DEVICE_TOKEN,
  });

  useEffect(() => {
    setCrecentials({
      ...credentials,
      loginType: 2,
      username: AUTH?.credentialsForRememberMe?.username,
      password: AUTH?.credentialsForRememberMe?.password,
    });
    setLoginCredential({
      ...loginCredential,

      username: AUTH?.credentialsForRememberMe?.username,
      password: AUTH?.credentialsForRememberMe?.password,
    });
  }, []);

  // useEffect(() => {
  //   setCrecentials({ ...credentials, remember_me: checkBox });
  // }, [checkBox]);
  useEffect(() => {
    setCrecentials({
      ...credentials,
      deviceName: osName + "-" + browserName,
      newDeviceTocken: SAVED_DEVICE_TOCKEN,
      deviceToken: DEVICE_TOKEN,
      username: AUTH?.credentialsForRememberMe?.username,
      password: AUTH?.credentialsForRememberMe?.password,
      loginType: 2,
    });
    setLoginCredential({
      ...loginCredential,

      username: AUTH?.credentialsForRememberMe?.username,
      password: AUTH?.credentialsForRememberMe?.password,
    });
  }, []);
  useEffect(() => {
    if (DEVICE_TOKEN != null && DEVICE_TOKEN.length > 0) {
      setCrecentials({
        ...credentials,
        deviceToken: DEVICE_TOKEN,
        newDeviceTocken: SAVED_DEVICE_TOCKEN,
      });
    }
  }, []);
  function getPiPStateFromStorage() {
    const storedState = localStorage.getItem("pipState");
    const parsedState = storedState
      ? JSON.parse(storedState)
      : { isPiPMode: false };
    return parsedState.isPiPMode || false; // Return true if isPiPMode is true, otherwise return false
  }

  const isPiPMode = getPiPStateFromStorage();

  if (isPiPMode === true) {
    document.exitPictureInPicture(); // Exit PiP mode when navigating back
  }
  // useEffect(() => {
  //   if (
  //     AUTH != undefined &&
  //     AUTH != null &&
  //     AUTH.credentials != undefined &&
  //     AUTH.credentials != null &&
  //     AUTH.credentials.length > 0 &&
  //     AUTH.credentials.username.length > 0 &&
  //     AUTH.credentials.password.length > 0
  //   ) {
  //     credentials.account_id = PLATFORM_ID.currentPlatformId;

  //     dispatch(ACTION_TYPES.loginStart(credentials));
  //     dispatch(
  //       CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(
  //         PLATFORM_ID.currentPlatformId
  //       )
  //     );
  //   }
  // });

  const platformRef = useRef({});
  const [PlatformIndex, setPlatformIndex] = useState(0);
  useEffect(() => {
    platformData?.filter((item, index) => {
      if (item.orgId == CURRENT_PLATFORM?.orgId) {
        setPlatformIndex(index);
      }
    });
  }, []);
  const [a, seta] = useState(false);

  useEffect(() => {
    if (PlatformIndex != 0 && a == false) {
      setTimeout(() => {
        seta(true);
        platformRef?.current[PlatformIndex]?.click();
      }, 300);
    }
  }, [PlatformIndex]);

  const handleCheckBox = () => {
    setCheckBox(!checkBox);
    setLoginCredential((prev) => ({
      ...prev,
      remember_me: !prev.remember_me,
    }));
    // setCrecentials({ ...credentials, remember_me: !credentials.remember_me });
  };
  let account_id = PLATFORM_ID.currentPlatformId;
  const handleSignUp = () => {
    window.open("https://dishhome.com.np/signup?source=dishhomego");
    // window.open("http://demo1.dishhome.com.np/signup");
    // window.open("https://www.riversilica.com/");
  };

  const handleClickLogin = () => {
    let data = {
      username: loginCredential?.username?.trim(),
      password: loginCredential?.password?.trim(),
      remember_me: loginCredential.remember_me,
      deviceName: credentials?.deviceName,
      newDeviceTocken: credentials?.newDeviceTocken,
      deviceToken: credentials?.deviceToken,
      loginType: 2,
      account_id,
    };

    if (AUTH.deviceToken != null && AUTH.deviceToken.length > 0) {
      credentials.deviceToken = AUTH.deviceToken;
    }
    credentials.account_id = PLATFORM_ID.currentPlatformId;
    setCrecentials({
      ...credentials,
      // remember_me: false,
      newDeviceTocken: SAVED_DEVICE_TOCKEN,
    });

    if (
      data.username != undefined &&
      data.username != null &&
      data.username.length > 0
    ) {
      if (
        data.password != undefined &&
        data.password != null &&
        data.password.length > 0
      ) {
        // alert(JSON.stringify(data.remember_me));

        dispatch(
          LOGIN_ACTION.verifyLoginStart({
            username: loginCredential?.username?.trim(),
            password: loginCredential?.password?.trim(),
            loginType: "4",
            account_id: account_id,
            // firebaseToken: FIREBASETOKEN,
            rememberMe: loginCredential.remember_me,
            deviceId:
              credentials?.deviceToken == null
                ? credentials?.newDeviceTocken
                : credentials.deviceToken,

            history: history,
            navigationPath,
            newDeviceTocken: credentials?.newDeviceTocken,
            oldDeviceToken: credentials?.deviceToken,
          })
        );
        // dispatch(
        //   ACTION_TYPES.loginStart({
        //     credentials: data,
        //     history: history,
        //     navigationPath,
        //   })
        // );
      } else {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text: "Password should not be empty",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Username should not be empty",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }
  };

  const handleClickPlatform = (item) => {
    //setSplashImage(newImageIpPath + item.webapp_splashscreen);
    //setBrandBackgroundImage(newImageIpPath + item.webappBackgroundImage);
    dispatch(CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(item.orgId));

    let account_id = item.orgId;

    // if (AUTH.credentials.remember_me == true && AUTH.isAuthenticated == true) {
    //   history.push("/devicesPage");
    // } else {
    //   // dispatch(LOGOUT_ACTION.logoutStart());

    //   history.push("/login");
    // }

    dispatch(CURRENT_PLATFORM_ACTION.getSelectedPlatform(item));
    // dispatch(LOGOUT_ACTION.getDeviceListStart({ account_id }));
    // setOrgId(item?.orgId);
  };

  const handleCredentials = (e) => {
    const value = e.target.value;
    setCrecentials({
      ...credentials,
      loginType: 2,
      [e.target.name]: value || "",
      account_id,
    });
    setLoginCredential({
      ...loginCredential,

      [e.target.name]: value || "",
    });
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      handleClickLogin();
    }
  };
  const handleForgotPassword = () => {
    window.open("https://dishhome.com.np/dishhome-go");
    // window.open("http://demo1.dishhome.com.np/dishhome-go");
    // window.open("https://www.riversilica.com/");

    // history.push("/forgotPasswordPage");
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      centerMode: true,
      centerSlidePercentage: 33.3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      centerMode: true,
      centerSlidePercentage: 50,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true,
      centerSlidePercentage: 100,
      slidesToSlide: 1,
    },
  };
  return (
    <div>
      <div
        // className="col-lg-11"
        // ref={backgroundImageRef}
        style={{
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(${brandBackgroundImage})`,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "fixed",
          overflowX: "scroll",
        }}
      >
        <div className="dish-login-page-wrap col-lg-12">
          <div className="dish-login1 col-lg-12">
            <div className="login-box1 d-flex align-items-center">
              <div className="login-image-slide-wrap1">
                <div className="login-image-slide1">
                  {platformData?.map((a, index) => (
                    <div key={index}>
                      <div className="login-left-image1">
                        <img className="d-desktop1" src={splashImage} />
                        <img className="d-mobile1" src={splashImage} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="login-right-dtl1">
                <h3 style={{ color: "white" }}>Welcome to DishHome.</h3>
                <div className="login-dtl-inn1">
                  <span className="platform-tag1" style={{ color: "white" }}>
                    Choose Platform : {CURRENT_PLATFORM?.productName}
                  </span>
                  <div
                    className="login-platform1"
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    {platformData?.length > 0 && (
                      <Carousel
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        keyBoardControl={true}
                        focusOnSelect={false}
                        activeSlide={3}
                      >
                        {platformData?.map((item, index) => (
                          <div
                            ref={(ref) => (platformRef.current[index] = ref)}
                            onClick={() => {
                              handleClickPlatform(item);
                            }}
                            platform-single
                            className={
                              item.orgId == currentOrgId ? "activePlat" : ""
                            }
                            style={{ margin: "10px" }}
                          >
                            <div className="platform-single-inn d-flex flex-column justify-content-between align-items-center">
                              <div
                                className="platform-s-image"
                                style={{
                                  width: "100%",
                                  height: "90px",
                                  padding: "10px",
                                  background:
                                    "linear-gradient(360deg, rgba(0, 0, 0, 0.8) 19.5%, rgba(0, 0, 0, 0) 44%), rgba(255, 255, 255, 0.8)",
                                  borderRadius: "12px",
                                }}
                              >
                                <img
                                  src={newImageIpPath + item.productLogo}
                                  alt="null"
                                />
                                <p
                                  className="mt-2"
                                  style={{
                                    fontSize: "10px",
                                    lineHeight: "12px",
                                    fontWeight: 600,
                                    textAlign: "center",
                                    color: "white",
                                    whiteSpace: "nowrap",
                                    bottom: "2px",
                                    zIndex: 1,
                                    position: "fixed",
                                    width: "70px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item?.productName}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>
                  <div className="login-form-wrap1">
                    <div className="login-input">
                      <div
                        className="input-single1 input-name1"
                        style={{
                          position: "relative",
                          borderRadius: "12px 12px 0 0",
                          borderBottom: "2px solid transparent",
                        }}
                      >
                        <input
                          type="text"
                          onChange={handleCredentials}
                          name="username"
                          value={loginCredential.username}
                          required
                          onKeyPress={onKeyPress}
                          placeholder="User Name"
                          maxLength="25"
                          style={{
                            width: "100%",
                            height: "48px",
                            backgroundColor: "transparent",
                            // background: "rgba(255, 255, 255, 0.8)",
                            outline: 0,
                            border: "2px solid #534040",
                            transition: "all 0.3s ease-in-out",
                            padding: "15px 15px 15px 44px",
                            fontSize: "14px",
                            color: "white",
                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            left: "17px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <img
                            className="login-user-n login-field-n1"
                            src="images/LoginPage/login-user.svg"
                          />
                          <img
                            className="login-user-c login-field-c1"
                            src="images/LoginPage/login-user-c.svg"
                          />
                        </span>
                      </div>
                      <div
                        className="input-single1 input-pass1"
                        style={{ position: "relative", color: "black" }}
                      >
                        <input
                          type={passwordType}
                          name="password"
                          value={loginCredential?.password}
                          onChange={handleCredentials}
                          maxLength="25"
                          required
                          onKeyPress={onKeyPress}
                          placeholder="Password"
                          style={{
                            width: "100%",
                            height: "48px",
                            background: "rgba(255, 255, 255, 0.8)",
                            backgroundColor: "transparent",
                            outline: 0,
                            border: "2px solid #534040",
                            transition: "all 0.3s ease-in-out",
                            padding: "15px 15px 15px 44px",
                            fontSize: "14px",
                            color: "white",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            left: "17px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <img
                            className="login-pass-n login-field-n1"
                            src="images/LoginPage/login-pass.svg"
                          />
                          <img
                            className="login-pass-c login-field-c1"
                            src="images/LoginPage/login-pass-c.svg"
                          />
                        </span>
                        <span className="pass-eye1">
                          {passwordType === "password" ? (
                            <i
                              className="bi bi-eye-slash"
                              style={{ color: "gray", float: "right" }}
                              onClick={togglePassword}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye"
                              onClick={togglePassword}
                              style={{ color: "gray", float: "right" }}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="rem-forgot1 d-flex align-items-center">
                      <div
                        className="remember-field1 d-flex align-items-center"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          height: "100%",
                        }}
                      >
                        <input
                          onClick={handleCheckBox}
                          type="checkbox"
                          name=""
                          value="true"
                          checked={checkBox}
                          style={{
                            appearance: "none",
                            backgroundColor: "var(--form-background)",
                            margin: 0,
                            font: "fontawesome",
                            color: "#fff",
                            width: "1.15em",
                            height: "1.15em",
                            border: "2px solid #534040",
                            borderRadius: "2px",
                            transform: "translateY(-0.075em)",
                            display: "grid",
                            placeContent: "center",
                            backgroundColor: "rgba(255, 239, 239, 0.4)",
                          }}
                        />
                        <label
                          className="ml-1 mt-1"
                          style={{
                            color: "white",
                            fontSize: "12px",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Remember Me
                        </label>
                      </div>
                      <div
                        className="forgot-pass1 d-flex justify-content-end"
                        style={{ marginLeft: "50px" }}
                      >
                        <a
                          className="ml-4"
                          style={{
                            cursor: "pointer",
                            color: "white",
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "15px",
                            display: "inline-block",
                          }}
                          onClick={handleForgotPassword}
                        >
                          Forgot Password ?
                        </a>
                      </div>
                    </div>
                    <div className="login-btn1">
                      <button type="button" onClick={handleClickLogin}>
                        Sign In
                      </button>
                    </div>
                    <p style={{ color: "white" }}>
                      New to DishHome GO?
                      <a
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={handleSignUp}
                      >
                        {" "}
                        Sign up now{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  AUTH: authSelector,
  PLATFORM_ID: platformsSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_DETAILS: getDeviceDetailsSelector /* Selector contains Device Details */,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
});

export default connect(mapStateToProps)(LoginScreen);
