/** @format */

import { call, put, takeLatest, take, all } from "redux-saga/effects";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import { getPasswordInfoAsync } from "./../../../services/manageprofile/manageProfileService";
// import { update_email_info_failure } from "../emailInfo/action";
import * as AUTHENTICATION_ACTIONS from "../../../Redux/authentication/actions";
import * as DEVICE_ACTION from "../../../Redux/auth/actions";

import {
  update_password_info_success,
  update_password_info_failure,
} from "./action";
import types from "./actiontype";
import { socketStatus, ws } from "../../../App";

function* passwordInfoSaga(payload) {
  // console.log("responsed..../////", JSON.stringify(payload));
  try {
    const { data } = yield call(getPasswordInfoAsync, payload);
    // console.log("dataaa" + JSON.stringify(data));

    if (data.status == true) {
      if (socketStatus == false) {
        ws.send(
          JSON.stringify({
            packageId: payload?.packageId,
            userId: payload?.updatedBy,
            type: "password",
          })
        );
      }

      if (payload?.rememberMe == true) {
        // console.log("arun" + JSON.stringify(data?.data.data?.[0]));

        yield put(
          DEVICE_ACTION.saveCredentialsForRememberMe({
            username: payload.userId,
            password: payload?.confirmPassword,
            remember_me: payload?.rememberMe,
          })
        );
      } else {
        yield put(
          DEVICE_ACTION.saveCredentialsForRememberMe({
            username: null,
            password: null,
            remember_me: false,
          })
        );
      }

      let data = {
        deviceId: payload.deviceId,
        userId: payload.updatedBy,
        orgId: payload.account_id,
      };
      // alert(JSON.stringify(payload));
      yield put(AUTHENTICATION_ACTIONS.logoutStart(data));
    }

    yield put(update_password_info_success(payload));

    // Swal.fire({
    //   icon: "success",
    //   // position: "top-end",
    //   text: "Updated successfully",
    //   allowOutsideClick: () => !swal.isLoading(),
    //   confirmButtonColor: "#3085d6",
    //   button: "OK",
    // });
    swal({
      text: "Password updated successfully.",
      icon: "success",
      closeOnClickOutside: false,
      confirmButtonColor: "#3085d6",
      // dangerMode: true,
    });
  } catch ({ error }) {
    yield put(update_password_info_failure({ error }));
  }
}

function* watcherSaga() {
  while (true) {
    const { payload } = yield take(types.UPDATE_PASSWORD_INFO_START);
    yield call(passwordInfoSaga, payload);
  }
}

export default function*() {
  yield all([watcherSaga()]);
}
