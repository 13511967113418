/** @format */

import ACTION_TYPES from "./actionTypes";
import { newImageIpPath } from "../../App";
const {
  GET_EPG_DETAILS_START,
  GET_EPG_DETAILS_SUCCESS,
  GET_EPG_DETAILS_FAILED,
  GET_EPG_STATE_SUCCESS,
  CLEAR_EPG_SUCCESS,
  EPG_UPPEND_SUCCESS,
  EPG_DATE,

} = ACTION_TYPES;

let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

var arr2 = todayDate.split("/");
//console.log("dghdsh ......", arr2);
var arr3 = arr2[2] + "/" + arr2[1];
//console.log("concatinated array", arr3);
let programMonth = arr2[1];
let programmeDate = arr2[2];
if (parseInt(programMonth) <= 9) {
  programMonth = "0" + programMonth;
}
if (parseInt(programmeDate) <= 9) {
  programmeDate = "0" + programmeDate;
}
// programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;
let newDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

const initialState = {
  epgDetails: { epgCatchup: [{ latha: "all lies" }] },
  isLoading: false,
  errors: "",
  epgApiDate: newDate,
  epgDate: todayDate,
  epgLoading: false,
  epgProccesing: false,
  updateOffsetEpg: false,
  epgLoadingStatus: false,
  programmeDate: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EPG_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        errors: "",
        epgLoading: true,
        epgProccesing: true,
        epgLoadingStatus: true,
        epgDetails: payload?.a ? state?.epgDetails : { channels: [], epg: [] },
        programmeDate: payload?.programmeDate,
        updateOffsetEpg: payload?.a,
      };
    case GET_EPG_DETAILS_SUCCESS:
      var channels;
      var epg;
      let channelName;
      var programmeDate = null;
      var channelData = null;
      var channels = payload?.programData?.data.map((item) => {
        var uuid = item.id + "";
        var title = item.title;
        var assetUrl = item.assetUrl;
        let drmId = item.drmId;
        channelName = item.title;
        var logo;
        if (
          item.thumbnails != undefined &&
          item.thumbnails != null &&
          item.thumbnails.length > 0
        ) {
          logo = newImageIpPath + item.thumbnails[0].thumbnailUrl;
        }

        channelData = { uuid, logo, title, assetUrl, drmId };
        if (item?.epg?.length > 0) {
          return channelData;
        }
      });
      epg = [];
      var programEpg = [];
      var itemChannelUuid = null;
      payload?.programData?.data?.map((item) => {
        programEpg = [];

        var thumbnail;
        if (
          item.thumbnails != undefined &&
          item.thumbnails != null &&
          item.thumbnails.length > 0
        ) {
          thumbnail = newImageIpPath + item?.thumbnails?.[0]?.thumbnailUrl;
        }
        var assetUrl = item.assetUrl;
        let assetId = item.id;
        item.epg.map((item) => {
          let startDate = new Date(Number(item?.startTimeTimestamp));
          // alert(startDate);
          let startYear = startDate.getFullYear();
          let startMonth = startDate.getMonth() + 1;
          if (parseInt(startMonth) <= 9) {
            startMonth = "0" + startMonth;
          }
          let startDay = startDate.getDate();
          if (parseInt(startDay) <= 9) {
            startDay = "0" + startDay;
          }
          let startHour = startDate.getHours();
          if (parseInt(startHour) <= 9) {
            startHour = "0" + startHour;
          }
          let startMinute = startDate.getMinutes();
          if (parseInt(startMinute) <= 9) {
            startMinute = "0" + startMinute;
          }
          let startSec = startDate.getSeconds();
          if (parseInt(startSec) <= 9) {
            startSec = "0" + startSec;
          }

          let stopDate = new Date(Number(item?.stopTimeTimestamp));
          let stopYear = stopDate.getFullYear();
          let stopMonth = stopDate.getMonth() + 1;
          if (parseInt(stopMonth) <= 9) {
            stopMonth = "0" + stopMonth;
          }
          let stopDay = stopDate.getDate();
          if (parseInt(stopDay) <= 9) {
            stopDay = "0" + stopDay;
          }
          let stopHour = stopDate.getHours();
          if (parseInt(stopHour) <= 9) {
            stopHour = "0" + stopHour;
          }
          let stopMinute = stopDate.getMinutes();
          if (parseInt(stopMinute) <= 9) {
            stopMinute = "0" + stopMinute;
          }
          let stopSec = stopDate.getSeconds();
          if (parseInt(stopSec) <= 9) {
            stopSec = "0" + stopSec;
          }

          let since =
            startYear +
            "-" +
            startMonth +
            "-" +
            startDay +
            "T" +
            startHour +
            ":" +
            startMinute +
            ":" +
            startSec;
          let till =
            stopYear +
            "-" +
            stopMonth +
            "-" +
            stopDay +
            "T" +
            stopHour +
            ":" +
            stopMinute +
            ":" +
            stopSec;

          var id = item.id;
          var title = item.title;

          var channelUuid = item.assetId;
          itemChannelUuid = item.assetId;
          let selectedDateTimeStamp = item.startTimeTimestamp;
          let selectedDate = new Date(Number(selectedDateTimeStamp));
          let selectedMonth = selectedDate.getMonth() + 1;
          programmeDate =
            selectedDate.getFullYear() +
            "-" +
            selectedMonth +
            "-" +
            selectedDate.getDate();
          var image = thumbnail;
          var startTimeTimeStamp = item.startTimeTimestamp;
          var stopTimeTimeStamp = item.stopTimeTimestamp;
          let description = item.description;
          var since1 = item.programmeDate + "T" + item.startTime;
          var till1 = item.programmeDate + "T" + item.stopTime;

          var startTime = item.startTime;
          var stopTime = item.stopTime;

          var epgData = {
            id,
            title,
            since,
            till,
            channelUuid,
            image,
            startTimeTimeStamp,
            stopTimeTimeStamp,
            assetUrl,
            assetId,
            description,
            channelName,
            startTime,
            stopTime,
            name: "pppp",
          };
          programEpg.push(epgData);
        });
        if (item?.perviousdayepg?.length > 0) {
          let item2 = item?.perviousdayepg[0];

          let startDate = new Date(Number(item2?.startTimeTimestamp));
          startDate.setDate(startDate.getDate() + 1);
          let currentProgrammeDate = new Date(programmeDate);
          currentProgrammeDate.setHours(
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            startDate.getMilliseconds()
          );

          if (startDate.getTime() === currentProgrammeDate.getTime()) {
            let startYear = startDate.getFullYear();
            let startMonth = startDate.getMonth() + 1;
            if (parseInt(startMonth) <= 9) {
              startMonth = "0" + startMonth;
            }
            let startDay = startDate.getDate();
            if (parseInt(startDay) <= 9) {
              startDay = "0" + startDay;
            }
            let startHour = startDate.getHours();
            if (parseInt(startHour) <= 9) {
              startHour = "0" + startHour;
            }
            let startMinute = startDate.getMinutes();
            if (parseInt(startMinute) <= 9) {
              startMinute = "0" + startMinute;
            }
            let startSec = startDate.getSeconds();
            if (parseInt(startSec) <= 9) {
              startSec = "0" + startSec;
            }

            let stopDate = new Date(Number(item2?.stopTimeTimestamp));

            let stopYear = stopDate.getFullYear();
            let stopMonth = stopDate.getMonth() + 1;
            if (parseInt(stopMonth) <= 9) {
              stopMonth = "0" + stopMonth;
            }
            let stopDay = stopDate.getDate();
            if (parseInt(stopDay) <= 9) {
              stopDay = "0" + stopDay;
            }
            let stopHour = stopDate.getHours();
            if (parseInt(stopHour) <= 9) {
              stopHour = "0" + stopHour;
            }
            let stopMinute = stopDate.getMinutes();
            if (parseInt(stopMinute) <= 9) {
              stopMinute = "0" + stopMinute;
            }
            let stopSec = stopDate.getSeconds();
            if (parseInt(stopSec) <= 9) {
              stopSec = "0" + stopSec;
            }

            let since =
              startYear + "-" + startMonth + "-" + startDay + "T00:00:00";
            let till =
              stopYear +
              "-" +
              stopMonth +
              "-" +
              stopDay +
              "T" +
              stopHour +
              ":" +
              stopMinute +
              ":" +
              stopSec;

            let id = item2.id;
            let title = item2.title;

            let channelUuid = item2.assetId;

            let startTimeTimeStamp = item2.startTimeTimestamp;
            let stopTimeTimeStamp = item2.stopTimeTimestamp;
            let startTime = item2.startTime;
            let stopTime = item2.stopTime;
            let description = item2.description;
            let isEnabled = true;

            programEpg.unshift({
              id,
              title,
              since,
              till,
              channelUuid,
              startTimeTimeStamp,
              stopTimeTimeStamp,
              startTime,
              stopTime,
              description,
              isEnabled,
              pooja: "poojasss",
              channelName,
              assetId: item2?.assetId,
            });
          } else {
            let firstProgramStartTimestamp = programEpg[0].startTimeTimeStamp;
            let firstProgramStartTime = programEpg[0].startTime;

            // alert(firstProgramStartTimestamp);

            let newProgrammeDate = new Date(Number(firstProgramStartTimestamp));
            // alert(newProgrammeDate);
            let newProgrammeHours = newProgrammeDate.getHours();
            if (parseInt(newProgrammeHours) <= 9) {
              newProgrammeHours = "0" + newProgrammeHours;
            }

            let newProgrammeMinutes = newProgrammeDate.getMinutes();
            if (parseInt(newProgrammeMinutes) <= 9) {
              newProgrammeMinutes = "0" + newProgrammeMinutes;
            }

            let newProgrammeSeconds = newProgrammeDate.getSeconds();
            if (parseInt(newProgrammeSeconds) <= 9) {
              newProgrammeSeconds = "0" + newProgrammeSeconds;
            }

            let todayStartDate = new Date(programmeDate);
            todayStartDate.setHours(0, 0, 0, 0);

            let previousDate = new Date(programmeDate);
            previousDate.setDate(previousDate.getDate() - 1);
            previousDate.setHours(0, 0, 0, 0);
            let previousDateMonth = previousDate.getMonth() + 1;
            if (parseInt(previousDateMonth) <= 9) {
              previousDateMonth = "0" + previousDateMonth;
            }

            let todayStartMilliseconds = todayStartDate.getTime();
            let todayMonth = todayStartDate.getMonth() + 1;

            if (parseInt(todayMonth) <= 9) {
              todayMonth = "0" + todayMonth;
            }

            // if (firstProgramStartTimestamp - todayStartMilliseconds > 10) {
            //   let id1 = firstProgramStartTimestamp;
            //   let title1 = "Programme Info Not Available";
            //   let since1 =
            //     todayStartDate.getFullYear() +
            //     "-" +
            //     todayMonth +
            //     "-" +
            //     todayStartDate.getDate() +
            //     "T00:00:00";
            //   let till1 =
            //     todayStartDate.getFullYear() +
            //     "-" +
            //     todayMonth +
            //     "-" +
            //     todayStartDate.getDate() +
            //     "T" +
            //     newProgrammeHours +
            //     ":" +
            //     newProgrammeMinutes +
            //     ":" +
            //     newProgrammeSeconds;
            //   //alert(itemChannelUuid);
            //   let channelUuid1 = itemChannelUuid;

            //   let startTimeTimeStamp1 = todayStartMilliseconds;
            //   let stopTimeTimeStamp1 = firstProgramStartTimestamp;
            //   let startTime1 =
            //     todayStartDate.getHours() + ":" + todayStartDate.getMinutes();
            //   let stopTime1 =
            //     newProgrammeHours +
            //     ":" +
            //     newProgrammeMinutes +
            //     ":" +
            //     newProgrammeSeconds;
            //   let description1 = "Programme Info Not Available";
            //   let isEnabled1 = false;

            //   programEpg.unshift({
            //     id: id1,
            //     title: title1,
            //     since: since1,
            //     till: till1,
            //     channelUuid: channelUuid1,
            //     startTimeTimeStamp: startTimeTimeStamp1,
            //     stopTimeTimeStamp: stopTimeTimeStamp1,
            //     startTime: startTime1,
            //     stopTime: stopTime1,
            //     description: description1,
            //     isEnabled: isEnabled1,
            //     pooja1: "pooja",
            //     channelName,
            //     assetId: itemChannelUuid,
            //   });
            // }
          }
        } else {
          //alert(programmeDate);
          let firstProgramStartTimestamp = programEpg?.[0]?.startTimeTimeStamp;
          let firstProgramStartTime = programEpg?.[0]?.startTime;

          // alert(firstProgramStartTimestamp);

          let newProgrammeDate = new Date(Number(firstProgramStartTimestamp));
          // alert(newProgrammeDate);
          let newProgrammeHours = newProgrammeDate.getHours();
          if (parseInt(newProgrammeHours) <= 9) {
            newProgrammeHours = "0" + newProgrammeHours;
          }

          let newProgrammeMinutes = newProgrammeDate.getMinutes();
          if (parseInt(newProgrammeMinutes) <= 9) {
            newProgrammeMinutes = "0" + newProgrammeMinutes;
          }

          let newProgrammeSeconds = newProgrammeDate.getSeconds();
          if (parseInt(newProgrammeSeconds) <= 9) {
            newProgrammeSeconds = "0" + newProgrammeSeconds;
          }

          let todayStartDate = new Date(programmeDate);
          todayStartDate.setHours(0, 0, 0, 0);

          let previousDate = new Date(programmeDate);
          previousDate.setDate(previousDate.getDate() - 1);
          previousDate.setHours(0, 0, 0, 0);
          let previousDateMonth = previousDate.getMonth() + 1;
          if (parseInt(previousDateMonth) <= 9) {
            previousDateMonth = "0" + previousDateMonth;
          }

          let todayStartMilliseconds = todayStartDate.getTime();
          let todayMonth = todayStartDate.getMonth() + 1;

          if (parseInt(todayMonth) <= 9) {
            todayMonth = "0" + todayMonth;
          }
          let startDate = todayStartDate.getDate();
          if (parseInt(startDate) <= 9) {
            startDate = "0" + startDate;
          }
          // if (firstProgramStartTimestamp - todayStartMilliseconds > 10) {
          //   let id1 = firstProgramStartTimestamp;
          //   let title1 = "Programme Info Not Available";
          //   let since1 =
          //     todayStartDate.getFullYear() +
          //     "-" +
          //     todayMonth +
          //     "-" +
          //     startDate +
          //     "T00:00:00";
          //   let till1 =
          //     todayStartDate.getFullYear() +
          //     "-" +
          //     todayMonth +
          //     "-" +
          //     startDate +
          //     "T" +
          //     newProgrammeHours +
          //     ":" +
          //     newProgrammeMinutes +
          //     ":" +
          //     newProgrammeSeconds;
          //   //alert(itemChannelUuid);
          //   let channelUuid1 = itemChannelUuid;

          //   let startTimeTimeStamp1 = todayStartMilliseconds;
          //   let stopTimeTimeStamp1 = firstProgramStartTimestamp;
          //   let programmeDateArray = programmeDate?.split("-");
          //   let programYear = programmeDateArray?.[0];
          //   let programMonth = programmeDateArray?.[1];
          //   let programDay = programmeDateArray?.[2];
          //   if (parseInt(programMonth <= 9)) {
          //     programMonth = "0" + programMonth;
          //   }
          //   if (parseInt(programDay <= 9)) {
          //     programDay = "0" + programDay;
          //   }

          //   let startTime1 =
          //     programYear + "-" + programMonth + "-" + programDay + " 00:00:00";
          //   let stopTime1 =
          //     programYear + "-" + programMonth + "-" + programDay + " 00:45:00";
          //   // newProgrammeHours +
          //   // ":" +
          //   // newProgrammeMinutes +
          //   // ":" +
          //   // newProgrammeSeconds;
          //   let description1 = "Programme Info Not Available";
          //   let isEnabled1 = false;

          //   programEpg.unshift({
          //     id: id1,
          //     title: title1,
          //     since: since1,
          //     till: till1,
          //     channelUuid: channelUuid1,
          //     startTimeTimeStamp: startTimeTimeStamp1,
          //     stopTimeTimeStamp: stopTimeTimeStamp1,
          //     startTime: startTime1,
          //     stopTime: stopTime1,
          //     description: description1,
          //     isEnabled: isEnabled1,
          //     pooja1: "pooja",
          //     channelName,
          //     assetId: channelUuid1,
          //   });
          // }
        }

        if (item?.nextdayepg?.length > 0) {
          let item2 = item?.nextdayepg[0];

          let startDate = new Date(Number(item2?.startTimeTimestamp));

          let startYear = startDate.getFullYear();
          let startMonth = startDate.getMonth() + 1;
          if (parseInt(startMonth) <= 9) {
            startMonth = "0" + startMonth;
          }
          let startDay = startDate.getDate();
          if (parseInt(startDay) <= 9) {
            startDay = "0" + startDay;
          }
          let startHour = startDate.getHours();
          if (parseInt(startHour) <= 9) {
            startHour = "0" + startHour;
          }
          let startMinute = startDate.getMinutes();
          if (parseInt(startMinute) <= 9) {
            startMinute = "0" + startMinute;
          }
          let startSec = startDate.getSeconds();
          if (parseInt(startSec) <= 9) {
            startSec = "0" + startSec;
          }

          let stopDate = new Date(Number(item2?.stopTimeTimestamp));
          let stopYear = stopDate.getFullYear();
          let stopMonth = stopDate.getMonth() + 1;
          if (parseInt(stopMonth) <= 9) {
            stopMonth = "0" + stopMonth;
          }
          let stopDay = stopDate.getDate();

          if (parseInt(stopDay) > 1) {
            stopDay = Number(stopDay - 1);
          }
          if (parseInt(stopDay) <= 9) {
            stopDay = "0" + stopDay;
          }
          let stopHour = stopDate.getHours();
          if (parseInt(stopHour) <= 9) {
            stopHour = "0" + stopHour;
          }
          let stopMinute = stopDate.getMinutes();
          if (parseInt(stopMinute) <= 9) {
            stopMinute = "0" + stopMinute;
          }
          let stopSec = stopDate.getSeconds();
          if (parseInt(stopSec) <= 9) {
            stopSec = "0" + stopSec;
          }

          // let previousDate = new Date(item2.programmeDate);
          // previousDate.setDate(previousDate.getDate() - 1);
          // let previousDate1 = previousDate.getDate();
          // if (parseInt(previousDate1) <= 9) {
          //   previousDate1 = "0" + previousDate1;
          // }
          // previousDate.setHours(0, 0, 0, 0);

          // let previousDateMonth = previousDate.getMonth() + 1;
          // if (parseInt(previousDateMonth) <= 9) {
          //   previousDateMonth = "0" + previousDateMonth;
          // }

          let since =
            startYear +
            "-" +
            startMonth +
            "-" +
            startDay +
            "T" +
            startHour +
            ":" +
            startMinute +
            ":" +
            startSec;
          let till =
            stopYear + "-" + stopMonth + "-" + stopDay + "T" + "23:59:00";

          let id = item2.id;
          let title = item2.title;
          // let since = item2.programmeDate + "T" + item2.startTime;
          // let till = item2.programmeDate + "T" + item2.stopTime;
          let channelUuid = item2.assetId;
          let startTimeTimeStamp = item2.startTimeTimestamp;
          let stopTimeTimeStamp = item2.stopTimeTimestamp;
          let startTime = item2.startTime;
          let stopTime = item2.stopTime;
          let description = item2.description;
          // programEpg.push({
          //   id,
          //   title: "poooooooooooojjjjjjjjjjjjjjjjjjjj",
          //   since,
          //   till,
          //   channelUuid,
          //   startTimeTimeStamp,
          //   stopTimeTimeStamp,
          //   startTime,
          //   stopTime,
          //   description,
          //   name: "kkkk",
          //   assetId: channelUuid,
          // });
        }
        // alert(" from epg reducer     " + JSON.stringify(epg));

        var lastProgram = null;
        var finalEpg = [];
        programEpg.map((item) => {
          // if (lastProgram != null) {
          //   if (lastProgram.stopTimeTimeStamp < item.startTimeTimeStamp) {
          //     let id1 = lastProgram.stopTimeTimeStamp;
          //     let title1 = "Programme Info Not Available";
          //     // let since1 = programmeDate + "T" + lastProgram.stopTime;
          //     // let till1 = programmeDate + "T" + item.startTime;
          //     let startDate = new Date(Number(lastProgram?.stopTimeTimeStamp));
          //     // alert(startDate);
          //     let startYear = startDate.getFullYear();
          //     let startMonth = startDate.getMonth() + 1;
          //     if (parseInt(startMonth) <= 9) {
          //       startMonth = "0" + startMonth;
          //     }
          //     let startDay = startDate.getDate();
          //     if (parseInt(startDay) <= 9) {
          //       startDay = "0" + startDay;
          //     }
          //     let startHour = startDate.getHours();
          //     if (parseInt(startHour) <= 9) {
          //       startHour = "0" + startHour;
          //     }
          //     let startMinute = startDate.getMinutes();
          //     if (parseInt(startMinute) <= 9) {
          //       startMinute = "0" + startMinute;
          //     }
          //     let startSec = startDate.getSeconds();
          //     if (parseInt(startSec) <= 9) {
          //       startSec = "0" + startSec;
          //     }

          //     let stopDate = new Date(Number(item?.startTimeTimeStamp));
          //     let stopYear = stopDate.getFullYear();
          //     let stopMonth = stopDate.getMonth() + 1;
          //     if (parseInt(stopMonth) <= 9) {
          //       stopMonth = "0" + stopMonth;
          //     }
          //     let stopDay = stopDate.getDate();
          //     if (parseInt(stopDay) <= 9) {
          //       stopDay = "0" + stopDay;
          //     }
          //     let stopHour = stopDate.getHours();
          //     if (parseInt(stopHour) <= 9) {
          //       stopHour = "0" + stopHour;
          //     }
          //     let stopMinute = stopDate.getMinutes();
          //     if (parseInt(stopMinute) <= 9) {
          //       stopMinute = "0" + stopMinute;
          //     }
          //     let stopSec = stopDate.getSeconds();
          //     if (parseInt(stopSec) <= 9) {
          //       stopSec = "0" + stopSec;
          //     }

          //     let since =
          //       startYear +
          //       "-" +
          //       startMonth +
          //       "-" +
          //       startDay +
          //       "T" +
          //       startHour +
          //       ":" +
          //       startMinute +
          //       ":" +
          //       startSec;
          //     let till =
          //       stopYear +
          //       "-" +
          //       stopMonth +
          //       "-" +
          //       stopDay +
          //       "T" +
          //       stopHour +
          //       ":" +
          //       stopMinute +
          //       ":" +
          //       stopSec;
          //     let channelUuid1 = item.channelUuid;

          //     let startTimeTimeStamp1 = lastProgram.stopTimeTimeStamp;
          //     let stopTimeTimeStamp1 = item.startTimeTimeStamp;
          //     let startTime1 = lastProgram.stopTime;
          //     let stopTime1 = item.startTime;
          //     let description1 = "Programme Info Not Available";
          //     let isEnabled1 = false;

          //     finalEpg.push({
          //       id: id1,
          //       title: title1,
          //       since: since,
          //       till: till,
          //       channelUuid: channelUuid1,
          //       startTimeTimeStamp: startTimeTimeStamp1,
          //       stopTimeTimeStamp: stopTimeTimeStamp1,
          //       startTime: startTime1,
          //       stopTime: stopTime1,
          //       description: description1,
          //       isEnabled: isEnabled1,
          //       name: "hii",
          //       channelName: item?.channelName,
          //       assetId: channelUuid1,
          //     });
          //   }
          // }

          lastProgram = item;
          finalEpg.push(item);
        });
        // lastProgram = finalEpg?.[finalEpg.length - 1];
        // let nextDate = new Date(Number(lastProgram?.startTimeTimeStamp));
        // nextDate.setDate(nextDate.getDate() + 1);
        // nextDate.setHours(0, 0, 0, 0);

        // if (
        //   parseInt(lastProgram?.stopTimeTimeStamp) <
        //   nextDate.getTime() - 60500
        // ) {
        //   let id1 = lastProgram?.stopTimeTimeStamp;
        //   let title1 = "Programme Info Not Available";
        //   // let since1 = programmeDate + "T" + lastProgram.stopTime;
        //   // let till1 = programmeDate + "T" + "23:59";

        //   // let till1 = programmeDate + "T" + item.startTime;
        //   let startDate = new Date(Number(lastProgram?.startTimeTimeStamp));
        //   // alert(startDate);
        //   let startYear = startDate.getFullYear();
        //   let startMonth = startDate.getMonth() + 1;
        //   if (parseInt(startMonth) <= 9) {
        //     startMonth = "0" + startMonth;
        //   }
        //   let startDay = startDate.getDate();
        //   if (parseInt(startDay) <= 9) {
        //     startDay = "0" + startDay;
        //   }
        //   let startHour = startDate.getHours();
        //   if (parseInt(startHour) <= 9) {
        //     startHour = "0" + startHour;
        //   }
        //   let startMinute = startDate.getMinutes();
        //   if (parseInt(startMinute) <= 9) {
        //     startMinute = "0" + startMinute;
        //   }
        //   let startSec = startDate.getSeconds();
        //   if (parseInt(startSec) <= 9) {
        //     startSec = "0" + startSec;
        //   }

        //   let stopDate = new Date(Number(lastProgram?.stopTimeTimeStamp));
        //   let stopYear = stopDate.getFullYear();
        //   let stopMonth = stopDate.getMonth() + 1;
        //   if (parseInt(stopMonth) <= 9) {
        //     stopMonth = "0" + stopMonth;
        //   }
        //   let stopDay = stopDate.getDate();
        //   if (parseInt(stopDay) <= 9) {
        //     stopDay = "0" + stopDay;
        //   }
        //   let stopHour = stopDate.getHours();
        //   if (parseInt(stopHour) <= 9) {
        //     stopHour = "0" + stopHour;
        //   }
        //   let stopMinute = stopDate.getMinutes();
        //   if (parseInt(stopMinute) <= 9) {
        //     stopMinute = "0" + stopMinute;
        //   }
        //   let stopSec = stopDate.getSeconds();
        //   if (parseInt(stopSec) <= 9) {
        //     stopSec = "0" + stopSec;
        //   }

        //   let since1 =
        //     startYear +
        //     "-" +
        //     startMonth +
        //     "-" +
        //     startDay +
        //     "T" +
        //     stopHour +
        //     ":" +
        //     stopMinute +
        //     ":" +
        //     stopSec;
        //   let till1 =
        //     startYear + "-" + startMonth + "-" + startDay + "T23:59:00";

        //   let channelUuid1 = lastProgram.channelUuid;

        //   let startTimeTimeStamp1 = lastProgram.stopTimeTimeStamp;
        //   let stopTimeTimeStamp1 = nextDate.getTime();
        //   let startTime1 = lastProgram.stopTime;
        //   let stopTime1 = "23:59";
        //   let description1 = "Programme Info Not Available";
        //   let isEnabled1 = false;

        //   finalEpg.push({
        //     id: id1,
        //     title: title1,
        //     since: since1,
        //     till: till1,
        //     channelUuid: channelUuid1,
        //     startTimeTimeStamp: startTimeTimeStamp1,
        //     stopTimeTimeStamp: stopTimeTimeStamp1,
        //     startTime: startTime1,
        //     stopTime: stopTime1,
        //     description: description1,
        //     isEnabled: isEnabled1,
        //     name: "jjjj",
        //     assetId: channelUuid1,
        //   });
        // }

        epg = epg.concat(finalEpg);
      });

      let filteredChannel = [];
      channels?.map((a) => {
        if (a?.uuid != undefined) {
          filteredChannel?.push(a);
        }
      });

      if (epg?.length > 0) {
        return {
          ...state,
          epgLoading: false,
          epgProccesing: false,
          epgLoadingStatus: false,
          isLoading: false,

          epgDetails: {
            channels: filteredChannel,
            epg: epg,
          },
        };
      }

    case GET_EPG_DETAILS_FAILED:
      return {
        ...state,
        // epgLoading: false,
        isLoading: false,
        errors: payload.message,
        epgProccesing: false,
        epgLoadingStatus: false,
      };
    case GET_EPG_STATE_SUCCESS:
      // alert("heloo" + payload);
      return {
        ...state,

        epgDate: payload,
        // epgProccesing: false,
        // epgLoadingStatus: false,
        // epgLoading: false,
        isLoading: false,
      };
    case CLEAR_EPG_SUCCESS:
      return {
        epgDetails: { epgCatchup: [{ latha: "all lies" }] },
        isLoading: false,
        errors: "",
        epgDate: "",
        epgProccesing: false,
        epgLoadingStatus: false,
        epgLoading: false,
      };
    case EPG_DATE:
      return {
        ...state,
        epgApiDate: payload,
      };

    default:
      return state;
  }
};
