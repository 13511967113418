import ACTION_TYPES from "./action.types";

const {
  GET_PLATFORMS_LIST_START,
  GET_PLATFORMS_LIST_SUCCESS,
  GET_PLATFORMS_LIST_FAILED,
  GET_CURRENT_PLATFORM_ID,
  CLEAR_PLATFORM_DATA,
  GET_SELECTED_PLATFORM,
} = ACTION_TYPES;

const DEFAULT_ERROR_MESSAGE_PROPS = {
  // name: "",
  // password: "",
  // username: "",
  // loginType: "2",
  // avatar: "",
};

const initialState = {
  platforms: [],
  isLoading: false,
  errors: "",
  currentPlatformId: null,
  platformLoading: false,
  currentPlatformData: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = DEFAULT_ERROR_MESSAGE_PROPS;
  //console.log("reducer is calling");

  switch (type) {
    case GET_PLATFORMS_LIST_START:
      return {
        ...state,

        isLoading: true,
        errors,

        platformLoading: true,
      };
    case GET_PLATFORMS_LIST_SUCCESS:
      return {
        ...state,
        platforms: payload.data,
        isLoading,
        errors,
        platformLoading: false,
      };

    case GET_PLATFORMS_LIST_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload.message,
      };
    case GET_CURRENT_PLATFORM_ID:
      return {
        ...state,

        currentPlatformId: payload,
      };
    case GET_SELECTED_PLATFORM:
      return {
        ...state,

        currentPlatformData: payload,
      };

    case CLEAR_PLATFORM_DATA:
      return {
        platforms: [],
        isLoading: false,
        errors: "",
        currentPlatformId: null,
      };

    default:
      return state;
  }
};
