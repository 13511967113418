/** @format */

import React, { useEffect, useState } from "react";
import "./LiveAssetViewPage.css";
import { createStructuredSelector } from "reselect";
import { getLiveChannelDataSelector } from "../../Redux/VideoRedux/selector";
import { connect, useDispatch } from "react-redux";
import { getLatestEpgDetailsSelector } from "../../Redux/LatestEPG/selector";
import DetailsPageProgressBar from "../DetailsPageProgressBar";
import {
  getGeneralAdsDetailSelector,
  getgeneralGenreDetailsSelector,
  getgeneralLanguageDetailsSelector,
  getgeneralTagDetailsSelector,
} from "../../Redux/AllLiveDetails/selectors";
import ShowMoreText from "react-show-more-text";
import LiveDetailsPlayer from "./LiveDetailsPlayer";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import * as LIVE_VIDEO_ACTION from "./../../Redux/VideoRedux/action";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import * as LIVE_ACTION from "./../../Redux/live/actions";
import { getFavouriteSelector } from "../../Redux/FavouteDataRedux/selector";
import * as GET_FAVORITES_ACTION from "./../../Redux/FavouteDataRedux/action";
import LandingScreenAlert from "../LandingScreens/LandingScreenAlert";
import AlertModal from "../AlertModal";
import * as FAVOURATE_ACTION from "./../../Redux/favourites/actions";
import AdsPage from "../AdsPage";
import { getDeviceStatusSelector } from "../../Redux/Delete Device/selectors";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../services/datAnalytics/dataAnalytics";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import { configurationSelector } from "../../Redux/DATA-analytics/selector";
import ReactShowMoreText from "react-show-more-text";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../Redux/package&subscriptions/selector";
import ReactTooltip from "react-tooltip";
import { getCurrentTimeZone } from "../../App";

const LiveAssetViewPage = ({
  setIsVideoPlayer,
  ASSET_DATA,
  LATEST_EPG_DETAILS,
  GENERAL_GENRE_DETAILS,
  GENERAL_TAG_DETAILS,
  GENERAL_LANGUAGE_DETAILS,
  USER_DETAILS,
  AUTHENTICATION,
  GET_FAVOURITES_DATA,
  DEVICE_STATUS,
  GENERAL_ADS_DETAILS,

  DEVICE_TOKEN,
  CONFIGURATION_DAS,
  CURRENT_PLATFORM_DATA,
  PACKAGE_DETAILS,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation().state;
  const timeZone = getCurrentTimeZone();

  let flag = 0;

  let date = new Date();

  let timestamp = date?.getTime();
  console.log("ASSET_DATA" + JSON.stringify(ASSET_DATA));
  // ***************PACKAGE_DETAILS**************
  for (var p = 0; p < ASSET_DATA?.packages?.length; p++) {
    let stopDate =
      PACKAGE_DETAILS?.[ASSET_DATA?.packages?.[p]?.assetGroupId]?.stopDate;

    let stopDateTimeStamp = parseInt(Date.parse(stopDate));
    if (
      ASSET_DATA?.packages[p]?.assetGroupId ==
        PACKAGE_DETAILS?.[ASSET_DATA?.packages[p]?.assetGroupId]?.packageId &&
      timestamp < stopDateTimeStamp
    ) {
      flag = 1;
    }
  }
  // ************ALACARTE_DETAILS***********************
  let stopDateAlacarte = ALACARTE_DETAILS?.[ASSET_DATA?.id]?.expiryDate;
  let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

  if (
    ASSET_DATA?.id == ALACARTE_DETAILS?.[ASSET_DATA?.id]?.assetId &&
    ALACARTE_DETAILS?.[ASSET_DATA?.id]?.categoryId == 1 &&
    timestamp < stopDateTimeStampAlacarte
  ) {
    flag = 1;
  }
  // *****************PAY_PER_VIEW_DETAILS*************
  let stopDatePPV = PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.expiryDate;
  let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

  if (
    ASSET_DATA?.id == PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.assetId &&
    // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
    timestamp < stopDateTimeStampPPV
  ) {
    flag = 1;
  }
  let from = location?.from;

  const [showOverlay, setShowOverlay] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [mouseMoveTimeout, setMouseMoveTimeout] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  let tag = [];
  let genre = [];
  let ads = [];
  let languages = [];
  if (
    GENERAL_ADS_DETAILS?.[Number(ASSET_DATA?.liveAssetsPostAds?.[0]?.adId)] !=
    null
  ) {
    ads?.push(
      GENERAL_ADS_DETAILS?.[Number(ASSET_DATA?.liveAssetsPostAds?.[0]?.adId)]
    );
  }

  useEffect(() => {
    if (ads?.length > 0 && AUTHENTICATION) {
      let data2 = {
        adsAnalyticsData: {
          addId: ads[0].id,
          userId: USER_DETAILS?.id,
          platformId: CURRENT_PLATFORM_DATA.orgId,
          profileId: 1,
          deviceId: DEVICE_TOKEN,
          categoryId: 1,
          assetId: ASSET_DATA?.id,
          timeZone: timeZone,
        },
        ServerIp: CONFIGURATION_DAS?.analyticsIp,
      };
      getSendingDataOfPosteradsAsync(data2);
    }
  }, []);

  for (var j = 0; j < ASSET_DATA?.genres?.length; j++) {
    genre?.push(GENERAL_GENRE_DETAILS?.[ASSET_DATA?.genres?.[j]?.genreId]);
  }

  for (var k = 0; k < ASSET_DATA?.tags?.length; k++) {
    tag?.push(GENERAL_TAG_DETAILS?.[ASSET_DATA?.tags[k]?.tagId]);
  }

  languages?.push(GENERAL_LANGUAGE_DETAILS?.[ASSET_DATA?.languageId]);
  const handleVolumeToggle = () => {
    setIsMuted(!isMuted);
  };
  const handleGoBack = () => {
    history.goBack();
  };
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);

  //checking fav
  let lengthList = null;

  if (
    GET_FAVOURITES_DATA != undefined &&
    GET_FAVOURITES_DATA != null &&
    GET_FAVOURITES_DATA != undefined
  ) {
    lengthList = GET_FAVOURITES_DATA?.length;
  }
  let CheckFavourite = false;

  if (lengthList != null) {
    for (var i = 0; i < lengthList; i++) {
      if (
        GET_FAVOURITES_DATA[i] != undefined &&
        GET_FAVOURITES_DATA[i] != null &&
        GET_FAVOURITES_DATA[i].categoryId == 1 &&
        ASSET_DATA != undefined &&
        ASSET_DATA != null
      ) {
        if (GET_FAVOURITES_DATA[i]?.assetId == ASSET_DATA?.id) {
          CheckFavourite = true;
        }
      }
    }
  }
  //end
  const [isFavourite, setIsFavourite] = useState(CheckFavourite);

  // useEffect(() => {
  //     setIsVideoPlayer(true);
  //     const timer = setTimeout(() => {
  //         setShowOverlay(false);
  //     }, 10000);
  //     return () => clearTimeout(timer);
  // }, []);

  // useEffect(() => {
  //     const handleMouseMove = () => {
  //         setShowOverlay(true);
  //         setShowDetails(true);

  //         // Clear existing timeout if there's one
  //         if (mouseMoveTimeout) {
  //             clearTimeout(mouseMoveTimeout);
  //         }

  //         // Hide details after 3 seconds of inactivity
  //         const timeout = setTimeout(() => {
  //             setShowOverlay(false);
  //             setShowDetails(false);
  //         }, 10000);

  //         setMouseMoveTimeout(timeout);
  //     };

  //     window.addEventListener("mousemove", handleMouseMove);

  //     return () => {
  //         window.removeEventListener("mousemove", handleMouseMove);
  //         if (mouseMoveTimeout) {
  //             clearTimeout(mouseMoveTimeout);
  //         }
  //     };
  // }, [mouseMoveTimeout]);

  const handleFavouriteFill = () => {
    let fav_data;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      fav_data = {
        userId: USER_DETAILS?.id,
        categoryId: 1,
        assetId: ASSET_DATA?.id,
      };
    }
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      AUTHENTICATION == true
    ) {
      setIsFavourite(!isFavourite);
      dispatch(
        GET_FAVORITES_ACTION.getFavouriteDataStart({
          userId: USER_DETAILS?.id,
        })
      );
      dispatch(FAVOURATE_ACTION.addFavouritesStart(fav_data));
      dispatch(
        GET_FAVORITES_ACTION.getFavouriteDataStart({
          userId: USER_DETAILS?.id,
        })
      );
    } else {
      setAlertOpen(true);
    }
  };

  //alert for not subscribed assets
  let text = "";
  if (
    ASSET_DATA?.isPPVRequired != undefined &&
    ASSET_DATA?.isPPVRequired == 0
  ) {
    text = "Please subscribe or upgrade your package to make it as favourite.";
  } else {
    // text ="This is a  Pay Per View Asset. You need to buy it on DishHome Portal to consume it";
    text = "Please subscribe to watch.";
  }
  if (alertOpen == true) {
    return <LandingScreenAlert open={alertOpen} setOpen={setAlertOpen} />;
  }
  if (open == true) {
    return <AlertModal open={open} setOpen={setOpen} text={text} />;
  }
  //end

  //epg time calculation
  let ampm = null;

  let pmam = null;
  let newStartTimeStampDate = new Date(
    Number(LATEST_EPG_DETAILS[ASSET_DATA?.id]?.currentEpg?.startTimeTimestamp)
  );
  let newStopTimeStampDate = new Date(
    Number(LATEST_EPG_DETAILS[ASSET_DATA?.id]?.currentEpg?.stopTimeTimestamp)
  );
  if (LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined) {
    if (
      newStartTimeStampDate != undefined &&
      newStartTimeStampDate != null &&
      newStartTimeStampDate.getHours() >= 12
    ) {
      ampm = "PM";
    } else {
      ampm = "AM";
    }

    if (
      newStopTimeStampDate != undefined &&
      newStopTimeStampDate != null &&
      newStopTimeStampDate.getHours() >= 12
    ) {
      pmam = "PM";
    } else {
      pmam = "AM";
    }
  }
  let newStartTimeStampHour = newStartTimeStampDate.getHours();
  if (parseInt(newStartTimeStampHour) <= 9) {
    newStartTimeStampHour = "0" + newStartTimeStampHour;
  }
  let newStartTimeStampMinute = newStartTimeStampDate.getMinutes();
  if (parseInt(newStartTimeStampMinute) <= 9) {
    newStartTimeStampMinute = "0" + newStartTimeStampMinute;
  }

  let newStopTimeStampHour = newStopTimeStampDate.getHours();
  if (parseInt(newStopTimeStampHour) <= 9) {
    newStopTimeStampHour = "0" + newStopTimeStampHour;
  }
  let newStopTimeStampMinutes = newStopTimeStampDate.getMinutes();
  if (parseInt(newStopTimeStampMinutes) <= 9) {
    newStopTimeStampMinutes = "0" + newStopTimeStampMinutes;
  }

  //end epg time calculation

  //code of creating tag lang genre using general details

  //end

  //function for playing the video

  const handleVideoDislay = () => {
    if (
      LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
      LATEST_EPG_DETAILS[ASSET_DATA?.id] != null
    ) {
      dispatch(
        LIVE_VIDEO_ACTION.setTimeStamp(
          LATEST_EPG_DETAILS[ASSET_DATA?.id]?.currentEpg?.startTimeTimestamp
        )
      );
    } else {
      dispatch(LIVE_VIDEO_ACTION.setTimeStamp(null));
    }

    dispatch(
      LIVE_VIDEO_ACTION.getDrmCatchupRequired(ASSET_DATA?.catchupRequired)
    );

    if (
      LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
      LATEST_EPG_DETAILS[ASSET_DATA?.id] != null
    ) {
      dispatch(
        LIVE_VIDEO_ACTION.setTimeStamp(
          LATEST_EPG_DETAILS[ASSET_DATA?.id]?.currentEpg?.startTimeTimestamp
        )
      );
    } else {
      dispatch(LIVE_VIDEO_ACTION.setTimeStamp(null));
    }

    dispatch(
      LIVE_VIDEO_ACTION.getDrmCatchupRequired(ASSET_DATA?.catchupRequired)
    );

    history.push({
      pathname: "/liveVideoPlayer",
      state: {
        from: from,
      },
    });
  };
  //end

  return (
    <div
      className="fullscreen-series-container p-0 m-0"
      style={{ overflow: "hidden" }}
    >
      <LiveDetailsPlayer
        setIsVideoPlayer={setIsVideoPlayer}
        isMuted={isMuted}
        flag={flag}
      />
      <div className="overlay">
        <div>
          <span
            id="mute-unmute"
            className="mute-unmute"
            style={{
              position: "absolute",
              top: "10px", // Move up by decreasing the top value
              right: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleVolumeToggle()}
          >
            {isMuted ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white" // Change icon color to white
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-volume-x"
                style={{ cursor: "pointer" }}
              >
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <line x1="23" y1="9" x2="17" y2="15"></line>
                <line x1="17" y1="9" x2="23" y2="15"></line>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white" // Change icon color to white
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-volume-2"
                style={{ cursor: "pointer" }}
              >
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
              </svg>
            )}
          </span>
        </div>{" "}
        {/* Go back icon */}
        <span
          className="go-back"
          style={{
            position: "absolute",
            top: "10px", // Move up by decreasing the top value
            left: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleGoBack()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white" // Set the stroke color to white
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-left-circle"
            style={{ cursor: "pointer" }}
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 8 8 12 12 16"></polyline>
            <line x1="16" y1="12" x2="8" y2="12"></line>
          </svg>
        </span>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            top: -10,
          }}
        >
          <div
            style={{
              flex: 1,
              marginTop: "100px", // Add this line for spacing
            }}
          >
            <div className="content">
              <h1 className="title">{ASSET_DATA?.title}</h1>
              <div className="p-0 m-0 col-9">
                {LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
                LATEST_EPG_DETAILS[ASSET_DATA?.id] != null &&
                newStartTimeStampDate != undefined &&
                newStartTimeStampDate != null &&
                newStopTimeStampDate != undefined &&
                newStopTimeStampDate != null ? (
                  <div
                    style={{
                      marginBottom: "10px",
                      color: "white",
                      fontSize: "18px",
                      color: "white",
                      fontFamily:
                        "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        color: "white",
                        fontSize: "18px",
                        color: "white",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                      }}
                    >
                      Progress Time &nbsp;
                    </span>
                    {newStartTimeStampHour + ":" + newStartTimeStampMinute} -{" "}
                    {newStopTimeStampHour + ":" + newStopTimeStampMinutes}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-12 p-0 m-0"
                style={{
                  width: "32.5%",
                  marginBottom: "10px",
                  // backgroundColor: "blue",
                  fontSize: "18px",
                }}
              >
                {LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
                  LATEST_EPG_DETAILS[ASSET_DATA?.id] != null && (
                    <DetailsPageProgressBar
                      displayMovieData={LATEST_EPG_DETAILS[ASSET_DATA?.id]}
                    />
                  )}
              </div>
              {LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
                LATEST_EPG_DETAILS[ASSET_DATA?.id] != null && (
                  <div>
                    <span>Current Playing : </span>
                    <span>
                      {LATEST_EPG_DETAILS[ASSET_DATA?.id]?.currentEpg?.title}
                    </span>
                  </div>
                )}
              <div
                className="col-sm-12 p-0"
                style={{
                  marginTop: "0px",
                  float: "left",
                  fontSize: "18px",
                  color: "white",
                  marginTop: "2px",
                  fontFamily:
                    "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                }}
              >
                {ASSET_DATA?.description ? (
                  <ReactShowMoreText
                    lines={5}
                    more="Show more"
                    less="Show less"
                    className="long-description"
                    anchorClass="show-more-anchor"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {ASSET_DATA?.description}
                  </ReactShowMoreText>
                ) : (
                  ""
                )}
                {/* {ASSET_DATA != undefined ? (
              <div
                style={{
                  fontSize: "18px",
                  color: "white",
                  marginTop: "15px",
                  fontFamily:
                    "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                }}
              >
                {ASSET_DATA?.description}
              </div>
            ) : (
              ""
            )} */}

                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Tags :{" "}
                </span>
                {tag != undefined && tag != null && tag.length > 0
                  ? tag.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.tag}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {", " + item?.tag}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Genres :{" "}
                </span>
                {genre.length > 0
                  ? genre.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.name}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {", " + item?.name}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Language :{" "}
                </span>
                {languages != undefined &&
                  languages != null &&
                  languages?.length > 0 &&
                  languages?.map((i) => (
                    <span
                      style={{
                        color: "white",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                      }}
                    >
                      {" " + i?.name}
                    </span>
                  ))}
              </div>
              {LATEST_EPG_DETAILS[ASSET_DATA?.id] != undefined &&
                LATEST_EPG_DETAILS[ASSET_DATA?.id] != null &&
                LATEST_EPG_DETAILS[ASSET_DATA?.id]?.nextEpg != undefined &&
                LATEST_EPG_DETAILS[ASSET_DATA?.id]?.nextEpg != null && (
                  <div
                    style={
                      {
                        // borderTop: "1px solid gray",
                        // width: "600px",
                        // marginTop: "20px",
                      }
                    }
                  >
                    {" "}
                    <span
                      style={{
                        color: "white",
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                        backgroundColor: "#33373d",
                      }}
                    >
                      UPCOMING{}
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                      }}
                    >
                      &nbsp;{" "}
                      {LATEST_EPG_DETAILS[ASSET_DATA?.id]?.nextEpg?.title}
                      &nbsp;
                    </span>
                  </div>
                )}
              <div className=" col-12 mt-3 ml-0">
                <div className="row">
                  <div className="col-3">
                    <button
                      onClick={handleVideoDislay}
                      className="glow-on-hover"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        class="bi bi-play-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.445 8L5.694 4.052a.5.5 0 0 0-.694.417v7.062a.5.5 0 0 0 .694.417l5.75-3.948a.5.5 0 0 0 0-.834z" />
                      </svg>
                      Play
                    </button>
                  </div>
                  <div
                    className="col-2 mt-3"
                    style={{ cursor: "pointer", top: -15, right: 46 }}
                  >
                    {USER_DETAILS != undefined &&
                    USER_DETAILS != null &&
                    AUTHENTICATION == true ? (
                      <div
                        className="icon-wrapper-fav"
                        onClick={handleFavouriteFill}
                      >
                        {isFavourite ? (
                          <img
                            height="40px"
                            width="40px"
                            src="images/login/h4.png"
                            alt="Favorite icon"
                          />
                        ) : (
                          <img
                            height="40px"
                            width="40px"
                            src="images/login/h3.png"
                            alt="Not favorite icon"
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <ReactTooltip type="dark" />
                  </div>

                  <div
                    className="col-12"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      top: 10,
                    }}
                  >
                    {ads && ads.length > 0 ? <AdsPage ads={ads} /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "50%", // Decrease the width to reduce the size
            left: -30,
            bottom: "-10px", // Ensure correct units for bottom positioning
            zIndex: 4,
            top: 0,
          }}
        >
          <div className="col-12">
            {ads && ads.length > 0 ? <AdsPage ads={ads} /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  ASSET_DATA: getLiveChannelDataSelector,
  LATEST_EPG_DETAILS: getLatestEpgDetailsSelector,
  GENERAL_GENRE_DETAILS: getgeneralGenreDetailsSelector,
  GENERAL_TAG_DETAILS: getgeneralTagDetailsSelector,
  GENERAL_LANGUAGE_DETAILS: getgeneralLanguageDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GET_FAVOURITES_DATA: getFavouriteSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  GENERAL_ADS_DETAILS: getGeneralAdsDetailSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,

  DEVICE_TOKEN: getDeviceTokenSelector,
  CONFIGURATION_DAS: configurationSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
});
export default connect(mapStateToProps)(LiveAssetViewPage);
