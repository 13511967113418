import { createSelector } from "reselect";
const getDefaultPlatforms = (state) => state.defaultPlatform.Defaultplatforms;

export const getDefaultPlatformsSelector = createSelector(
  getDefaultPlatforms,
  (Defaultplatforms) => Defaultplatforms
);

const getDefaultPlatformsForRoute = (state) =>
  state.defaultPlatform.DefaultplatformsforRoute;

export const getDefaultPlatformsForRouteSelector = createSelector(
  getDefaultPlatformsForRoute,
  (DefaultplatformsforRoute) => DefaultplatformsforRoute
);

// DefaultplatformsforRoute;
