import React, { useEffect, useState, useRef } from "react";
import { getSendingDataOfGenreVideoAsync } from "../../services/datAnalytics/dataAnalytics";
import { getCurrentTimeZone, newImageIpPath } from "../../App";

const AdsComponent = ({
  assetUrl,
  genreId,
  addId,
  userId,
  platformId,
  DEVICE_TOKEN,
  CONFIGURATION_DAS,
  AUTHENTICATION,
  selectedCategory,
}) => {
  const [viewedFor5Seconds, setViewedFor5Seconds] = useState(false);
  const videoRef = useRef(null);
  const timeZone = getCurrentTimeZone();

  useEffect(() => {
    if (videoRef.current) {
      const handleTimeUpdate = () => {
        if (
          videoRef.current?.currentTime >= 5 &&
          !viewedFor5Seconds &&
          AUTHENTICATION
        ) {
          setViewedFor5Seconds(true);
          sendAdAnalytics();
        }
      };

      videoRef.current?.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [viewedFor5Seconds]);

  const sendAdAnalytics = async () => {
    try {
      const response = await getSendingDataOfGenreVideoAsync({
        adsAnalyticsData: {
          addId: addId,
          userId: userId,
          platformId: platformId,
          profileId: 1,
          deviceId: DEVICE_TOKEN,
          genreId: genreId,
          timeZone: timeZone,
        },
        ServerIp: CONFIGURATION_DAS?.analyticsIp,
      });
      console.log("Ad analytics sent successfully", addId);
    } catch (error) {
      console.error("Failed to send ad analytics", error);
    }
  };

  const handleGifLoad = () => {
    if (AUTHENTICATION) {
      sendAdAnalytics(); // Send analytics data when GIF loads
    }
  };

  const handleFocus = () => {
    if (AUTHENTICATION) {
      sendAdAnalytics(); // Send analytics data immediately on focus
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        // marginTop: "15%",
        // marginBottom: "14%",
        borderRadius: 10,
      }}
    >
      <div className="favorites-contents">
        <ul className="list-inline p-0 mb-0 iq-rtl-direction">
          <li className="slide-item" style={{ borderRadius: "10px" }}>
            {assetUrl?.search(".gif") > 0 ? (
              <img
                src={newImageIpPath + assetUrl}
                alt="Ad GIF"
                style={{
                  width: "310px",
                  height: "180px",
                  borderRadius: 10,
                  marginTop: selectedCategory == 1 ? "-15%" : "03%",
                }}
                onLoad={handleGifLoad} // Send analytics data on GIF load
              />
            ) : (
              <video
                ref={videoRef}
                src={
                  assetUrl?.includes("http")
                    ? assetUrl
                    : `${newImageIpPath}${assetUrl}`
                }
                controls
                autoPlay
                loop
                muted // Mute the video
                disablePictureInPicture // Disable picture-in-picture mode
                controlsList="nodownload" // Disable the download option
                // onFocus={handleFocus} // Handle focus event
                style={{
                  width: "300px",
                  height: "180px",
                  borderRadius: "10px",
                  marginTop: selectedCategory == 1 ? "-15%" : "03%",
                }}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdsComponent;
