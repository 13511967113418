import ACTION_TYPES from "./action.types";

const {
  GET_LIVE_ASSETS_DATA_START,
  GET_LIVE_ASSETS_DATA_SUCCESS,
  GET_LIVE_ASSETS_DATA_FAILED,
} = ACTION_TYPES;

const initialState = {
  liveAssetsData: [],

  liveLoading: false,
};

export default (state = initialState, { type, payload }) => {
  const errors = null;

  switch (type) {
    case GET_LIVE_ASSETS_DATA_START:
      return {
        ...state,
        liveLoading: true,
      };

    case GET_LIVE_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        liveAssetsData: payload.data,
        liveLoading: false,
        errors,
      };

    case GET_LIVE_ASSETS_DATA_FAILED:
      return {
        ...state,
        liveAssetsData: [],
        liveLoading: false,
        errors: payload.message,
      };

    default:
      return state;
  }
};
