import ACTION_TYPES from "./actionTypes";
const {
    ACTIVE_EMAIL_START,
    ACTIVE_EMAIL_SUCCESS,
    ACTIVE_EMAIL_FAILED,
    ACTIVE_USER_ID,
} = ACTION_TYPES;

export const activeEmailStart = (payload) => ({
  type: ACTIVE_EMAIL_START,
  payload,
});

export const activeEmailSuccess = (payload) => ({
  type: ACTIVE_EMAIL_SUCCESS,
  payload,
});

export const activeEmailFailed = (payload) => ({
  type: ACTIVE_EMAIL_FAILED,
  payload,
});

export const getActiveUserId = (payload) => ({
  type: ACTIVE_USER_ID,
  payload,
});