/** @format */

import React, { useEffect } from "react";
import NewVideoPlayerJs from "./NewVideoPlayerJs";

const MainVideoPlayer = ({ setIsVideoPlayer, setSelectedCategory }) => {
  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);

  return (
    <div>
      <NewVideoPlayerJs setSelectedCategory={setSelectedCategory} />
    </div>
  );
};

export default MainVideoPlayer;
