import { createSelector } from "reselect";

const getLiveVersion = (state) => state?.dataVersionReducer?.isLiveUpdated;
export const getLiveVersionSelector = createSelector(
  getLiveVersion,
  (isLiveUpdated) => isLiveUpdated
);

const getMovieVersion = (state) => state?.dataVersionReducer?.isMovieUpdated;
export const getMovieVersionSelector = createSelector(
  getMovieVersion,
  (isMovieUpdated) => isMovieUpdated
);

const getSeriesVersion = (state) => state?.dataVersionReducer?.isSeriesUpdated;
export const getSeriesVersionSelector = createSelector(
  getSeriesVersion,
  (isSeriesUpdated) => isSeriesUpdated
);

const getBannerVersion = (state) => state?.dataVersionReducer?.isBannerUpdated;
export const getBannerVersionSelector = createSelector(
  getBannerVersion,
  (isBannerUpdated) => isBannerUpdated
);

const getGeneralDetailsVersion = (state) =>
  state?.dataVersionReducer?.isGeneralDetailsUpdated;
export const getGeneralDetailsVersionSelector = createSelector(
  getGeneralDetailsVersion,
  (isGeneralDetailsUpdated) => isGeneralDetailsUpdated
);
