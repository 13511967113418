const ACTION_TYPES = {
  CREATE_LIVE_ASSET: "CREATE_LIVE_ASSET",

  GET_LIVE_ASSETS_START: "GET_LIVE_ASSETS_START",
  GET_LIVE_ASSETS_SUCCESS: "GET_LIVE_ASSETS_SUCCESS",
  GET_LIVE_ASSETS_FAILED: "GET_LIVE_ASSETS_FAILED",

  GET_FINAL_LIVE_ASSET_DETAILS: "GET_FINAL_LIVE_ASSET_DETAILS",
  GET_BANNERS_START: "GET_BANNERS_START",
  GET_BANNERS_SUCCESS: "GET_BANNERS_SUCCESS",
  GET_BANNERS_FAILED: "GET_BANNERS_FAILED",

  GET_GENERAL_SETTINGS_START: "GET_GENERAL_SETTINGS_START",
  GET_GENERAL_SETTINGS_SUCCESS: "GET_GENERAL_SETTINGS_SUCCESS",
  GET_GENERAL_SETTINGS_FAILED: "GET_GENERAL_SETTINGS_FAILED",
  CLEAR_LIVE_ASSETS: "CLEAR_LIVE_ASSETS",

  GET_LIVE_GENRE_ASSETS_FOR_LISTING: "GET_LIVE_GENRE_ASSETS_FOR_LISTING",
  GET_GENRE_DETAILS_PROPS: "GET_GENRE_DETAILS_PROPS",

  GET_ASSET_OFFSET_LENGTH: "GET_ASSET_OFFSET_LENGTH",

  GET_OFFSET_LENGTH: "GET_OFFSET_LENGTH",
};

export default ACTION_TYPES;
