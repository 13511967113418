import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
import { connect, useDispatch } from "react-redux";
import {
  authenticatedStatusSelector,
  loginDetailsSelector,
  userDetailsSelector,
} from "../Redux/authentication/selectors";
import * as CURRENT_PLATFORM_ACTION from "./../Redux/platforms/actions";
import * as AUTHENTICATION_ACTIONS from "../Redux/authentication/actions";
import { getDeviceTokenSelector } from "../Redux/deviceRedux/selectors";
import { getSaveDeviceTockenSelector } from "../Redux/SaveDeviceTocken/selectors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../Redux/package&subscriptions/actions";

const GetStartedScreen = ({
  PLATFORM,
  USER_DETAILS,
  LOGIN_DETAILS,
  AUTHENTICATION,
  CURRENT_PLATFORM,
  DEVICE_TOKEN,
  SAVED_DEVICE_TOCKEN,
  setSelectedCategory,
}) => {
  let account_id = PLATFORM.currentPlatformId;
  const history = useHistory();
  const dispatch = useDispatch();
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  useEffect(() => {
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      LOGIN_DETAILS?.rememberMe == true &&
      AUTHENTICATION == true
    ) {
      let selectedPlatform;
      if (
        PLATFORM != undefined &&
        PLATFORM != null &&
        PLATFORM?.platforms != undefined &&
        PLATFORM?.platforms != null &&
        PLATFORM?.platforms.length > 0
      ) {
        selectedPlatform = PLATFORM?.platforms?.filter((a) => {
          if (a?.orgId == USER_DETAILS?.orgId) {
            return a;
          }
        });
      }
      dispatch(
        CURRENT_PLATFORM_ACTION.getSelectedPlatform(selectedPlatform?.[0])
      );

      let navigationPath = "/container";

      if (defaultLandingPage == 1) {
        setSelectedCategory(1);
      } else if (defaultLandingPage == 2) {
        setSelectedCategory(2);
      } else if (defaultLandingPage == 3) {
        setSelectedCategory(3);
      } else {
        setSelectedCategory(1);
      }
      dispatch(
        AUTHENTICATION_ACTIONS.verifyLoginStart({
          username: LOGIN_DETAILS?.data.username,
          password: LOGIN_DETAILS?.password?.trim(),
          loginType: "4",
          account_id: account_id,
          deviceId: DEVICE_TOKEN,
          rememberMe: LOGIN_DETAILS?.rememberMe,
          history: history,
          navigationPath,
          newDeviceTocken: SAVED_DEVICE_TOCKEN,
          oldDeviceToken: DEVICE_TOKEN,
        })
      );

      dispatch(
        CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(USER_DETAILS?.orgId)
      );
    } else {
      history.push("/container");
    }
  }, []);

  useEffect(() => {
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      LOGIN_DETAILS?.rememberMe == true &&
      AUTHENTICATION == true
    ) {
      dispatch(
        CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(USER_DETAILS?.orgId)
      );
    } else if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      LOGIN_DETAILS?.rememberMe != true &&
      AUTHENTICATION == true &&
      account_id != undefined &&
      account_id != null
    ) {
      let data = {
        deviceId: DEVICE_TOKEN,
        userId: USER_DETAILS?.id,
        orgId: account_id,
      };
      dispatch(AUTHENTICATION_ACTIONS.logoutStart(data));
      dispatch(AUTHENTICATION_ACTIONS.loginStausFalse());
      dispatch(PACKAGE_SUBSCRIPTION_ACTION.makePackageDetailsEmpty());
    }

    if (defaultLandingPage == 1) {
      setSelectedCategory(1);
    } else if (defaultLandingPage == 2) {
      setSelectedCategory(2);
    } else if (defaultLandingPage == 3) {
      setSelectedCategory(3);
    } else {
      setSelectedCategory(1);
    }
  }, []);
  // setting default category using current platform

  return <></>;
};
const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  USER_DETAILS: userDetailsSelector,
  LOGIN_DETAILS: loginDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
});
export default connect(mapStateToProps)(GetStartedScreen);
