import ACTION_TYPES from "./actionTypes";
const {
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_STATUS,
} = ACTION_TYPES;

export const resetPasswordStart = (payload) => ({
  type: RESET_PASSWORD_START,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailed = (payload) => ({
  type: RESET_PASSWORD_FAILED,
  payload,
});
export const resetPasswordSTATUS = (payload) => ({
  type: RESET_PASSWORD_STATUS,
  payload,
});