// import { Alert } from "react-native";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const getVideoAdverticementAsync = async () => {
  // Alert.alert("platform service is working");
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post(
      "/videoAdvertisement",
      {},
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
