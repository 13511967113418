import ACTION_TYPES from "./actionTypes";

const {
  GET_PACKAGE_DETAILS_FAILED,
  GET_PACKAGE_DETAILS_START,
  GET_PACKAGE_DETAILS_SUCCESS,
  MAKE_PACKAGE_DETAILS_EMPTY,
} = ACTION_TYPES;

export const getPackageDetailsStart = (payload) => ({
  type: GET_PACKAGE_DETAILS_START,
  payload,
});

export const getPackageDetailsSuccess = (payload) => ({
  type: GET_PACKAGE_DETAILS_SUCCESS,
  payload,
});

export const getPackageDetailsFailed = (payload) => ({
  type: GET_PACKAGE_DETAILS_FAILED,
  payload,
});
export const makePackageDetailsEmpty = () => ({
  type: MAKE_PACKAGE_DETAILS_EMPTY,
});
