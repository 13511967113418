import ACTION_TYPES from "./action.types";

const {
  GET_PUSH_NOTIFICATION_START,
  GET_PUSH_NOTIFICATION_SUCCESS,
  GET_PUSH_NOTIFICATION_FAILED,
  CURRENT_NOTIFICATIONS,
} = ACTION_TYPES;

const initialState = {
  notifications: [],
  currentNotifications: [],
  notificationId: null,
  isLoading: false,
  errors: "",
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  switch (type) {
    case GET_PUSH_NOTIFICATION_START:
      return {
        ...state,

        isLoading: true,
      };
    case GET_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: payload.data,
        isLoading,
      };

    case GET_PUSH_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading,
        notifications: [],
        errors: payload.message,
      };

    case CURRENT_NOTIFICATIONS:
      return {
        ...state,
        isLoading,
        currentNotifications: payload,
      };

    default:
      return state;
  }
};
