// HeadersAcessToken.js

export function HeadersAcessToken(accessToken) {
  return {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    "access-token":
      "$2a$10$BehSptzBuqn3B8h/qCJCSOPEFo6KyYsTaKAD7Hw3vtG.oB0v5KozC+n1chpyt123vr9jnjxz9hcktux70hgh4n68aut8czhyr6zm8c0x1r5znbbyeqd9jvpdzkv365ua2w4478xk3bvb9rtyz0qubk125ypkd2hzxcecdtjg513mtvzbfprn8h67194v9za5c9wd6ez54p9g813bqdz3aufzyrqzj3pq485aewr3tjvf9hpfnpmppjp12c0exd4k7ik7b9wrc5rezdw6xy9yq55vrdai78bgvh444cyiu6212euw58mjzwa3eq250kvmr3j6d406r0y9v76z9x9j0zmr0c7zc1tgz+eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaWF0IjoxNjg4MDEzMzc2LCJleHAiOjE2ODgwOTc0NzZ9.GYoKD74TQSnbAdvAco4hAoXq8ouc3_oDAU7Zf2eMK2E",
    "x-header-1":
      "$2a$10$BehSptzBuqn3B8h/qCJCSOPEFo6KyYsTaKAD7Hw3vtG.oB0v5KozC",
    "custom-token":
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaWF0IjoxNjg4MDEzMzc2LCJleHAiOjE2ODgwOTc0NzZ9.GYoKD74TQSnbAdvAco4hAoXq8ouc3_oDAU7Zf2eMK2E",
    "user-identification":
      "$2a$10$kM7tzOMTUPf7UEOgNpYFfOVW/.xTZdhGwE6oGw1yJZIkt8iL.9NOi",
    "secure-access":
      "$2a$10$aOdWajvjL6ejMTKDgQxeYe3z89Se2AMU3gcJ/QBJiy.86ZY5WcvSi",
    "hidden-key":
      "$2a$10$zFCNVsFu7hNcD6/LZ4SL1OxBNY9Dzn59uGCldWDOgJ0q65WPUSgHu",
    "alpha-auth":
      "$2a$10$35fC7oGooR/.wOxNlwW0ne6bCbqmDUoiuZr2jxFX32sge7ScUmi3W",
    "opaque-id":
      "n1chpyt123vr9jnjxz9hcktux70hgh4n68aut8czhyr6zm8c0x1r5znbbyeqd9jvpdzkv365ua2w4478xk3bvb9rtyz0qubk125ypkd2hzxcecdtjg513mtvzbfprn8h67194v9za5c9wd6ez54p9g813bqdz3aufzyrqzj3pq485aewr3tjvf9hpfnpmppjp12c0exd4k7ik7b9wrc5rezdw6xy9yq55vrdai78bgvh444cyiu6212euw58mjzwa3eq250kvmr3j6d406r0y9v76z9x9j0zmr0c7zc1tgz",
    "cryptic-sign":
      "2H(,@;KtW.mb3dCtE0BfY;bjitmSZ/AM+[pq+;NX0c@5PSJLur*Z1EXeP*9*Pbc4YF8M)h*@f0jdYySMf0Q*4&d9UW.DQ=m1Sfg$",
    "phantom-code":
      "5445729754618698053115730183844673396525219316942273969691374411667540635760541658252444252819568",
    "x-access-token": accessToken,
  };
}

export function HeadersAcessTokenForFormData(accessToken) {
  return {
    "Content-Type": "multipart/form-data",
    "access-token":
      "$2a$10$BehSptzBuqn3B8h/qCJCSOPEFo6KyYsTaKAD7Hw3vtG.oB0v5KozC+n1chpyt123vr9jnjxz9hcktux70hgh4n68aut8czhyr6zm8c0x1r5znbbyeqd9jvpdzkv365ua2w4478xk3bvb9rtyz0qubk125ypkd2hzxcecdtjg513mtvzbfprn8h67194v9za5c9wd6ez54p9g813bqdz3aufzyrqzj3pq485aewr3tjvf9hpfnpmppjp12c0exd4k7ik7b9wrc5rezdw6xy9yq55vrdai78bgvh444cyiu6212euw58mjzwa3eq250kvmr3j6d406r0y9v76z9x9j0zmr0c7zc1tgz+eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaWF0IjoxNjg4MDEzMzc2LCJleHAiOjE2ODgwOTc0NzZ9.GYoKD74TQSnbAdvAco4hAoXq8ouc3_oDAU7Zf2eMK2E",
    "x-header-1":
      "$2a$10$BehSptzBuqn3B8h/qCJCSOPEFo6KyYsTaKAD7Hw3vtG.oB0v5KozC",
    "custom-token":
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaWF0IjoxNjg4MDEzMzc2LCJleHAiOjE2ODgwOTc0NzZ9.GYoKD74TQSnbAdvAco4hAoXq8ouc3_oDAU7Zf2eMK2E",
    "user-identification":
      "$2a$10$kM7tzOMTUPf7UEOgNpYFfOVW/.xTZdhGwE6oGw1yJZIkt8iL.9NOi",
    "secure-access":
      "$2a$10$aOdWajvjL6ejMTKDgQxeYe3z89Se2AMU3gcJ/QBJiy.86ZY5WcvSi",
    "hidden-key":
      "$2a$10$zFCNVsFu7hNcD6/LZ4SL1OxBNY9Dzn59uGCldWDOgJ0q65WPUSgHu",
    "alpha-auth":
      "$2a$10$35fC7oGooR/.wOxNlwW0ne6bCbqmDUoiuZr2jxFX32sge7ScUmi3W",
    "opaque-id":
      "n1chpyt123vr9jnjxz9hcktux70hgh4n68aut8czhyr6zm8c0x1r5znbbyeqd9jvpdzkv365ua2w4478xk3bvb9rtyz0qubk125ypkd2hzxcecdtjg513mtvzbfprn8h67194v9za5c9wd6ez54p9g813bqdz3aufzyrqzj3pq485aewr3tjvf9hpfnpmppjp12c0exd4k7ik7b9wrc5rezdw6xy9yq55vrdai78bgvh444cyiu6212euw58mjzwa3eq250kvmr3j6d406r0y9v76z9x9j0zmr0c7zc1tgz",
    "cryptic-sign":
      "2H(,@;KtW.mb3dCtE0BfY;bjitmSZ/AM+[pq+;NX0c@5PSJLur*Z1EXeP*9*Pbc4YF8M)h*@f0jdYySMf0Q*4&d9UW.DQ=m1Sfg$",
    "phantom-code":
      "5445729754618698053115730183844673396525219316942273969691374411667540635760541658252444252819568",
    "x-access-token": accessToken,
  };
}
