import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as CREATE_ACTION from "./../../Redux/auth/actions";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import CreateProfileModal from "./CreateProfileModal";
import "./profile.css";
// import swal from "sweetalert/typings/core";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { createStructuredSelector } from "reselect";
import { authSelector } from "../../Redux/auth/selectors";
import { platformsSelector } from "../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
let createValues;

const CreateProfile = ({ AUTH, PLATFORMID, USER_DETAILS, AUTHENTICATION }) => {
  // alert(JSON.stringify(AUTH.userPackageDetails))
  let noOfProfile;
  //console.log("dcdscds.......................", AUTH.userPackageDetails);

  if (
    AUTH != undefined &&
    AUTH != null &&
    AUTH.auth != undefined &&
    AUTH.auth != undefined &&
    AUTH.auth != null
  ) {
    noOfProfile = AUTH.userPackageDetails.noOfProfiles;
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const [createProfile, setCreateProfile] = useState("");
  const [createProfileError, setCreateProfileError] = useState();
  const [imageUpload, setImageUpload] = useState();

  const [image, setImage] = useState();
  //console.log(image);
  // let profileUrl;
  // if (image == undefined && image == null) {
  //   profileUrl = "http://10.0.90.25/genesis/medialocal/avatars/user-avatar.png";
  // } else {
  //   profileUrl = image;
  // }
  ////console.log(profileUrl);
  const [forKid, setForKid] = useState(0);
  const handleInputChange = (e) => {
    // const value = e.target.value;

    let isChecked = e.target.checked;
    //console.log("check value", isChecked);
    if (isChecked == true) {
      setForKid(1);
    } else {
      setForKid(0);
    }
    //console.log("for kid", forKid);

    //console.log(createValues.profileUrl);
  };
  createValues = {
    account_id: PLATFORMID.currentPlatformId,
    userId: USER_DETAILS?.id, //manually
    updatedBy: USER_DETAILS?.id, //manually
    currentPackageId: USER_DETAILS?.packageId,
    name: createProfile,
    isForKids: forKid,
    profileUrl: image,
  };

  const handleInputFieldChange = (e) => {
    setCreateProfile(e.target.value);
  };
  useEffect(() => {
    //console.log(createProfile)
  }, [createProfile]);

  const onCreateProfile = (e) => {
    e.preventDefault();
    setCreateProfileError(false);
    if (createProfile == undefined || createProfile.length <= 3) {
      setCreateProfileError(true);
    } else {
      //console.log("dispatch callllinggg" + JSON.stringify(createValues));
      if (AUTH.profiles.length <= noOfProfile) {
        dispatch(CREATE_ACTION.profileCreateStart(createValues));
      } else {
        alert("You are reached maximum profile");
      }

      dispatch(CREATE_ACTION.getProfileListStart({ userId: USER_DETAILS?.id }));
      //alert(JSON.stringify(createValues))
      history.push("/profileList");
    }
  };
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);

  return (
    <div style={{ backgroundColor: "black" }}>
      <h3 style={{ color: "white", textAlign: "center" }}>
        Create New Profile
      </h3>
      <div className="form-form">
        <div>
          <img
            className=" mt-4"
            src={imageUpload}
            width="150px"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20px",
              height: "100px",
            }}
          />
          <div className="overlay">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
                onClick={onOpenModal}
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <CreateProfileModal
                open={open}
                setOpen={setOpen}
                setImageUpload={setImageUpload}
                setImage={setImage}
              />
            </div>
          </div>
        </div>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "1%", borderColor: "yellow" }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            // name="name"
            value={createProfile}
            onChange={handleInputFieldChange}
            error={createProfileError}
            helperText="user name must conatins more than 3 characters"
          />
        </Grid>
        <div>
          <FormGroup style={{ alignItems: "center" }}>
            <FormControlLabel
              label={<b style={{ color: "white" }}>For kids</b>}
              control={<Switch name="isForKids" onChange={handleInputChange} />}
            />
          </FormGroup>
        </div>

        <Grid container justifyContent="center">
          <Button
            variant="contained"
            style={{ textAlign: "center" }}
            onClick={onCreateProfile}
          >
            Save
          </Button>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  AUTH: authSelector,
  PLATFORMID: platformsSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(CreateProfile);

// if (createProfile.name == undefined || createProfile.name.length == 0) {
//   swal("please enter name");
// } else if (createProfile.name.length <= 3) {
//   swal("user name must conatins more  than 3  letters");
// } else {
//   //console.log("dispatch callllinggg" + JSON.stringify(createProfile));

//   dispatch(CREATE_ACTION.profileCreateStart(createProfile));
//   history.push("/");
// }
