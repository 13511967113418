import ACTION_TYPES from "./action.types";

const {
  GET_ADD_DEVICES_START,
  GET_ADD_DEVICES_SUCCESS,
  GET_ADD_DEVICES_FAILED,
  CLEAR_DEVICE_TOCKEN,
  SAVE_DEVICE_TOCKEN,
  SAVE_DEVICE_NAME,
  CLEAR_DEVICE_STATUS,
} = ACTION_TYPES;

const initialState = {
  AddDevice: "narendra",
  deviceToken: null,
  AddDeviceLoading: false,
  deviceTokenStatus: false,
  deviceName: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;
  // deviceTokenStatus = false;

  switch (type) {
    case GET_ADD_DEVICES_START:
      return {
        ...state,
        AddDeviceLoading: true,
        deviceTokenStatus: false,
      };

    case GET_ADD_DEVICES_SUCCESS:
      return {
        ...state,
        AddDevice: payload,
        deviceTokenStatus: true,
        AddDeviceLoading: false,
        errors,
      };

    case GET_ADD_DEVICES_FAILED:
      return {
        ...state,
        deviceTokenStatus: false,
        AddDeviceLoading: false,
        errors: payload.message,
      };

    case SAVE_DEVICE_TOCKEN:
      // alert("reducer manu  123 " + payload);
      state = { ...state, deviceId: "manu", deviceToken: payload };
      // alert("state after saving device token " + JSON.stringify(state));
      return {
        ...state,
        deviceId: "manu",
        deviceToken: payload,
      };
    case SAVE_DEVICE_NAME:
      return {
        ...state,

        deviceName: payload,
      };
    case CLEAR_DEVICE_TOCKEN:
      // alert("hi I am clearing the device");
      return {
        ...state,
        deviceToken: null,
        AddDeviceLoading: false,
      };
    case CLEAR_DEVICE_STATUS:
      // alert("hi I am clearing the device");
      return {
        ...state,
        deviceTokenStatus: false,
      };
    default:
      return state;
  }
};
