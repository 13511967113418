const ACTION_TYPES = {
  

  GET_SEARCH_DATA_START: "GET_SEARCH_DATA_START",
  GET_SEARCH_DATA_SUCCESS: "GET_SEARCH_DATA_SUCCESS",
  GET_SEARCH_DATA_FAILED: "GET_SEARCH_DATA_FAILED",

  GET_SEARCH_SERIES_DATA_FAILED: "GET_SEARCH_SERIES_DATA_FAILED",



 
  
};

export default ACTION_TYPES;
