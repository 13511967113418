/** @format */

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import "./movieScreenDisplayMovieImage.css";
import { newImageIpPath } from "../../App";
import ProgressBarr from "../progress-bar/ProgressBar";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import { configurationSelector } from "../../Redux/DATA-analytics/selector";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import swal from "sweetalert";
import { getMovieDetaisSelector } from "../../Redux/MoviesRedux/selector.js";
import * as VIDEO_ACTION from "../../Redux/VideoRedux/action";
import { data } from "jquery";

const MovieScreenDisplayMovieImage = ({
  item,
  MOVIE_DETAILS,
  CURRENT_PLATFORM_DATA,
  USER_DETAILS,
  AUTHENTICATION,
  CONFIGURATION_DAS,
  PLATFORMS,
  cindex,
  from,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let assetDetails = MOVIE_DETAILS?.movieliveAssetDetails;
  const handleDeleteContinueWatching = () => {
    Swal.fire({
      title: "Are you sure?",
      text:
        item?.type == 1
          ? "Remove from Continue Watching!"
          : "Remove From watches history",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          dataForRemoveContinueWatching: {
            platformId: PLATFORMS?.currentPlatformId,
            profileId: 1,
            userId: USER_DETAILS?.id,
            assetId: Number(item?.assetId),
            categoryId: 2,
            type: "single",
          },
          ServerIp: CONFIGURATION_DAS?.analyticsIp,
        };
        if (item?.type == 1) {
          dispatch(DATA_ANALYTICS.removeContinueWatchingStart(data));
        } else if (item?.type == 2) {
          dispatch(DATA_ANALYTICS.removeWatchesHistoryStart(data));
        }

        swal("Deleted!", {
          icon: "success",
        });
      }
    });
  };

  let thumnailUrl = null;

  const element = assetDetails?.[item?.assetId];

  thumnailUrl = newImageIpPath + element?.thumbnailUrl;

  const handleDisplayMovie = (id) => {
    localStorage.setItem("onmovies", false);

    sessionStorage.setItem("scrollIndex", cindex - 1);
    if (element != undefined && element != null) {
      dispatch(VIDEO_ACTION.getMovieData(element));
    } else {
      let requestData = {
        id: id,
        account_id: 1,
      };

      dispatch(VIDEO_ACTION.getMovieDetailsStart(requestData));
    }
    history.push({
      pathname: "/MovieDetailsPage",
      state: {
        from: from,
      },
    });
  };

  // console.log("arunvn1" + JSON.stringify(item));
  // console.log("arunvn2" + JSON.stringify(element));
  return (
    <div>
      {item?.active == 1 && element != undefined && (
        <div className="favorites-contens p-0" style={{ cursor: "pointer" }}>
          <ul className=" list-inline   p-0 mb-0 iq-rtl-direction">
            <li className="slide-item mt-3">
              <div
                className="block-images position-relative"
                onClick={() => handleDisplayMovie(item?.assetId)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {/* Close button */}
                {isHovered &&
                  item?.isContineuwatching !== undefined &&
                  item?.isContineuwatching !== null && (
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: "250",
                      }}
                      title={
                        item?.type == 1
                          ? "Remove From Now"
                          : "Remove From watch history"
                      }
                      // Add a title attribute for the tooltip
                      onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation

                        handleDeleteContinueWatching(); // Call handleClick function
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="35px"
                        height="35px"
                        className="highlight-svg" // Add a class name to the SVG for styling
                        title="Remove From Now" // Add a title attribute for the tooltip
                        style={{ backgroundColor: "white" }} // Set background color to white
                      >
                        <path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z" />
                      </svg>
                    </div>
                  )}{" "}
                <div
                  className="img-box"
                  style={{
                    position: "relative",

                    boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.5)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    backdropFilter: "blur(5px)",
                    objectFit: "cover",
                  }}
                >
                  <img
                    src={thumnailUrl}
                    style={{
                      width: "300px",
                      height: "180px",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      display: "block",
                      margin: "auto auto",
                    }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                {/* Button or div with three dots (ellipsis) */}
                {item?.isPurchased == true && (
                  <svg
                    style={{
                      alignSelf: "flex-end",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      padding: "5px",
                      color: "#FFD700",
                      // border: "1px solid #000",
                      // borderRadius: "1px",
                    }}
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50px"
                    height="50px"
                    viewBox="0 0 1280.000000 815.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,815.000000) scale(0.100000,-0.100000)"
                      fill="#FFD700"
                      stroke="none"
                    >
                      <path
                        d="M6224 8131 c-137 -37 -202 -83 -331 -229 -139 -159 -190 -310 -179
-527 9 -184 62 -316 185 -461 38 -44 91 -97 118 -117 55 -40 169 -97 195 -97
9 0 19 -4 22 -9 10 -16 -743 -2610 -779 -2686 -48 -100 -88 -150 -141 -176
-41 -19 -50 -20 -86 -10 -55 17 -124 88 -185 191 -27 47 -343 465 -702 929
l-652 845 46 39 c209 179 315 387 315 617 0 172 -47 303 -159 442 -132 164
-238 240 -389 279 -133 34 -263 18 -402 -49 -58 -28 -93 -55 -159 -122 -136
-139 -209 -256 -242 -390 -17 -71 -17 -249 0 -320 19 -77 81 -207 132 -276
116 -158 250 -254 404 -291 39 -9 71 -17 72 -18 3 -2 -194 -1964 -203 -2020
-12 -74 -54 -192 -84 -233 -75 -104 -178 -97 -335 23 -38 29 -385 259 -770
510 -385 251 -706 463 -713 470 -11 10 -8 21 22 63 142 197 175 498 79 726
-83 199 -274 374 -468 432 -73 21 -217 24 -295 5 -30 -7 -93 -31 -140 -53 -71
-35 -100 -56 -180 -137 -74 -74 -105 -115 -137 -176 -68 -131 -78 -178 -78
-355 0 -135 3 -165 24 -230 98 -314 354 -513 661 -513 109 -1 171 15 268 68
35 20 65 35 67 33 5 -7 275 -516 383 -723 327 -629 481 -1071 562 -1610 6 -38
13 -82 16 -98 l6 -27 4398 0 4397 0 7 52 c12 95 76 400 112 535 77 294 201
611 374 962 103 209 458 890 471 905 4 5 21 -1 37 -13 80 -56 244 -98 346 -87
174 20 302 81 426 206 47 47 100 111 119 142 197 336 129 725 -172 978 -77 65
-183 121 -267 141 -71 17 -200 17 -270 0 -127 -31 -278 -131 -375 -249 -124
-150 -172 -298 -162 -504 7 -163 52 -301 134 -416 25 -36 30 -49 20 -58 -6 -6
-330 -218 -718 -471 -388 -254 -737 -485 -775 -514 -89 -67 -137 -89 -200 -89
-94 0 -157 69 -194 214 -14 57 -48 371 -115 1089 -52 555 -95 1013 -95 1018 0
5 7 9 14 9 38 0 179 54 233 89 118 76 246 231 299 363 69 168 72 395 7 558
-39 98 -87 165 -193 271 -107 107 -188 155 -315 185 -135 31 -299 2 -432 -78
-70 -42 -202 -174 -258 -258 -147 -223 -146 -563 4 -792 49 -76 137 -171 206
-225 l40 -30 -31 -39 c-288 -365 -1292 -1681 -1329 -1743 -56 -93 -138 -175
-185 -184 -77 -16 -158 60 -216 203 -12 30 -76 240 -142 465 -66 226 -238 810
-382 1300 -143 489 -258 895 -256 902 3 7 12 13 20 13 7 0 51 18 96 41 100 50
237 180 294 279 116 199 139 467 59 670 -74 188 -263 377 -432 431 -96 31
-271 36 -367 10z"
                      />
                      <path d="M1990 660 l0 -660 4395 0 4395 0 2 660 3 660 -4397 0 -4398 0 0 -660z" />
                    </g>
                  </svg>
                )}
                <div className="block-description">
                  <div
                    className="iq-title"
                    style={{ fontFamily: "Montserrat,sans-serif" }}
                  >
                    {/* <h6>{title}</h6> */}
                  </div>
                </div>
                <div
                  style={{
                    zIndex: 200,
                    position: "absolute",
                    left: "3%",
                    right: "3%",
                    bottom: 2,
                    width: "94%",
                    height: "5%",
                  }}
                >
                  {item?.isContineuwatching != undefined &&
                  item?.isContineuwatching != null &&
                  AUTHENTICATION === true &&
                  Number(CURRENT_PLATFORM_DATA?.viewing_analytics_enabled) ==
                    1 &&
                  Number(USER_DETAILS?.viewing_analytics_status) == 1 ? (
                    <ProgressBarr completed={item?.isContineuwatching} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  MOVIE_DETAILS: getMovieDetaisSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  CONFIGURATION_DAS: configurationSelector,
  PLATFORMS: platformsSelector,
});

export default connect(mapStateToProps)(MovieScreenDisplayMovieImage);
