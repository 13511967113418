/** @format */

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getSearchDataSelector,
  getSearchLoadingSelector,
} from "../../Redux/Search/selectors";
import NewLoadingPage from "../NewLoadingPage";
import SearchDisplayImage from "./SearchDisplayImage";
import SearchMessagePage from "./SearchMessagePage";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import * as CHECK_DEVICES from "./../../Redux/Delete Device/actions";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { newImageIpPath } from "../../App";
import { getAllAssetsIdSelector } from "../../Redux/AllLiveDetails/selectors";

const SearchPage = ({
  SEARCH_DATA,
  SEARCH_LOADING,

  CURRENT_PLATFORM,

  searchKey,
  setSearchState,
  PLATFORM_ID,
  DEVICE_NAME,
  DEVICE_TOKEN,
  setClearSearchState,
  ASSET_IDs_BASED_ON_LCN,
}) => {
  let backgroundUrl = newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage;
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";
  }, []);
  // console.log(
  //   "ASSET_IDs_BASED_ON_LCN" + JSON.stringify(ASSET_IDs_BASED_ON_LCN)
  // );
  // console.log("ASSET_IDs_BASED_ON_LCN" + JSON.stringify(SEARCH_DATA?.data));

  const filteredData = SEARCH_DATA?.data;
  // console.log("ASSET_IDs_BASED_ON_LCN" + JSON.stringify(filteredData));
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 901,
        backgroundImage: `url(${backgroundUrl})`,
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      {SEARCH_LOADING == false ? (
        <div style={{ marginTop: "100px" }}>
          <div className="row col-lg-12">
            <div
              className="col-lg-2"
              style={{
                color: "white",
                fontSize: "34",
                float: "right",
                cursor: "pointer",
                fontFamily: "Montserrat,sans-serif",
              }}
              onClick={() => setSearchState(false)}
            >
              Filter : {searchKey}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x-circle"
                color="red"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  setClearSearchState(true);
                }}
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
            </div>
            <div
              className="col-lg-10"
              style={{
                color: "white",
                fontSize: "20px",
                fontFamily: "Montserrat,sans-serif",
              }}
            ></div>
          </div>

          {SEARCH_DATA != undefined &&
          SEARCH_DATA != null &&
          SEARCH_DATA.data != undefined &&
          SEARCH_DATA.data != null &&
          SEARCH_DATA.data.length > 0 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "black",
                position: "relative",

                marginLeft: "10px",
                top: 0,
                right: 0,
                zIndex: 901,
                backgroundColor: "transparent",
              }}
            >
              {filteredData.map((item, index) => {
                return (
                  <div style={{ display: "inline-block" }}>
                    <SearchDisplayImage
                      key={index}
                      item={item}
                      searchKey={searchKey}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <SearchMessagePage searchKey={searchKey} />
          )}
        </div>
      ) : (
        <div style={{ marginTop: "6%" }}>
          <NewLoadingPage />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  SEARCH_DATA: getSearchDataSelector,
  SEARCH_LOADING: getSearchLoadingSelector,

  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  PLATFORM_ID: platformsSelector,
  ASSET_IDs_BASED_ON_LCN: getAllAssetsIdSelector,
});
export default connect(mapStateToProps)(SearchPage);
