/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import { nodeimprovisationServerPath } from "../../components/IpConstantFile/IpConstantFile";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");
export const liveAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the payload.id
    var ciphertext = encryptData(payload?.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    // Make the POST request with the encrypted id
    const response = await axiosInstance().post(
      "/live/" + urlSafeCiphertext,
      payload,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};

export const allLiveAssetsAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post(nodeimprovisationServerPath + "/genre/liveDetails", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const allLiveAssetsBasedOnKeyAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  // alert("service" + JSON.stringify(payload));

  return await axiosInstance()
    .post(nodeimprovisationServerPath + "/liveAssetsBasedOnkey", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const getGeneralDetailsAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post(
      nodeimprovisationServerPath + "/generalDetails",
      {},
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const signupAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post("/login", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      Promise.reject(error.response.data);
    });
};

export const bannerAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post("/bannerDetailsV2", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      Promise.reject(error.response.data);
    });
};

export const logoutAsync = async () => {
  return await axiosInstance()
    .post("/logout")
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};

export const generalDataAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();
  let data = { account_id: 0 };
  // console.log("payloadARUN" + JSON.stringify(data));

  return await axiosInstance()
    .post("/generalsettings", data, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      // console.log(
      //   "arun response.generalsettings" + JSON.stringify(response.data.message)
      // );

      return response.data;
    })
    .catch((error) => {
      Promise.reject(error.response.data);
    });
};
