/** @format */

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as ACTION_TYPE from "./../../Redux/resetPassword/action";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getActiveStateSelector,
  getActiveUserIdSelector,
} from "../../Redux/email-activation/selector";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import { useHistory } from "react-router-dom";
import { resetPasswordStateSelector } from "../../Redux/resetPassword/selector";
import * as ACTION_TYPES from "./../../Redux/platforms/actions";
import * as CURRENT_PLATFORM_ACTION from "./../../Redux/platforms/actions";
import { generalSettingsSelector } from "../../Redux/live/selectors";
import * as ACTION from "./../../Redux/email-activation/action";
import EmailLoadingPage from "./EmailLoadingPage";
import "./resetPassword.css";
import * as LIVE_ACTION from "./../../Redux/live/actions";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import "@sweetalert2/themes/dark/dark.scss";
import { getDeviceDetailsSelector } from "../../Redux/deviceRedux/selectors";
import * as MAIL_VALIDITY from "./../../Redux/Checking the mail validity/actions";
import * as DEFAULT_PLATFORM from "./../../Redux/DefaultPlatform/actions";

import {
  getCheckingMailValiditySelector,
  getMailLoadingSelector,
} from "../../Redux/Checking the mail validity/selectors";
import NewLoadingPage from "../NewLoadingPage";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import * as AUTHENTICATION_ACTIONS from "./../../Redux/authentication/actions";

function ResetPasswordPage({
  USERID,
  PLATFORM_ID,
  RESET_STATUS,
  GENERAL_SETTINGS_DATA,
  DEVICE_DETAILS,
  ACTIVE_STATE,
  CHECK_MAIL_VALIDITY_DATA,
  ACTIVATED_ID,
  MAIL_LOADING,
  CURRENT_PLATFORM,

  USER_DETAILS,
  AUTHENTICATION,
}) {
  // alert(window.onload);

  const [brandBackgroundImage, setBrandBackgroundImage] = useState(
    newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage
  );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  var userId;
  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    userId = url.searchParams.get("userId");
    dispatch(MAIL_VALIDITY.checkingMailValidityStart(userId));
    // dispatch(ACTION.activeEmailStart({ id: userId }));
    dispatch(ACTION.getActiveUserId(userId));
    // alert(userId);
  }, []);
  useEffect(() => {
    if (CHECK_MAIL_VALIDITY_DATA === true) {
      dispatch(ACTION.activeEmailStart({ id: ACTIVATED_ID }));
    }
  }, [CHECK_MAIL_VALIDITY_DATA]);
  // window.onload = function() {
  //   alert("hello");
  //   try {
  //     var url_string = window.location.href;
  //     var url = new URL(url_string);
  //     userId = url.searchParams.get("userId");

  //     //console.log(" and " + userId);
  //   } catch (err) {
  //     //console.log("Issues with Parsing URL Parameter's - " + err);
  //   }
  //   // dispatch(MAIL_VALIDITY.checkingMailValidityStart(userId)); **
  //   alert("hiii");
  //   dispatch(ACTION.activeEmailStart({ id: userId }));
  //   dispatch(ACTION.getActiveUserId(userId));
  // };
  useEffect(() => {
    dispatch(DEFAULT_PLATFORM.getDefaultPlatformsListStart());
  }, []);

  useEffect(() => {
    dispatch(ACTION_TYPES.getPlatformsListStart());
    dispatch(LIVE_ACTION.getGeneralSettingsStart({ account_id: 0 }));
  }, []);

  useEffect(() => {
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      AUTHENTICATION != undefined &&
      AUTHENTICATION != null
    ) {
      dispatch(AUTHENTICATION_ACTIONS.loginStausFalse());
    }
  }, []);

  const handleValidation = (event) => {
    let formIsValid = true;
    // if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
    //   formIsValid = false;
    //   setemailError("Email Not Valid");
    //   return false;
    // } else {
    //   setemailError("");
    //   formIsValid = true;
    // }

    if (!newPassword.match(/^[a-zA-Z]{8,22}$/)) {
      formIsValid = false;
      setpasswordError(
        "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
      );
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };
  let data = {
    id: USERID,
    updatedBy: USERID,
    account_id: PLATFORM_ID.currentPlatformId,
    password: newPassword,
  };

  const loginSubmit = (e) => {
    let platformData;
    if (
      PLATFORM_ID?.platforms != undefined &&
      PLATFORM_ID?.platforms != null &&
      PLATFORM_ID?.platforms?.length > 0
    ) {
      platformData = PLATFORM_ID?.platforms?.filter(
        ({ status }) => status == 1
      );
    }

    let currentplatformData = null;
    let currentplatformData1 = null;

    if (GENERAL_SETTINGS_DATA.defaultPlatform == 0) {
      currentplatformData = platformData[platformData.length - 1];
      dispatch(
        CURRENT_PLATFORM_ACTION.getSelectedPlatform(currentplatformData)
      );
      dispatch(
        CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(
          currentplatformData?.orgId
        )
      );
    } else {
      currentplatformData1 = platformData?.filter((item, index) => {
        // alert(
        //   "false" + index + item.orgId + GENERAL_SETTINGS_DATA.defaultPlatform
        // );
        if (item.orgId == GENERAL_SETTINGS_DATA.defaultPlatform) {
          // alert("true");
          return item;
        }
      });
      dispatch(
        CURRENT_PLATFORM_ACTION.getSelectedPlatform(currentplatformData1?.[0])
      );

      dispatch(
        CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(
          currentplatformData1?.[0]?.orgId
        )
      );
    }

    e.preventDefault();
    // alert(JSON.stringify(data))

    if (newPassword?.trim().length != 0) {
      var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
      var result = pattern.test(newPassword);
      if (result == true) {
        if (confirmPassword?.trim().length != 0) {
          if (newPassword == confirmPassword) {
            dispatch(
              ACTION_TYPE.resetPasswordStart({ data: data, history: history })
            );
            // window.location.href =  "//login";
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Password doesn't match.",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text: "Please enter confirm password.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "Password must contain at least one number, one uppercase ,one lowercase letter, one special character, and at least six or more characters",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please enter new password.",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }
    // Lost your password? Please enter your email address. You will receive a link to create a new password via email.
    // if(RESET_STATUS == true){
    // history.push("/login");
    // }
    handleValidation();
  };
  //console.log(oldPassword, newPassword);
  const handleClick = () => {
    history.push("/login");
  };
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  return MAIL_LOADING == false ? (
    CHECK_MAIL_VALIDITY_DATA == true ? (
      <div
        className="main-content p-0 iq-favorites pt-5 col-lg-12"
        style={{
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(${brandBackgroundImage})`,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "fixed",
        }}
      >
        <div
          className="container justify-content-center col-lg-6 pageP"
          style={{
            width: "50%",
            height: "60%",
            backgroundColor: "rgba(52, 53, 53, 0.9)",

            borderRadius: "15px",
          }}
        >
          <div className="row d-flex justify-content-center col-lg-12">
            <div
              className="col-lg-12 row mt-4"
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 24 24"
                  fill="none"
                  color="white"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-lock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "34px",
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                Reset Password.
              </div>
            </div>
            <div
              className="col-lg-12 mt-5"
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                width: "100%",
                display: "flex",
              }}
            >
              <div>
                {" "}
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <input
                    type={passwordType}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    onChange={(event) => setNewPassword(event.target.value)}
                    style={{
                      borderRadius: 0,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                      borderColor: "white",
                    }}
                    maxLength="25"
                  />
                  <div
                    style={{
                      backgroundColor: "white",
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderColor: "white",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      paddingBottom: 5,
                      paddingRight: 10,
                    }}
                  >
                    {passwordType === "password" ? (
                      <i
                        className="bi bi-eye-slash"
                        style={{ color: "black", float: "right" }}
                        onClick={togglePassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye"
                        onClick={togglePassword}
                        style={{ color: "black", float: "right" }}
                      ></i>
                    )}
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <input
                    type={confirmPasswordType}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Confirm password"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    style={{
                      borderRadius: 0,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                      borderColor: "white",
                    }}
                    maxLength="25"
                  />
                  <div
                    style={{
                      backgroundColor: "white",
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderColor: "white",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      paddingBottom: 5,
                      paddingRight: 10,
                    }}
                  >
                    {confirmPasswordType === "password" ? (
                      <i
                        className="bi bi-eye-slash"
                        style={{ color: "black", float: "right" }}
                        onClick={toggleConfirmPassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye"
                        onClick={toggleConfirmPassword}
                        style={{ color: "black", float: "right" }}
                      ></i>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-danger mt-3 "
                  style={{ marginLeft: "35%" }}
                  onClick={loginSubmit}
                >
                  Submit
                </button>
                <div
                  className="mt-3"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {/* <a
                  href="//login"
                  // style={{ marginLeft: "100px", marginTop: "80px" }}
                >
                  Skip
                </a> */}
                </div>
              </div>
            </div>
            {/* <div className="mt-4">
            <div className=""></div>
            
            <div
              style={{
                width: 250,
                display: "flex"
              }}
            >
              <input
                type={passwordType}
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                onChange={event => setNewPassword(event.target.value)}
                style={{
                  borderRadius: 0,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  borderColor: "white"
                }}
              />
              <div
                style={{
                  backgroundColor: "white",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  borderColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingBottom: 5,
                  paddingRight: 10
                }}
              >
                {passwordType === "password" ? (
                  <i
                    className="bi bi-eye-slash"
                    style={{ color: "black", float: "right" }}
                    onClick={togglePassword}
                  ></i>
                ) : (
                  <i
                    className="bi bi-eye"
                    onClick={togglePassword}
                    style={{ color: "black", float: "right" }}
                  ></i>
                )}
              </div>
            </div>
            <div
              className="mt-3"
              style={{
                width: 250,
                display: "flex"
              }}
            >
              <input
                type={confirmPasswordType}
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Confirm password"
                onChange={event => setConfirmPassword(event.target.value)}
                style={{
                  borderRadius: 0,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  borderColor: "white"
                }}
              />
              <div
                style={{
                  backgroundColor: "white",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  borderColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingBottom: 5,
                  paddingRight: 10
                }}
              >
                {confirmPasswordType === "password" ? (
                  <i
                    className="bi bi-eye-slash"
                    style={{ color: "black", float: "right" }}
                    onClick={toggleConfirmPassword}
                  ></i>
                ) : (
                  <i
                    className="bi bi-eye"
                    onClick={toggleConfirmPassword}
                    style={{ color: "black", float: "right" }}
                  ></i>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-3 "
              style={{ marginLeft: "80px" }}
              onClick={loginSubmit}
            >
              Submit
            </button>
            <a
              href="//login"
              style={{ marginLeft: "100px", marginTop: "80px" }}
            >
              Skip
            </a>
          
          </div> */}
          </div>
        </div>
      </div>
    ) : (
      <div
        className="col-12 "
        style={{
          // height: "100%",
          justifyContent: "center",
          alignItems: "center",
          // display: "flex",
          marginTop: "15%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            display: "flex",
          }}
        >
          <img src="/images/warning1.png" height={80} />
        </div>
        <div className="mt-5">
          <h3 style={{ textAlign: "center", color: "white" }}>
            User Activation Link has expired. Reach out to the Admin
          </h3>
        </div>
      </div>
    )
  ) : (
    <div style={{ marginTop: "7%" }}>
      <NewLoadingPage />
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  USERID: getActiveUserIdSelector,
  PLATFORM_ID: platformsSelector,
  RESET_STATUS: resetPasswordStateSelector,
  GENERAL_SETTINGS_DATA: generalSettingsSelector,
  ACTIVE_STATE: getActiveStateSelector,
  DEVICE_DETAILS: getDeviceDetailsSelector,
  CHECK_MAIL_VALIDITY_DATA: getCheckingMailValiditySelector,
  ACTIVATED_ID: getActiveUserIdSelector,
  MAIL_LOADING: getMailLoadingSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});
export default connect(mapStateToProps)(ResetPasswordPage);
