import { createSelector } from "reselect";
const getEpgDetails = (state) => {
  return state.epg.epgDetails;
};
const getEpgDate = (state) => {
  return state.epg.epgDate;
};
const getEpgApiDate = (state) => {
  return state.epg.epgApiDate;
};
const getEpgLoading = (state) => {
  return state.epg.epgProccesing;
};
const getEpgState = (state) => state.epg;
const getEpgCatchup = (state) => {
  return state.epg.epgCatchup;
};

export const epgSelector = createSelector(
  getEpgDetails,
  (epgDetails) => epgDetails
);

export const getEpgLoadingSelector = createSelector(
  getEpgLoading,
  (epgProccesing) => epgProccesing
);

export const epgDateSelector = createSelector(getEpgDate, (epgDate) => epgDate);
export const epgApiDateSelector = createSelector(
  getEpgApiDate,
  (epgApiDate) => epgApiDate
);

export const epgStateSelector = createSelector(getEpgState, (epg) => epg);
export const getEpgCatchupSelector = createSelector(
  getEpgCatchup,
  (epgCatchup) => epgCatchup
);

const getEpgLoadingStatus = (state) => {
  return state.epg.epgLoadingStatus;
};

export const getEpgLoadingStatusSelector = createSelector(
  getEpgLoadingStatus,
  (epgLoadingStatus) => epgLoadingStatus
);
