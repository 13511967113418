import { createSelector } from "reselect";
const getNotifications = (state) => state.Notification.notifications;
const getCurrentNotifications = (state) =>
  state.Notification.currentNotifications;

export const getNotificationsSelector = createSelector(
  getNotifications,
  (notifications) => notifications
);

export const getCurrentNotificationsSelector = createSelector(
  getCurrentNotifications,
  (currentNotifications) => currentNotifications
);
