import React from "react";
import { Modal } from "react-responsive-modal";
import { Alert, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
const LandingScreenAlert = ({ setOpen, open }) => {
  // import './examples/custom-styling.css';
  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const handleLogin = () => {
    history.push("/login");
  };
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x"
      color="white"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
  return (
    <>
      <Modal
        closeIcon={closeIcon}
        open={open}
        onClose={() => setOpen(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        {/* <Stack sx={{backgroundColor:"black"}}>
            
       <Alert severity="warning"> You are Not Subscribed</Alert>
       </Stack> */}

        {/* <span style={{ alignItems: "center", justifyContent: "center" }}>
          <span style={{}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              fill="none"
              color="red"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-alert-triangle"
            >
              <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              <line x1="12" y1="9" x2="12" y2="13"></line>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
          </span>
          <div
            style={{
              // backgroundColor: "red",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <span
              style={{
                marginLeft: "30px",
                color: "white",
                fontSize: "16px",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {" "}
              You are not Signed in.{" "}
            </span>
            <pre
              style={{
                marginLeft: "30px",
                color: "white",
                fontSize: "16px",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {" "}
              Please Sign in to continue watching the video
            </pre>

            <a
              href=""
              style={{ color: "red", float: "right" }}
              onClick={handleLogin}
            >
              Sign
            </a>
          </div>
        </span> */}

        <div className="col-12">
          <div className="col-12 row">
            <div className="col-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                color="red"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-alert-triangle"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </div>
            <div
              className="col-9"
              style={{
                // backgroundColor: "blue",
                alignItems: "center",
                justifyContent: "center",
                // display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              >
                {" "}
                You are not Signed in.
              </div>
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              >
                Please Sign in to make it as favourite.
              </div>
              <a
                href=""
                style={{ color: "red", float: "right" }}
                onClick={handleLogin}
              >
                Sign
              </a>
              {/* <div
                className="col-9"
                style={{
                  color: "white",
                  fontSize: "26px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              >
                You are not Signed in.
              </div>
              <div
                className="col-9"
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              >
                Please Sign in to continue watching the video
              </div> */}
            </div>
          </div>
        </div>

        {/* <button onClick={handleClose} style={{color:'white'}}>ok</button> */}
      </Modal>
    </>
  );
};

export default LandingScreenAlert;
