import { createSelector } from "reselect";
const getVideoAds = (state) => {
  // alert(JSON.stringify(state.VideoAds))
  return state.VideoAds.videoAdverticement;
};

// const getVideoGenreAssociation = (state) =>{

//   return state.VideoAds.videoGenreAssociation;}

//   const getVideoPackageAssociation = (state) =>{

//     return state.VideoAds.videoPackageAssociation;}

export const getVideoAdsSelector = createSelector(
  getVideoAds,
  (videoAdverticement) => videoAdverticement
);

// export const getVideoGenreAssociationSelector = createSelector(
//   getVideoGenreAssociation,
//   (videoGenreAssociation) => videoGenreAssociation
// );

// export const getVideoPackageAssociationSelector = createSelector(
//   getVideoPackageAssociation,
//   (videoPackageAssociation) => videoPackageAssociation
// );
