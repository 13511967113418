/** @format */

import ACTION_TYPES from "./actionType";
const {
  GET_MOVIES_DATA_START,
  GET_MOVIES_DATA_SUCCESS,
  GET_MOVIES_DATA_FAILED,
  GET_MOVIE_GENRE_ASSETS_FOR_LISTING,
} = ACTION_TYPES;

export const getMoviesDataStart = (payload) => ({
  type: GET_MOVIES_DATA_START,
  payload,
});

export const getMoviesDataSuccess = (payload) => ({
  type: GET_MOVIES_DATA_SUCCESS,
  payload,
});

export const getMoviesDataFailed = (payload) => ({
  type: GET_MOVIES_DATA_FAILED,
  payload,
});


export const getMovieGenreAssetsForListing = (payload) => ({
  type: GET_MOVIE_GENRE_ASSETS_FOR_LISTING,
  payload,
});
