import React from 'react'
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const EmailLoadingPage = () => {
  return (
    <div style={{color:"white"}}>
            <FadeLoader color="white" loading={true} css={override} size={100} />

    </div>
  )
}

export default EmailLoadingPage