/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");

// export const liveChannelAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .post("/live/" + payload.id, payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response.data);
//     });
// };
export const liveChannelAsync = async (payload) => {
  try {
    const ConstantHeaders = HeadersAcessToken();

    // Encrypt the payload.id
    var ciphertext = encryptData(payload.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().post(
      "/live/" + urlSafeCiphertext, // Use the encrypted id in the URL
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};
export const MovieDataAsync = async (payload) => {
  try {
    // Encrypt the payload.id
    var ciphertext = encryptData(payload?.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const ConstantHeaders = HeadersAcessToken();

    // Use the encrypted id in the URL
    const response = await axiosInstance().post(
      "/vod/" + urlSafeCiphertext,
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// export const SeriesDataAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .post("/series/" + payload.id, payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response.data);
//     });
// };

export const SeriesDataAsync = async (payload) => {
  try {
    const ConstantHeaders = HeadersAcessToken();
    var ciphertext = encryptData(payload?.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().post(
      "/series/" + urlSafeCiphertext,
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};
