import ACTION_TYPES from "./actionTypes";
const {
  GET_PACKAGE_DETAILS_FAILED,
  GET_PACKAGE_DETAILS_START,
  GET_PACKAGE_DETAILS_SUCCESS,
  MAKE_PACKAGE_DETAILS_EMPTY,
} = ACTION_TYPES;

const initialState = {
  packageDetails: {},
  alacarteDetails: {},
  payPerViewDetails: {},

  payPerViewAssetDetails: [],
  packageOrderedData: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PACKAGE_DETAILS_START:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        // packageDetails: {},
        // alacarteDetails: {},
        // payPerViewDetails: {},
        // payPerViewAssetDetails: {},
      };
    case GET_PACKAGE_DETAILS_SUCCESS:
      console.log("arunppvArray" + JSON.stringify(payload?.data?.ppvArray));

      // Add `active` and `isPurchased` to each item in `ppvArray`
      const updatedPpvArray = payload?.data?.ppvArray.map((item) => ({
        ...item,
        active: 1,
        isPurchased: true,
      }));

      return {
        ...state,
        packageDetails: payload?.data?.packages,
        alacarteDetails: payload?.data?.alacarteAssets,
        payPerViewDetails: payload?.data?.PPV,
        payPerViewAssetDetails: updatedPpvArray, // Use the updated array here
        packageOrderedData: payload?.data?.orderedArray,
      };

    case MAKE_PACKAGE_DETAILS_EMPTY:
      return {
        ...state,
        packageDetails: {},
        alacarteDetails: {},
        payPerViewDetails: {},
        payPerViewAssetDetails: {},
        packageOrderedData: [],
      };
    case GET_PACKAGE_DETAILS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
