import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
import * as ACTION from "./actions";
import * as LIVE_ACTION from "./../live/actions";
import * as CURRENT_PLATFORM_ACTION from "./../platforms/actions";
import * as EPG_ACTION from "./../EPG/action";
import * as MOVIE_ACTION from "./../MoviesRedux/actions";
import * as SERIAS_GENRE_ACTION from "./../SeriesGenre/actions";
import * as PLATFORMS_API from "./../../services/platforms/DefaultPlatform";
// import { Alert } from "react-native";
const {
  GET_DEFAULT_PLATFORMS_LIST_START,
  GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START,
} = ACTION_TYPES;

function* getDefaultPlatformsListSaga() {
  try {
    const { data } = yield call(PLATFORMS_API.getDefaultPlatformsListAsync);
    //console.log("default platform data       ", JSON.stringify(data.data));

    if (data.status === true) {
      yield put(CURRENT_PLATFORM_ACTION.getSelectedPlatform(data.data));
      yield put(CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(data.data.orgId));

      // yield put(
      //   MOVIE_ACTION.getMoviesDataStart({ account_id: data.data.orgId })
      // );
      // yield put(
      //   SERIAS_GENRE_ACTION.getSriesGenresStart({ account_id: data.data.orgId })
      // );

      yield put(ACTION.getDefaultPlatformsListSuccess({ data }));
      let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
      var arr2 = todayDate.split("/");

      let programMonth = arr2[1];
      let programmeDate = arr2[2];
      if (parseInt(programMonth) <= 9) {
        programMonth = "0" + programMonth;
      }
      if (parseInt(programmeDate) <= 9) {
        programmeDate = "0" + programmeDate;
      }
      // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

      let date = arr2[0] + "-" + programMonth + "-" + programmeDate;

      let startTimeStamp = new Date(
        programMonth + "/" + programmeDate + "/" + arr2[0]
      ).setHours(0, 0, 0, 0);
      let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
      let data1 = {
        startTimeTimestamp: startTimeStamp,
        stopTimeTimestamp: stopTimeStamp,
        account_id: data.data.orgId,
        offset: 0,
        limit: 1000,
        a: false,
        programmeDate: date,
      };

      yield put(EPG_ACTION.getEpgDetailsStart(data1));
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.getDefaultPlatformsListFailed({ message }));
  }
}
function* getDefaultPlatformsListForRoutingSaga() {
  try {
    const { data } = yield call(PLATFORMS_API.getDefaultPlatformsListAsync);
    //console.log("default platform data       ", JSON.stringify(data.data));

    if (data.status == true) {
      yield put(ACTION.getDefaultPlatformsListForRouteSuccess(data));
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.getDefaultPlatformsListForRouteFailed({ message }));
  }
}

function* getDefaultPlatformsListWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    yield take(GET_DEFAULT_PLATFORMS_LIST_START);
    yield call(getDefaultPlatformsListSaga);
  }
}
function* getDefaultPlatformsListForRouteWatcher() {
  while (true) {
    //console.log("watcher saga is called");
    yield take(GET_DEFAULT_PLATFORMS_LIST_FOR_ROUTE_START);
    yield call(getDefaultPlatformsListForRoutingSaga);
  }
}

export default function*() {
  yield all([
    getDefaultPlatformsListWatcher(),
    getDefaultPlatformsListForRouteWatcher(),
  ]);
}
