import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
import store from "./../../Redux/store.js";
import * as activeAccessTokenService from "./../activeAccessToken/activeAccessToken.js";
import * as AUTH_ACTION from "./../../Redux/authentication/actions.js";
var qs = require("qs");

// export const addFavouritesDetailsAsync = async (payload) => {
//   let access_data = store.getState();
//   let access_token =
//     access_data?.authenticationReducer?.loginDetails?.access_token;
//   let refresh_token =
//     access_data?.authenticationReducer?.loginDetails?.refresh_token;
//   try {
//     const response = await axiosInstance().post("/getfavouritesV1", payload, {
//       headers: HeadersAcessToken(access_token),
//     });
//     return response.data;
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data &&
//       error.response.data.auth === false
//     ) {
//       try {
//         const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
//           {
//             refreshToken: refresh_token,
//           }
//         );
//         await store.dispatch(
//           AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
//         );
//         const newAccessToken = newAccessTokenResponse.data.accessToken;

//         const response = await axiosInstance().post(
//           "/getfavouritesV1",
//           payload,
//           {
//             headers: HeadersAcessToken(newAccessToken),
//           }
//         );

//         return response.data;
//       } catch (error) {
//         //alert("something went wrong while refreshing token");
//         throw error;
//       }
//     } else {
//       throw error.response.data;
//     }
//   }
// };
export const addFavouritesDetailsAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/getfavouritesV1", payload);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};
