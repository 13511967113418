const ACTION_TYPES = {
  CONFIGURATION_START: "CONFIGURATION_START",
  CONFIGURATION_SUCCESS: "CONFIGURATION_SUCCESS",
  CONFIGURATION_FAILED: "CONFIGURATION_FAILED",

  SENDING_DATA_TO_DAS_START: "SENDING_DATA_TO_DAS_START",
  SENDING_DATA_TO_DAS_SUCCESS: "SENDING_DATA_TO_DAS_SUCCESS",
  SENDING_DATA_TO_DAS_FAILED: "SENDING_DATA_TO_DAS_FAILED",

  // /continue/watching
  FETCING_DATA_FOR_CONTINUE_WATCHING_START:
    "FETCING_DATA_FOR_CONTINUE_WATCHING_START",
  FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS:
    "FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS",
  FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED:
    "FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED",
  FETCHING_PLAYER_DATA_SUCCESS: "FETCHING_PLAYER_DATA_SUCCESS",

  // /watchesHistory
  FETCING_DATA_FOR_WATCHED_HISTORY_START:
    "FETCING_DATA_FOR_WATCHED_HISTORY_START",
  FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS:
    "FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS",
  FETCING_DATA_FOR_WATCHED_HISTORY_FAILED:
    "FETCING_DATA_FOR_WATCHED_HISTORY_FAILED",

  // /remove/continueWatching
  REMOVE_CONTINUE_WATCHING_HISTORY_START:
    "REMOVE_CONTINUE_WATCHING_HISTORY_START",
  REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS:
    "REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS",
  REMOVE_CONTINUE_WATCHING_HISTORY_FAILED:
    "REMOVE_CONTINUE_WATCHING_HISTORY_FAILED",
  CLEAR_CONTINUE_WATCHING: "CLEAR_CONTINUE_WATCHING",

  // /remove/WatchesHistory

  REMOVE_WATCHES_HISTORY_START: "REMOVE_WATCHES_HISTORY_START",
  REMOVE_WATCHES_HISTORY_SUCCESS: "REMOVE_WATCHES_HISTORY_SUCCESS",
  REMOVE_WATCHES_HISTORY_FAILED: "REMOVE_WATCHES_HISTORY_FAILED",
  CLEAR_WATCHED_HISTORY: "CLEAR_WATCHED_HISTORY",
  // viewAnalyticsStatus

  VIEW_ANALYTICS_STATUS_START: "VIEW_ANALYTICS_STATUS_START",
  VIEW_ANALYTICS_STATUS_SUCCESS: "VIEW_ANALYTICS_STATUS_SUCCESS",
  VIEW_ANALYTICS_STATUS_FAILED: "VIEW_ANALYTICS_STATUS_FAILED",
};

export default ACTION_TYPES;
