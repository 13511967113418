import ACTION_TYPES from "./action.types";

const {
  GET_DRM_DETAILS_START,
  GET_DRM_DETAILS_SUCCESS,
  GET_DRM_DETAILS_FAILED
} = ACTION_TYPES;

const initialState = {
  drmDetails: [],
  isLoading: false,
  errors: ""
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;

  //console.log("reducer is calling");

  switch (type) {
    case GET_DRM_DETAILS_START:
      return {
        ...state,

        isLoading: true
      };
    case GET_DRM_DETAILS_SUCCESS:
      return {
        ...state,
        drmDetails: payload
      };

    case GET_DRM_DETAILS_FAILED:
      return {
        ...state,
        isLoading,
        errors: payload
      };

    default:
      return state;
  }
};
